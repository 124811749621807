<p-dialog [(visible)]="visible" appendTo="body" [modal]="true" [breakpoints]="{ '960px': '75vw', '640px': '100vw' }" [style]="{ 'max-height': '90vh', 'overflow': 'auto', width: '800px' }" [closable]="false">
  <ng-template #header>
    <div class="flex items-center">
      <span class="flex items-center justify-center text-orange-500 mr-4 rounded-full" style="width: 32px; height: 32px">
        <i class="pi pi-question-circle" style="font-size: 1.5rem"></i>
      </span>
      <span class="font-medium text-2xl text-surface-900 dark:text-surface-0">
        <span>{{ 'modal.leaveComponent.title' | transloco: {default: 'Pagina verlaten?'} }}</span>
      </span>
    </div>
  </ng-template>
  <p class="leading-normal p-0 m-0">
    <span>{{ 'modal.leaveComponent.message' | transloco: {default: 'Als u de pagina verlaat zonder op te slaan, gaan al uw wijzigingen verloren. Bent u zeker dat u de pagina wenst te verlaten?'} }}</span>
  </p>
  <ng-template #footer>
    <div class="border-t border-surface pt-4 flex flex-row gap-2">
      <button pButton  (click)="deny()" class="p-button-sm p-button-secondary p-button-text">
        <span pButtonLabel>{{ 'modal.leaveComponent.deny' | transloco: {default: 'Annuleren'} }}</span>
      </button>
      <button pButton  (click)="confirm()" class="p-button-sm p-button-danger">
        <span pButtonIcon><i class="pi pi-sign-out"></i></span>
        <span pButtonLabel>{{ 'modal.leaveComponent.confirm' | transloco: {default: 'Verlaten'} }}</span>
      </button>
    </div>
  </ng-template>
</p-dialog>

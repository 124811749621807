import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import {CommonModule} from "@angular/common";
import {Dialog} from "primeng/dialog";
import {TranslocoPipe} from "@jsverse/transloco";

import {ButtonDirective, ButtonIcon, ButtonLabel} from "primeng/button";

@Component({
    selector: 'doffice-leave-component-modal',
    templateUrl: 'acceptance-leave-component-modal.component.html',
    styleUrls: ['acceptance-leave-component-modal.component.scss'],
    imports: [CommonModule, Dialog, TranslocoPipe, ButtonDirective, ButtonLabel, ButtonIcon]
})
export class AcceptanceLeaveComponentModal implements OnDestroy {
  private _visible = new BehaviorSubject<boolean>(true);
  get visible$() {
    return this._visible.asObservable();
  }
  get visible() {
    return this._visible.getValue();
  }
  set visible(visible: boolean) {
    this._visible.next(visible);  }


  private _confirmed: Subject<boolean> = new Subject();
  get confirmed$() {
    return this._confirmed.asObservable();
  }

  private _destroy$: Subject<void> = new Subject();

  constructor(private cdr: ChangeDetectorRef) {
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 10);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  deny() {
    this._confirmed.next(false);
    this._visible.next(false);
  }

  confirm() {
    this._confirmed.next(true);
    this._visible.next(false);
  }
}

import {Routes} from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'authentication',
        loadChildren: () => import('./feature/authentication/authentication.routing').then(r => r.AuthenticationRoutes),
      },
      {
        path: 'client',
        loadChildren: () => import('./feature/client/client.routing').then(r => r.clientRoutes),
      },
      {
        path: 'partner',
        loadChildren: () => import('./feature/partner/partner.routing').then(r => r.partnerRoutes),
      },
      {
        path: 'guest',
        loadChildren: () => import('./feature/guest/guest.routing').then(r => r.guestRoutes),
      },
      {
        path: 'registration',
        loadChildren: () => import('./feature/registration/registration.routing').then(r => r.registrationRoutes),
      },
      {
        path: 'onboarding',
        loadChildren: () => import('./feature/onboarding/onboarding.routing').then(r => r.onboardingRoutes),
      },
      {
        path: 'export',
        loadChildren: () => import('./feature/export/export.routing').then(r => r.ledgerExportRoutes),
      },
      /* Error pages */
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'client'
      },
      {
        path: '403',
        loadComponent: () => import('./403.component').then(c => c.Error403Page)
      },
      {
        path: '404',
        loadComponent: () => import('./404.component').then(c => c.Error404Page)
      },
      {
        path: '**',
        redirectTo: '404'
      },
    ],
  },
];

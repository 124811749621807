[
  {
    "key": "DATABASE_CONNECTION_FAILED",
    "values": [
      {
        "iso": "nl",
        "value": "Het systeem is er niet in geslaagd verbinding met de database te maken. Indien dit probleem blijft aanhouden kunt u contact opnemen met support."
      },
      {
        "iso": "en",
        "value": "The system couldn't connect to the database. If this error keeps happening, contact support."
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_EXAMPLE",
    "values": [
      {
        "iso": "nl",
        "value": "Document voorbeeld"
      },
      {
        "iso": "en",
        "value": "Document example"
      }
    ]
  },
  {
    "key": "UI_OCR_EMAIL",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail"
      },
      {
        "iso": "en",
        "value": "E-mail"
      }
    ]
  },
  {
    "key": "UI_OCR_EMAIL_RECEIVED",
    "values": [
      {
        "iso": "nl",
        "value": "Document '{{0}}' ontvangen via e-mail."
      },
      {
        "iso": "en",
        "value": "Received document '{{0}}' via email."
      }
    ]
  },
  {
    "key": "UI_OCR_PEPPOL_RECEIVED",
    "values": [
      {
        "iso": "nl",
        "value": "Document '{{0}}' ontvangen via het Peppol netwerk."
      },
      {
        "iso": "en",
        "value": "Received document '{{0}}' via Peppol network."
      }
    ]
  },
  {
    "key": "UI_LEDGER_EXPORT_DOSSIER_CLOSED_CONFIRMED",
    "values": [
      {
        "iso": "nl",
        "value": "Het octopus dossier is gesloten"
      },
      {
        "iso": "en",
        "value": "The octopus file is closed"
      }
    ]
  },
  {
    "key": "USER_ROLE_DOCUMENTS",
    "values": [
      {
        "iso": "nl",
        "value": "Documenten"
      },
      {
        "iso": "en",
        "value": "Documents"
      }
    ]
  },
  {
    "key": "UI_WRITEOFF_PRODUCT",
    "values": [
      {
        "iso": "nl",
        "value": "Productgegevens"
      },
      {
        "iso": "en",
        "value": "Product details"
      }
    ]
  },
  {
    "key": "UI_VALUE_TABLE",
    "values": [
      {
        "iso": "nl",
        "value": "Waardetabel"
      },
      {
        "iso": "en",
        "value": "Value table"
      }
    ]
  },
  {
    "key": "UI_LEDGER_EXPORT_OCTOPUS_DOSSIER_CLOSED_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Bevestig dat de octopus omgeving van dit dossier is afgesloten. Wanneer de omgeving geopend is kan dit syncronisatie fouten veroorzaken."
      },
      {
        "iso": "en",
        "value": "Confirm that the octopus environment of this file is closed. When the environment is open, it can cause synchronization errors."
      }
    ]
  },
  {
    "key": "UI_LEDGER_EXPORT_OCTOPUS_DOSSIER_CLOSED_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "Sluit octopus dossier"
      },
      {
        "iso": "en",
        "value": "Close octopus dossier"
      }
    ]
  },
  {
    "key": "UI_PAYMENTS",
    "values": [
      {
        "iso": "nl",
        "value": "Betalingen"
      },
      {
        "iso": "en",
        "value": "Payments"
      }
    ]
  },
  {
    "key": "UI_UPLOAD_DOCUMENT",
    "values": [
      {
        "iso": "nl",
        "value": "Document opladen"
      },
      {
        "iso": "en",
        "value": "Upload document"
      }
    ]
  },
  {
    "key": "UI_UPLOAD_DOCUMENTS",
    "values": [
      {
        "iso": "nl",
        "value": "Document opladen"
      },
      {
        "iso": "en",
        "value": "Upload document"
      }
    ]
  },
  {
    "key": "UI_SYSTEM_EMAIL_REGISTERED",
    "values": [
      {
        "iso": "nl",
        "value": "Systeem e-mail is geregistreerd"
      },
      {
        "iso": "en",
        "value": "System email has been registered"
      }
    ]
  },
  {
    "key": "UI_EMAIL_SYSTEM_EMAIL",
    "values": [
      {
        "iso": "nl",
        "value": "Antwoord e-mailadres"
      },
      {
        "iso": "en",
        "value": "Reply emailaddress"
      }
    ]
  },
  {
    "key": "UI_SUPPORT_SYSTEM_EMAIL_RESET_CONFIGURATION",
    "values": [
      {
        "iso": "nl",
        "value": "Opnieuw configureren"
      },
      {
        "iso": "en",
        "value": "Reset configuration"
      }
    ]
  },
  {
    "key": "UI_MINUTES",
    "values": [
      {
        "iso": "nl",
        "value": "Minuten"
      },
      {
        "iso": "en",
        "value": "Minutes"
      }
    ]
  },
  {
    "key": "UI_DELIVERY",
    "values": [
      {
        "iso": "nl",
        "value": "Levering"
      },
      {
        "iso": "en",
        "value": "Delivery"
      }
    ]
  },
  {
    "key": "UI_NO_STOCK_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Geen stock gevonden"
      },
      {
        "iso": "en",
        "value": "No stock found"
      }
    ]
  },
  {
    "key": "UI_SALESINVOICE_IMPORT_WORKPERFORMANCE_NO_RELATION_SELECTED_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "Geen relatie gevonden"
      },
      {
        "iso": "en",
        "value": "No relation found"
      }
    ]
  },
  {
    "key": "UI_INVOICE_SUBLINE_LINKED_TO_WORKPERFORMANCE",
    "values": [
      {
        "iso": "nl",
        "value": "Gelinked aan werkprestatie van {{0}}"
      },
      {
        "iso": "en",
        "value": "Linked to workperformance of {{0}}"
      }
    ]
  },
  {
    "key": "UI_SALESINVOICE_IMPORT_WORKPERFORMANCE_NO_RELATION_SELECTED_BODY",
    "values": [
      {
        "iso": "nl",
        "value": "Gelieve een relatie toe te wijzen aan de verkoopfactuur alvorens werkprestaties te importereren."
      },
      {
        "iso": "en",
        "value": "Please assign a relation to the sales invoice before importing work performances."
      }
    ]
  },
  {
    "key": "UI_NO_WORKPERFORMANCES_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Geen werkprestaties gevonden"
      },
      {
        "iso": "en",
        "value": "No workprestations found"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_EXAMPLE_PARAMETERS",
    "values": [
      {
        "iso": "nl",
        "value": "Document parameters"
      },
      {
        "iso": "en",
        "value": "Document parameters"
      }
    ]
  },
  {
    "key": "UI_AUTHENTICATE",
    "values": [
      {
        "iso": "nl",
        "value": "Authenticeren "
      },
      {
        "iso": "en",
        "value": "Authenticate"
      }
    ]
  },
  {
    "key": "UI_MANAGE_SUBSCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Abonnement beheren "
      },
      {
        "iso": "en",
        "value": "Manage subscription"
      }
    ]
  },
  {
    "key": "UI_USER_LIMIT_REACHED_MESSAGE_ON_USER_LIST_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "Gebruikers limiet bereikt."
      },
      {
        "iso": "en",
        "value": "User limit reached."
      }
    ]
  },
  {
    "key": "UI_USER_LIMIT_REACHED_MESSAGE_ON_USER_LIST_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Verander het abonnement om extra gebruikers toe te voegen."
      },
      {
        "iso": "en",
        "value": "Change the subscription to add extra users."
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_LIMIT_REACHED_MESSAGE_ON_DOCUMENT_DETAIL_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "Document limiet bereikt."
      },
      {
        "iso": "en",
        "value": "Document limit reached."
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_LIMIT_REACHED_MESSAGE_ON_DOCUMENT_DETAIL_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Verander het abonnement om documenten te kunnen bevestigen."
      },
      {
        "iso": "en",
        "value": "Change the subscription to confirm documents."
      }
    ]
  },
  {
    "key": "UI_MODAL_CONFIRM_ACTION_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "Bevestig de actie"
      },
      {
        "iso": "en",
        "value": "Confirm the action"
      }
    ]
  },
  {
    "key": "UI_ARE_YOU_SURE",
    "values": [
      {
        "iso": "nl",
        "value": "Ben je zeker"
      },
      {
        "iso": "en",
        "value": "Are you sure"
      }
    ]
  },
  {
    "key": "UI_NAVIGATE_TO",
    "values": [
      {
        "iso": "nl",
        "value": "Navigeren naar"
      },
      {
        "iso": "en",
        "value": "Navigate to"
      }
    ]
  },
  {
    "key": "UI_LINKED_SALES_INVOICE_SUBSCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Bron abonnement"
      },
      {
        "iso": "en",
        "value": "Source subscription"
      }
    ]
  },
  {
    "key": "UI_EDIT_CONFIRMED_INVOICE_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "Bevestigde factuur bewerken"
      },
      {
        "iso": "en",
        "value": "Edit confirmed invoice"
      }
    ]
  },
  {
    "key": "UI_MODAL_DOUBLE_CONFIRM_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Ik bevestig mijn keuze en erken de risico's"
      },
      {
        "iso": "en",
        "value": "I confirm my choice and acknowledge the risks"
      }
    ]
  },
  {
    "key": "UI_CONFIRM_INVOICE_FROM_SUBSCRIPTION_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "Factuur aanmaken?"
      },
      {
        "iso": "en",
        "value": "Create invoice?"
      }
    ]
  },
  {
    "key": "UI_COWORKERS",
    "values": [
      {
        "iso": "nl",
        "value": "Collega's"
      },
      {
        "iso": "en",
        "value": "Coworkers"
      }
    ]
  },
  {
    "key": "UI_COWORKER",
    "values": [
      {
        "iso": "nl",
        "value": "Collega"
      },
      {
        "iso": "en",
        "value": "Coworker"
      }
    ]
  },
  {
    "key": "UI_NO_COMMUNICATION_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Geen communicatie gevonden"
      },
      {
        "iso": "en",
        "value": "No communication found"
      }
    ]
  },
  {
    "key": "UI_NO_TRANSACTIONS_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Geen betalingen gevonden"
      },
      {
        "iso": "en",
        "value": "No payments found"
      }
    ]
  },
  {
    "key": "UI_NO_TRANSLOCATIONS_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Geen verplaatsingen gevonden"
      },
      {
        "iso": "en",
        "value": "No translocations found"
      }
    ]
  },
  {
    "key": "UI_NO_DOCUMENTS_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Geen bijlages gevonden"
      },
      {
        "iso": "en",
        "value": "No attachments found"
      }
    ]
  },
  {
    "key": "UI_OVERDUE_PAID",
    "values": [
      {
        "iso": "nl",
        "value": "Openstaand betaald"
      },
      {
        "iso": "en",
        "value": "Overdue paid"
      }
    ]
  },
  {
    "key": "UI_NO_WORKPERFORMANCE_EXTRAS_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Geen extra's gevonden"
      },
      {
        "iso": "en",
        "value": "No extras found"
      }
    ]
  },
  {
    "key": "UI_NO_WORKPERFORMANCE_COWORKERS_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Geen collega registraties gevonden"
      },
      {
        "iso": "en",
        "value": "No coworker registration found"
      }
    ]
  },
  {
    "key": "UI_REGISTRATION",
    "values": [
      {
        "iso": "nl",
        "value": "Registratie"
      },
      {
        "iso": "en",
        "value": "Registration"
      }
    ]
  },
  {
    "key": "UI_DEFAULT_REGISTRATION",
    "values": [
      {
        "iso": "nl",
        "value": "Standaard registratie"
      },
      {
        "iso": "en",
        "value": "Default registration"
      }
    ]
  },
  {
    "key": "UI_COMMUNICATION_EMAIL_SEND_TO",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail verstuurd naar {{0}}"
      },
      {
        "iso": "en",
        "value": "Email send to {{0}}"
      }
    ]
  },
  {
    "key": "UI_SUBJECT",
    "values": [
      {
        "iso": "nl",
        "value": "Onderwerp"
      },
      {
        "iso": "en",
        "value": "Subject"
      }
    ]
  },
  {
    "key": "UI_UNOPENED",
    "values": [
      {
        "iso": "nl",
        "value": "Ongeopend"
      },
      {
        "iso": "en",
        "value": "Unopened"
      }
    ]
  },
  {
    "key": "UI_OPENED",
    "values": [
      {
        "iso": "nl",
        "value": "Geopened"
      },
      {
        "iso": "en",
        "value": "Opened"
      }
    ]
  },
  {
    "key": "UI_READ_MESSAGES",
    "values": [
      {
        "iso": "nl",
        "value": "Meldingen lezen"
      },
      {
        "iso": "en",
        "value": "Read messages"
      }
    ]
  },
  {
    "key": "UI_SEND_DOCUMENT_EMAIL_MODAL_STEP_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Bericht"
      },
      {
        "iso": "en",
        "value": "Message"
      }
    ]
  },
  {
    "key": "UI_SEND_DOCUMENT_EMAIL_MODAL_STEP_ATTACHMENTS",
    "values": [
      {
        "iso": "nl",
        "value": "Bijlages"
      },
      {
        "iso": "en",
        "value": "Attachments"
      }
    ]
  },
  {
    "key": "UI_SEND_DOCUMENT_EMAIL_MODAL_STEP_RECEIVERS",
    "values": [
      {
        "iso": "nl",
        "value": "Ontvangers"
      },
      {
        "iso": "en",
        "value": "Receivers"
      }
    ]
  },
  {
    "key": "UI_DUPLICATE_SUCCESS_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Kopieren geslaagd en document geladen."
      },
      {
        "iso": "en",
        "value": "Copy successful and document loaded."
      }
    ]
  },
  {
    "key": "UI_CONVERT_SUCCESS_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Omzetten geslaagd en document geladen."
      },
      {
        "iso": "en",
        "value": "Convertion successful and document loaded."
      }
    ]
  },
  {
    "key": "UI_TO_CREDITNOTE",
    "values": [
      {
        "iso": "nl",
        "value": "Naar creditnota"
      },
      {
        "iso": "en",
        "value": "To creditnote"
      }
    ]
  },
  {
    "key": "UI_TO_SUBSCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Naar abonnement"
      },
      {
        "iso": "en",
        "value": "To subscription"
      }
    ]
  },
  {
    "key": "UI_TO_REMINDER",
    "values": [
      {
        "iso": "nl",
        "value": "Naar herinnering"
      },
      {
        "iso": "en",
        "value": "To reminder"
      }
    ]
  },
  {
    "key": "UI_TO_PRODUCT_DELIVERY",
    "values": [
      {
        "iso": "nl",
        "value": "Naar levering"
      },
      {
        "iso": "en",
        "value": "To delivery"
      }
    ]
  },
  {
    "key": "UI_TO_PREPAID_SERVICES",
    "values": [
      {
        "iso": "nl",
        "value": "Naar vooruitbetaalde diensten"
      },
      {
        "iso": "en",
        "value": "To pre-paid services"
      }
    ]
  },
  {
    "key": "UI_QUOTE_TO_ORDER_CONVERTED_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Op basis van de offerte is de bestelling aangemaakt en geladen."
      },
      {
        "iso": "en",
        "value": "Based on the quotation, the order was created and loaded."
      }
    ]
  },
  {
    "key": "UI_PRODUCT_DELIVERY_TO_INVOICE_CONVERTED_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Op basis van de levering is de factuur aangemaakt en geladen."
      },
      {
        "iso": "en",
        "value": "Based on the delivery, the invoice was created and loaded."
      }
    ]
  },
  {
    "key": "UI_PRODUCT_RECEPTION_TO_INVOICE_CONVERTED_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Op basis van de receptie is de factuur aangemaakt en geladen."
      },
      {
        "iso": "en",
        "value": "Based on the reception, the invoice was created and loaded."
      }
    ]
  },
  {
    "key": "UI_PURCHASE_ORDER_TO_INVOICE_CONVERTED_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Op basis van de bestelling is de factuur aangemaakt en geladen."
      },
      {
        "iso": "en",
        "value": "Based on the order, the invoice was created and loaded."
      }
    ]
  },
  {
    "key": "UI_ORDER_TO_INVOICE_CONVERTED_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Op basis van de bestelling is de factuur aangemaakt en geladen."
      },
      {
        "iso": "en",
        "value": "Based on the order, the invoice was created and loaded."
      }
    ]
  },
  {
    "key": "UI_CONTACT_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Contacten"
      },
      {
        "iso": "en",
        "value": "Contacts"
      }
    ]
  },
  {
    "key": "UI_NO_EMAILADDRESSES_FOUND_FOR_THIS_RELATION",
    "values": [
      {
        "iso": "nl",
        "value": "Geen e-mailadressen gevonden voor deze relatie"
      },
      {
        "iso": "en",
        "value": "No emailaddresses found for this relation"
      }
    ]
  },
  {
    "key": "UI_NO_PHONE_NUMBERS_FOUND_FOR_THIS_RELATION",
    "values": [
      {
        "iso": "nl",
        "value": "Geen telefonnummers gevonden voor deze relatie"
      },
      {
        "iso": "en",
        "value": "No phonenumbers found for this relation"
      }
    ]
  },
  {
    "key": "UI_NO_CONTACTS_FOUND_FOR_THIS_RELATION",
    "values": [
      {
        "iso": "nl",
        "value": "Geen contact personen gevonden voor deze relatie"
      },
      {
        "iso": "en",
        "value": "No contact persons found for this relation"
      }
    ]
  },
  {
    "key": "UI_NO_EXTRA_ADDRESSES_FOUND_FOR_THIS_RELATION",
    "values": [
      {
        "iso": "nl",
        "value": "Geen extra adressen gevonden voor deze relatie"
      },
      {
        "iso": "en",
        "value": "No extra addresses found for this relation"
      }
    ]
  },
  {
    "key": "UI_NO_FINANCIAL_INFORMATION_FOUND_FOR_THIS_RELATION",
    "values": [
      {
        "iso": "nl",
        "value": "Geen financiële informatie gevonden voor deze relatie"
      },
      {
        "iso": "en",
        "value": "No financial information found for this relation"
      }
    ]
  },
  {
    "key": "UI_EMAILADDRESSES",
    "values": [
      {
        "iso": "nl",
        "value": "E-mailadressen"
      },
      {
        "iso": "en",
        "value": "Emailadresses"
      }
    ]
  },
  {
    "key": "UI_EXTRA_ADDRESSES",
    "values": [
      {
        "iso": "nl",
        "value": "Extra addressen"
      },
      {
        "iso": "en",
        "value": "Extra addresses"
      }
    ]
  },
  {
    "key": "UI_RELATION_DEFAULT_ADDRESS",
    "values": [
      {
        "iso": "nl",
        "value": "Standaard adres"
      },
      {
        "iso": "en",
        "value": "Default address"
      }
    ]
  },
  {
    "key": "UI_NO_PURCHASE_PRICES_FOUND_FOR_THIS_PRODUCT",
    "values": [
      {
        "iso": "nl",
        "value": "Geen aankoopprijzen gevonden voor dit product"
      },
      {
        "iso": "en",
        "value": "No purchase prices found for this product"
      }
    ]
  },
  {
    "key": "UI_CONVERT",
    "values": [
      {
        "iso": "nl",
        "value": "Omzetten"
      },
      {
        "iso": "en",
        "value": "Convert"
      }
    ]
  },
  {
    "key": "UI_NO_SUBSCRIBERS_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Geen abonnee's gevonden voor het ingevulde e-mailadres"
      },
      {
        "iso": "en",
        "value": "No subscribers found for the enteres emailaddress"
      }
    ]
  },
  {
    "key": "UI_TO_ORDER",
    "values": [
      {
        "iso": "nl",
        "value": "Naar bestelling"
      },
      {
        "iso": "en",
        "value": "To order"
      }
    ]
  },
  {
    "key": "UI_TO_INVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "Naar factuur"
      },
      {
        "iso": "en",
        "value": "To invoice"
      }
    ]
  },
  {
    "key": "UI_PAYMENT_TYPE",
    "values": [
      {
        "iso": "nl",
        "value": "Betalingstype"
      },
      {
        "iso": "en",
        "value": "Payment type"
      }
    ]
  },
  {
    "key": "UI_EMAIL_INVALID",
    "values": [
      {
        "iso": "nl",
        "value": "Foutief e-mailadres"
      },
      {
        "iso": "en",
        "value": "Invalid emailaddress"
      }
    ]
  },
  {
    "key": "UI_REGISTRATION_ONBOARDING_REQUEST_RECEIVED",
    "values": [
      {
        "iso": "nl",
        "value": "We hebben jouw aanmelding goed ontvangen!"
      },
      {
        "iso": "en",
        "value": "We have received your registration successfully!"
      }
    ]
  },
  {
    "key": "UI_REGISTRATION_ONBOARDING_CONFIRM_YOUR_EMAIL",
    "values": [
      {
        "iso": "nl",
        "value": "Bevestig jouw aanmelding via de link in de e-mail."
      },
      {
        "iso": "en",
        "value": "Confirm your registration through the link in the email."
      }
    ]
  },
  {
    "key": "UI_REGISTRATION_ONBOARDING_CONFIRM_YOUR_EMAIL_SPAM_WARNING",
    "values": [
      {
        "iso": "nl",
        "value": "Bekijk ook de SPAM box!"
      },
      {
        "iso": "en",
        "value": "Also, check the SPAM box!"
      }
    ]
  },
  {
    "key": "UI_REGISTRATION_ONBOARDING_KEEP_READY",
    "values": [
      {
        "iso": "nl",
        "value": "Hou dit paraat:"
      },
      {
        "iso": "en",
        "value": "Keep this ready at hand:"
      }
    ]
  },
  {
    "key": "UI_REGISTRATION_ONBOARDING_KEEP_READY_VAT_NUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "BTW nummer"
      },
      {
        "iso": "en",
        "value": "VAT number"
      }
    ]
  },
  {
    "key": "UI_REGISTRATION_ONBOARDING_KEEP_READY_ACCOUNT_NUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "Bankgegevens voor klanten"
      },
      {
        "iso": "en",
        "value": "Bank account number for customers."
      }
    ]
  },
  {
    "key": "UI_REGISTRATION_ONBOARDING_KEEP_READY_LOGO",
    "values": [
      {
        "iso": "nl",
        "value": "Logo (optioneel)"
      },
      {
        "iso": "en",
        "value": "Logo (optional)"
      }
    ]
  },
  {
    "key": "UI_HOW",
    "values": [
      {
        "iso": "nl",
        "value": "Hoe?"
      },
      {
        "iso": "en",
        "value": "How?"
      }
    ]
  },
  {
    "key": "UI_UNINVOICED_LICENSES",
    "values": [
      {
        "iso": "nl",
        "value": "Niet gefactureerde licenties"
      },
      {
        "iso": "en",
        "value": "Uninvoiced licenses"
      }
    ]
  },
  {
    "key": "UI_PARTNER_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Partner informatie"
      },
      {
        "iso": "en",
        "value": "Partner information"
      }
    ]
  },
  {
    "key": "UI_PARTNER_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Partner informatie"
      },
      {
        "iso": "en",
        "value": "Partner information"
      }
    ]
  },
  {
    "key": "UI_REGISTER_FREE",
    "values": [
      {
        "iso": "nl",
        "value": "GRATIS registreren"
      },
      {
        "iso": "en",
        "value": "Register free"
      }
    ]
  },
  {
    "key": "UI_REGISTRATION_HOW_DO_YOU_KNOW_US_OFFLINEADVERTISEMENT",
    "values": [
      {
        "iso": "nl",
        "value": "Advertenties (offline)"
      },
      {
        "iso": "en",
        "value": "Advertisments (offline)"
      }
    ]
  },
  {
    "key": "UI_REGISTRATION_HOW_DO_YOU_KNOW_US_FRIEND",
    "values": [
      {
        "iso": "nl",
        "value": "Via vrienden"
      },
      {
        "iso": "en",
        "value": "By friends"
      }
    ]
  },
  {
    "key": "UI_REGISTRATION_HOW_DO_YOU_KNOW_US_ACCOUNTANT",
    "values": [
      {
        "iso": "nl",
        "value": "Via mijn boekhouder / accountant"
      },
      {
        "iso": "en",
        "value": "By my accountant"
      }
    ]
  },
  {
    "key": "UI_REGISTRATION_HOW_DO_YOU_KNOW_US_SOCIALMEDIA",
    "values": [
      {
        "iso": "nl",
        "value": "Via sociale media"
      },
      {
        "iso": "en",
        "value": "By social media"
      }
    ]
  },
  {
    "key": "UI_REGISTRATION_HOW_DO_YOU_KNOW_US_GOOGLE",
    "values": [
      {
        "iso": "nl",
        "value": "Via google"
      },
      {
        "iso": "en",
        "value": "By google"
      }
    ]
  },
  {
    "key": "UI_REGISTRATION_HOW_DO_YOU_KNOW_US_OTHER",
    "values": [
      {
        "iso": "nl",
        "value": "Andere"
      },
      {
        "iso": "en",
        "value": "Other"
      }
    ]
  },
  {
    "key": "UI_REGISTER_FREE_COMPLETED",
    "values": [
      {
        "iso": "nl",
        "value": "Registratie voltooid"
      },
      {
        "iso": "en",
        "value": "Register completed"
      }
    ]
  },
  {
    "key": "UI_PARTNER_RESELLER",
    "values": [
      {
        "iso": "nl",
        "value": "Verkooppunt"
      },
      {
        "iso": "en",
        "value": "Sales point"
      }
    ]
  },
  {
    "key": "UI_PARTNER_ACCOUNTANCY",
    "values": [
      {
        "iso": "nl",
        "value": "Boekhouder"
      },
      {
        "iso": "en",
        "value": "Accountant"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_EXAMPLE_PAYMENT",
    "values": [
      {
        "iso": "nl",
        "value": "Betalingen"
      },
      {
        "iso": "en",
        "value": "Payment"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_EXAMPLE_LINES",
    "values": [
      {
        "iso": "nl",
        "value": "Lijnen"
      },
      {
        "iso": "en",
        "value": "Lines"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_EXAMPLE_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Bijkomende informatie"
      },
      {
        "iso": "en",
        "value": "Extra information"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_EXAMPLE_FOOTER",
    "values": [
      {
        "iso": "nl",
        "value": "Footer"
      },
      {
        "iso": "en",
        "value": "Footer"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_EXAMPLE_LOGO",
    "values": [
      {
        "iso": "nl",
        "value": "Logo"
      },
      {
        "iso": "en",
        "value": "Logo"
      }
    ]
  },
  {
    "key": "UI_BANNER_DEV",
    "values": [
      {
        "iso": "nl",
        "value": "U bevindt zich in de ontwikkel omgeving!"
      },
      {
        "iso": "en",
        "value": "You are in the development environment!"
      }
    ]
  },
  {
    "key": "UI_COMPANY_DOCUMENT_WIZARD",
    "values": [
      {
        "iso": "nl",
        "value": "Documentstijl instellen"
      },
      {
        "iso": "en",
        "value": "Setup document style"
      }
    ]
  },
  {
    "key": "UI_REVENUE_CHART_SIZE_DESKTOP",
    "values": [
      {
        "iso": "nl",
        "value": "Omzet grafiek grootte voor desktop (in maanden)"
      },
      {
        "iso": "en",
        "value": "Revenue chart size for desktop (in months)"
      }
    ]
  },
  {
    "key": "UI_REVENUE_CHART_SIZE_MOBILE",
    "values": [
      {
        "iso": "nl",
        "value": "Omzet grafiek grootte voor mobiel (in maanden)"
      },
      {
        "iso": "en",
        "value": "Revenue chart size for mobile (in months)"
      }
    ]
  },
  {
    "key": "UI_DEFAULT_COMPANY_BANK_ACCOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Standaard bank rekening"
      },
      {
        "iso": "en",
        "value": "Default bank account"
      }
    ]
  },
  {
    "key": "UI_ADD_PARTNER",
    "values": [
      {
        "iso": "nl",
        "value": "Partner toevoegen"
      },
      {
        "iso": "en",
        "value": "Add partner"
      }
    ]
  },
  {
    "key": "UI_TRADEMARK_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Handelsnaam"
      },
      {
        "iso": "en",
        "value": "Trademark name"
      }
    ]
  },
  {
    "key": "UI_ID",
    "values": [
      {
        "iso": "nl",
        "value": "ID"
      },
      {
        "iso": "en",
        "value": "ID"
      }
    ]
  },
  {
    "key": "UI_EXCLUDE_ACCOUNTNUMBERS",
    "values": [
      {
        "iso": "nl",
        "value": "Enkel niet-toegewezen rekeningen"
      },
      {
        "iso": "en",
        "value": "Only non assigned ledgers"
      }
    ]
  },
  {
    "key": "UI_INVOICE_NEGATIVE_CREDITNOTE_QUESTION",
    "values": [
      {
        "iso": "nl",
        "value": "Een factuur kan in theorie nooit negatief zijn. Ben je zeker dat je geen creditnota wenst te maken ipv een factuur?"
      },
      {
        "iso": "en",
        "value": "In theory, an invoice can never be negative. Are you sure you don't want to make a credit note instead of an invoice?"
      }
    ]
  },
  {
    "key": "UI_CREDITNOTE_NEGATIVE_INVOICE_QUESTION",
    "values": [
      {
        "iso": "nl",
        "value": "Een creditnota kan in theorie nooit negatief zijn. Ben je zeker dat je geen factuur wenst te maken ipv een creditnota?"
      },
      {
        "iso": "en",
        "value": "In theory, a credit note can never be negative. Are you sure you do not wish to create an invoice instead of a credit note?"
      }
    ]
  },
  {
    "key": "UI_DELETING_SELECTION",
    "values": [
      {
        "iso": "nl",
        "value": "Selectie wissen..."
      },
      {
        "iso": "en",
        "value": "Deleted selection..."
      }
    ]
  },
  {
    "key": "UI_SELECTION_DELETED",
    "values": [
      {
        "iso": "nl",
        "value": "Selectie gewist."
      },
      {
        "iso": "en",
        "value": "Selection deleted."
      }
    ]
  },
  {
    "key": "UI_FAILED_TO_DELETE",
    "values": [
      {
        "iso": "nl",
        "value": "Het wissen van de gevraagde data is mislukt!"
      },
      {
        "iso": "en",
        "value": "Erasing the requested data failed!"
      }
    ]
  },
  {
    "key": "UI_UPDATE_PAGINATION",
    "values": [
      {
        "iso": "nl",
        "value": "Paginering wijzigen: {{0}}"
      },
      {
        "iso": "en",
        "value": "Adjust pagination: {{0}}"
      }
    ]
  },
  {
    "key": "UI_UPDATE_SELECTION",
    "values": [
      {
        "iso": "nl",
        "value": "Selectie wijzigen"
      },
      {
        "iso": "en",
        "value": "Update selection"
      }
    ]
  },
  {
    "key": "UI_SUBSCRIBER",
    "values": [
      {
        "iso": "nl",
        "value": "Abonnee"
      },
      {
        "iso": "en",
        "value": "Subscriber"
      }
    ]
  },
  {
    "key": "UI_ACCOUNT_NUMBER_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Boekhoudkundige omschrijving"
      },
      {
        "iso": "en",
        "value": "Ledger description"
      }
    ]
  },
  {
    "key": "UI_EXPECTED_PRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Verwachte opbrengst"
      },
      {
        "iso": "en",
        "value": "Expected price"
      }
    ]
  },
  {
    "key": "UI_ERRORS",
    "values": [
      {
        "iso": "nl",
        "value": "Foutmeldingen"
      },
      {
        "iso": "en",
        "value": "Error messages"
      }
    ]
  },
  {
    "key": "UI_CONTACT_ALWAYS_IMPORT_DATA",
    "values": [
      {
        "iso": "nl",
        "value": "E-mailadres importeren bij andere contacten"
      },
      {
        "iso": "en",
        "value": "Import email address on other contacts"
      }
    ]
  },
  {
    "key": "UI_VAT_SEARCHED_NOT_FOUND_MESSAGE_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "BTW nummer niet geverifieerd"
      },
      {
        "iso": "en",
        "value": "VAT number not verified"
      }
    ]
  },
  {
    "key": "UI_VAT_SEARCHED_NOT_FOUND_MESSAGE_BODY",
    "values": [
      {
        "iso": "nl",
        "value": "Het BTW nummer kon niet worden geverifieerd. Controleer de geldigheid bij de bevoegde instantie."
      },
      {
        "iso": "en",
        "value": "The VAT number could not be verified. Please check its validity with the relevant authority."
      }
    ]
  },
  {
    "key": "UI_VAT_SEARCHED_FOUND_MESSAGE_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "BTW nummer geverifieerd"
      },
      {
        "iso": "en",
        "value": "VAT number verified"
      }
    ]
  },
  {
    "key": "UI_UPDATING_SUBSCRIPTION_STATUS",
    "values": [
      {
        "iso": "nl",
        "value": "Abonnementstatus wordt aangepast"
      },
      {
        "iso": "en",
        "value": "Updating subscription status"
      }
    ]
  },
  {
    "key": "UI_SUBSCRIPTION_STATUS_UPDATED",
    "values": [
      {
        "iso": "nl",
        "value": "Abonnementstatus aangepast"
      },
      {
        "iso": "en",
        "value": "updated subscription status"
      }
    ]
  },
  {
    "key": "UI_UPDATING_SALES_ORDER_STATUS",
    "values": [
      {
        "iso": "nl",
        "value": "Bestelstatus wordt aangepast"
      },
      {
        "iso": "en",
        "value": "Updating order status"
      }
    ]
  },
  {
    "key": "UI_SALES_ORDER_STATUS_UPDATED",
    "values": [
      {
        "iso": "nl",
        "value": "Bestelstatus aangepast"
      },
      {
        "iso": "en",
        "value": "updated order status"
      }
    ]
  },
  {
    "key": "UI_UPDATING_PURCHASE_ORDER_STATUS",
    "values": [
      {
        "iso": "nl",
        "value": "Bestelstatus wordt aangepast"
      },
      {
        "iso": "en",
        "value": "Updating order status"
      }
    ]
  },
  {
    "key": "UI_PURCHASE_ORDER_STATUS_UPDATED",
    "values": [
      {
        "iso": "nl",
        "value": "Bestelstatus aangepast"
      },
      {
        "iso": "en",
        "value": "updated order status"
      }
    ]
  },
  {
    "key": "UI_UPDATING_ORDER_STATUS",
    "values": [
      {
        "iso": "nl",
        "value": "Bestelstatus wordt aangepast"
      },
      {
        "iso": "en",
        "value": "Updating order status"
      }
    ]
  },
  {
    "key": "UI_ORDER_STATUS_UPDATED",
    "values": [
      {
        "iso": "nl",
        "value": "Bestelstatus aangepast"
      },
      {
        "iso": "en",
        "value": "updated order status"
      }
    ]
  },
  {
    "key": "UI_UPDATING_QUOTE_STATUS",
    "values": [
      {
        "iso": "nl",
        "value": "Offertestatus wordt aangepast"
      },
      {
        "iso": "en",
        "value": "Updating quote status"
      }
    ]
  },
  {
    "key": "UI_QUOTE_STATUS_UPDATED",
    "values": [
      {
        "iso": "nl",
        "value": "Offertestatus aangepast"
      },
      {
        "iso": "en",
        "value": "updated quote status"
      }
    ]
  },
  {
    "key": "UI_LOGIN",
    "values": [
      {
        "iso": "nl",
        "value": "Inloggen"
      },
      {
        "iso": "en",
        "value": "Login"
      }
    ]
  },
  {
    "key": "UI_UPDATE_DOCUMENT_STATUS",
    "values": [
      {
        "iso": "nl",
        "value": "Status wijzigen"
      },
      {
        "iso": "en",
        "value": "Update status"
      }
    ]
  },
  {
    "key": "UI_DELETE_EMAIL_RECEIVER",
    "values": [
      {
        "iso": "nl",
        "value": "Ontvanger verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete receiver"
      }
    ]
  },
  {
    "key": "UI_ADD_EMAIL_RECEIVER",
    "values": [
      {
        "iso": "nl",
        "value": "Ontvanger toevoegen"
      },
      {
        "iso": "en",
        "value": "Add receiver"
      }
    ]
  },
  {
    "key": "UI_DELETE_EMAIL_ATTACHMENT",
    "values": [
      {
        "iso": "nl",
        "value": "Bijlage verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete attachment"
      }
    ]
  },
  {
    "key": "UI_ADD_EMAIL_ATTACHMENT",
    "values": [
      {
        "iso": "nl",
        "value": "Bijlage toevoegen"
      },
      {
        "iso": "en",
        "value": "Add attachment"
      }
    ]
  },
  {
    "key": "UI_GENERATE_PDF_FOR_WORKPERFORMANCE",
    "values": [
      {
        "iso": "nl",
        "value": "Genereer PDF document voor werkprestaties"
      },
      {
        "iso": "en",
        "value": "Generate PDF document for workperformances"
      }
    ]
  },
  {
    "key": "UI_PASSWORD",
    "values": [
      {
        "iso": "nl",
        "value": "Wachtwoord"
      },
      {
        "iso": "en",
        "value": "Password"
      }
    ]
  },
  {
    "key": "UI_LOGIN",
    "values": [
      {
        "iso": "nl",
        "value": "Wachtwoord"
      },
      {
        "iso": "en",
        "value": "Password"
      }
    ]
  },
  {
    "key": "UI_SYSTEM_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Systeem informatie"
      },
      {
        "iso": "en",
        "value": "System information"
      }
    ]
  },
  {
    "key": "UI_CREATIONDATE",
    "values": [
      {
        "iso": "nl",
        "value": "Aanmaakdatum"
      },
      {
        "iso": "en",
        "value": "Creation date"
      }
    ]
  },
  {
    "key": "UI_CREATED",
    "values": [
      {
        "iso": "nl",
        "value": "Aangemaakt"
      },
      {
        "iso": "en",
        "value": "Created"
      }
    ]
  },
  {
    "key": "UI_UPDATED",
    "values": [
      {
        "iso": "nl",
        "value": "Laatste update"
      },
      {
        "iso": "en",
        "value": "Last update"
      }
    ]
  },
  {
    "key": "UI_GENERAL_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Algemene informatie"
      },
      {
        "iso": "en",
        "value": "General information"
      }
    ]
  },
  {
    "key": "UI_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Naam"
      },
      {
        "iso": "en",
        "value": "Name"
      }
    ]
  },
  {
    "key": "UI_CUSTOMER_NUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "Klantennummer"
      },
      {
        "iso": "en",
        "value": "Customer number"
      }
    ]
  },
  {
    "key": "UI_GENERAL_PHONE",
    "values": [
      {
        "iso": "nl",
        "value": "Algemeen telefoonnummer"
      },
      {
        "iso": "en",
        "value": "General phone number"
      }
    ]
  },
  {
    "key": "UI_GENERAL_EMAIL",
    "values": [
      {
        "iso": "nl",
        "value": "Algemeen e-mailadres"
      },
      {
        "iso": "en",
        "value": "General email address"
      }
    ]
  },
  {
    "key": "UI_LANGUAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Taal"
      },
      {
        "iso": "en",
        "value": "Language"
      }
    ]
  },
  {
    "key": "UI_WEBSITE",
    "values": [
      {
        "iso": "nl",
        "value": "Website"
      },
      {
        "iso": "en",
        "value": "Website"
      }
    ]
  },
  {
    "key": "UI_NOTE",
    "values": [
      {
        "iso": "nl",
        "value": "Opmerking"
      },
      {
        "iso": "en",
        "value": "Remark"
      }
    ]
  },
  {
    "key": "UI_ENTITY_TYPE",
    "values": [
      {
        "iso": "nl",
        "value": "Relatie type"
      },
      {
        "iso": "en",
        "value": "Relation type"
      }
    ]
  },
  {
    "key": "UI_VAT_NUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "BTW nummer"
      },
      {
        "iso": "en",
        "value": "VAT number"
      }
    ]
  },
  {
    "key": "UI_FINANCIAL_EMAIL",
    "values": [
      {
        "iso": "nl",
        "value": "Financieel e-mailadres"
      },
      {
        "iso": "en",
        "value": "Financial emailaddress"
      }
    ]
  },
  {
    "key": "UI_PAYMENTTERMS",
    "values": [
      {
        "iso": "nl",
        "value": "Betalingstermijn"
      },
      {
        "iso": "en",
        "value": "Payment terms"
      }
    ]
  },
  {
    "key": "UI_FINANCIAL_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Financiële informatie"
      },
      {
        "iso": "en",
        "value": "Financial information"
      }
    ]
  },
  {
    "key": "UI_TIME_DAYS",
    "values": [
      {
        "iso": "nl",
        "value": "Dagen"
      },
      {
        "iso": "en",
        "value": "Days"
      }
    ]
  },
  {
    "key": "UI_CONTACTS",
    "values": [
      {
        "iso": "nl",
        "value": "Contact personen"
      },
      {
        "iso": "en",
        "value": "Contact persons"
      }
    ]
  },
  {
    "key": "UI_FUNCTION",
    "values": [
      {
        "iso": "nl",
        "value": "Functie"
      },
      {
        "iso": "en",
        "value": "Function"
      }
    ]
  },
  {
    "key": "UI_PHONE",
    "values": [
      {
        "iso": "nl",
        "value": "Telefoon nummer"
      },
      {
        "iso": "en",
        "value": "Telphone number"
      }
    ]
  },
  {
    "key": "UI_EMAIL",
    "values": [
      {
        "iso": "nl",
        "value": "E-mailadres"
      },
      {
        "iso": "en",
        "value": "Email address"
      }
    ]
  },
  {
    "key": "UI_RELATIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Relaties"
      },
      {
        "iso": "en",
        "value": "Relations"
      }
    ]
  },
  {
    "key": "UI_ARTICLES",
    "values": [
      {
        "iso": "nl",
        "value": "Artikelen"
      },
      {
        "iso": "en",
        "value": "Articles"
      }
    ]
  },
  {
    "key": "UI_DOCUMENTS",
    "values": [
      {
        "iso": "nl",
        "value": "Documenten"
      },
      {
        "iso": "en",
        "value": "Documents"
      }
    ]
  },
  {
    "key": "UI_LEDGER",
    "values": [
      {
        "iso": "nl",
        "value": "Grootboek"
      },
      {
        "iso": "en",
        "value": "Ledger"
      }
    ]
  },
  {
    "key": "UI_PARAMETERS",
    "values": [
      {
        "iso": "nl",
        "value": "Parameters"
      },
      {
        "iso": "en",
        "value": "Parameters"
      }
    ]
  },
  {
    "key": "UI_SETTINGS",
    "values": [
      {
        "iso": "nl",
        "value": "Instellingen"
      },
      {
        "iso": "en",
        "value": "Settings"
      }
    ]
  },
  {
    "key": "UI_LOGOUT",
    "values": [
      {
        "iso": "nl",
        "value": "Uitloggen"
      },
      {
        "iso": "en",
        "value": "Logout"
      }
    ]
  },
  {
    "key": "UI_CUSTOMERS",
    "values": [
      {
        "iso": "nl",
        "value": "Klanten"
      },
      {
        "iso": "en",
        "value": "Customers"
      }
    ]
  },
  {
    "key": "UI_SEARCH",
    "values": [
      {
        "iso": "nl",
        "value": "Zoeken"
      },
      {
        "iso": "en",
        "value": "Search"
      }
    ]
  },
  {
    "key": "UI_NUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "Nummer"
      },
      {
        "iso": "en",
        "value": "Number"
      }
    ]
  },
  {
    "key": "UI_NUMBER_SHORT",
    "values": [
      {
        "iso": "nl",
        "value": "Nr."
      },
      {
        "iso": "en",
        "value": "No"
      }
    ]
  },
  {
    "key": "UI_DATA",
    "values": [
      {
        "iso": "nl",
        "value": "Gegevens"
      },
      {
        "iso": "en",
        "value": "Data"
      }
    ]
  },
  {
    "key": "UI_LEVEL",
    "values": [
      {
        "iso": "nl",
        "value": "Niveau"
      },
      {
        "iso": "en",
        "value": "Niveau"
      }
    ]
  },
  {
    "key": "UI_PROFORMA",
    "values": [
      {
        "iso": "nl",
        "value": "Proforma"
      },
      {
        "iso": "en",
        "value": "Proforma"
      }
    ]
  },
  {
    "key": "UI_ADD_WORKPERFORMANCE_USER",
    "values": [
      {
        "iso": "nl",
        "value": "Gebruiker toevoegen"
      },
      {
        "iso": "en",
        "value": "Add user"
      }
    ]
  },
  {
    "key": "UI_DELETE_WORKPERFORMANCE_USER",
    "values": [
      {
        "iso": "nl",
        "value": "Gebruiker verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete user"
      }
    ]
  },
  {
    "key": "UI_ADD_WORKPERFORMANCE_EXTRA",
    "values": [
      {
        "iso": "nl",
        "value": "Extra's toevoegen"
      },
      {
        "iso": "en",
        "value": "Add extras"
      }
    ]
  },
  {
    "key": "UI_DELETE_WORKPERFORMANCE_EXTRA",
    "values": [
      {
        "iso": "nl",
        "value": "Extra's verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete extras"
      }
    ]
  },
  {
    "key": "UI_ADD_CONTACT",
    "values": [
      {
        "iso": "nl",
        "value": "Contactpersoon toevoegen"
      },
      {
        "iso": "en",
        "value": "Add contact person"
      }
    ]
  },
  {
    "key": "UI_DELETE_CONTACT",
    "values": [
      {
        "iso": "nl",
        "value": "Contactpersoon verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete contact person"
      }
    ]
  },
  {
    "key": "UI_ADD_EMAIL",
    "values": [
      {
        "iso": "nl",
        "value": "E-mailadres toevoegen"
      },
      {
        "iso": "en",
        "value": "Add email address"
      }
    ]
  },
  {
    "key": "UI_DELETE_EMAIL",
    "values": [
      {
        "iso": "nl",
        "value": "E-mailadres verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete email address"
      }
    ]
  },
  {
    "key": "UI_ADD_PHONE",
    "values": [
      {
        "iso": "nl",
        "value": "Telefoonnummer toevoegen"
      },
      {
        "iso": "en",
        "value": "Add phone number"
      }
    ]
  },
  {
    "key": "UI_DELETE_PHONE",
    "values": [
      {
        "iso": "nl",
        "value": "Telefoonnummer verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete phone number"
      }
    ]
  },
  {
    "key": "UI_ADD_ADDRESS",
    "values": [
      {
        "iso": "nl",
        "value": "Adres toevoegen"
      },
      {
        "iso": "en",
        "value": "Add address"
      }
    ]
  },
  {
    "key": "UI_DELETE_ADDRESS",
    "values": [
      {
        "iso": "nl",
        "value": "Adres verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete address"
      }
    ]
  },
  {
    "key": "UI_ADD_BANK_ACCOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Bankrekening toevoegen"
      },
      {
        "iso": "en",
        "value": "Add bank account"
      }
    ]
  },
  {
    "key": "UI_DELETE_BANK_ACCOUNTS",
    "values": [
      {
        "iso": "nl",
        "value": "Bankrekeningen verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete bank accounts"
      }
    ]
  },
  {
    "key": "UI_DELETE_BANK_ACCOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Bankrekening verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete bank account"
      }
    ]
  },
  {
    "key": "UI_ADD_ATTACHMENT",
    "values": [
      {
        "iso": "nl",
        "value": "Bijlage toevoegen"
      },
      {
        "iso": "en",
        "value": "Add attachment"
      }
    ]
  },
  {
    "key": "UI_DELETE_ATTACHMENT",
    "values": [
      {
        "iso": "nl",
        "value": "Bijlage verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete attachment"
      }
    ]
  },
  {
    "key": "UI_ADD_CUSTOMER",
    "values": [
      {
        "iso": "nl",
        "value": "Klant aanmaken"
      },
      {
        "iso": "en",
        "value": "Create customer"
      }
    ]
  },
  {
    "key": "UI_DELETE_CUSTOMERS",
    "values": [
      {
        "iso": "nl",
        "value": "Klanten verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete customers"
      }
    ]
  },
  {
    "key": "UI_DELETE_CREDITNOTES",
    "values": [
      {
        "iso": "nl",
        "value": "Creditnota's verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete creditnotes"
      }
    ]
  },
  {
    "key": "UI_REFRESH_DATA",
    "values": [
      {
        "iso": "nl",
        "value": "Herladen"
      },
      {
        "iso": "en",
        "value": "Refresh"
      }
    ]
  },
  {
    "key": "UI_CONFIRM_DELETE_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Bent u zeker dat u geselecteerde gegevens wenst te wissen? Verwijderen van data is altijd definitief en kan niet meer ongedaan gemaakt worden!"
      },
      {
        "iso": "en",
        "value": "Are you sure you want to delete selected data? Deletion of data is always permanent and cannot be undone!"
      }
    ]
  },
  {
    "key": "UI_CONFIRM_DELETE_MESSAGE_BODY",
    "values": [
      {
        "iso": "nl",
        "value": "Bent u zeker dat u deze gegevens wenst te wissen?"
      },
      {
        "iso": "en",
        "value": "Are you sure you want to delete this data?"
      }
    ]
  },
  {
    "key": "UI_CONFIRM_DELETE_SELECTED_MESSAGE_BODY",
    "values": [
      {
        "iso": "nl",
        "value": "Bent u zeker dat u geselecteerde gegevens wenst te wissen?"
      },
      {
        "iso": "en",
        "value": "Are you sure you want to delete selected data?"
      }
    ]
  },
  {
    "key": "UI_CONFIRM_DELETE_MESSAGE_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Verwijderen van data is altijd definitief, eenmaal gewist is er geen enkele mogelijkheid om deze data te recupereren!"
      },
      {
        "iso": "en",
        "value": "Deleting data is always final, once deleted there is no possibility to recover this data!"
      }
    ]
  },
  {
    "key": "UI_DUTCH",
    "values": [
      {
        "iso": "nl",
        "value": "Nederlands"
      },
      {
        "iso": "en",
        "value": "Dutch"
      }
    ]
  },
  {
    "key": "UI_ENGLISH",
    "values": [
      {
        "iso": "nl",
        "value": "Engels"
      },
      {
        "iso": "en",
        "value": "English"
      }
    ]
  },
  {
    "key": "UI_ADDRESSES",
    "values": [
      {
        "iso": "nl",
        "value": "Adressen"
      },
      {
        "iso": "en",
        "value": "Addresses"
      }
    ]
  },
  {
    "key": "UI_STREET",
    "values": [
      {
        "iso": "nl",
        "value": "Straat naam"
      },
      {
        "iso": "en",
        "value": "Street name"
      }
    ]
  },
  {
    "key": "UI_HOUSENUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "Huisnummer"
      },
      {
        "iso": "en",
        "value": "House number"
      }
    ]
  },
  {
    "key": "UI_ZIP",
    "values": [
      {
        "iso": "nl",
        "value": "Postcode"
      },
      {
        "iso": "en",
        "value": "Postal code"
      }
    ]
  },
  {
    "key": "UI_CITY",
    "values": [
      {
        "iso": "nl",
        "value": "Stad"
      },
      {
        "iso": "en",
        "value": "City"
      }
    ]
  },
  {
    "key": "UI_COUNTY",
    "values": [
      {
        "iso": "nl",
        "value": "Provincie"
      },
      {
        "iso": "en",
        "value": "County"
      }
    ]
  },
  {
    "key": "UI_COUNTRY",
    "values": [
      {
        "iso": "nl",
        "value": "Land"
      },
      {
        "iso": "en",
        "value": "Country"
      }
    ]
  },
  {
    "key": "UI_WARNING",
    "values": [
      {
        "iso": "nl",
        "value": "Interne waarschuwing"
      },
      {
        "iso": "en",
        "value": "Internal warning"
      }
    ]
  },
  {
    "key": "UI_UPDATE",
    "values": [
      {
        "iso": "nl",
        "value": "Bewerken"
      },
      {
        "iso": "en",
        "value": "Edit"
      }
    ]
  },
  {
    "key": "UI_DELETE",
    "values": [
      {
        "iso": "nl",
        "value": "Verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete"
      }
    ]
  },
  {
    "key": "UI_DOCUMENTS",
    "values": [
      {
        "iso": "nl",
        "value": "Documenten"
      },
      {
        "iso": "en",
        "value": "Documents"
      }
    ]
  },
  {
    "key": "UI_ISO",
    "values": [
      {
        "iso": "nl",
        "value": "ISO code"
      },
      {
        "iso": "en",
        "value": "ISO code"
      }
    ]
  },
  {
    "key": "UI_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Omschrijving"
      },
      {
        "iso": "en",
        "value": "Description"
      }
    ]
  },
  {
    "key": "UI_VAT_REQUIRED",
    "values": [
      {
        "iso": "nl",
        "value": "BTW plichtig"
      },
      {
        "iso": "en",
        "value": "VAT liable"
      }
    ]
  },
  {
    "key": "UI_SAVE",
    "values": [
      {
        "iso": "nl",
        "value": "Opslaan"
      },
      {
        "iso": "en",
        "value": "Save"
      }
    ]
  },
  {
    "key": "UI_MODAL_CREATE_ARTICLE_CATEGORY",
    "values": [
      {
        "iso": "nl",
        "value": "Artikel categorie aanmaken"
      },
      {
        "iso": "en",
        "value": "Create article category"
      }
    ]
  },
  {
    "key": "UI_SAVE_AND_USE",
    "values": [
      {
        "iso": "nl",
        "value": "Opslaan en gebruiken"
      },
      {
        "iso": "en",
        "value": "Save and use"
      }
    ]
  },
  {
    "key": "UI_DOCUMENTS",
    "values": [
      {
        "iso": "nl",
        "value": "Documenten"
      },
      {
        "iso": "en",
        "value": "Documents"
      }
    ]
  },
  {
    "key": "UI_DATE",
    "values": [
      {
        "iso": "nl",
        "value": "Datum"
      },
      {
        "iso": "en",
        "value": "Date"
      }
    ]
  },
  {
    "key": "UI_PAYMENT_METHOD",
    "values": [
      {
        "iso": "nl",
        "value": "Betaalmethode"
      },
      {
        "iso": "en",
        "value": "Payment method"
      }
    ]
  },
  {
    "key": "UI_PAY_LICENSE_ONLINE",
    "values": [
      {
        "iso": "nl",
        "value": "Online & direct betalen"
      },
      {
        "iso": "en",
        "value": "Pay online & direct"
      }
    ]
  },
  {
    "key": "UI_PAY_LICENSE_ON_INVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "Betaal later via factuur"
      },
      {
        "iso": "en",
        "value": "Pay later by invoice"
      }
    ]
  },
  {
    "key": "UI_PAY_LICENSE_ON_CREDIT",
    "values": [
      {
        "iso": "nl",
        "value": "Betaal via krediet"
      },
      {
        "iso": "en",
        "value": "Pay by credit"
      }
    ]
  },
  {
    "key": "UI_LICENSE_AUTO_PROLONGUE_TOGGLE",
    "values": [
      {
        "iso": "nl",
        "value": "Licentie automatisch vernieuwen"
      },
      {
        "iso": "en",
        "value": "Automatic prolongue the license"
      }
    ]
  },
  {
    "key": "UI_LICENSE_PAYMENT_METHOD_ATTENTION_CREDIT",
    "values": [
      {
        "iso": "nl",
        "value": "Wanneer het kredietbedrag niet toereikend is, zal het resterende bedrag worden gefactureerd."
      },
      {
        "iso": "en",
        "value": "When the credit amount is insufficient, the remaining balance will be invoiced."
      }
    ]
  },
  {
    "key": "UI_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Bericht"
      },
      {
        "iso": "en",
        "value": "Message"
      }
    ]
  },
  {
    "key": "UI_SYSTEM",
    "values": [
      {
        "iso": "nl",
        "value": "Systeem"
      },
      {
        "iso": "en",
        "value": "System"
      }
    ]
  },
  {
    "key": "UI_MAPS_OPEN_REQUEST",
    "values": [
      {
        "iso": "nl",
        "value": "Hoe wenst u dit adres te raadplegen?"
      },
      {
        "iso": "en",
        "value": "How would you like to consult this address?"
      }
    ]
  },
  {
    "key": "UI_LOCATION",
    "values": [
      {
        "iso": "nl",
        "value": "Locatie"
      },
      {
        "iso": "en",
        "value": "Location"
      }
    ]
  },
  {
    "key": "UI_DIRECTIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Routebeschrijving"
      },
      {
        "iso": "en",
        "value": "Directions"
      }
    ]
  },
  {
    "key": "UI_CONTACT_CONTACT",
    "values": [
      {
        "iso": "nl",
        "value": "Hoe wenst u de contactpersoon te contacteren?"
      },
      {
        "iso": "en",
        "value": "How would you like to contact this person?"
      }
    ]
  },
  {
    "key": "UI_EMAIL_CONTACT",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail"
      },
      {
        "iso": "en",
        "value": "Email"
      }
    ]
  },
  {
    "key": "UI_PHONE_CONTACT",
    "values": [
      {
        "iso": "nl",
        "value": "GSM"
      },
      {
        "iso": "en",
        "value": "GSM"
      }
    ]
  },
  {
    "key": "UI_ADD",
    "values": [
      {
        "iso": "nl",
        "value": "Toevoegen"
      },
      {
        "iso": "en",
        "value": "Add"
      }
    ]
  },
  {
    "key": "UI_YES",
    "values": [
      {
        "iso": "nl",
        "value": "Ja"
      },
      {
        "iso": "en",
        "value": "Yes"
      }
    ]
  },
  {
    "key": "UI_NO",
    "values": [
      {
        "iso": "nl",
        "value": "Nee"
      },
      {
        "iso": "en",
        "value": "No"
      }
    ]
  },
  {
    "key": "UI_CANCEL",
    "values": [
      {
        "iso": "nl",
        "value": "Annuleren"
      },
      {
        "iso": "en",
        "value": "Cancel"
      }
    ]
  },
  {
    "key": "UI_SUPPLIERS",
    "values": [
      {
        "iso": "nl",
        "value": "Leveranciers"
      },
      {
        "iso": "en",
        "value": "Suppliers"
      }
    ]
  },
  {
    "key": "UI_DELETE_SUPPLIERS",
    "values": [
      {
        "iso": "nl",
        "value": "Leveranciers verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete suppliers"
      }
    ]
  },
  {
    "key": "UI_ADD_SUPPLIER",
    "values": [
      {
        "iso": "nl",
        "value": "Leverancier aanmaken"
      },
      {
        "iso": "en",
        "value": "Create supplier"
      }
    ]
  },
  {
    "key": "UI_SUPPLIER_NUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "Leveranciersnummer"
      },
      {
        "iso": "en",
        "value": "supplier number"
      }
    ]
  },
  {
    "key": "UI_WEBSHOP",
    "values": [
      {
        "iso": "nl",
        "value": "Webshop"
      },
      {
        "iso": "en",
        "value": "Webshop"
      }
    ]
  },
  {
    "key": "UI_PRODUCTS",
    "values": [
      {
        "iso": "nl",
        "value": "Producten"
      },
      {
        "iso": "en",
        "value": "Products"
      }
    ]
  },
  {
    "key": "UI_SERVICES",
    "values": [
      {
        "iso": "nl",
        "value": "Diensten"
      },
      {
        "iso": "en",
        "value": "Services"
      }
    ]
  },
  {
    "key": "UI_COMBINED_ARTICLES",
    "values": [
      {
        "iso": "nl",
        "value": "Gecombineerde artikelen"
      },
      {
        "iso": "en",
        "value": "Combined articles"
      }
    ]
  },
  {
    "key": "UI_COMPOSITE_ARTICLES",
    "values": [
      {
        "iso": "nl",
        "value": "Samengestelde artikelen"
      },
      {
        "iso": "en",
        "value": "Composite articles"
      }
    ]
  },
  {
    "key": "UI_COMBINED_PRODUCTS",
    "values": [
      {
        "iso": "nl",
        "value": "Gecombineerde producten"
      },
      {
        "iso": "en",
        "value": "Combined products"
      }
    ]
  },
  {
    "key": "UI_COMPOSITE_PRODUCTS",
    "values": [
      {
        "iso": "nl",
        "value": "Samengestelde producten"
      },
      {
        "iso": "en",
        "value": "Composite products"
      }
    ]
  },
  {
    "key": "UI_STOCK_PRODUCTS",
    "values": [
      {
        "iso": "nl",
        "value": "Stock producten"
      },
      {
        "iso": "en",
        "value": "Stock products"
      }
    ]
  },
  {
    "key": "UI_STOCK_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Stocklijst"
      },
      {
        "iso": "en",
        "value": "Stock list"
      }
    ]
  },
  {
    "key": "UI_SKU",
    "values": [
      {
        "iso": "nl",
        "value": "SKU"
      },
      {
        "iso": "en",
        "value": "SKU"
      }
    ]
  },
  {
    "key": "UI_CATEGORY",
    "values": [
      {
        "iso": "nl",
        "value": "Categorie"
      },
      {
        "iso": "en",
        "value": "Category"
      }
    ]
  },
  {
    "key": "UI_DEFAULT_SALESPRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Standaard verkoopprijs"
      },
      {
        "iso": "en",
        "value": "Default salesprice"
      }
    ]
  },
  {
    "key": "UI_ADD_SERVICE",
    "values": [
      {
        "iso": "nl",
        "value": "Dienst aanmaken"
      },
      {
        "iso": "en",
        "value": "Add service"
      }
    ]
  },
  {
    "key": "UI_DELETE_SERVICES",
    "values": [
      {
        "iso": "nl",
        "value": "Diensten verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete services"
      }
    ]
  },
  {
    "key": "UI_EAN",
    "values": [
      {
        "iso": "nl",
        "value": "EAN-code"
      },
      {
        "iso": "en",
        "value": "EAN Code"
      }
    ]
  },
  {
    "key": "UI_ADD_PRODUCT",
    "values": [
      {
        "iso": "nl",
        "value": "Product aanmaken"
      },
      {
        "iso": "en",
        "value": "Create product"
      }
    ]
  },
  {
    "key": "UI_DELETE_PRODUCTS",
    "values": [
      {
        "iso": "nl",
        "value": "producten verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete products"
      }
    ]
  },
  {
    "key": "UI_PURCHASEPRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoopprijs"
      },
      {
        "iso": "en",
        "value": "Purchase price"
      }
    ]
  },
  {
    "key": "UI_URL",
    "values": [
      {
        "iso": "nl",
        "value": "Url"
      },
      {
        "iso": "en",
        "value": "URL"
      }
    ]
  },
  {
    "key": "UI_ADD_COMBINEDARTICLE",
    "values": [
      {
        "iso": "nl",
        "value": "Gecombineerd artikel aanmaken"
      },
      {
        "iso": "en",
        "value": "Create combined article"
      }
    ]
  },
  {
    "key": "UI_DELETE_COMBINEDARTICLES",
    "values": [
      {
        "iso": "nl",
        "value": "gecombineerde artikels verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete combined articles"
      }
    ]
  },
  {
    "key": "UI_GOTO_SUPPLIER",
    "values": [
      {
        "iso": "nl",
        "value": "Ga naar leverancier"
      },
      {
        "iso": "en",
        "value": "Go to supplier"
      }
    ]
  },
  {
    "key": "UI_GOTO_PRODUCT",
    "values": [
      {
        "iso": "nl",
        "value": "Ga naar het product"
      },
      {
        "iso": "en",
        "value": "Go to the product"
      }
    ]
  },
  {
    "key": "UI_GOTO_SERVICE",
    "values": [
      {
        "iso": "nl",
        "value": "Ga naar de dienst"
      },
      {
        "iso": "en",
        "value": "Go to the service"
      }
    ]
  },
  {
    "key": "UI_VISIT_LINK",
    "values": [
      {
        "iso": "nl",
        "value": "Bezoek website"
      },
      {
        "iso": "en",
        "value": "Visit website"
      }
    ]
  },
  {
    "key": "UI_USE_PRODUCTSUPPLIER",
    "values": [
      {
        "iso": "nl",
        "value": "Hoe wenst u deze link met de leverancier te gebruiken?"
      },
      {
        "iso": "en",
        "value": "How do you wish to use this link with the supplier?"
      }
    ]
  },
  {
    "key": "UI_SUBLINES",
    "values": [
      {
        "iso": "nl",
        "value": "Sublijnen"
      },
      {
        "iso": "en",
        "value": "Sublines"
      }
    ]
  },
  {
    "key": "UI_INVOICE_SUBLINES",
    "values": [
      {
        "iso": "nl",
        "value": "Factuurlijnen"
      },
      {
        "iso": "en",
        "value": "Invoice lines"
      }
    ]
  },
  {
    "key": "UI_BULK_EDIT_DOCUMENT_SUBLINES",
    "values": [
      {
        "iso": "nl",
        "value": "Documentlijnen"
      },
      {
        "iso": "en",
        "value": "Document sublines"
      }
    ]
  },
  {
    "key": "UI_BULK_EDIT_LEDGER_SALES_INVOICE_SUBLINES",
    "values": [
      {
        "iso": "nl",
        "value": "Verkoop factuurlijnen aanpassen"
      },
      {
        "iso": "en",
        "value": "Edit sales invoice lines"
      }
    ]
  },
  {
    "key": "UI_BULK_EDIT_LEDGER_SALES_CREDITNOTE_SUBLINES",
    "values": [
      {
        "iso": "nl",
        "value": "Verkoop creditnotalijn aanpassen"
      },
      {
        "iso": "en",
        "value": "Edit sales creditnote lines"
      }
    ]
  },
  {
    "key": "UI_BULK_EDIT_LEDGER_PURCHASE_INVOICE_SUBLINES",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoop factuurlijnen aanpassen"
      },
      {
        "iso": "en",
        "value": "Edit purchase invoice lines"
      }
    ]
  },
  {
    "key": "UI_BULK_EDIT_LEDGER_PURCHASE_CREDITNOTE_SUBLINES",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoop creditnotalijnen aanpassen"
      },
      {
        "iso": "en",
        "value": "Edit purchase invoice lines"
      }
    ]
  },
  {
    "key": "UI_QUOTE_SUBLINES",
    "values": [
      {
        "iso": "nl",
        "value": "Offertelijnen"
      },
      {
        "iso": "en",
        "value": "Quotation lines"
      }
    ]
  },
  {
    "key": "UI_ORDER_SUBLINES",
    "values": [
      {
        "iso": "nl",
        "value": "Bestellijnen"
      },
      {
        "iso": "en",
        "value": "Order lines"
      }
    ]
  },
  {
    "key": "UI_PRODUCT_RECEPTION_SUBLINES",
    "values": [
      {
        "iso": "nl",
        "value": "Receptielijnen"
      },
      {
        "iso": "en",
        "value": "Reception lines"
      }
    ]
  },
  {
    "key": "UI_PURCHASE_INVOICE_SUBLINES",
    "values": [
      {
        "iso": "nl",
        "value": "Factuurlijnen"
      },
      {
        "iso": "en",
        "value": "Invoice lines"
      }
    ]
  },
  {
    "key": "UI_PRODUCT_DELIVERY_SUBLINES",
    "values": [
      {
        "iso": "nl",
        "value": "Leverlijnen"
      },
      {
        "iso": "en",
        "value": "Delivery lines"
      }
    ]
  },
  {
    "key": "UI_PURCHASE_CREDITNOTE_SUBLINES",
    "values": [
      {
        "iso": "nl",
        "value": "Kredietlijnen"
      },
      {
        "iso": "en",
        "value": "Credit lines"
      }
    ]
  },
  {
    "key": "UI_CREDITNOTE_SUBLINES",
    "values": [
      {
        "iso": "nl",
        "value": "Kredietlijnen"
      },
      {
        "iso": "en",
        "value": "Credit lines"
      }
    ]
  },
  {
    "key": "UI_PURCHASE_ORDER_SUBLINES",
    "values": [
      {
        "iso": "nl",
        "value": "Bestellijnen"
      },
      {
        "iso": "en",
        "value": "Order lines"
      }
    ]
  },
  {
    "key": "UI_AMOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Aantal"
      },
      {
        "iso": "en",
        "value": "Amount"
      }
    ]
  },
  {
    "key": "UI_PRODUCT_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Product naam"
      },
      {
        "iso": "en",
        "value": "Product name"
      }
    ]
  },
  {
    "key": "UI_SERVICE_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Dienst naam"
      },
      {
        "iso": "en",
        "value": "Service name"
      }
    ]
  },
  {
    "key": "UI_USE_COMBINEDARTICLE_SUBLINE_PRODUCT",
    "values": [
      {
        "iso": "nl",
        "value": "Hoe wenst u dit product te gebruiken?"
      },
      {
        "iso": "en",
        "value": "How do you wish to use this product?"
      }
    ]
  },
  {
    "key": "UI_USE_COMBINEDARTICLE_SUBLINE_SERVICE",
    "values": [
      {
        "iso": "nl",
        "value": "Hoe wenst u deze dienst te gebruiken?"
      },
      {
        "iso": "en",
        "value": "How do you wish to use this sevice?"
      }
    ]
  },
  {
    "key": "UI_SERIALNUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "Serienummer"
      },
      {
        "iso": "en",
        "value": "Serialnumber"
      }
    ]
  },
  {
    "key": "UI_SERIALNUMBERS_SEPERATE",
    "values": [
      {
        "iso": "nl",
        "value": "Serienummers (Splitsen doormiddel van een komma)"
      },
      {
        "iso": "en",
        "value": "Serialnumber (Split by decimal)"
      }
    ]
  },
  {
    "key": "UI_MONTHS",
    "values": [
      {
        "iso": "nl",
        "value": "Maanden"
      },
      {
        "iso": "en",
        "value": "Months"
      }
    ]
  },
  {
    "key": "UI_MONTH",
    "values": [
      {
        "iso": "nl",
        "value": "Maand"
      },
      {
        "iso": "en",
        "value": "Month"
      }
    ]
  },
  {
    "key": "UI_YEARS",
    "values": [
      {
        "iso": "nl",
        "value": "Jaren"
      },
      {
        "iso": "en",
        "value": "Years"
      }
    ]
  },
  {
    "key": "UI_YEAR",
    "values": [
      {
        "iso": "nl",
        "value": "Jaar"
      },
      {
        "iso": "en",
        "value": "Year"
      }
    ]
  },
  {
    "key": "UI_SALESPRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Verkoopprijs"
      },
      {
        "iso": "en",
        "value": "Sales price"
      }
    ]
  },
  {
    "key": "UI_SALESPRICE_INCL_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Verkoopprijs incl. BTW"
      },
      {
        "iso": "en",
        "value": "Sales price incl. VAT"
      }
    ]
  },
  {
    "key": "UI_CLICK_ON_PRICE_BUTTON_TO_CHANGE_THE_PRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Klik op excl. / incl. om deze te wijzigen"
      },
      {
        "iso": "en",
        "value": "Click on excl. / incl. to modify these."
      }
    ]
  },
  {
    "key": "UI_CLICK_ON_DISCOUNT_BUTTON_TO_CHANGE_THE_DISCOUNT_VIEW",
    "values": [
      {
        "iso": "nl",
        "value": "Wijzig het kortingsoverzicht door op de knop te klikken."
      },
      {
        "iso": "en",
        "value": "Modify the discount overview by clicking the button."
      }
    ]
  },
  {
    "key": "UI_WARRANTY",
    "values": [
      {
        "iso": "nl",
        "value": "Garantie"
      },
      {
        "iso": "en",
        "value": "Warranty"
      }
    ]
  },
  {
    "key": "UI_STATUS",
    "values": [
      {
        "iso": "nl",
        "value": "Status"
      },
      {
        "iso": "en",
        "value": "Status"
      }
    ]
  },
  {
    "key": "UI_STOCK_STOCK",
    "values": [
      {
        "iso": "nl",
        "value": "In stock"
      },
      {
        "iso": "en",
        "value": "In stock"
      }
    ]
  },
  {
    "key": "UI_STOCK_SOLD",
    "values": [
      {
        "iso": "nl",
        "value": "Verkocht"
      },
      {
        "iso": "en",
        "value": "Sold"
      }
    ]
  },
  {
    "key": "UI_STOCK_USED",
    "values": [
      {
        "iso": "nl",
        "value": "Gebruikt"
      },
      {
        "iso": "en",
        "value": "Used"
      }
    ]
  },
  {
    "key": "UI_STOCK_LOANED",
    "values": [
      {
        "iso": "nl",
        "value": "Uitgeleend"
      },
      {
        "iso": "en",
        "value": "Loaned"
      }
    ]
  },
  {
    "key": "UI_STOCK_DEFECT",
    "values": [
      {
        "iso": "nl",
        "value": "Defect"
      },
      {
        "iso": "en",
        "value": "Defect"
      }
    ]
  },
  {
    "key": "UI_STOCK_DESTROYED",
    "values": [
      {
        "iso": "nl",
        "value": "Vernietigd"
      },
      {
        "iso": "en",
        "value": "Destroyed"
      }
    ]
  },
  {
    "key": "UI_SELECT_CLEAR",
    "values": [
      {
        "iso": "nl",
        "value": "Verwijder filter"
      },
      {
        "iso": "en",
        "value": "Clear filter"
      }
    ]
  },
  {
    "key": "UI_PURCHASE_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoop informatie"
      },
      {
        "iso": "en",
        "value": "Purchase information"
      }
    ]
  },
  {
    "key": "UI_SALES_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Verkoop informatie"
      },
      {
        "iso": "en",
        "value": "Sales information"
      }
    ]
  },
  {
    "key": "UI_INVOICES",
    "values": [
      {
        "iso": "nl",
        "value": "Facturen"
      },
      {
        "iso": "en",
        "value": "Invoices"
      }
    ]
  },
  {
    "key": "UI_SALES_INVOICES",
    "values": [
      {
        "iso": "nl",
        "value": "Verkoop facturen"
      },
      {
        "iso": "en",
        "value": "Sales invoices"
      }
    ]
  },
  {
    "key": "UI_SALES_ORDERS",
    "values": [
      {
        "iso": "nl",
        "value": "Verkoop bestellingen"
      },
      {
        "iso": "en",
        "value": "Sales orders"
      }
    ]
  },
  {
    "key": "UI_PURCHASE_ORDERS",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoop bestellingen"
      },
      {
        "iso": "en",
        "value": "Purchase orders"
      }
    ]
  },
  {
    "key": "UI_SALES_QUOTES",
    "values": [
      {
        "iso": "nl",
        "value": "Verkoop offertes"
      },
      {
        "iso": "en",
        "value": "Sales quotes"
      }
    ]
  },
  {
    "key": "UI_ORDERS",
    "values": [
      {
        "iso": "nl",
        "value": "Bestellingen"
      },
      {
        "iso": "en",
        "value": "Orders"
      }
    ]
  },
  {
    "key": "UI_QUOTES",
    "values": [
      {
        "iso": "nl",
        "value": "Offertes"
      },
      {
        "iso": "en",
        "value": "Quotes"
      }
    ]
  },
  {
    "key": "UI_PURCHASEORDERS",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoop bestelling"
      },
      {
        "iso": "en",
        "value": "Purchase orders"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_NUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "Document nummer"
      },
      {
        "iso": "en",
        "value": "Document number"
      }
    ]
  },
  {
    "key": "UI_COMPANY_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Bedrijf naam"
      },
      {
        "iso": "en",
        "value": "Company name"
      }
    ]
  },
  {
    "key": "UI_CUSTOMER_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Klant naam"
      },
      {
        "iso": "en",
        "value": "Customer name"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_VALUE_EXCL_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Document waarde excl. BTW"
      },
      {
        "iso": "en",
        "value": "Document value excl. VAT"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_VALUE_INCL_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Document waarde incl. BTW"
      },
      {
        "iso": "en",
        "value": "Document value incl. VAT"
      }
    ]
  },
  {
    "key": "UI_VALUE_INCL_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Waarde incl. BTW"
      },
      {
        "iso": "en",
        "value": "Value incl. VAT"
      }
    ]
  },
  {
    "key": "UI_ENDDATE",
    "values": [
      {
        "iso": "nl",
        "value": "Vervaldatum"
      },
      {
        "iso": "en",
        "value": "Expiration date"
      }
    ]
  },
  {
    "key": "UI_ADD_QUOTE",
    "values": [
      {
        "iso": "nl",
        "value": "Offerte toevoegen"
      },
      {
        "iso": "en",
        "value": "Add quote"
      }
    ]
  },
  {
    "key": "UI_DELETE_QUOTES",
    "values": [
      {
        "iso": "nl",
        "value": "Offertes verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete quotes"
      }
    ]
  },
  {
    "key": "UI_DELETE_INVOICE_REMINDERS",
    "values": [
      {
        "iso": "nl",
        "value": "Herinneringen verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete reminders"
      }
    ]
  },
  {
    "key": "UI_INTERNAL_NOTE",
    "values": [
      {
        "iso": "nl",
        "value": "Interne opmerking"
      },
      {
        "iso": "en",
        "value": "Internal remark"
      }
    ]
  },
  {
    "key": "UI_PRODUCT_SKU",
    "values": [
      {
        "iso": "nl",
        "value": "Product SKU"
      },
      {
        "iso": "en",
        "value": "Product SKU"
      }
    ]
  },
  {
    "key": "UI_SERVICE_SKU",
    "values": [
      {
        "iso": "nl",
        "value": "Dienst SKU"
      },
      {
        "iso": "en",
        "value": "Service SKU"
      }
    ]
  },
  {
    "key": "UI_PRODUCT",
    "values": [
      {
        "iso": "nl",
        "value": "Product"
      },
      {
        "iso": "en",
        "value": "Product"
      }
    ]
  },
  {
    "key": "UI_SERVICE",
    "values": [
      {
        "iso": "nl",
        "value": "Dienst"
      },
      {
        "iso": "en",
        "value": "Service"
      }
    ]
  },
  {
    "key": "UI_COMBINED_ARTICLE",
    "values": [
      {
        "iso": "nl",
        "value": "Gecombineerd artikel"
      },
      {
        "iso": "en",
        "value": "Combined article"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_VALUE_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Document BTW waarde"
      },
      {
        "iso": "en",
        "value": "Document VAT value"
      }
    ]
  },
  {
    "key": "UI_VAT_NAME_VALUE",
    "values": [
      {
        "iso": "nl",
        "value": "BTW tarief"
      },
      {
        "iso": "en",
        "value": "Vat rate"
      }
    ]
  },
  {
    "key": "UI_BASE_SALESPRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Basis verkoopprijs"
      },
      {
        "iso": "en",
        "value": "Base sales price"
      }
    ]
  },
  {
    "key": "UI_DISCOUNT_AMOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Kortings bedrag"
      },
      {
        "iso": "en",
        "value": "Discount amount"
      }
    ]
  },
  {
    "key": "UI_METHOD",
    "values": [
      {
        "iso": "nl",
        "value": "Methode"
      },
      {
        "iso": "en",
        "value": "Method"
      }
    ]
  },
  {
    "key": "UI_LEDGER_EXPORT",
    "values": [
      {
        "iso": "nl",
        "value": "Export taak"
      },
      {
        "iso": "en",
        "value": "Export task"
      }
    ]
  },
  {
    "key": "UI_UNIT_SALESPRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Eenheidsprijs"
      },
      {
        "iso": "en",
        "value": "Unit sales price"
      }
    ]
  },
  {
    "key": "UI_UNIT_REFUNDPRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Eenheidsprijs"
      },
      {
        "iso": "en",
        "value": "Unit refund price"
      }
    ]
  },
  {
    "key": "UI_MENU_CHANGE_TO_FULLWIDTH_CONTENT",
    "values": [
      {
        "iso": "nl",
        "value": "Volledige breedte"
      },
      {
        "iso": "en",
        "value": "Fullwidth"
      }
    ]
  },
  {
    "key": "UI_MENU_CHANGE_TO_BOXED_CONTENT",
    "values": [
      {
        "iso": "nl",
        "value": "Beperkte breedte"
      },
      {
        "iso": "en",
        "value": "Boxed width"
      }
    ]
  },
  {
    "key": "UI_USE_PRODUCT",
    "values": [
      {
        "iso": "nl",
        "value": "Hoe wenst u deze link met het product te gebruiken?"
      },
      {
        "iso": "en",
        "value": "How do you wish to use this link with the product?"
      }
    ]
  },
  {
    "key": "UI_USE_SERVICE",
    "values": [
      {
        "iso": "nl",
        "value": "Hoe wenst u deze link met de dienst te gebruiken?"
      },
      {
        "iso": "en",
        "value": "How do you wish to use this link with the service?"
      }
    ]
  },
  {
    "key": "UI_USE_COMBINEDARTICLE",
    "values": [
      {
        "iso": "nl",
        "value": "Hoe wenst u deze link met het gecombineerde artikel te gebruiken?"
      },
      {
        "iso": "en",
        "value": "How do you wish to use this link with the combined article?"
      }
    ]
  },
  {
    "key": "UI_GOTO_COMBINEDARTICLE",
    "values": [
      {
        "iso": "nl",
        "value": "Ga naar het gecombineerd artikel"
      },
      {
        "iso": "en",
        "value": "Got to the combined article"
      }
    ]
  },
  {
    "key": "UI_DEFAULT_VAT_NAME_VALUE",
    "values": [
      {
        "iso": "nl",
        "value": "Standaard BTW tarief"
      },
      {
        "iso": "en",
        "value": "Default VAT rate"
      }
    ]
  },
  {
    "key": "UI_DEFAULT_VAT_SALES_NAME_VALUE",
    "values": [
      {
        "iso": "nl",
        "value": "Standaard BTW tarief voor verkoop"
      },
      {
        "iso": "en",
        "value": "Default VAT rate for sales"
      }
    ]
  },
  {
    "key": "UI_DEFAULT_VAT_PURCHASE_NAME_VALUE",
    "values": [
      {
        "iso": "nl",
        "value": "Standaard BTW tarief voor aankoop"
      },
      {
        "iso": "en",
        "value": "Default VAT rate for purchase"
      }
    ]
  },
  {
    "key": "UI_COMPANY_ADDRESS",
    "values": [
      {
        "iso": "nl",
        "value": "Bedrijf adres"
      },
      {
        "iso": "en",
        "value": "Company address"
      }
    ]
  },
  {
    "key": "UI_CUSTOMER_ADDRESS",
    "values": [
      {
        "iso": "nl",
        "value": "Klant adres"
      },
      {
        "iso": "en",
        "value": "Customer address"
      }
    ]
  },
  {
    "key": "UI_ADD_ORDER",
    "values": [
      {
        "iso": "nl",
        "value": "Bestelling toevoegen"
      },
      {
        "iso": "en",
        "value": "Add order"
      }
    ]
  },
  {
    "key": "UI_DELETE_ORDERS",
    "values": [
      {
        "iso": "nl",
        "value": "Bestellingen verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete orders"
      }
    ]
  },
  {
    "key": "UI_QUOTE_UPGRADE_ORDER",
    "values": [
      {
        "iso": "nl",
        "value": "Offerte naar bestelling omzetten"
      },
      {
        "iso": "en",
        "value": "Convert quote to order"
      }
    ]
  },
  {
    "key": "UI_ORDER_UPGRADE_INVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "Bestelling naar factuur omzetten"
      },
      {
        "iso": "en",
        "value": "Convert order to invoice"
      }
    ]
  },
  {
    "key": "UI_ADD_INVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "Factuur toevoegen"
      },
      {
        "iso": "en",
        "value": "Add invoice"
      }
    ]
  },
  {
    "key": "UI_DELETE_INVOICES",
    "values": [
      {
        "iso": "nl",
        "value": "Facturen verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete invoices"
      }
    ]
  },
  {
    "key": "UI_SUPPLIER_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Leveranciersnaam"
      },
      {
        "iso": "en",
        "value": "Supplier name"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_VALUE",
    "values": [
      {
        "iso": "nl",
        "value": "Document waarde"
      },
      {
        "iso": "en",
        "value": "Document value"
      }
    ]
  },
  {
    "key": "UI_ADD_PURCHASEORDER",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoop bestelling toevoegen"
      },
      {
        "iso": "en",
        "value": "Add purchase order"
      }
    ]
  },
  {
    "key": "UI_DELETE_PURCHASEORDERS",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoop bestellingen verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete purchase orders"
      }
    ]
  },
  {
    "key": "UI_SUPPLIER_ADDRESS",
    "values": [
      {
        "iso": "nl",
        "value": "Leverancier adres"
      },
      {
        "iso": "en",
        "value": "Supplier address"
      }
    ]
  },
  {
    "key": "UI_UNIT_PURCHASEPRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Eenheids aankoopprijs"
      },
      {
        "iso": "en",
        "value": "Unit purchaseprice"
      }
    ]
  },
  {
    "key": "UI_PURCHASE_INVOICES",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoopfacturen"
      },
      {
        "iso": "en",
        "value": "Purchase invoices"
      }
    ]
  },
  {
    "key": "UI_PURCHASE_INVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoopfactuur"
      },
      {
        "iso": "en",
        "value": "Purchase invoice"
      }
    ]
  },
  {
    "key": "UI_ADD_PURCHASE_INVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoopfactuur toevoegen"
      },
      {
        "iso": "en",
        "value": "Add purchase invoice"
      }
    ]
  },
  {
    "key": "UI_DELETE_PURCHASE_INVOICES",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoopfacturen verwijderen"
      },
      {
        "iso": "en",
        "value": "Remove purchase invoices"
      }
    ]
  },
  {
    "key": "UI_PERIOD",
    "values": [
      {
        "iso": "nl",
        "value": "Periode"
      },
      {
        "iso": "en",
        "value": "Period"
      }
    ]
  },
  {
    "key": "UI_WRITEOFF_PERIOD",
    "values": [
      {
        "iso": "nl",
        "value": "Periode (in jaren)"
      },
      {
        "iso": "en",
        "value": "Period (in years)"
      }
    ]
  },
  {
    "key": "UI_VAT_PERCENT",
    "values": [
      {
        "iso": "nl",
        "value": "BTW percent"
      },
      {
        "iso": "en",
        "value": "VAT percentage"
      }
    ]
  },
  {
    "key": "UI_IS_VAT_INTRACOMMUNAUTAIRE",
    "values": [
      {
        "iso": "nl",
        "value": "Intracommunautair BTW tarief"
      },
      {
        "iso": "en",
        "value": "Intracommunautair VAT value"
      }
    ]
  },
  {
    "key": "UI_IS_VAT_COUNTRY",
    "values": [
      {
        "iso": "nl",
        "value": "Buitenlands BTW tarief"
      },
      {
        "iso": "en",
        "value": "Foreign VAT value"
      }
    ]
  },
  {
    "key": "UI_RELATION_TYPES",
    "values": [
      {
        "iso": "nl",
        "value": "Relatie types"
      },
      {
        "iso": "en",
        "value": "Relation types"
      }
    ]
  },
  {
    "key": "UI_CUSTOMER_TYPES",
    "values": [
      {
        "iso": "nl",
        "value": "Klant types"
      },
      {
        "iso": "en",
        "value": "Customer types"
      }
    ]
  },
  {
    "key": "UI_RELATION_TYPE",
    "values": [
      {
        "iso": "nl",
        "value": "Relatie type"
      },
      {
        "iso": "en",
        "value": "Relation type"
      }
    ]
  },
  {
    "key": "UI_ARTICLE_CATEGORIES",
    "values": [
      {
        "iso": "nl",
        "value": "Artikel categorieën"
      },
      {
        "iso": "en",
        "value": "Article categories"
      }
    ]
  },
  {
    "key": "UI_PRODUCT_CATEGORIES",
    "values": [
      {
        "iso": "nl",
        "value": "Product categorieën"
      },
      {
        "iso": "en",
        "value": "Product categories"
      }
    ]
  },
  {
    "key": "UI_VAT_RATES",
    "values": [
      {
        "iso": "nl",
        "value": "BTW tarieven"
      },
      {
        "iso": "en",
        "value": "VAT rates"
      }
    ]
  },
  {
    "key": "UI_LEDGER_NUMBERS",
    "values": [
      {
        "iso": "nl",
        "value": "Grootboek nummers"
      },
      {
        "iso": "en",
        "value": "Ledger numbers"
      }
    ]
  },
  {
    "key": "UI_TRANSACTION_GATEWAYS",
    "values": [
      {
        "iso": "nl",
        "value": "Betaling methodes"
      },
      {
        "iso": "en",
        "value": "Payment gateways"
      }
    ]
  },
  {
    "key": "UI_HR",
    "values": [
      {
        "iso": "nl",
        "value": "Personeelszaken"
      },
      {
        "iso": "en",
        "value": "Human resources"
      }
    ]
  },
  {
    "key": "UI_USER",
    "values": [
      {
        "iso": "nl",
        "value": "Gebruiker"
      },
      {
        "iso": "en",
        "value": "User"
      }
    ]
  },
  {
    "key": "UI_USERS",
    "values": [
      {
        "iso": "nl",
        "value": "Gebruikers"
      },
      {
        "iso": "en",
        "value": "Users"
      }
    ]
  },
  {
    "key": "UI_RECOMMEND_END_USER_PRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Aanbevolen eindgebruikersprijs"
      },
      {
        "iso": "en",
        "value": "recommended end user price"
      }
    ]
  },
  {
    "key": "UI_COMPANIES",
    "values": [
      {
        "iso": "nl",
        "value": "Bedrijven"
      },
      {
        "iso": "en",
        "value": "Companies"
      }
    ]
  },
  {
    "key": "UI_USER_SETTINGS",
    "values": [
      {
        "iso": "nl",
        "value": "Gebruikers instellingen"
      },
      {
        "iso": "en",
        "value": "User settings"
      }
    ]
  },
  {
    "key": "UI_SUBSCRIBER_SETTINGS",
    "values": [
      {
        "iso": "nl",
        "value": "Abonnee instellingen"
      },
      {
        "iso": "en",
        "value": "Subscriber settings"
      }
    ]
  },
  {
    "key": "UI_ADD_RELATION_TYPE",
    "values": [
      {
        "iso": "nl",
        "value": "Relatie type toevoegen"
      },
      {
        "iso": "en",
        "value": "Add relation type"
      }
    ]
  },
  {
    "key": "UI_DELETE_RELATION_TYPES",
    "values": [
      {
        "iso": "nl",
        "value": "Relatie types verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete relation types"
      }
    ]
  },
  {
    "key": "UI_ADD_ARTICLE_CATEGORY",
    "values": [
      {
        "iso": "nl",
        "value": "Artikel category toevoegen"
      },
      {
        "iso": "en",
        "value": "Add article category"
      }
    ]
  },
  {
    "key": "UI_DELETE_ARTICLE_CATEGORIES",
    "values": [
      {
        "iso": "nl",
        "value": "Artikel categorieën verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete article categories"
      }
    ]
  },
  {
    "key": "UI_ADD_TRANSACTION_GATEWAY",
    "values": [
      {
        "iso": "nl",
        "value": "Transactie methode toevoegen"
      },
      {
        "iso": "en",
        "value": "Add transaction gateway"
      }
    ]
  },
  {
    "key": "UI_DELETE_TRANSACTION_GATEWAYS",
    "values": [
      {
        "iso": "nl",
        "value": "Transactie methodes verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete transaction gateways"
      }
    ]
  },
  {
    "key": "UI_ADD_VATRATE",
    "values": [
      {
        "iso": "nl",
        "value": "BTW tarief toevoegen"
      },
      {
        "iso": "en",
        "value": "Add vat rate"
      }
    ]
  },
  {
    "key": "UI_DELETE_VATRATES",
    "values": [
      {
        "iso": "nl",
        "value": "BTW tarieven verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete Vat rates"
      }
    ]
  },
  {
    "key": "UI_VAT_RATE",
    "values": [
      {
        "iso": "nl",
        "value": "BTW tarief"
      },
      {
        "iso": "en",
        "value": "VAT rate"
      }
    ]
  },
  {
    "key": "UI_LEDGER_GROUP",
    "values": [
      {
        "iso": "nl",
        "value": "Grootboek groep"
      },
      {
        "iso": "en",
        "value": "Ledger group"
      }
    ]
  },
  {
    "key": "UI_LICENSE_DOCUMENT_LIMIT",
    "values": [
      {
        "iso": "nl",
        "value": "Limit van <b>{{0}} documenten</b> per jaar *"
      },
      {
        "iso": "en",
        "value": "Limit of <b>{{0}} documents</b> per year *"
      }
    ]
  },
  {
    "key": "UI_LICENSE_NO_DOCUMENT_LIMIT",
    "values": [
      {
        "iso": "nl",
        "value": "<b>Geen limiet</b> op documenten"
      },
      {
        "iso": "en",
        "value": "<b>No limit</b> on documents"
      }
    ]
  },
  {
    "key": "UI_LICENSE_USER_LIMIT",
    "values": [
      {
        "iso": "nl",
        "value": "Maximum <b>{{0}} gebruikers</b>"
      },
      {
        "iso": "en",
        "value": "Maximum <b>{{0}} users</b>"
      }
    ]
  },
  {
    "key": "UI_LICENSE_NO_USER_LIMIT",
    "values": [
      {
        "iso": "nl",
        "value": "<b>Geen limiet</b> op gebruikersaantal"
      },
      {
        "iso": "en",
        "value": "<b>No limit</b> on users"
      }
    ]
  },
  {
    "key": "UI_LICENSE_SELECTION_LIMIT_DOCUMENT_ASTRIX",
    "values": [
      {
        "iso": "nl",
        "value": "* Limiet wordt berekent op aankoop en verkoop facturen / creditnota's"
      },
      {
        "iso": "en",
        "value": "* The limit is calculated based on purchase and sales invoices / credit notes"
      }
    ]
  },
  {
    "key": "UI_GENERATE_NEW_PDF",
    "values": [
      {
        "iso": "nl",
        "value": "Genereer nieuw PDF document"
      },
      {
        "iso": "en",
        "value": "Generate new PDF document"
      }
    ]
  },
  {
    "key": "UI_GENERATED_DATE",
    "values": [
      {
        "iso": "nl",
        "value": "Aangemaakt op"
      },
      {
        "iso": "en",
        "value": "Generated on"
      }
    ]
  },
  {
    "key": "UI_PDF_SETTINGS",
    "values": [
      {
        "iso": "nl",
        "value": "Document instellingen"
      },
      {
        "iso": "en",
        "value": "Document settings"
      }
    ]
  },
  {
    "key": "UI_ENTITY_SETTINGS",
    "values": [
      {
        "iso": "nl",
        "value": "Relatie instellingen"
      },
      {
        "iso": "en",
        "value": "Relation settings"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_SETTINGS",
    "values": [
      {
        "iso": "nl",
        "value": "Document instellingen"
      },
      {
        "iso": "en",
        "value": "Document settings"
      }
    ]
  },
  {
    "key": "UI_ENTITY_RELATION_NR_PREFIX",
    "values": [
      {
        "iso": "nl",
        "value": "Prefix voor relatienummer"
      },
      {
        "iso": "en",
        "value": "Prefix for relation number"
      }
    ]
  },
  {
    "key": "UI_ENTITY_RELATION_NR_SIZE",
    "values": [
      {
        "iso": "nl",
        "value": "Groote van relatienummer"
      },
      {
        "iso": "en",
        "value": "Size of relation number"
      }
    ]
  },
  {
    "key": "UI_LEDGER_WRITEOFF_NR_PREFIX",
    "values": [
      {
        "iso": "nl",
        "value": "Prefix voor afschrijvings nummer"
      },
      {
        "iso": "en",
        "value": "Prefix for write-off number"
      }
    ]
  },
  {
    "key": "UI_LEDGER_WRITEOFF_NR_SIZE",
    "values": [
      {
        "iso": "nl",
        "value": "Groote van afschrijvings nummer"
      },
      {
        "iso": "en",
        "value": "Size of write-off number"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_SPLIT_SYMBOL",
    "values": [
      {
        "iso": "nl",
        "value": "Scheidings teken"
      },
      {
        "iso": "en",
        "value": "Split symbol"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_INVOICE_NR_PREFIX",
    "values": [
      {
        "iso": "nl",
        "value": "Factuurprefix"
      },
      {
        "iso": "en",
        "value": "Invoice prefix"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_INVOICE_NR_SIZE",
    "values": [
      {
        "iso": "nl",
        "value": "Groote van factuur nummer"
      },
      {
        "iso": "en",
        "value": "Size of invoice number"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_CREDITNOTE_NR_PREFIX",
    "values": [
      {
        "iso": "nl",
        "value": "Creditnota prefix"
      },
      {
        "iso": "en",
        "value": "Creditnote prefix"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_CREDITNOTE_NR_SIZE",
    "values": [
      {
        "iso": "nl",
        "value": "Groote van creditnota nummer"
      },
      {
        "iso": "en",
        "value": "Size of creditnote number"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_ORDER_NR_PREFIX",
    "values": [
      {
        "iso": "nl",
        "value": "Prefix voor bestelling nummer"
      },
      {
        "iso": "en",
        "value": "Prefix for order number"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_ORDER_NR_SIZE",
    "values": [
      {
        "iso": "nl",
        "value": "Groote van bestelling nummer"
      },
      {
        "iso": "en",
        "value": "Size of order number"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_INVOICE_REMINDER_NR_PREFIX",
    "values": [
      {
        "iso": "nl",
        "value": "Prefix voor herinnering nummer"
      },
      {
        "iso": "en",
        "value": "Prefix for reminder number"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_INVOICE_REMINDER_NR_SIZE",
    "values": [
      {
        "iso": "nl",
        "value": "Groote van herinnering nummer"
      },
      {
        "iso": "en",
        "value": "Size of reminder number"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_PRODUCT_DELIVERY_NR_PREFIX",
    "values": [
      {
        "iso": "nl",
        "value": "Prefix voor product levering nummer"
      },
      {
        "iso": "en",
        "value": "Prefix for product delivery number"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_PRODUCT_DELIVERY_NR_SIZE",
    "values": [
      {
        "iso": "nl",
        "value": "Groote van product levering nummer"
      },
      {
        "iso": "en",
        "value": "Size of product delivery number"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_PRODUCT_RECEPTION_NR_PREFIX",
    "values": [
      {
        "iso": "nl",
        "value": "Prefix voor product receptie nummer"
      },
      {
        "iso": "en",
        "value": "Prefix for product reception number"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_PRODUCT_RECEPTION_NR_SIZE",
    "values": [
      {
        "iso": "nl",
        "value": "Groote van product receptie nummer"
      },
      {
        "iso": "en",
        "value": "Size of product reception number"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_QUOTATION_NR_PREFIX",
    "values": [
      {
        "iso": "nl",
        "value": "Prefix voor offerte nummer"
      },
      {
        "iso": "en",
        "value": "Prefix for quotation number"
      }
    ]
  },
  {
    "key": "UI_SECURITY_RIGHTS",
    "values": [
      {
        "iso": "nl",
        "value": "Rechten"
      },
      {
        "iso": "en",
        "value": "Rights"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_QUOTATION_NR_SIZE",
    "values": [
      {
        "iso": "nl",
        "value": "Groote van offerte nummer"
      },
      {
        "iso": "en",
        "value": "Size of quotation number"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_PURCHASEORDER_NR_PREFIX",
    "values": [
      {
        "iso": "nl",
        "value": "Prefix voor aankoop bestelling nummer"
      },
      {
        "iso": "en",
        "value": "Prefix for purchase order number"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_PURCHASEORDER_NR_SIZE",
    "values": [
      {
        "iso": "nl",
        "value": "Groote van aankoop bestelling nummer"
      },
      {
        "iso": "en",
        "value": "Size of purchase order number"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_SUBSCRIPTION_NR_PREFIX",
    "values": [
      {
        "iso": "nl",
        "value": "Prefix voor abonnement nummer"
      },
      {
        "iso": "en",
        "value": "Prefix for subscription number"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_SUBSCRIPTION_NR_SIZE",
    "values": [
      {
        "iso": "nl",
        "value": "Groote van abonnement nummer"
      },
      {
        "iso": "en",
        "value": "Size of subscription number"
      }
    ]
  },
  {
    "key": "UI_INVOICE_SETTINGS",
    "values": [
      {
        "iso": "nl",
        "value": "Factuur instellingen"
      },
      {
        "iso": "en",
        "value": "Invoice settings"
      }
    ]
  },
  {
    "key": "UI_CREDITNOTE_SETTINGS",
    "values": [
      {
        "iso": "nl",
        "value": "Creditnota instellingen"
      },
      {
        "iso": "en",
        "value": "Creditnote settings"
      }
    ]
  },
  {
    "key": "UI_ORDER_SETTINGS",
    "values": [
      {
        "iso": "nl",
        "value": "Bestelling instellingen"
      },
      {
        "iso": "en",
        "value": "Order settings"
      }
    ]
  },
  {
    "key": "UI_QUOTE_SETTINGS",
    "values": [
      {
        "iso": "nl",
        "value": "Offerte instellingen"
      },
      {
        "iso": "en",
        "value": "Quote settings"
      }
    ]
  },
  {
    "key": "UI_PDF_ENABLE_BACKGROUND_LOGO",
    "values": [
      {
        "iso": "nl",
        "value": "Achtergrond logo"
      },
      {
        "iso": "en",
        "value": "Background logo"
      }
    ]
  },
  {
    "key": "UI_PDF_ENABLE_BANK_DETAILS",
    "values": [
      {
        "iso": "nl",
        "value": "Financiele informatie"
      },
      {
        "iso": "en",
        "value": "Financial information"
      }
    ]
  },
  {
    "key": "UI_PDF_ENABLE_DISCOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Korting"
      },
      {
        "iso": "en",
        "value": "Discount"
      }
    ]
  },
  {
    "key": "UI_PDF_ENABLE_INCL_PRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Prijs incl BTW"
      },
      {
        "iso": "en",
        "value": "Price incl VAT"
      }
    ]
  },
  {
    "key": "UI_PDF_ENABLE_UNIT_PRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Eenheidsprijs"
      },
      {
        "iso": "en",
        "value": "Unit price"
      }
    ]
  },
  {
    "key": "UI_PDF_ENABLE_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "BTW"
      },
      {
        "iso": "en",
        "value": "VAT"
      }
    ]
  },
  {
    "key": "UI_PDF_DEFAULT_TEXT_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "Standaard text-title"
      },
      {
        "iso": "en",
        "value": "Default text-title"
      }
    ]
  },
  {
    "key": "UI_PDF_DEFAULT_TEXT",
    "values": [
      {
        "iso": "nl",
        "value": "Standaard tekst"
      },
      {
        "iso": "en",
        "value": "Default text"
      }
    ]
  },
  {
    "key": "UI_LEDGERGROUP_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Grootboek groep omschrijving"
      },
      {
        "iso": "en",
        "value": "Ledger group description"
      }
    ]
  },
  {
    "key": "UI_CONCEPT",
    "values": [
      {
        "iso": "nl",
        "value": "Concept"
      },
      {
        "iso": "en",
        "value": "Concept"
      }
    ]
  },
  {
    "key": "UI_SEND",
    "values": [
      {
        "iso": "nl",
        "value": "Verzonden"
      },
      {
        "iso": "en",
        "value": "Send"
      }
    ]
  },
  {
    "key": "UI_ACCEPTED",
    "values": [
      {
        "iso": "nl",
        "value": "Geaccepteerd"
      },
      {
        "iso": "en",
        "value": "Accepted"
      }
    ]
  },
  {
    "key": "UI_REJECTED",
    "values": [
      {
        "iso": "nl",
        "value": "Geweigerd"
      },
      {
        "iso": "en",
        "value": "Rejected"
      }
    ]
  },
  {
    "key": "UI_REJECTED",
    "values": [
      {
        "iso": "nl",
        "value": "Geweigerd"
      },
      {
        "iso": "en",
        "value": "Rejected"
      }
    ]
  },
  {
    "key": "UI_ORDERED",
    "values": [
      {
        "iso": "nl",
        "value": "Besteld"
      },
      {
        "iso": "en",
        "value": "Ordered"
      }
    ]
  },
  {
    "key": "UI_UNPROCESSED",
    "values": [
      {
        "iso": "nl",
        "value": "Onverwerkt"
      },
      {
        "iso": "en",
        "value": "Unprocessed"
      }
    ]
  },
  {
    "key": "UI_PROCESSED",
    "values": [
      {
        "iso": "nl",
        "value": "Verwerkt"
      },
      {
        "iso": "en",
        "value": "Processed"
      }
    ]
  },
  {
    "key": "UI_INVOICED",
    "values": [
      {
        "iso": "nl",
        "value": "Gefactureerd"
      },
      {
        "iso": "en",
        "value": "Invoiced"
      }
    ]
  },
  {
    "key": "UI_UNPAID",
    "values": [
      {
        "iso": "nl",
        "value": "Onbetaald"
      },
      {
        "iso": "en",
        "value": "Unpaid"
      }
    ]
  },
  {
    "key": "UI_PARTIALLY_PAID",
    "values": [
      {
        "iso": "nl",
        "value": "Gedeeltelijk betaald"
      },
      {
        "iso": "en",
        "value": "Partially paid"
      }
    ]
  },
  {
    "key": "UI_PAID",
    "values": [
      {
        "iso": "nl",
        "value": "Betaald"
      },
      {
        "iso": "en",
        "value": "Paid"
      }
    ]
  },
  {
    "key": "UI_PAYMENT_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Betaal gegevens"
      },
      {
        "iso": "en",
        "value": "Payment information"
      }
    ]
  },
  {
    "key": "UI_PAID_AMOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Betaald bedreg"
      },
      {
        "iso": "en",
        "value": "Paid amount"
      }
    ]
  },
  {
    "key": "UI_ORDERED",
    "values": [
      {
        "iso": "nl",
        "value": "Besteld"
      },
      {
        "iso": "en",
        "value": "Ordered"
      }
    ]
  },
  {
    "key": "UI_PARTIALLY_RECEIVED",
    "values": [
      {
        "iso": "nl",
        "value": "Gedeeltelijk ontvangen"
      },
      {
        "iso": "en",
        "value": "Partially received"
      }
    ]
  },
  {
    "key": "UI_RECEIVED",
    "values": [
      {
        "iso": "nl",
        "value": "Ontvangen"
      },
      {
        "iso": "en",
        "value": "Received"
      }
    ]
  },
  {
    "key": "UI_MODAL_REMOVE_DATA_ACCEPTANCE_TITLE_WITH_TYPE",
    "values": [
      {
        "iso": "nl",
        "value": "{{0}} verwijderen?"
      },
      {
        "iso": "en",
        "value": "Delete {{0}}"
      }
    ]
  },
  {
    "key": "UI_MODAL_REMOVE_DATA_ACCEPTANCE_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "Gegevens verwijderen?"
      },
      {
        "iso": "en",
        "value": "Delete data"
      }
    ]
  },
  {
    "key": "UI_MODAL_REMOVE_DATA_ACCEPTANCE_SKIP_DELETEION_MODAL_IN_CURRENT_VIEW",
    "values": [
      {
        "iso": "nl",
        "value": "Vraag niet opnieuw totdat de pagina wordt verlaten."
      },
      {
        "iso": "en",
        "value": "Do not ask again until the page is abandoned."
      }
    ]
  },
  {
    "key": "UI_MODAL_REMOVE_DATA_ACCEPTANCE_BODY_WITH_TYPE_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Ben je zeker dat je {{0}} \"{{1}}\" wilt verwijderen? Eenmaal gewist is er geen enkele mogelijkheid om deze {{0}} te recupereren!"
      },
      {
        "iso": "en",
        "value": "Are you sure you want to {{0}} \"{{1}}\" delete? Once deleted, there is no way to recover this {{0}}!"
      }
    ]
  },
  {
    "key": "UI_MODAL_REMOVE_DATA_ACCEPTANCE_BODY",
    "values": [
      {
        "iso": "nl",
        "value": "Ben je zeker dat je deze gegevens wilt verwijderen? Eenmaal gewist is er geen enkele mogelijkheid om de gegevens te recupereren!"
      },
      {
        "iso": "en",
        "value": "Are you sure you want to delete this data? Once deleted, there is no way to recover the data!"
      }
    ]
  },
  {
    "key": "UI_CONFIRM",
    "values": [
      {
        "iso": "nl",
        "value": "Bevestigen"
      },
      {
        "iso": "en",
        "value": "Confirm"
      }
    ]
  },
  {
    "key": "UI_HIDE_FILTERS",
    "values": [
      {
        "iso": "nl",
        "value": "Filters verbergen"
      },
      {
        "iso": "en",
        "value": "Hide filters"
      }
    ]
  },
  {
    "key": "UI_SHOW_FILTERS",
    "values": [
      {
        "iso": "nl",
        "value": "Filters tonen"
      },
      {
        "iso": "en",
        "value": "Show filters"
      }
    ]
  },
  {
    "key": "UI_SHOW_CONCEPT",
    "values": [
      {
        "iso": "nl",
        "value": "Toon concept"
      },
      {
        "iso": "en",
        "value": "Show concept"
      }
    ]
  },
  {
    "key": "UI_SHOW_UNPAID",
    "values": [
      {
        "iso": "nl",
        "value": "Toon onbetaalde"
      },
      {
        "iso": "en",
        "value": "Show unpaid"
      }
    ]
  },
  {
    "key": "UI_SHOW_PAID",
    "values": [
      {
        "iso": "nl",
        "value": "Toon betaalde"
      },
      {
        "iso": "en",
        "value": "Show paid"
      }
    ]
  },
  {
    "key": "UI_SHOW_FINISHED",
    "values": [
      {
        "iso": "nl",
        "value": "Toon afgeronde"
      },
      {
        "iso": "en",
        "value": "Show finished"
      }
    ]
  },
  {
    "key": "UI_TRANSACTIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Transacties"
      },
      {
        "iso": "en",
        "value": "Transactions"
      }
    ]
  },
  {
    "key": "UI_TRANSACTION_GATEWAY",
    "values": [
      {
        "iso": "nl",
        "value": "Betalingsmethode"
      },
      {
        "iso": "en",
        "value": "Payment method"
      }
    ]
  },
  {
    "key": "UI_AMOUNT_MONEY",
    "values": [
      {
        "iso": "nl",
        "value": "Bedrag"
      },
      {
        "iso": "en",
        "value": "Amount"
      }
    ]
  },
  {
    "key": "UI_SHOW_UNPROCESSED",
    "values": [
      {
        "iso": "nl",
        "value": "Toon onverwerkte"
      },
      {
        "iso": "en",
        "value": "show unprocessed"
      }
    ]
  },
  {
    "key": "UI_SHOW_PROCESSED",
    "values": [
      {
        "iso": "nl",
        "value": "Toon verwerkte"
      },
      {
        "iso": "en",
        "value": "Show processed"
      }
    ]
  },
  {
    "key": "UI_SHOW_INVOICED",
    "values": [
      {
        "iso": "nl",
        "value": "Toon gefactureerde"
      },
      {
        "iso": "en",
        "value": "Show invoiced"
      }
    ]
  },
  {
    "key": "UI_SHOW_SEND",
    "values": [
      {
        "iso": "nl",
        "value": "Toon verzonden"
      },
      {
        "iso": "en",
        "value": "Show send"
      }
    ]
  },
  {
    "key": "UI_SHOW_ACCEPTED",
    "values": [
      {
        "iso": "nl",
        "value": "Toon geaccepteerde"
      },
      {
        "iso": "en",
        "value": "Show accepted"
      }
    ]
  },
  {
    "key": "UI_SHOW_REJECTED",
    "values": [
      {
        "iso": "nl",
        "value": "Toon afgewezen"
      },
      {
        "iso": "en",
        "value": "Show rejected"
      }
    ]
  },
  {
    "key": "UI_SHOW_ORDERED",
    "values": [
      {
        "iso": "nl",
        "value": "Toon bestelde"
      },
      {
        "iso": "en",
        "value": "Show ordered"
      }
    ]
  },
  {
    "key": "UI_SHOW_RECEIVED",
    "values": [
      {
        "iso": "nl",
        "value": "Toon ontvangen"
      },
      {
        "iso": "en",
        "value": "Show received"
      }
    ]
  },
  {
    "key": "UI_SHOW_FINISHED",
    "values": [
      {
        "iso": "nl",
        "value": "Toon afgewerkte"
      },
      {
        "iso": "en",
        "value": "Show finished"
      }
    ]
  },
  {
    "key": "UI_VAT_PERIODS",
    "values": [
      {
        "iso": "nl",
        "value": "BTW periodes"
      },
      {
        "iso": "en",
        "value": "VAT periods"
      }
    ]
  },
  {
    "key": "UI_ADD_VATPERIOD",
    "values": [
      {
        "iso": "nl",
        "value": "BTW periode toevoegen"
      },
      {
        "iso": "en",
        "value": "Add VAT period"
      }
    ]
  },
  {
    "key": "UI_DELETE_VATPERIODS",
    "values": [
      {
        "iso": "nl",
        "value": "BTW periodes verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete VAT periods"
      }
    ]
  },
  {
    "key": "UI_PERIOD_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Periode informatie"
      },
      {
        "iso": "en",
        "value": "Period information"
      }
    ]
  },
  {
    "key": "UI_START_DATE",
    "values": [
      {
        "iso": "nl",
        "value": "Start datum"
      },
      {
        "iso": "en",
        "value": "Start date"
      }
    ]
  },
  {
    "key": "UI_END_DATE",
    "values": [
      {
        "iso": "nl",
        "value": "Eind datum"
      },
      {
        "iso": "en",
        "value": "End date"
      }
    ]
  },
  {
    "key": "UI_BLOCKED_PERIOD",
    "values": [
      {
        "iso": "nl",
        "value": "Geblokeerde periode"
      },
      {
        "iso": "en",
        "value": "Blocked period"
      }
    ]
  },
  {
    "key": "UI_ROSTER",
    "values": [
      {
        "iso": "nl",
        "value": "Rooster"
      },
      {
        "iso": "en",
        "value": "Roster"
      }
    ]
  },
  {
    "key": "UI_VALUE",
    "values": [
      {
        "iso": "nl",
        "value": "Waarde"
      },
      {
        "iso": "en",
        "value": "Value"
      }
    ]
  },
  {
    "key": "UI_CUSTOMER_LISTINGS",
    "values": [
      {
        "iso": "nl",
        "value": "Klantlistings"
      },
      {
        "iso": "en",
        "value": "Customer listings"
      }
    ]
  },
  {
    "key": "UI_FROM",
    "values": [
      {
        "iso": "nl",
        "value": "Vanaf"
      },
      {
        "iso": "en",
        "value": "From"
      }
    ]
  },
  {
    "key": "UI_ADD_CUSTOMERLISTING",
    "values": [
      {
        "iso": "nl",
        "value": "Klantenlisting toevoegen"
      },
      {
        "iso": "en",
        "value": "Add customer listing"
      }
    ]
  },
  {
    "key": "UI_DELETE_CUSTOMERLISTINGS",
    "values": [
      {
        "iso": "nl",
        "value": "Klantenlistings verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete customer listings"
      }
    ]
  },
  {
    "key": "UI_TOTAL_REVENUE",
    "values": [
      {
        "iso": "nl",
        "value": "Totale omzet"
      },
      {
        "iso": "en",
        "value": "Total Revenue"
      }
    ]
  },
  {
    "key": "UI_TOTAL_VAT_AMOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Totale belastingswaarde"
      },
      {
        "iso": "en",
        "value": "Total vat amount"
      }
    ]
  },
  {
    "key": "UI_LATEST_VALUE_GENERATE",
    "values": [
      {
        "iso": "nl",
        "value": "Laatste generatie van waardes"
      },
      {
        "iso": "en",
        "value": "Last generate of values"
      }
    ]
  },
  {
    "key": "UI_REVENUE",
    "values": [
      {
        "iso": "nl",
        "value": "Omzet"
      },
      {
        "iso": "en",
        "value": "Revenue"
      }
    ]
  },
  {
    "key": "UI_VAT_AMOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Belastingswaarde"
      },
      {
        "iso": "en",
        "value": "VAT amount"
      }
    ]
  },
  {
    "key": "UI_VAT_VALUE",
    "values": [
      {
        "iso": "nl",
        "value": "BTW waarde"
      },
      {
        "iso": "en",
        "value": "VAT value"
      }
    ]
  },
  {
    "key": "UI_GENERAL_SETTINGS",
    "values": [
      {
        "iso": "nl",
        "value": "Algemene instellingen"
      },
      {
        "iso": "en",
        "value": "General settings"
      }
    ]
  },
  {
    "key": "UI_GOTO_STOCK",
    "values": [
      {
        "iso": "nl",
        "value": "Ga naar het stock artikel"
      },
      {
        "iso": "en",
        "value": "Go to the stock article"
      }
    ]
  },
  {
    "key": "UI_DEFAULT",
    "values": [
      {
        "iso": "nl",
        "value": "Standaard"
      },
      {
        "iso": "en",
        "value": "Default"
      }
    ]
  },
  {
    "key": "UI_CHECK_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "BTW nummer controleren"
      },
      {
        "iso": "en",
        "value": "Check VAT number"
      }
    ]
  },
  {
    "key": "UI_CURRENT_VALUE",
    "values": [
      {
        "iso": "nl",
        "value": "Huidige"
      },
      {
        "iso": "en",
        "value": "Current"
      }
    ]
  },
  {
    "key": "UI_SUGGESTED_VALUE",
    "values": [
      {
        "iso": "nl",
        "value": "Aangeraden"
      },
      {
        "iso": "en",
        "value": "Suggested"
      }
    ]
  },
  {
    "key": "UI_CHANGE_SELECTED",
    "values": [
      {
        "iso": "nl",
        "value": "Wijzig geselecteerde waardes"
      },
      {
        "iso": "en",
        "value": "Update selected values"
      }
    ]
  },
  {
    "key": "UI_DEFAULT_ADDRESS",
    "values": [
      {
        "iso": "nl",
        "value": "Standaard adres"
      },
      {
        "iso": "en",
        "value": "Default address"
      }
    ]
  },
  {
    "key": "UI_SHOW_STOCK_PRODUCTS",
    "values": [
      {
        "iso": "nl",
        "value": "Toon stock producten"
      },
      {
        "iso": "en",
        "value": "Show stock products"
      }
    ]
  },
  {
    "key": "UI_SHOW_SOLD_PRODUCTS",
    "values": [
      {
        "iso": "nl",
        "value": "Toon verkochte producten"
      },
      {
        "iso": "en",
        "value": "Show sold products"
      }
    ]
  },
  {
    "key": "UI_SHOW_USED_PRODUCTS",
    "values": [
      {
        "iso": "nl",
        "value": "Toon gebruikte producten"
      },
      {
        "iso": "en",
        "value": "Show used producten"
      }
    ]
  },
  {
    "key": "UI_SHOW_LOANED_PRODUCTS",
    "values": [
      {
        "iso": "nl",
        "value": "Toon uitgeleende producten"
      },
      {
        "iso": "en",
        "value": "Show loaned products"
      }
    ]
  },
  {
    "key": "UI_SHOW_DEFECT_PRODUCTS",
    "values": [
      {
        "iso": "nl",
        "value": "Toon defecte producten"
      },
      {
        "iso": "en",
        "value": "Show defect products"
      }
    ]
  },
  {
    "key": "UI_SHOW_DESTROYED_PRODUCTS",
    "values": [
      {
        "iso": "nl",
        "value": "Toon verwoeste producten"
      },
      {
        "iso": "en",
        "value": "Show destroyed products"
      }
    ]
  },
  {
    "key": "UI_EMAIL_SETTINGS",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail instellingen"
      },
      {
        "iso": "en",
        "value": "Email settings"
      }
    ]
  },
  {
    "key": "UI_LOGS",
    "values": [
      {
        "iso": "nl",
        "value": "Logs"
      },
      {
        "iso": "en",
        "value": "Logs"
      }
    ]
  },
  {
    "key": "UI_BODY_TEMPLATES",
    "values": [
      {
        "iso": "nl",
        "value": "Bericht sjablonen"
      },
      {
        "iso": "en",
        "value": "Message templates"
      }
    ]
  },
  {
    "key": "UI_FOOTER_TEMPLATES",
    "values": [
      {
        "iso": "nl",
        "value": "Handtekening sjablonen"
      },
      {
        "iso": "en",
        "value": "Footer signatures"
      }
    ]
  },
  {
    "key": "UI_TEMPLATE",
    "values": [
      {
        "iso": "nl",
        "value": "Sjabloon"
      },
      {
        "iso": "en",
        "value": "Template"
      }
    ]
  },
  {
    "key": "UI_FILENAME",
    "values": [
      {
        "iso": "nl",
        "value": "Bestandsnaam"
      },
      {
        "iso": "en",
        "value": "File name"
      }
    ]
  },
  {
    "key": "UI_TYPE",
    "values": [
      {
        "iso": "nl",
        "value": "Type"
      },
      {
        "iso": "en",
        "value": "Type"
      }
    ]
  },
  {
    "key": "UI_INVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "Factuur"
      },
      {
        "iso": "en",
        "value": "Invoice"
      }
    ]
  },
  {
    "key": "UI_ORDER",
    "values": [
      {
        "iso": "nl",
        "value": "Bestelbon"
      },
      {
        "iso": "en",
        "value": "Order"
      }
    ]
  },
  {
    "key": "UI_QUOTE",
    "values": [
      {
        "iso": "nl",
        "value": "Offerte"
      },
      {
        "iso": "en",
        "value": "Quote"
      }
    ]
  },
  {
    "key": "UI_SEND_EMAIL",
    "values": [
      {
        "iso": "nl",
        "value": "Verstuur email"
      },
      {
        "iso": "en",
        "value": "Send email"
      }
    ]
  },
  {
    "key": "UI_SEND_PEPPOL",
    "values": [
      {
        "iso": "nl",
        "value": "Verstuur peppol"
      },
      {
        "iso": "en",
        "value": "Send peppol"
      }
    ]
  },
  {
    "key": "UI_EMAIL_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Email informatie"
      },
      {
        "iso": "en",
        "value": "Email information"
      }
    ]
  },
  {
    "key": "UI_EMAIL_RECEIVERS",
    "values": [
      {
        "iso": "nl",
        "value": "Ontvangers"
      },
      {
        "iso": "en",
        "value": "Receivers"
      }
    ]
  },
  {
    "key": "UI_EMAIL_ATTACHMENTS",
    "values": [
      {
        "iso": "nl",
        "value": "Bijlages"
      },
      {
        "iso": "en",
        "value": "Bijlage"
      }
    ]
  },
  {
    "key": "UI_SEND_BY_EMAIL",
    "values": [
      {
        "iso": "nl",
        "value": "Versturen via e-mail"
      },
      {
        "iso": "en",
        "value": "Send by email"
      }
    ]
  },
  {
    "key": "UI_SEND_BY",
    "values": [
      {
        "iso": "nl",
        "value": "Versturen"
      },
      {
        "iso": "en",
        "value": "Send"
      }
    ]
  },
  {
    "key": "UI_BY_EMAIL",
    "values": [
      {
        "iso": "nl",
        "value": "Via e-mail"
      },
      {
        "iso": "en",
        "value": "By email"
      }
    ]
  },
  {
    "key": "UI_SEND_BY_PEPPOL",
    "values": [
      {
        "iso": "nl",
        "value": "Versturen via Peppol"
      },
      {
        "iso": "en",
        "value": "Send by Peppol"
      }
    ]
  },
  {
    "key": "UI_EMAIL_BODY",
    "values": [
      {
        "iso": "nl",
        "value": "Email bericht"
      },
      {
        "iso": "en",
        "value": "Email message"
      }
    ]
  },
  {
    "key": "UI_EMAIL_FOOTER",
    "values": [
      {
        "iso": "nl",
        "value": "Email handtekening"
      },
      {
        "iso": "en",
        "value": "Email signature"
      }
    ]
  },
  {
    "key": "UI_EMAIL_SUBJECT",
    "values": [
      {
        "iso": "nl",
        "value": "Email onderwerp"
      },
      {
        "iso": "en",
        "value": "Email subject"
      }
    ]
  },
  {
    "key": "UI_EMAIL_SIGNATUR",
    "values": [
      {
        "iso": "nl",
        "value": "Email handtekening"
      },
      {
        "iso": "en",
        "value": "Email signature"
      }
    ]
  },
  {
    "key": "UI_EMAIL_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Email bericht"
      },
      {
        "iso": "en",
        "value": "Email message"
      }
    ]
  },
  {
    "key": "UI_EMAIL_TYPE_TO",
    "values": [
      {
        "iso": "nl",
        "value": "Aan"
      },
      {
        "iso": "en",
        "value": "To"
      }
    ]
  },
  {
    "key": "UI_EMAIL_TYPE_CC",
    "values": [
      {
        "iso": "nl",
        "value": "CC"
      },
      {
        "iso": "en",
        "value": "CC"
      }
    ]
  },
  {
    "key": "UI_EMAIL_TYPE_BCC",
    "values": [
      {
        "iso": "nl",
        "value": "BCC"
      },
      {
        "iso": "en",
        "value": "BCC"
      }
    ]
  },
  {
    "key": "UI_IMPORT_CONTACTS",
    "values": [
      {
        "iso": "nl",
        "value": "Importeer contactpersonen"
      },
      {
        "iso": "en",
        "value": "Import contact persons"
      }
    ]
  },
  {
    "key": "UI_IMPORT_TEMPLATE",
    "values": [
      {
        "iso": "nl",
        "value": "Importeer sjabloon"
      },
      {
        "iso": "en",
        "value": "Import template"
      }
    ]
  },
  {
    "key": "UI_IMPORT",
    "values": [
      {
        "iso": "nl",
        "value": "Importeren"
      },
      {
        "iso": "en",
        "value": "Import"
      }
    ]
  },
  {
    "key": "UI_IMPORT_DOCUMENTS",
    "values": [
      {
        "iso": "nl",
        "value": "Importeer documenten"
      },
      {
        "iso": "en",
        "value": "Import documents"
      }
    ]
  },
  {
    "key": "UI_EMAILS",
    "values": [
      {
        "iso": "nl",
        "value": "E-mails"
      },
      {
        "iso": "en",
        "value": "Emails"
      }
    ]
  },
  {
    "key": "UI_RECEIVERS",
    "values": [
      {
        "iso": "nl",
        "value": "Ontvangers"
      },
      {
        "iso": "en",
        "value": "Receivers"
      }
    ]
  },
  {
    "key": "UI_CC",
    "values": [
      {
        "iso": "nl",
        "value": "CC"
      },
      {
        "iso": "en",
        "value": "CC"
      }
    ]
  },
  {
    "key": "UI_BCC",
    "values": [
      {
        "iso": "nl",
        "value": "BCC"
      },
      {
        "iso": "en",
        "value": "BCC"
      }
    ]
  },
  {
    "key": "UI_QUEUE",
    "values": [
      {
        "iso": "nl",
        "value": "In wacht"
      },
      {
        "iso": "en",
        "value": "In queue"
      }
    ]
  },
  {
    "key": "UI_PROCESSING",
    "values": [
      {
        "iso": "nl",
        "value": "Verwerken"
      },
      {
        "iso": "en",
        "value": "Processing"
      }
    ]
  },
  {
    "key": "UI_FAILED",
    "values": [
      {
        "iso": "nl",
        "value": "Mislukt"
      },
      {
        "iso": "en",
        "value": "Failed"
      }
    ]
  },
  {
    "key": "UI_EMAIL_TYPE",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail Type"
      },
      {
        "iso": "en",
        "value": "Email type"
      }
    ]
  },
  {
    "key": "UI_EMAIL_SENDER_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Naam van afzender"
      },
      {
        "iso": "en",
        "value": "Name of the sender"
      }
    ]
  },
  {
    "key": "UI_EMAIL_SENDER_EMAIL",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail van afzender"
      },
      {
        "iso": "en",
        "value": "Email of the sender"
      }
    ]
  },
  {
    "key": "UI_EMAIL_SMTP_USER",
    "values": [
      {
        "iso": "nl",
        "value": "SMTP gebruikersnaam"
      },
      {
        "iso": "en",
        "value": "SMTP username"
      }
    ]
  },
  {
    "key": "UI_EMAIL_SMTP_PASSWORD",
    "values": [
      {
        "iso": "nl",
        "value": "SMTP wachtwoord"
      },
      {
        "iso": "en",
        "value": "SMTP password"
      }
    ]
  },
  {
    "key": "UI_EMAIL_SMTP_HOST",
    "values": [
      {
        "iso": "nl",
        "value": "SMTP hostnaam"
      },
      {
        "iso": "en",
        "value": "SMTP hostname"
      }
    ]
  },
  {
    "key": "UI_EMAIL_SMTP_PORT",
    "values": [
      {
        "iso": "nl",
        "value": "SMTP poort"
      },
      {
        "iso": "en",
        "value": "SMTP port"
      }
    ]
  },
  {
    "key": "UI_EMAIL_SMTP_SECURE",
    "values": [
      {
        "iso": "nl",
        "value": "Gebruik TLS?"
      },
      {
        "iso": "en",
        "value": "Use TLS?"
      }
    ]
  },
  {
    "key": "UI_EMAIL_TYPE_SMTP",
    "values": [
      {
        "iso": "nl",
        "value": "SMTP"
      },
      {
        "iso": "en",
        "value": "SMTP"
      }
    ]
  },
  {
    "key": "UI_COSTS",
    "values": [
      {
        "iso": "nl",
        "value": "Kosten"
      },
      {
        "iso": "en",
        "value": "Costs"
      }
    ]
  },
  {
    "key": "UI_INVESTMENT",
    "values": [
      {
        "iso": "nl",
        "value": "Investeringen"
      },
      {
        "iso": "en",
        "value": "Investments"
      }
    ]
  },
  {
    "key": "UI_GAIN",
    "values": [
      {
        "iso": "nl",
        "value": "Winst"
      },
      {
        "iso": "en",
        "value": "Gain"
      }
    ]
  },
  {
    "key": "UI_THIS_YEAR",
    "values": [
      {
        "iso": "nl",
        "value": "Dit jaar"
      },
      {
        "iso": "en",
        "value": "This year"
      }
    ]
  },
  {
    "key": "UI_UNPAID_INVOICES",
    "values": [
      {
        "iso": "nl",
        "value": "Onbetaalde verkoop facturen"
      },
      {
        "iso": "en",
        "value": "Unpaid sales invoices"
      }
    ]
  },
  {
    "key": "UI_UNPAID_INVOICES_AMOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Aantal"
      },
      {
        "iso": "en",
        "value": "Amount"
      }
    ]
  },
  {
    "key": "UI_UNPAID_INVOICES_EXCL_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Bedrag excl BTW"
      },
      {
        "iso": "en",
        "value": "Value excl VAT"
      }
    ]
  },
  {
    "key": "UI_UNPAID_INVOICES_VAT_AMOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "BTW bedrag"
      },
      {
        "iso": "en",
        "value": "VAT value"
      }
    ]
  },
  {
    "key": "UI_UNPAID_INVOICES_INCL_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Bedrag incl BTW"
      },
      {
        "iso": "en",
        "value": "Value incl VAT"
      }
    ]
  },
  {
    "key": "UI_OVERDUE_INVOICES",
    "values": [
      {
        "iso": "nl",
        "value": "Vervallen verkoop facturen"
      },
      {
        "iso": "en",
        "value": "Overdue sales invoices"
      }
    ]
  },
  {
    "key": "UI_OVERDUE_INVOICES_AMOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Aantal"
      },
      {
        "iso": "en",
        "value": "Amount"
      }
    ]
  },
  {
    "key": "UI_OVERDUE_INVOICES_EXCL_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Bedrag excl BTW"
      },
      {
        "iso": "en",
        "value": "Value excl VAT"
      }
    ]
  },
  {
    "key": "UI_OVERDUE_INVOICES_VAT_AMOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "BTW bedrag"
      },
      {
        "iso": "en",
        "value": "VAT value"
      }
    ]
  },
  {
    "key": "UI_OVERDUE_INVOICES_INCL_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Bedrag incl BTW"
      },
      {
        "iso": "en",
        "value": "Value incl VAT"
      }
    ]
  },
  {
    "key": "UI_UNPAID_PURCHASE_INVOICES",
    "values": [
      {
        "iso": "nl",
        "value": "Onbetaalde aankoop facturen"
      },
      {
        "iso": "en",
        "value": "Unpaid purchase invoices"
      }
    ]
  },
  {
    "key": "UI_UNPAID_PURCHASE_INVOICES_AMOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Aantal"
      },
      {
        "iso": "en",
        "value": "Amount"
      }
    ]
  },
  {
    "key": "UI_UNPAID_PURCHASE_INVOICES_EXCL_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Bedrag excl BTW"
      },
      {
        "iso": "en",
        "value": "Value excl VAT"
      }
    ]
  },
  {
    "key": "UI_UNPAID_PURCHASE_INVOICES_VAT_AMOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "BTW bedrag"
      },
      {
        "iso": "en",
        "value": "VAT value"
      }
    ]
  },
  {
    "key": "UI_UNPAID_PURCHASE_INVOICES_INCL_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Bedrag incl BTW"
      },
      {
        "iso": "en",
        "value": "Value incl VAT"
      }
    ]
  },
  {
    "key": "UI_POSSIBLE_INCOME",
    "values": [
      {
        "iso": "nl",
        "value": "Mogelijk inkomen"
      },
      {
        "iso": "en",
        "value": "Possible income"
      }
    ]
  },
  {
    "key": "UI_UNFINISHED_QUOTES",
    "values": [
      {
        "iso": "nl",
        "value": "Onafgewerkte offertes"
      },
      {
        "iso": "en",
        "value": "Unfinished quotes"
      }
    ]
  },
  {
    "key": "UI_UNFINISHED_QUOTES_EXCL_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Bedrag excl BTW"
      },
      {
        "iso": "en",
        "value": "Value excl VAT"
      }
    ]
  },
  {
    "key": "UI_UNFINISHED_ORDERS",
    "values": [
      {
        "iso": "nl",
        "value": "Onafgewerkte bestellingen"
      },
      {
        "iso": "en",
        "value": "Unfinished orders"
      }
    ]
  },
  {
    "key": "UI_UNFINISHED_ORDERS_EXCL_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Bedrag incl BTW"
      },
      {
        "iso": "en",
        "value": "Value incl VAT"
      }
    ]
  },
  {
    "key": "UI_FIRST_READ",
    "values": [
      {
        "iso": "nl",
        "value": "Eerst geopend op"
      },
      {
        "iso": "en",
        "value": "First opened on"
      }
    ]
  },
  {
    "key": "UI_LAST_READ",
    "values": [
      {
        "iso": "nl",
        "value": "Laats geopend op"
      },
      {
        "iso": "en",
        "value": "Last openend on"
      }
    ]
  },
  {
    "key": "UI_READ_COUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Aantal keer geopend"
      },
      {
        "iso": "en",
        "value": "Amount of opening"
      }
    ]
  },
  {
    "key": "UI_DETAILS",
    "values": [
      {
        "iso": "nl",
        "value": "Informatie"
      },
      {
        "iso": "en",
        "value": "Details"
      }
    ]
  },
  {
    "key": "UI_NEW",
    "values": [
      {
        "iso": "nl",
        "value": "Nieuw"
      },
      {
        "iso": "en",
        "value": "New"
      }
    ]
  },
  {
    "key": "UI_OVERVIEW",
    "values": [
      {
        "iso": "nl",
        "value": "Overzicht"
      },
      {
        "iso": "en",
        "value": "Overview"
      }
    ]
  },
  {
    "key": "UI_PDF",
    "values": [
      {
        "iso": "nl",
        "value": "Document"
      },
      {
        "iso": "en",
        "value": "Document"
      }
    ]
  },
  {
    "key": "UI_ADD_PAYMENT_GATEWAY",
    "values": [
      {
        "iso": "nl",
        "value": "Betalings methode toevoegen"
      },
      {
        "iso": "en",
        "value": "Add payment gateway"
      }
    ]
  },
  {
    "key": "UI_DELETE_PAYMENT_GATEWAYS",
    "values": [
      {
        "iso": "nl",
        "value": "Betalings methodes verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete payment gateways"
      }
    ]
  },
  {
    "key": "UI_PURCHASEORDER_TO_PURCHASEINVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "Aaankoop besteling naar aankoop factuur omzetten"
      },
      {
        "iso": "en",
        "value": "Convert purchase order to purchase invoice"
      }
    ]
  },
  {
    "key": "UI_COMBINED_ARTICLE_SKU",
    "values": [
      {
        "iso": "nl",
        "value": "Gecombineerd artikel SKU"
      },
      {
        "iso": "en",
        "value": "Combined article SKU"
      }
    ]
  },
  {
    "key": "UI_COMBINED_ARTICLE_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Gecombineerd artikel naam"
      },
      {
        "iso": "en",
        "value": "Combined article name"
      }
    ]
  },
  {
    "key": "UI_PURCHASEPRICE_INCL_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoopprijs incl. BTW"
      },
      {
        "iso": "en",
        "value": "Purchaseprice incl. VAT"
      }
    ]
  },
  {
    "key": "UI_MENU",
    "values": [
      {
        "iso": "nl",
        "value": "Menu"
      },
      {
        "iso": "en",
        "value": "Menu"
      }
    ]
  },
  {
    "key": "UI_PART_OF_COMBINED_ARTICLE",
    "values": [
      {
        "iso": "nl",
        "value": "Dit artikel is onderdeel van het gecombineerd artikel '{{0}}'"
      },
      {
        "iso": "en",
        "value": "This article is a part of the combined article '{{0}}'"
      }
    ]
  },
  {
    "key": "UI_SUBSCRIBER_AUTOGEN_INVOICE_DOCUMENT_AFTER_FINALIZE",
    "values": [
      {
        "iso": "nl",
        "value": "Genereren van een document na het finaliseren van een factuur"
      },
      {
        "iso": "en",
        "value": "Generate a document when you finalize the invoice"
      }
    ]
  },
  {
    "key": "UI_SUBSCRIBER_AUTOGEN_CREDITNOTE_DOCUMENT_AFTER_FINALIZE",
    "values": [
      {
        "iso": "nl",
        "value": "Genereren van een document na het finaliseren van een creditnota"
      },
      {
        "iso": "en",
        "value": "Generate a document when you finalize the creditnote"
      }
    ]
  },
  {
    "key": "UI_TRANSLOCATIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Verplaatsingen"
      },
      {
        "iso": "en",
        "value": "Translocations"
      }
    ]
  },
  {
    "key": "UI_FROM_ADDRESS",
    "values": [
      {
        "iso": "nl",
        "value": "Vertrek adres"
      },
      {
        "iso": "en",
        "value": "Start address"
      }
    ]
  },
  {
    "key": "UI_END_ADDRESS",
    "values": [
      {
        "iso": "nl",
        "value": "Aankomst adres"
      },
      {
        "iso": "en",
        "value": "End address"
      }
    ]
  },
  {
    "key": "UI_DISTANCE",
    "values": [
      {
        "iso": "nl",
        "value": "Afstand"
      },
      {
        "iso": "en",
        "value": "Distance"
      }
    ]
  },
  {
    "key": "UI_FROM_ADDRESS_STREET",
    "values": [
      {
        "iso": "nl",
        "value": "Vertrek adres - Straat"
      },
      {
        "iso": "en",
        "value": "Start address - Street"
      }
    ]
  },
  {
    "key": "UI_FROM_ADDRESS_NUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "Vertrek adres - Huisnummer"
      },
      {
        "iso": "en",
        "value": "Start address - House number"
      }
    ]
  },
  {
    "key": "UI_FROM_ADDRESS_ZIP",
    "values": [
      {
        "iso": "nl",
        "value": "Vertrek adres - Postcode"
      },
      {
        "iso": "en",
        "value": "Start address - Zip code"
      }
    ]
  },
  {
    "key": "UI_FROM_ADDRESS_CITY",
    "values": [
      {
        "iso": "nl",
        "value": "Vertrek adres - Stad"
      },
      {
        "iso": "en",
        "value": "Start address - City"
      }
    ]
  },
  {
    "key": "UI_FROM_ADDRESS_COUNTY",
    "values": [
      {
        "iso": "nl",
        "value": "Vertrek adres - Gewest"
      },
      {
        "iso": "en",
        "value": "Start address - County"
      }
    ]
  },
  {
    "key": "UI_FROM_ADDRESS_COUNTRY",
    "values": [
      {
        "iso": "nl",
        "value": "Vertrek adres - Land"
      },
      {
        "iso": "en",
        "value": "Start address - Country"
      }
    ]
  },
  {
    "key": "UI_END_ADDRESS_STREET",
    "values": [
      {
        "iso": "nl",
        "value": "Aankomst adres - Straat"
      },
      {
        "iso": "en",
        "value": "End address - Street"
      }
    ]
  },
  {
    "key": "UI_END_ADDRESS_NUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "Aankomst adres - Huisnummer"
      },
      {
        "iso": "en",
        "value": "End address - House number"
      }
    ]
  },
  {
    "key": "UI_END_ADDRESS_ZIP",
    "values": [
      {
        "iso": "nl",
        "value": "Aankomst adres - Postcode"
      },
      {
        "iso": "en",
        "value": "End address - Zip code"
      }
    ]
  },
  {
    "key": "UI_END_ADDRESS_CITY",
    "values": [
      {
        "iso": "nl",
        "value": "Aankomst adres - Stad"
      },
      {
        "iso": "en",
        "value": "End address - City"
      }
    ]
  },
  {
    "key": "UI_END_ADDRESS_COUNTY",
    "values": [
      {
        "iso": "nl",
        "value": "Aankomst adres - Gewest"
      },
      {
        "iso": "en",
        "value": "End address - County"
      }
    ]
  },
  {
    "key": "UI_END_ADDRESS_COUNTRY",
    "values": [
      {
        "iso": "nl",
        "value": "Aankomst adres - Land"
      },
      {
        "iso": "en",
        "value": "End address - Country"
      }
    ]
  },
  {
    "key": "UI_TRANSLOCATION_ONE_WAY",
    "values": [
      {
        "iso": "nl",
        "value": "Enkele rit"
      },
      {
        "iso": "en",
        "value": "Single ride"
      }
    ]
  },
  {
    "key": "UI_TRANSLOCATION_BIDIRECTIONAL",
    "values": [
      {
        "iso": "nl",
        "value": "Heen en terugreis"
      },
      {
        "iso": "en",
        "value": "Round trip"
      }
    ]
  },
  {
    "key": "UI_COMMERCIAL_NAMES",
    "values": [
      {
        "iso": "nl",
        "value": "Handelsnamen"
      },
      {
        "iso": "en",
        "value": "Trade names"
      }
    ]
  },
  {
    "key": "UI_COMMERCIAL_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Handelsnaam"
      },
      {
        "iso": "en",
        "value": "Trade name"
      }
    ]
  },
  {
    "key": "UI_ADD_COMMERCIAL_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Handelsnaam toevoegen"
      },
      {
        "iso": "en",
        "value": "Add trade name"
      }
    ]
  },
  {
    "key": "UI_DEFAULT_PAYMENTTERMS",
    "values": [
      {
        "iso": "nl",
        "value": "Standaard betalingstermijn"
      },
      {
        "iso": "en",
        "value": "Default paymentterm"
      }
    ]
  },
  {
    "key": "UI_DELETE_COMMERCIAL_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Handelsnaam verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete trade name"
      }
    ]
  },
  {
    "key": "UI_COMPANY",
    "values": [
      {
        "iso": "nl",
        "value": "Bedrijf"
      },
      {
        "iso": "en",
        "value": "Company"
      }
    ]
  },
  {
    "key": "UI_CUSTOMER",
    "values": [
      {
        "iso": "nl",
        "value": "Klant"
      },
      {
        "iso": "en",
        "value": "Customer"
      }
    ]
  },
  {
    "key": "UI_PDF_LEGAL_INFORMATION_LOCATION",
    "values": [
      {
        "iso": "nl",
        "value": "Locatie van de legale informatie"
      },
      {
        "iso": "en",
        "value": "Location of the legal information"
      }
    ]
  },
  {
    "key": "UI_PDF_LEGAL_INFORMATION_LOCATION_FOOTER",
    "values": [
      {
        "iso": "nl",
        "value": "Voettekst"
      },
      {
        "iso": "en",
        "value": "Footer"
      }
    ]
  },
  {
    "key": "UI_PDF_LEGAL_INFORMATION_LOCATION_DETAILS",
    "values": [
      {
        "iso": "nl",
        "value": "Bedrijf details"
      },
      {
        "iso": "en",
        "value": "Company details"
      }
    ]
  },
  {
    "key": "UI_PDF_NAME_TYPE",
    "values": [
      {
        "iso": "nl",
        "value": "Bedrijfsnaam"
      },
      {
        "iso": "en",
        "value": "Company name"
      }
    ]
  },
  {
    "key": "UI_PDF_NAME_TYPE_COMPANY",
    "values": [
      {
        "iso": "nl",
        "value": "Handelsnaam"
      },
      {
        "iso": "en",
        "value": "Trade name"
      }
    ]
  },
  {
    "key": "UI_PDF_NAME_TYPE_LEGAL",
    "values": [
      {
        "iso": "nl",
        "value": "Legale naam"
      },
      {
        "iso": "en",
        "value": "Legal name"
      }
    ]
  },
  {
    "key": "UI_ADD_TRANSLOCATION",
    "values": [
      {
        "iso": "nl",
        "value": "Verplaatsing toevoegen"
      },
      {
        "iso": "en",
        "value": "Add translocation"
      }
    ]
  },
  {
    "key": "UI_DELETE_TRANSLOCATION",
    "values": [
      {
        "iso": "nl",
        "value": "Verplaatsing verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete translocation"
      }
    ]
  },
  {
    "key": "UI_DELETE_TRANSLOCATIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Verplaatsingen verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete translocations"
      }
    ]
  },
  {
    "key": "UI_ADD_TRANSLOCATION_EXPENSE",
    "values": [
      {
        "iso": "nl",
        "value": "Verplaatsing vergoeding toevoegen"
      },
      {
        "iso": "en",
        "value": "Add translocation expense"
      }
    ]
  },
  {
    "key": "UI_DELETE_TRANSLOCATION_EXPENSES",
    "values": [
      {
        "iso": "nl",
        "value": "Verplaatsing vergoedingen verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete translocation expenses"
      }
    ]
  },
  {
    "key": "UI_CALCULATE_DISTANCE",
    "values": [
      {
        "iso": "nl",
        "value": "Afstand berekenen"
      },
      {
        "iso": "en",
        "value": "Calculate distance"
      }
    ]
  },
  {
    "key": "UI_DUPLICATE",
    "values": [
      {
        "iso": "nl",
        "value": "Dupliceren"
      },
      {
        "iso": "en",
        "value": "Duplicate"
      }
    ]
  },
  {
    "key": "UI_OTHERS",
    "values": [
      {
        "iso": "nl",
        "value": "Overige"
      },
      {
        "iso": "en",
        "value": "Others"
      }
    ]
  },
  {
    "key": "UI_PRIORITY",
    "values": [
      {
        "iso": "nl",
        "value": "Prioriteit"
      },
      {
        "iso": "en",
        "value": "Priority"
      }
    ]
  },
  {
    "key": "UI_LOW",
    "values": [
      {
        "iso": "nl",
        "value": "Laag"
      },
      {
        "iso": "en",
        "value": "Low"
      }
    ]
  },
  {
    "key": "UI_NORMAL",
    "values": [
      {
        "iso": "nl",
        "value": "Normaal"
      },
      {
        "iso": "en",
        "value": "Normal"
      }
    ]
  },
  {
    "key": "UI_HIGH",
    "values": [
      {
        "iso": "nl",
        "value": "Hoog"
      },
      {
        "iso": "en",
        "value": "High"
      }
    ]
  },
  {
    "key": "UI_ATTACHMENTS",
    "values": [
      {
        "iso": "nl",
        "value": "Bijlages"
      },
      {
        "iso": "en",
        "value": "Attachments"
      }
    ]
  },
  {
    "key": "UI_SERVER_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Server informatie"
      },
      {
        "iso": "en",
        "value": "Server information"
      }
    ]
  },
  {
    "key": "UI_RAW_SERVER_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Onverwerkte server informatie"
      },
      {
        "iso": "en",
        "value": "Unprocessed server information"
      }
    ]
  },
  {
    "key": "UI_WORKPERFORMANCES",
    "values": [
      {
        "iso": "nl",
        "value": "Werk prestaties"
      },
      {
        "iso": "en",
        "value": "Work prestations"
      }
    ]
  },
  {
    "key": "UI_WORKSLIPS",
    "values": [
      {
        "iso": "nl",
        "value": "Werkbonnen"
      },
      {
        "iso": "en",
        "value": "Workslips"
      }
    ]
  },
  {
    "key": "UI_TIME_REGISTRATION",
    "values": [
      {
        "iso": "nl",
        "value": "Tijds registratie"
      },
      {
        "iso": "en",
        "value": "Time registration"
      }
    ]
  },
  {
    "key": "UI_WORKPERFORMANCE",
    "values": [
      {
        "iso": "nl",
        "value": "Werk prestatie"
      },
      {
        "iso": "en",
        "value": "Workprestation"
      }
    ]
  },
  {
    "key": "UI_ADD_WORKPERFORMANCE",
    "values": [
      {
        "iso": "nl",
        "value": "Werk prestatie toevoegen"
      },
      {
        "iso": "en",
        "value": "Add work prestations"
      }
    ]
  },
  {
    "key": "UI_DELETE_WORKPERFORMANCES",
    "values": [
      {
        "iso": "nl",
        "value": "Werk prestaties verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete work prestations"
      }
    ]
  },
  {
    "key": "UI_TIME",
    "values": [
      {
        "iso": "nl",
        "value": "Tijd"
      },
      {
        "iso": "en",
        "value": "Time"
      }
    ]
  },
  {
    "key": "UI_BREAK",
    "values": [
      {
        "iso": "nl",
        "value": "Pauze"
      },
      {
        "iso": "en",
        "value": "Break"
      }
    ]
  },
  {
    "key": "UI_LINKED_DATA",
    "values": [
      {
        "iso": "nl",
        "value": "Gelinkte gegevens"
      },
      {
        "iso": "en",
        "value": "Linked data"
      }
    ]
  },
  {
    "key": "UI_PRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Prijs"
      },
      {
        "iso": "en",
        "value": "Price"
      }
    ]
  },
  {
    "key": "UI_ESTIMATED_PRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Verwachte prijs"
      },
      {
        "iso": "en",
        "value": "Estimated price"
      }
    ]
  },
  {
    "key": "UI_DELETE_WORKPERFORMANCES_WARNING",
    "values": [
      {
        "iso": "nl",
        "value": "Opgelet! U wist enkel de link tussen de prestatie en de factuur. De automatisch aangemaakte factuurlijn blijft bestaan. Wenst u door te gaan?"
      },
      {
        "iso": "en",
        "value": "Attention! You are only breaking the link between the work performance and the invoice. The automatic created invoice line is not removed from the invoie. Are you sure you want to continue?"
      }
    ]
  },
  {
    "key": "UI_ARCHIVE",
    "values": [
      {
        "iso": "nl",
        "value": "Archiveren"
      },
      {
        "iso": "en",
        "value": "Archive"
      }
    ]
  },
  {
    "key": "UI_ACTIVATE",
    "values": [
      {
        "iso": "nl",
        "value": "Activeren"
      },
      {
        "iso": "en",
        "value": "Activate"
      }
    ]
  },
  {
    "key": "UI_ACTIVE",
    "values": [
      {
        "iso": "nl",
        "value": "Actief"
      },
      {
        "iso": "en",
        "value": "Active"
      }
    ]
  },
  {
    "key": "UI_EXPIRED",
    "values": [
      {
        "iso": "nl",
        "value": "Vervallen"
      },
      {
        "iso": "en",
        "value": "Expired"
      }
    ]
  },
  {
    "key": "UI_UPGRADED",
    "values": [
      {
        "iso": "nl",
        "value": "Geüpgrade"
      },
      {
        "iso": "en",
        "value": "Upgraded"
      }
    ]
  },
  {
    "key": "UI_TOINVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "Te factureren"
      },
      {
        "iso": "en",
        "value": "To invoice"
      }
    ]
  },
  {
    "key": "UI_SOLDOUT",
    "values": [
      {
        "iso": "nl",
        "value": "Uitverkocht"
      },
      {
        "iso": "en",
        "value": "Sold out"
      }
    ]
  },
  {
    "key": "UI_ARCHIVED",
    "values": [
      {
        "iso": "nl",
        "value": "Gearchiveerd"
      },
      {
        "iso": "en",
        "value": "Archived"
      }
    ]
  },
  {
    "key": "UI_SHOW_ARCHIVED",
    "values": [
      {
        "iso": "nl",
        "value": "Toon gearchiveerde"
      },
      {
        "iso": "en",
        "value": "Show archived"
      }
    ]
  },
  {
    "key": "UI_SHOW_ACTIVE",
    "values": [
      {
        "iso": "nl",
        "value": "Toon actieve"
      },
      {
        "iso": "en",
        "value": "Show active"
      }
    ]
  },
  {
    "key": "UI_LAST_LOGIN_DATE",
    "values": [
      {
        "iso": "nl",
        "value": "Laatste inlogdatum"
      },
      {
        "iso": "en",
        "value": "Last login date"
      }
    ]
  },
  {
    "key": "UI_ACTIVATED",
    "values": [
      {
        "iso": "nl",
        "value": "Geactiveerd"
      },
      {
        "iso": "en",
        "value": "Activated"
      }
    ]
  },
  {
    "key": "UI_ADD_USER",
    "values": [
      {
        "iso": "nl",
        "value": "Gebruiker aanmaken"
      },
      {
        "iso": "en",
        "value": "Add user"
      }
    ]
  },
  {
    "key": "UI_DELETE_USERS",
    "values": [
      {
        "iso": "nl",
        "value": "Gebruikers verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete users"
      }
    ]
  },
  {
    "key": "UI_ONBOARDING_USER_ACTIVATION_NOT_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Uw activatie code is ongeldig.<br />Gelieve contact op te nemen met \"support\" voor meer informatie. U wordt omgeleid naar de authenticatie pagina in 10 seconden..."
      },
      {
        "iso": "en",
        "value": "Your activation code is invalid.<br />Please contact support for more information. You will be redirected to the authentication page in 10 seconds...."
      }
    ]
  },
  {
    "key": "UI_ONBOARDING_USER_ACTIVATION_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Dag {{0}},<br /><br />Kies een wachtwoord en druk op de activatie knop om uw account te activeren.\nU wordt erna omgeleid naar de authenticatie pagina."
      },
      {
        "iso": "en",
        "value": "Hi {{0}},<br /><br />Chose a password and press the activation button to activate your account.\nYou will be redirected to the authentication page after you activated your account."
      }
    ]
  },
  {
    "key": "UI_ONBOARDING_USER_PASSWORD_RESET_NOT_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Uw wachtwoord reset code is ongeldig.<br />Gelieve contact op te nemen met \"support\" voor meer informatie. U wordt omgeleid naar de authenticatie pagina in 10 seconden..."
      },
      {
        "iso": "en",
        "value": "Your password reset code is invalid.<br />Please contact support for more information. You will be redirected to the authentication page in 10 seconds...."
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_USER_ACTIVATION_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "<h3>Gebruiker activeren</h3><p>Kies een wachtwoord om het account \"{{0}}\" te activeren.</p."
      },
      {
        "iso": "en",
        "value": "<h3>Activate user</h3><p>Choose a password to activate the account \"{{0}}\".</p>"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_USER_PASSWORD_NOT_SIMILAR",
    "values": [
      {
        "iso": "nl",
        "value": "Wachtwoorden komen niet overeen"
      },
      {
        "iso": "en",
        "value": "Passwords aren't similar"
      }
    ]
  },
  {
    "key": "UI_ONBOARDING_LINK_INVALID",
    "values": [
      {
        "iso": "nl",
        "value": "De link is niet langer geldig. Contacteer het verkooppunt of de support voor meer informatie."
      },
      {
        "iso": "en",
        "value": "The link is no longer valid. Please contact the sales point or support for more information."
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_USER_PASSWORD_RESET_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "<h3>Wachtwoord herstellen</h3><p>Kies een nieuw wachtwoord en bevestig doormiddel van de reset knop om het wachtoord te resetten voor \"{{0}}\"</p>"
      },
      {
        "iso": "en",
        "value": "<h3>Reset password</h3><p>Choose a new password and confirm by using the reset button to reset the password for \"{{0}}\"</p>"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_USER_PASSWORD_RESET_SAVING",
    "values": [
      {
        "iso": "nl",
        "value": "<h3>Wachtwoord herstellen</h3><p>Het wachtwoord wordt gewijzigd, even geduld...</p>"
      },
      {
        "iso": "en",
        "value": "<h3>Password Recovery</h3><p>The password is being changed, please be patient...</p>"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_USER_PASSWORD_RESET_SAVED",
    "values": [
      {
        "iso": "nl",
        "value": "<h3>Wachtwoord gewijzigd</h3><p>Het wachwoord is gewijzigd. Het nieuwe wachtwoord kan gebruikt worden op de authenticatie pagina.</p>"
      },
      {
        "iso": "en",
        "value": "<h3>Password Changed</h3><p>The password has been updated. You can now use the new password on the authentication page.</p>"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_USER_ACTIVATION_SAVING",
    "values": [
      {
        "iso": "nl",
        "value": "<h3>Account activeren</h3><p>Het account wordt geactiveerd, even geduld...</p>"
      },
      {
        "iso": "en",
        "value": "<h3>Activate Account</h3><p>The account is being activated, please be patient...</p>"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_USER_ACTIVATION_SAVED",
    "values": [
      {
        "iso": "nl",
        "value": "<h3>Account geactiveerd</h3><p>Het account is geactiveerd. Het nieuwe account kan gebruikt worden om in te loggen.</p>"
      },
      {
        "iso": "en",
        "value": "<h3>Account Activated</h3><p>The account has been activated. The new account can be used to log in.</p>"
      }
    ]
  },
  {
    "key": "UI_GO_TO_LOGIN_PAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Ga naar de authenticatie pagina"
      },
      {
        "iso": "en",
        "value": "Go to the authentication page"
      }
    ]
  },
  {
    "key": "UI_ACTIVATE_ACCOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Account activeren"
      },
      {
        "iso": "en",
        "value": "Activate account"
      }
    ]
  },
  {
    "key": "UI_CHOSE_A_PASSWORD",
    "values": [
      {
        "iso": "nl",
        "value": "Kies een sterk wachtwoord doormiddel van een complexe combinatie van tekens, cijfers en letters die minimum 8 karakters lang is."
      },
      {
        "iso": "en",
        "value": "Choose a strong password using a complex combination of characters, numbers and letters that is at least 8 characters long."
      }
    ]
  },
  {
    "key": "UI_PURCHASE_LICENSE",
    "values": [
      {
        "iso": "nl",
        "value": "License aankopen"
      },
      {
        "iso": "en",
        "value": "Purchase license"
      }
    ]
  },
  {
    "key": "UI_LICENSE_EXTRA_USER_PRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Extra gebruiker"
      },
      {
        "iso": "en",
        "value": "Extra user"
      }
    ]
  },
  {
    "key": "UI_PASSWORD_TOO_WEAK",
    "values": [
      {
        "iso": "nl",
        "value": "Uw wachtwoord is te zwak! Kies een sterker wachtwoord."
      },
      {
        "iso": "en",
        "value": "Your password is too weak! Pick a stronger one."
      }
    ]
  },
  {
    "key": "UI_PASSWORD_WEAK",
    "values": [
      {
        "iso": "nl",
        "value": "Wachtwoord: zwak"
      },
      {
        "iso": "en",
        "value": "Password: weak"
      }
    ]
  },
  {
    "key": "UI_PASSWORD_MEDIUM",
    "values": [
      {
        "iso": "nl",
        "value": "Wachtwoord: gemiddeld"
      },
      {
        "iso": "en",
        "value": "Password: medium"
      }
    ]
  },
  {
    "key": "UI_PASSWORD_STRONG",
    "values": [
      {
        "iso": "nl",
        "value": "Wachtwoord: sterk"
      },
      {
        "iso": "en",
        "value": "Password: strong"
      }
    ]
  },
  {
    "key": "UI_USER_ACTIVATED",
    "values": [
      {
        "iso": "nl",
        "value": "De gebruiker is geactiveerd. U kunt nu inloggen."
      },
      {
        "iso": "en",
        "value": "User has been activated. You can now login."
      }
    ]
  },
  {
    "key": "UI_INACTIVE",
    "values": [
      {
        "iso": "nl",
        "value": "Gedeactiveerd"
      },
      {
        "iso": "en",
        "value": "Deactivated"
      }
    ]
  },
  {
    "key": "UI_INACTIVATE",
    "values": [
      {
        "iso": "nl",
        "value": "De-activeren"
      },
      {
        "iso": "en",
        "value": "Deactivate"
      }
    ]
  },
  {
    "key": "UI_SHOW_INACTIVE",
    "values": [
      {
        "iso": "nl",
        "value": "Toon gedeactiveerde"
      },
      {
        "iso": "en",
        "value": "Show deactivated"
      }
    ]
  },
  {
    "key": "UI_RESET_PASSWORD",
    "values": [
      {
        "iso": "nl",
        "value": "Wachtwoord resetten"
      },
      {
        "iso": "en",
        "value": "Reset password"
      }
    ]
  },
  {
    "key": "UI_RESET_PASSWORD_MAIL_SENDING",
    "values": [
      {
        "iso": "nl",
        "value": "Wachtwoord reset e-mail versturen..."
      },
      {
        "iso": "en",
        "value": "Sending reset password email..."
      }
    ]
  },
  {
    "key": "UI_RESET_PASSWORD_MAIL_SEND",
    "values": [
      {
        "iso": "nl",
        "value": "Wachtwoord reset e-mail is verstuurd"
      },
      {
        "iso": "en",
        "value": "Reset password email has been send"
      }
    ]
  },
  {
    "key": "UI_RESET_PASSWORD_MAIL_FAILED",
    "values": [
      {
        "iso": "nl",
        "value": "Wachtwoord reset e-mail is niet verstuurd wegens een fout"
      },
      {
        "iso": "en",
        "value": "Reset password email couldn't be send due to an error"
      }
    ]
  },
  {
    "key": "UI_RESET",
    "values": [
      {
        "iso": "nl",
        "value": "Reset"
      },
      {
        "iso": "en",
        "value": "Reset"
      }
    ]
  },
  {
    "key": "UI_USER_PASSWORD_RESETTED",
    "values": [
      {
        "iso": "nl",
        "value": "Het wachtwoord is gereset."
      },
      {
        "iso": "en",
        "value": "The password has been reset."
      }
    ]
  },
  {
    "key": "UI_DEFAULT_WORKPERFORMANCE_VALUES",
    "values": [
      {
        "iso": "nl",
        "value": "Standaard prestatie waardes"
      },
      {
        "iso": "en",
        "value": "Default work performance Values"
      }
    ]
  },
  {
    "key": "UI_DEFAULT_SERVICE",
    "values": [
      {
        "iso": "nl",
        "value": "Standaard dienst"
      },
      {
        "iso": "en",
        "value": "Default service"
      }
    ]
  },
  {
    "key": "UI_USER_SETTINGS_PASSWORD_IS_REQUIRED_ON_EMAIL_CHANGE",
    "values": [
      {
        "iso": "nl",
        "value": "Wachtwoord is verplicht bij iedere wijziging aan de e-mail instellingen"
      },
      {
        "iso": "en",
        "value": "Password is required for any changes to the email settings"
      }
    ]
  },
  {
    "key": "UI_DEFAULT_PRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Standaard prijs"
      },
      {
        "iso": "en",
        "value": "Default price"
      }
    ]
  },
  {
    "key": "UI_PRICE_PER_KM",
    "values": [
      {
        "iso": "nl",
        "value": "Prijs per kilometer"
      },
      {
        "iso": "en",
        "value": "Price per kilometer"
      }
    ]
  },
  {
    "key": "UI_KM",
    "values": [
      {
        "iso": "nl",
        "value": "Km"
      },
      {
        "iso": "en",
        "value": "Km"
      }
    ]
  },
  {
    "key": "UI_MODAL_LEAVE_COMPONENT_ACCEPTANCE_DENY_BUTTON",
    "values": [
      {
        "iso": "nl",
        "value": "Annuleren"
      },
      {
        "iso": "en",
        "value": "Cancel"
      }
    ]
  },
  {
    "key": "UI_MODAL_LEAVE_COMPONENT_ACCEPTANCE_CONFIRM_BUTTON",
    "values": [
      {
        "iso": "nl",
        "value": "Verlaten"
      },
      {
        "iso": "en",
        "value": "Leave"
      }
    ]
  },
  {
    "key": "UI_MODAL_LEAVE_COMPONENT_ACCEPTANCE_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "Pagina verlaten?"
      },
      {
        "iso": "en",
        "value": "Leave page?"
      }
    ]
  },
  {
    "key": "UI_LEAVE_PAGE_WITH_UNSAVED_CHANGES",
    "values": [
      {
        "iso": "nl",
        "value": "Als u de pagina verlaat zonder op te slaan, gaan al uw wijzigingen verloren. Bent u zeker dat u de pagina wenst te verlaten?"
      },
      {
        "iso": "en",
        "value": "If you leave the page without saving, all your changes will be lost. Are you sure you wish to leave this page?"
      }
    ]
  },
  {
    "key": "UI_MODAL_SET_GLOBAL_ACCOUNTNUMBER_ACCEPTANCE_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "Rekeningnummer instellen"
      },
      {
        "iso": "en",
        "value": "Set Account number"
      }
    ]
  },
  {
    "key": "UI_MODAL_SET_GLOBAL_ACCOUNTNUMBER_ACCEPTANCE_BODY",
    "values": [
      {
        "iso": "nl",
        "value": "Boekhoudkundig rekeningnummer toepassen op alle actieve sublijnen? Het huidige boekhoudkundig rekeningnummer word definitief aangepast."
      },
      {
        "iso": "en",
        "value": "Apply accounting number to all active sublines? The current accounting number will be permanently updated."
      }
    ]
  },
  {
    "key": "UI_MODAL_SET_GLOBAL_ACCOUNTNUMBER_ACCEPTANCE_DENY_BUTTON",
    "values": [
      {
        "iso": "nl",
        "value": "Weigeren"
      },
      {
        "iso": "en",
        "value": "Deny"
      }
    ]
  },
  {
    "key": "UI_MODAL_SET_GLOBAL_ACCOUNTNUMBER_ACCEPTANCE_CONFIRM_BUTTON",
    "values": [
      {
        "iso": "nl",
        "value": "Bevestigen"
      },
      {
        "iso": "en",
        "value": "Confirm"
      }
    ]
  },
  {
    "key": "UI_MODAL_SET_GLOBAL_VAT_ACCEPTANCE_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "BTW tarief instellen"
      },
      {
        "iso": "en",
        "value": "Set VAT rate"
      }
    ]
  },
  {
    "key": "UI_MODAL_SET_GLOBAL_VAT_ACCEPTANCE_BODY",
    "values": [
      {
        "iso": "nl",
        "value": "BTW tarief toepassen op alle actieve sublijnen? De huidige BTW tarieven en bijhorende prijzen worden definitief aangepast."
      },
      {
        "iso": "en",
        "value": "Apply VAT rate to all active sublines? The current VAT rates and corresponding prices will be definitively adjusted."
      }
    ]
  },
  {
    "key": "UI_MODAL_SET_GLOBAL_VAT_ACCEPTANCE_DENY_BUTTON",
    "values": [
      {
        "iso": "nl",
        "value": "Weigeren"
      },
      {
        "iso": "en",
        "value": "Deny"
      }
    ]
  },
  {
    "key": "UI_MODAL_SET_GLOBAL_VAT_ACCEPTANCE_CONFIRM_BUTTON",
    "values": [
      {
        "iso": "nl",
        "value": "Bevestigen"
      },
      {
        "iso": "en",
        "value": "Confirm"
      }
    ]
  },
  {
    "key": "UI_COMMUNICATION",
    "values": [
      {
        "iso": "nl",
        "value": "Communicatie"
      },
      {
        "iso": "en",
        "value": "Cummunication"
      }
    ]
  },
  {
    "key": "UI_ADD_COMPANY",
    "values": [
      {
        "iso": "nl",
        "value": "Bedrijf toevoegen"
      },
      {
        "iso": "en",
        "value": "Add company"
      }
    ]
  },
  {
    "key": "UI_DELETE_COMPANIES",
    "values": [
      {
        "iso": "nl",
        "value": "Bedrijven verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete companies"
      }
    ]
  },
  {
    "key": "UI_BANKS",
    "values": [
      {
        "iso": "nl",
        "value": "Banken"
      },
      {
        "iso": "en",
        "value": "Banking houses"
      }
    ]
  },
  {
    "key": "UI_ACCOUNTNUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "Rekeningnummer"
      },
      {
        "iso": "en",
        "value": "Account number"
      }
    ]
  },
  {
    "key": "UI_BIC",
    "values": [
      {
        "iso": "nl",
        "value": "BIC"
      },
      {
        "iso": "en",
        "value": "BIC"
      }
    ]
  },
  {
    "key": "UI_APPLY",
    "values": [
      {
        "iso": "nl",
        "value": "Toepassen"
      },
      {
        "iso": "en",
        "value": "Apply"
      }
    ]
  },
  {
    "key": "UI_ATTACHMENT",
    "values": [
      {
        "iso": "nl",
        "value": "Bijlage"
      },
      {
        "iso": "en",
        "value": "Attachment"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_PDF",
    "values": [
      {
        "iso": "nl",
        "value": "PDF"
      },
      {
        "iso": "en",
        "value": "PDF"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_UBL",
    "values": [
      {
        "iso": "nl",
        "value": "UBL"
      },
      {
        "iso": "en",
        "value": "UBL"
      }
    ]
  },
  {
    "key": "UI_SHOW_PDF_VIEWER",
    "values": [
      {
        "iso": "nl",
        "value": "PDF voorbeeld tonen"
      },
      {
        "iso": "en",
        "value": "Show PDF example"
      }
    ]
  },
  {
    "key": "UI_HIDE_PDF_VIEWER",
    "values": [
      {
        "iso": "nl",
        "value": "PDF voorbeeld verbergen"
      },
      {
        "iso": "en",
        "value": "Hide PDF example"
      }
    ]
  },
  {
    "key": "UI_PURCHASE_INVOICE_DOCUMENT_TYPE_MODAL_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Niet alle documenten zijn dezelfde. Om deze reden delen we alle documenten op in verschillende onderdelen. Kies hieronder het correcte type van het document."
      },
      {
        "iso": "en",
        "value": "Not all documents are the same. Because of this reason, we devide all documents into several parts. Select the correct type of the document."
      }
    ]
  },
  {
    "key": "UI_PURCHASE_INVOICE_DOCUMENT_TYPE_MODAL_ATTACHMENT",
    "values": [
      {
        "iso": "nl",
        "value": "Het document is bedoeld als een bijlage die de aankoop factuur aanvult. Deze bijlages hebben in de meeste gevallen geen wettelijke waarden maar kunnen meer informatie geven omtrent de aankoop. Enkele voorbeelden hiervan zijn ontvangstbewijzen, leveringsinformatie of BTW certificaten."
      },
      {
        "iso": "en",
        "value": "The document is intended as an attachment that supplements the purchase invoice. In mast cases, these attachmetns hold no legal value but can provide more information about the purchase. Some examples are delivery receipts, travel information or VAT certificates."
      }
    ]
  },
  {
    "key": "UI_PURCHASE_INVOICE_DOCUMENT_TYPE_MODAL_PDF",
    "values": [
      {
        "iso": "nl",
        "value": "Het document is een aankoopfactuur geleverd in PDF formaat. Deze bijlage heeft een belangrijke juridische waarde, in de meeste gevallen is hier slechts 1 versie van dit document per aankoop."
      },
      {
        "iso": "en",
        "value": "The document is a purchase invoice delivered in PDF format. This document has an important legal value, in most cases there is only one version of this document per purchase."
      }
    ]
  },
  {
    "key": "UI_PURCHASE_INVOICE_DOCUMENT_TYPE_MODAL_UBL",
    "values": [
      {
        "iso": "nl",
        "value": "Het document is een aankoopfactuur geleverd in UBL formaat. Deze bijlage bevat alle informatie alsook de aankoopfactuur die een belangrijke juridische waarde bevat."
      },
      {
        "iso": "en",
        "value": "The document is a purchase invoice delivered in UBL format. This documents contains all information as well as the purchase invoice, which contains important legal value."
      }
    ]
  },
  {
    "key": "UI_AUTOMATIC_IMPORT_ATTACHMENTS_ON_EMAIL_CREATION",
    "values": [
      {
        "iso": "nl",
        "value": "Automatisch importeer bijlage's bij het aanmaken van een e-mail"
      },
      {
        "iso": "en",
        "value": "Automatic import attachments when creating an email"
      }
    ]
  },
  {
    "key": "UI_EXPAND_PDF_ON_INVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "Toon document PDF bij het openen van de factuur"
      },
      {
        "iso": "en",
        "value": "Show document PDF when opening an invoice"
      }
    ]
  },
  {
    "key": "UI_EXPAND_PDF_ON_ORDER",
    "values": [
      {
        "iso": "nl",
        "value": "Toon document PDF bij het openen van de bestelling"
      },
      {
        "iso": "en",
        "value": "Show document PDF when opening an order"
      }
    ]
  },
  {
    "key": "UI_EXPAND_PDF_ON_QUOTE",
    "values": [
      {
        "iso": "nl",
        "value": "Toon document PDF bij het openen van de offerte"
      },
      {
        "iso": "en",
        "value": "Show document PDF when opening a quote"
      }
    ]
  },
  {
    "key": "UI_EXPAND_PDF_ON_PURCHASE_INVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "Toon document PDF bij het openen van de aankoop factuur"
      },
      {
        "iso": "en",
        "value": "Show document PDF when opening a purchase invoice"
      }
    ]
  },
  {
    "key": "UI_DELETE_ARTICLECATEGORIES",
    "values": [
      {
        "iso": "nl",
        "value": "Artikel categorieën wissen"
      },
      {
        "iso": "en",
        "value": "Delete article categories"
      }
    ]
  },
  {
    "key": "UI_ADD_ARTICLECATEGORY",
    "values": [
      {
        "iso": "nl",
        "value": "Artikel categorie toevoegen"
      },
      {
        "iso": "en",
        "value": "Add article category"
      }
    ]
  },
  {
    "key": "UI_EMAIL_TYPE_GOOGLE_API",
    "values": [
      {
        "iso": "nl",
        "value": "Google workspace / Gmail"
      },
      {
        "iso": "en",
        "value": "Google workspace / Gmail"
      }
    ]
  },
  {
    "key": "UI_ADD_GOOGLE_API",
    "values": [
      {
        "iso": "nl",
        "value": "Google account ophalen"
      },
      {
        "iso": "en",
        "value": "Fetch google account"
      }
    ]
  },
  {
    "key": "UI_REMOVE_GOOGLE_API",
    "values": [
      {
        "iso": "nl",
        "value": "Google account loskoppelen"
      },
      {
        "iso": "en",
        "value": "Unlink your google account"
      }
    ]
  },
  {
    "key": "UI_CLOSE",
    "values": [
      {
        "iso": "nl",
        "value": "Sluiten"
      },
      {
        "iso": "en",
        "value": "Close"
      }
    ]
  },
  {
    "key": "UI_LOGIN_FAILED",
    "values": [
      {
        "iso": "nl",
        "value": "Login mislukt. Gelieve uw gegevens na te kijken op fouten en opnieuw te proberen."
      },
      {
        "iso": "en",
        "value": "Login failed. Please check your credentials for errors and try again."
      }
    ]
  },
  {
    "key": "UI_FIELD_REQUIRED",
    "values": [
      {
        "iso": "nl",
        "value": "Dit veld is verplicht!"
      },
      {
        "iso": "en",
        "value": "This field is required!"
      }
    ]
  },
  {
    "key": "UI_PEPPOL_PASSPORT_INSTRUCTIONS",
    "values": [
      {
        "iso": "nl",
        "value": "De afbeelding (jpg of png) van het paspoort mag geen achtergrond bevatten, mag niet wazig zijn en mag een maximum groote van 1MB hebben. Deze moet van een officiële vertegenwoordiger zijn van de onderneming waarvan enkel de voorkant verplicht is."
      },
      {
        "iso": "en",
        "value": "The image (jpg or png) of the passport should have no background, should not be blurred and should have a maximum size of 1MB. It must be from an official representative of the company of which only the front cover is required."
      }
    ]
  },
  {
    "key": "UI_ID_IMAGE_SIZE_EXCEEDED_PEPPOL",
    "values": [
      {
        "iso": "nl",
        "value": "De afbeelding van het paspoort mag niet grooter zijn dan 1MB!"
      },
      {
        "iso": "en",
        "value": "Passport image must not exceed 1MB in size!"
      }
    ]
  },
  {
    "key": "UI_FIELD_REQUIRED_WITH_INTERNATIONAL_PHONE_NUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "Het is verplicht een internationaal nummer te gebruiken (bv +32xxxxxxxxx)"
      },
      {
        "iso": "en",
        "value": "It is mandatory to use an international number (e.g. +32xxxxxxxxxxx)"
      }
    ]
  },
  {
    "key": "UI_FIELD_REQUIRED_WITH_HTTP_URL",
    "values": [
      {
        "iso": "nl",
        "value": "Ongeldige URL. Gelieve een volledige URL te gebruiken startend met http of https."
      },
      {
        "iso": "en",
        "value": "Invalid URL. Please use a complete URL starting with http or https."
      }
    ]
  },
  {
    "key": "UI_FIELD_REQUIRED_VALID_EMAIL",
    "values": [
      {
        "iso": "nl",
        "value": "Een geldig e-mailadres is verplicht!"
      },
      {
        "iso": "en",
        "value": "A valid email address is required!"
      }
    ]
  },
  {
    "key": "UI_RELATION_SAVE_NO_ADDRESSES",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen adressen toegekend voor deze klant. Dit kan limiterende gevolgen hebben in de applicatie met voorname bij documenten."
      },
      {
        "iso": "en",
        "value": "No addresses have been assigned for this customer. This can have limiting consequences in the application, especially with documents."
      }
    ]
  },
  {
    "key": "UI_PURCHASEINVOICE_SAVE_NO_LEGAL_DOCUMENT",
    "values": [
      {
        "iso": "nl",
        "value": "Er is geen geldig legaal document gevonden voor deze aankoop factuur. Dit kan juridische gevolgen hebben voor uw bedrijf. We raden u ten strengste aan om een PDF factuur of UBL bestand toe te voegen."
      },
      {
        "iso": "en",
        "value": "No valid legal document has been found for this purchase invoice. This can have legal consequences for your company. We strongly recommend that you add a PDF invoice or UBL file."
      }
    ]
  },
  {
    "key": "UI_SKU_INFORMATION_TEXT",
    "values": [
      {
        "iso": "nl",
        "value": "Een stock keeping unit (SKU) is een Engelse term voor een uniek product of dienst, bedoeld voor de verkoop, met inbegrip van alle kenmerken die het onderscheiden van andere artikelen en uitvoeringen. De eigenschappen kunnen onder meer bestaan uit de fabrikant, materiaal, kleur, grootte, verpakking, onderdelen, functionaliteit/uitbreidingen en voorwaarden."
      },
      {
        "iso": "en",
        "value": "A stock keeping unit (SKU) is an term for a unique product or service intended for sale, including all the features that distinguish it from other items and designs. The properties may include manufacturer, material, color, size, packaging, parts, functionality/extensions and conditions."
      }
    ]
  },
  {
    "key": "UI_DUPLICATED",
    "values": [
      {
        "iso": "nl",
        "value": "Dupliceren gelukt, u bevind zich nu op de pagina van het gedupliceerd object."
      },
      {
        "iso": "en",
        "value": "Duplicated successfully, You're currently on the page of the duplicated object."
      }
    ]
  },
  {
    "key": "UI_NEXT",
    "values": [
      {
        "iso": "nl",
        "value": "Volgende"
      },
      {
        "iso": "en",
        "value": "Next"
      }
    ]
  },
  {
    "key": "UI_COMPLETE",
    "values": [
      {
        "iso": "nl",
        "value": "Voltooien"
      },
      {
        "iso": "en",
        "value": "Complete"
      }
    ]
  },
  {
    "key": "UI_ONBOARDING_SUBSCRIBER_THEME",
    "values": [
      {
        "iso": "nl",
        "value": "Naast taal hebben we nog looks...<br /><br />Bent u een liefhebber van zonneschijn of maanlicht? Kies het gewenste thema voor de applicatie wanneer u ingelogd bent.<br />Wees gerust, deze instelling kunt u later uitgebreid aanpassen in de applicatie bij uw gebruikersinstellingen."
      },
      {
        "iso": "en",
        "value": "In addition to language, we also have looks...<br /><br />Are you a lover of sunshine or moonlight? Choose the desired theme for the application when you are logged in.<br />Don't worry, you can later extensively adjust this setting in the application in your user settings."
      }
    ]
  },
  {
    "key": "UI_ONBOARDING_SUBSCRIBER_COMPANY",
    "values": [
      {
        "iso": "nl",
        "value": "Volgende stap...<br />We beginnen alvast met uw bedrijfsgegevens. Uw bedrijf is het toegangsticket tot de applicatie en bevat al uw bedrijfslogica.<br />De gegevens en instellingen op bedrijfsniveau zijn overkoepelend voor al de gebruikers die werken onder uw bedrijf."
      },
      {
        "iso": "en",
        "value": "Next step...<br />We start with your company details. Your company is the entrance ticket to the application and contains all your business logic.<br />The company level data and settings are umbrella for all the users working under your company."
      }
    ]
  },
  {
    "key": "UI_ONBOARDING_SUBSCRIBER_DOCUMENTSETTINGS",
    "values": [
      {
        "iso": "nl",
        "value": "Documenten zijn het belangrijkste onderdeel van de applicatie.<br />Daarom willen we dan ook dat uw documenten direct opgemaakt worden met de correcte nummers. Daarom kunt u hieronder de standaard prefixen en scheidingsnummer aanpassen.<br /><br />Wenst u dit later nog aan te passen? Dan kan dit gemakkelijk in de abonnee-instellingen."
      },
      {
        "iso": "en",
        "value": "Documents are the most important part of the application.<br />That is why we want your documents to be immediately formatted with the correct numbers. Therefore you can change the default prefixes and separator number below.<br /><br />Would you like to change this later? Then this can easily be done in the subscriber settings."
      }
    ]
  },
  {
    "key": "UI_ONBOARDING_SUBSCRIBER_USER",
    "values": [
      {
        "iso": "nl",
        "value": "Documenten zijn het belangrijkste onderdeel van de applicatie.<br />Daarom willen we dan ook dat uw documenten direct opgemaakt worden met de correcte nummers. Daarom kunt u hieronder de standaard prefixen en scheidingsnummer aanpassen.<br /><br />Wenst u dit later nog aan te passen? Dan kan dit gemakkelijk in de abonnee-instellingen."
      },
      {
        "iso": "en",
        "value": "Documents are the most important part of the application.<br />That is why we want your documents to be immediately formatted with the correct numbers. Therefore you can change the default prefixes and separator number below.<br /><br />Would you like to change this later? Then this can easily be done in the subscriber settings."
      }
    ]
  },
  {
    "key": "UI_ONBOARDING_SUBSCRIBER_EMAIL",
    "values": [
      {
        "iso": "nl",
        "value": "Uw gebruiker is aangemaakt maar wist u dat u ook uw e-mail kan koppelen?<br /><br />Via het systeem hebt u namelijk de mogelijkheid om mails te versturen naar uw klanten,<br />Lekker handig, niet?<br />U kunt hiervoor de standaard no-reply systeem van de applicatie gebruiken, maar via een eigen e-mail systeem is nog beter.<br />Op deze manier kunt u voorkomen dat uw e-mails als spam geregistreerd worden bij uw klant.<br />Weet u niet hoe u uw e-mail kunt instellen? Kies dat voor de standaard applicatie methode en contacteer support om dit samen te voltooien."
      },
      {
        "iso": "en",
        "value": "Your user has been created, but did you know that you can also link your e-mail?<br /><br />Through the system you have the option to send e-mails to your customers,<br />Nice and handy, isn't it? <br />You can use the application's standard no-reply system for this, but using your own e-mail system is even better.<br />This way you can prevent your e-mails from being registered as spam at your customer.<br />Don't know how to set up your email? Choose that for the standard application method and contact support to complete this together."
      }
    ]
  },
  {
    "key": "UI_ONBOARDING_SUBSCRIBER_DEFAULTPARAMETERS",
    "values": [
      {
        "iso": "nl",
        "value": "Voila, u bent klaar!<br /><br />Ofja bijna toch. In het systeem hebben we een hele hoop parameters die in vele zaken benodigd zijn voor een goede werking.<br />U kunt deze later zelf aanmaken, verwijderen of bewerken onder de optie Parameters in de applicatie.<br /><br />Wenst u dat we de standaard belgische parameters aanmaken voor u? Dan kunt u de gewenste parameters aanvinken alvorens op voltooien te klikken en regelen wij de rest."
      },
      {
        "iso": "en",
        "value": "Voila, you're done!<br /><br />Almost. In the system we have a lot of parameters that are needed in many things for proper functioning.<br />You can later create, delete or edit these yourself under the Parameters option in the application.<br /><br /> Would you like us to create the standard Belgian parameters for you? Then you can tick the desired parameters before clicking on finish and we will take care of the rest."
      }
    ]
  },
  {
    "key": "UI_ONBOARDING_SUBSCRIBER_LOGO",
    "values": [
      {
        "iso": "nl",
        "value": "Uw bedrijfsgegevens zijn niet compleet zonder logo. Klik op het onderstaande tijdelijk logo en kies uw eigen logo.<br />U kunt uw logo later wijzigen onder HR - Bedrijven of meerderer bedrijven, met elk zijn gegevens en logo, aanmaken onder hetzelfde BTW nummer voor verschillende handelsmerken."
      },
      {
        "iso": "en",
        "value": "Your company information is not complete without a logo. Click on the placeholder logo and choose your own.<br />You can change your logo later under HR - Companies or create more companies, each with its data and logo, under the same VAT number for different trademarks."
      }
    ]
  },
  {
    "key": "UI_ONBOARDING_TOKEN_NOT_FOUND_OR_EXPIRED",
    "values": [
      {
        "iso": "nl",
        "value": "Uw onboarding url wordt niet herkend of is verlopen. Gelieve support te contacteren voor meer informatie."
      },
      {
        "iso": "en",
        "value": "Your onboarding url isn't known or is expired. Please contact support for more information."
      }
    ]
  },
  {
    "key": "UI_SUBSCRIBER_ONBOARDING_COMPLETED",
    "values": [
      {
        "iso": "nl",
        "value": "Uw account is aangemaakt en uw voorkeursinstellingen zijn toegepast. U kunt nu inloggen met uw nieuw account."
      },
      {
        "iso": "en",
        "value": "Your account has been created and your preferences have been applied. You can now log in with your new account."
      }
    ]
  },
  {
    "key": "UI_SUBSCRIBER_ONBOARDING_VAT_INVALID",
    "values": [
      {
        "iso": "nl",
        "value": "Het opgegeven BTW nummer ({{0}}) is niet geldig volgens VIES, gelieve de spelling te bekijken of support te contacteren."
      },
      {
        "iso": "en",
        "value": "The specified VAT number ({{0}}) is not valid according to VIES, please check the spelling or contact support."
      }
    ]
  },
  {
    "key": "UI_CREDITNOTES",
    "values": [
      {
        "iso": "nl",
        "value": "Creditnota's"
      },
      {
        "iso": "en",
        "value": "Creditnotes"
      }
    ]
  },
  {
    "key": "UI_ADD_CREDITNOTE",
    "values": [
      {
        "iso": "nl",
        "value": "Creditnota toevoegen"
      },
      {
        "iso": "en",
        "value": "Add creditnote"
      }
    ]
  },
  {
    "key": "UI_REFUNDPRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Terugbetaling"
      },
      {
        "iso": "en",
        "value": "Refund"
      }
    ]
  },
  {
    "key": "UI_REFUNDPRICE_INCL_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Terugbetaling incl. BTW"
      },
      {
        "iso": "en",
        "value": "Refund incl. VAT"
      }
    ]
  },
  {
    "key": "UI_CONVERT_TO",
    "values": [
      {
        "iso": "nl",
        "value": "Omzetten naar"
      },
      {
        "iso": "en",
        "value": "Convert to"
      }
    ]
  },
  {
    "key": "UI_CREDITNOTE",
    "values": [
      {
        "iso": "nl",
        "value": "Creditnota"
      },
      {
        "iso": "en",
        "value": "Creditnote"
      }
    ]
  },
  {
    "key": "UI_PURCHASE_CREDITNOTES",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoopcreditnota's"
      },
      {
        "iso": "en",
        "value": "Purchasecreditnote's"
      }
    ]
  },
  {
    "key": "UI_SALES_CREDITNOTES",
    "values": [
      {
        "iso": "nl",
        "value": "Verkoop creditnota's"
      },
      {
        "iso": "en",
        "value": "Sales creditnote's"
      }
    ]
  },
  {
    "key": "UI_SALES_CREDITNOTE_SUBLINES",
    "values": [
      {
        "iso": "nl",
        "value": "Verkoop kredietlijnen"
      },
      {
        "iso": "en",
        "value": "Sales creditlines"
      }
    ]
  },
  {
    "key": "UI_EMAIL_TEMPLATES",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail sjablonen"
      },
      {
        "iso": "en",
        "value": "Email templates"
      }
    ]
  },
  {
    "key": "UI_ADD_BODY_TEMPLATE",
    "values": [
      {
        "iso": "nl",
        "value": "Bericht sjabloon toevoegen"
      },
      {
        "iso": "en",
        "value": "Add body template"
      }
    ]
  },
  {
    "key": "UI_ADD_FOOTER_TEMPLATE",
    "values": [
      {
        "iso": "nl",
        "value": "handtekening sjabloon toevoegen"
      },
      {
        "iso": "en",
        "value": "Add signature template"
      }
    ]
  },
  {
    "key": "UI_STOCK",
    "values": [
      {
        "iso": "nl",
        "value": "Stock"
      },
      {
        "iso": "en",
        "value": "Stock"
      }
    ]
  },
  {
    "key": "UI_SUPPLY",
    "values": [
      {
        "iso": "nl",
        "value": "Voorraad"
      },
      {
        "iso": "en",
        "value": "Supply"
      }
    ]
  },
  {
    "key": "UI_TRANSLOCATION",
    "values": [
      {
        "iso": "nl",
        "value": "Verplaatsing"
      },
      {
        "iso": "en",
        "value": "Translocation"
      }
    ]
  },
  {
    "key": "UI_EMAIL_TYPE_AZURE_API",
    "values": [
      {
        "iso": "nl",
        "value": "Exchange 365 / Outlook"
      },
      {
        "iso": "en",
        "value": "Exchange 365 / Outlook"
      }
    ]
  },
  {
    "key": "UI_ADD_AZURE_API",
    "values": [
      {
        "iso": "nl",
        "value": "Microsoft account ophalen"
      },
      {
        "iso": "en",
        "value": "Fetch microsoft account"
      }
    ]
  },
  {
    "key": "UI_REMOVE_AZURE_API",
    "values": [
      {
        "iso": "nl",
        "value": "Microsoft account loskoppelen"
      },
      {
        "iso": "en",
        "value": "Unlink Microsoft account"
      }
    ]
  },
  {
    "key": "UI_SUBSCRIPTIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Abonnementen"
      },
      {
        "iso": "en",
        "value": "Subscriptions"
      }
    ]
  },
  {
    "key": "UI_SUBSCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Abonnement"
      },
      {
        "iso": "en",
        "value": "Subscription"
      }
    ]
  },
  {
    "key": "UI_ADD_SUBSCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Abonnement toevoegen"
      },
      {
        "iso": "en",
        "value": "Add subscription"
      }
    ]
  },
  {
    "key": "UI_DELETE_SUBSCRIPTIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Abonnementen verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete subscriptions"
      }
    ]
  },
  {
    "key": "UI_STARTDATE",
    "values": [
      {
        "iso": "nl",
        "value": "Startdatum"
      },
      {
        "iso": "en",
        "value": "Startdate"
      }
    ]
  },
  {
    "key": "UI_FREQUENCY_SETTINGS",
    "values": [
      {
        "iso": "nl",
        "value": "Frequentie instellingen"
      },
      {
        "iso": "en",
        "value": "Frequency setting"
      }
    ]
  },
  {
    "key": "UI_SUBSCRIPTION_FREQUENCY_VALUE",
    "values": [
      {
        "iso": "nl",
        "value": "Elke"
      },
      {
        "iso": "en",
        "value": "Every"
      }
    ]
  },
  {
    "key": "UI_FREQUENCY",
    "values": [
      {
        "iso": "nl",
        "value": "Frequentie"
      },
      {
        "iso": "en",
        "value": "Frequency"
      }
    ]
  },
  {
    "key": "UI_YEARLY",
    "values": [
      {
        "iso": "nl",
        "value": "Jaarlijks"
      },
      {
        "iso": "en",
        "value": "Yearly"
      }
    ]
  },
  {
    "key": "UI_WEEKLY",
    "values": [
      {
        "iso": "nl",
        "value": "Wekelijks"
      },
      {
        "iso": "en",
        "value": "Weekly"
      }
    ]
  },
  {
    "key": "UI_MONTHLY",
    "values": [
      {
        "iso": "nl",
        "value": "Maandelijks"
      },
      {
        "iso": "en",
        "value": "Monthly"
      }
    ]
  },
  {
    "key": "UI_DAILY",
    "values": [
      {
        "iso": "nl",
        "value": "Dagelijks"
      },
      {
        "iso": "en",
        "value": "Daily"
      }
    ]
  },
  {
    "key": "UI_WEEKS",
    "values": [
      {
        "iso": "nl",
        "value": "Weken"
      },
      {
        "iso": "en",
        "value": "Weeks"
      }
    ]
  },
  {
    "key": "UI_DAYS",
    "values": [
      {
        "iso": "nl",
        "value": "Dagen"
      },
      {
        "iso": "en",
        "value": "Days"
      }
    ]
  },
  {
    "key": "UI_UBL",
    "values": [
      {
        "iso": "nl",
        "value": "UBL"
      },
      {
        "iso": "en",
        "value": "UBL"
      }
    ]
  },
  {
    "key": "UI_USER_MESSAGE_FOR_INVOICE_FROM_SUBSCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Nieuwe factuur aangemaakt voor abonnement '{{0}}'"
      },
      {
        "iso": "en",
        "value": "New invoice has been created for subscription '{{0}}'"
      }
    ]
  },
  {
    "key": "UI_INVOICE_FROM_SUBSCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Factuur is automatisch aangemaakt voor abonnement '{{0}}'"
      },
      {
        "iso": "en",
        "value": "Invoice automatic created from subscription '{{0}}'"
      }
    ]
  },
  {
    "key": "UI_SUBSCRIPTION_NEXTDATE",
    "values": [
      {
        "iso": "nl",
        "value": "Volgende factuurdatum"
      },
      {
        "iso": "en",
        "value": "Next invoice date"
      }
    ]
  },
  {
    "key": "UI_ONHOLD",
    "values": [
      {
        "iso": "nl",
        "value": "In wacht"
      },
      {
        "iso": "en",
        "value": "On hold"
      }
    ]
  },
  {
    "key": "UI_CANCELED",
    "values": [
      {
        "iso": "nl",
        "value": "Geannulleerd"
      },
      {
        "iso": "en",
        "value": "Canceled"
      }
    ]
  },
  {
    "key": "UI_BALANCE",
    "values": [
      {
        "iso": "nl",
        "value": "Balans"
      },
      {
        "iso": "en",
        "value": "Balance"
      }
    ]
  },
  {
    "key": "UI_FINANCIAL",
    "values": [
      {
        "iso": "nl",
        "value": "Financieel"
      },
      {
        "iso": "en",
        "value": "Financial"
      }
    ]
  },
  {
    "key": "UI_BANK_ACCOUNTS",
    "values": [
      {
        "iso": "nl",
        "value": "Bank rekeningen"
      },
      {
        "iso": "en",
        "value": "Bank accounts"
      }
    ]
  },
  {
    "key": "UI_REFERENCE",
    "values": [
      {
        "iso": "nl",
        "value": "Referentie"
      },
      {
        "iso": "en",
        "value": "Reference"
      }
    ]
  },
  {
    "key": "UI_AUTHENTICATION_USERNAME_UNKNOWN",
    "values": [
      {
        "iso": "nl",
        "value": "E-mailadres niet gekend"
      },
      {
        "iso": "en",
        "value": "Unknown emailadres"
      }
    ]
  },
  {
    "key": "UI_AUTHENTICATION_INCORRECT_PASSWORD",
    "values": [
      {
        "iso": "nl",
        "value": "Onjuist wachtwoord"
      },
      {
        "iso": "en",
        "value": "Incorrect password"
      }
    ]
  },
  {
    "key": "UI_CHANGE_SUBSCRIBER",
    "values": [
      {
        "iso": "nl",
        "value": "Abonnee wijzigen"
      },
      {
        "iso": "en",
        "value": "Change subscriber"
      }
    ]
  },

  {
    "key": "UI_CUSTOMER_PORTAL",
    "values": [
      {
        "iso": "nl",
        "value": "Klanten portaal"
      },
      {
        "iso": "en",
        "value": "Customer portal"
      }
    ]
  },
  {
    "key": "UI_PARTNER_PORTAL",
    "values": [
      {
        "iso": "nl",
        "value": "Partner portaal"
      },
      {
        "iso": "en",
        "value": "Partner portal"
      }
    ]
  },
  {
    "key": "UI_VISIT_WEBSITE",
    "values": [
      {
        "iso": "nl",
        "value": "Website bezoeken"
      },
      {
        "iso": "en",
        "value": "Visit website"
      }
    ]
  },
  {
    "key": "UI_AUTHENTICATION_UNKNOWN_ERROR_AT_LOGIN",
    "values": [
      {
        "iso": "nl",
        "value": "Onverwachte fout tijdens het inloggen. Contacteer support of probeer later opnieuw."
      },
      {
        "iso": "en",
        "value": "Unexpected error on login. Contact support or try again later."
      }
    ]
  },
  {
    "key": "UI_FACEBOOK_COMMUNITY_GROUP",
    "values": [
      {
        "iso": "nl",
        "value": "Facebook community"
      },
      {
        "iso": "en",
        "value": "Facebook community"
      }
    ]
  },
  {
    "key": "UI_INCOMING",
    "values": [
      {
        "iso": "nl",
        "value": "Inkomende"
      },
      {
        "iso": "en",
        "value": "Incoming"
      }
    ]
  },
  {
    "key": "UI_OUTGOING",
    "values": [
      {
        "iso": "nl",
        "value": "Uitgaande"
      },
      {
        "iso": "en",
        "value": "Outgoing"
      }
    ]
  },
  {
    "key": "UI_LAST_SYNCED",
    "values": [
      {
        "iso": "nl",
        "value": "Laatst gesyncroniseerd"
      },
      {
        "iso": "en",
        "value": "Last synced"
      }
    ]
  },
  {
    "key": "UI_FORCE_RESYNC",
    "values": [
      {
        "iso": "nl",
        "value": "Sycnronisatie forceren"
      },
      {
        "iso": "en",
        "value": "Force resync"
      }
    ]
  },
  {
    "key": "UI_SELECTED_BANK",
    "values": [
      {
        "iso": "nl",
        "value": "Geselecteerde bank"
      },
      {
        "iso": "en",
        "value": "Selected bank"
      }
    ]
  },
  {
    "key": "UI_PAYMENT_REFERENCE",
    "values": [
      {
        "iso": "nl",
        "value": "Betalingsreferentie"
      },
      {
        "iso": "en",
        "value": "Payment reference"
      }
    ]
  },
  {
    "key": "UI_STRUCTURED_PAYMENT_REFERENCE",
    "values": [
      {
        "iso": "nl",
        "value": "Gestructureerde betalingsreferenctie"
      },
      {
        "iso": "en",
        "value": "Structured payment reference"
      }
    ]
  },
  {
    "key": "UI_BANK",
    "values": [
      {
        "iso": "nl",
        "value": "Bank"
      },
      {
        "iso": "en",
        "value": "Bank"
      }
    ]
  },
  {
    "key": "UI_DO_NOT_LINK_BANK",
    "values": [
      {
        "iso": "nl",
        "value": "Niet gelinkte bank gebruiken"
      },
      {
        "iso": "en",
        "value": "Use non-linked bank"
      }
    ]
  },
  {
    "key": "UI_NO_BANK_FOUND_SEND_TO_COMPANY",
    "values": [
      {
        "iso": "nl",
        "value": "Geen gelinkte banken gevonden. Een nieuwe bankrekening kan toegevoegd en gelinkt worden via de bedrijfsinstellingen."
      },
      {
        "iso": "en",
        "value": "No linked banks found. A new bank account can be added and linked through the company settings."
      }
    ]
  },
  {
    "key": "UI_BLOCKED",
    "values": [
      {
        "iso": "nl",
        "value": "geblokkeerd"
      },
      {
        "iso": "en",
        "value": "Blocked"
      }
    ]
  },
  {
    "key": "UI_PHONE_NUMBERS",
    "values": [
      {
        "iso": "nl",
        "value": "Telefoonnummers"
      },
      {
        "iso": "en",
        "value": "Phone numbers"
      }
    ]
  },
  {
    "key": "UI_PHONE_NUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "Telefoon nummer"
      },
      {
        "iso": "en",
        "value": "Phone number"
      }
    ]
  },
  {
    "key": "UI_IBAN",
    "values": [
      {
        "iso": "nl",
        "value": "IBAN"
      },
      {
        "iso": "en",
        "value": "IBAN"
      }
    ]
  },
  {
    "key": "UI_SHOW_ON_HOLD",
    "values": [
      {
        "iso": "nl",
        "value": "Toon in wacht"
      },
      {
        "iso": "en",
        "value": "Show on hold"
      }
    ]
  },
  {
    "key": "UI_SHOW_CANCELLED",
    "values": [
      {
        "iso": "nl",
        "value": "Toon geannuleerde"
      },
      {
        "iso": "en",
        "value": "Show cancelled"
      }
    ]
  },
  {
    "key": "UI_SHOW_EXPIRED",
    "values": [
      {
        "iso": "nl",
        "value": "Toon verlopen"
      },
      {
        "iso": "en",
        "value": "Show expired"
      }
    ]
  },
  {
    "key": "UI_SHOW_BLOCKED",
    "values": [
      {
        "iso": "nl",
        "value": "Toon geblokkeerde"
      },
      {
        "iso": "en",
        "value": "Show blocked"
      }
    ]
  },
  {
    "key": "UI_OUTSTANDING_TRANSACTIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Openstaande transacties"
      },
      {
        "iso": "en",
        "value": "Outstanding transactions"
      }
    ]
  },
  {
    "key": "UI_OUTSTANDING_TRANSACTIONS_INCOME",
    "values": [
      {
        "iso": "nl",
        "value": "Openstaande inkomsten"
      },
      {
        "iso": "en",
        "value": "Outstanding incomes"
      }
    ]
  },
  {
    "key": "UI_OUTSTANDING_TRANSACTIONS_PAYMENTS",
    "values": [
      {
        "iso": "nl",
        "value": "Openstaande betalingen"
      },
      {
        "iso": "en",
        "value": "Outstanding payments"
      }
    ]
  },
  {
    "key": "UI_OUTSTANDING_PAYMENTS",
    "values": [
      {
        "iso": "nl",
        "value": "Openstaande betalingen"
      },
      {
        "iso": "en",
        "value": "Outstanding payments"
      }
    ]
  },
  {
    "key": "UI_AUTOMATIC_MATCHED_TRANSACTIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Automatisch gekoppelde transacties"
      },
      {
        "iso": "en",
        "value": "Automatic matched transactions"
      }
    ]
  },
  {
    "key": "UI_MATCHED_TO",
    "values": [
      {
        "iso": "nl",
        "value": "Gekoppeld aan"
      },
      {
        "iso": "en",
        "value": "Matched to"
      }
    ]
  },
  {
    "key": "UI_OUTSTANDING_AMOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Openstaand bedrag"
      },
      {
        "iso": "en",
        "value": "Outstanding amount"
      }
    ]
  },
  {
    "key": "UI_DELETE_MATCHED_TRANSACTION",
    "values": [
      {
        "iso": "nl",
        "value": "Geselecteerde koppelingen verwijderen"
      },
      {
        "iso": "en",
        "value": "Remove selected matches"
      }
    ]
  },
  {
    "key": "UI_RECONCILE",
    "values": [
      {
        "iso": "nl",
        "value": "Afpunten"
      },
      {
        "iso": "en",
        "value": "Reconcile"
      }
    ]
  },
  {
    "key": "UI_RECONCILE_SELECTION",
    "values": [
      {
        "iso": "nl",
        "value": "Selectie afpunten"
      },
      {
        "iso": "en",
        "value": "Reconcile selection"
      }
    ]
  },
  {
    "key": "UI_RECONCILE_SELECTED_INVOICES",
    "values": [
      {
        "iso": "nl",
        "value": "Geselecteerde facturen afpunten"
      },
      {
        "iso": "en",
        "value": "Reconcile selected invoices"
      }
    ]
  },
  {
    "key": "UI_RECONCILE_SELECTED_PURCHASE_INVOICES",
    "values": [
      {
        "iso": "nl",
        "value": "Geselecteerde aankoopfacturen afpunten"
      },
      {
        "iso": "en",
        "value": "Reconcile selected purchase invoices"
      }
    ]
  },
  {
    "key": "UI_CONFIRM_MATCHED_TRANSACTION",
    "values": [
      {
        "iso": "nl",
        "value": "Bevestig geselecteerde koppelingen"
      },
      {
        "iso": "en",
        "value": "Confirm selected matches"
      }
    ]
  },
  {
    "key": "UI_SUPPLIER",
    "values": [
      {
        "iso": "nl",
        "value": "Leverancier"
      },
      {
        "iso": "en",
        "value": "Supplier"
      }
    ]
  },
  {
    "key": "UI_VATCODE_SALES",
    "values": [
      {
        "iso": "nl",
        "value": "BTW Code voor verkoop"
      },
      {
        "iso": "en",
        "value": "VAT Code for sales"
      }
    ]
  },
  {
    "key": "UI_VATCODE_PURCHASE",
    "values": [
      {
        "iso": "nl",
        "value": "BTW Code voor aankoop"
      },
      {
        "iso": "en",
        "value": "VAT Code for purchase"
      }
    ]
  },
  {
    "key": "UI_VAT_TYPE",
    "values": [
      {
        "iso": "nl",
        "value": "BTW type"
      },
      {
        "iso": "en",
        "value": "VAT type"
      }
    ]
  },
  {
    "key": "UI_LEGAL_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Juridische informatie"
      },
      {
        "iso": "en",
        "value": "Legal information"
      }
    ]
  },
  {
    "key": "UI_ADD_INTRA_NOTICE_ON_PDF",
    "values": [
      {
        "iso": "nl",
        "value": "Voeg een 'BTW verlegd' opmerking op documenten."
      },
      {
        "iso": "en",
        "value": "Add 'Intracommunautaire' notice on documents."
      }
    ]
  },
  {
    "key": "UI_ADD_RENOVATION_NOTICE_ON_PDF",
    "values": [
      {
        "iso": "nl",
        "value": "Voeg een '6% verbouwing' opmerking op documenten."
      },
      {
        "iso": "en",
        "value": "Add '6% renovation' notice on documents."
      }
    ]
  },
  {
    "key": "UI_ADD_COCONTRACTOR_NOTICE_ON_PDF",
    "values": [
      {
        "iso": "nl",
        "value": "Voeg een 'medecontractor' opmerking op documenten."
      },
      {
        "iso": "en",
        "value": "Add 'Co Contractor' notice on documents."
      }
    ]
  },
  {
    "key": "UI_PURCHASE",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoop"
      },
      {
        "iso": "en",
        "value": "Purchase"
      }
    ]
  },
  {
    "key": "UI_SALES",
    "values": [
      {
        "iso": "nl",
        "value": "Verkoop"
      },
      {
        "iso": "en",
        "value": "Sales"
      }
    ]
  },
  {
    "key": "UI_DELETE_RELATIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Relaties verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete relations"
      }
    ]
  },
  {
    "key": "UI_ADD_RELATION",
    "values": [
      {
        "iso": "nl",
        "value": "Relatie toevoegen"
      },
      {
        "iso": "en",
        "value": "Add relation"
      }
    ]
  },
  {
    "key": "UI_RELATIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Relaties"
      },
      {
        "iso": "en",
        "value": "Relations"
      }
    ]
  },
  {
    "key": "UI_RELATION_NUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "Relatie nummer"
      },
      {
        "iso": "en",
        "value": "Relatie nummer"
      }
    ]
  },
  {
    "key": "UI_PROSPECT",
    "values": [
      {
        "iso": "nl",
        "value": "Prospect"
      },
      {
        "iso": "en",
        "value": "Prospect"
      }
    ]
  },
  {
    "key": "UI_PROSPECTS",
    "values": [
      {
        "iso": "nl",
        "value": "Prospecten"
      },
      {
        "iso": "en",
        "value": "Prospects"
      }
    ]
  },
  {
    "key": "UI_SET_GLOBAL_VAT_TO_SUBLINES",
    "values": [
      {
        "iso": "nl",
        "value": "Bestaande sublijnen aanpassen naar het gekozen BTW tarief?"
      },
      {
        "iso": "en",
        "value": "Adjust existing sublines to the chosen VAT rate?"
      }
    ]
  },
  {
    "key": "UI_SET_GLOBAL_VAT_TO_SUBLINES_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "BTW tarief toepassen"
      },
      {
        "iso": "en",
        "value": "Apply VAT rate"
      }
    ]
  },
  {
    "key": "UI_SET_GLOBAL_ACCOUNT_NUMBER_TO_SUBLINES_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "Rekeningnummer toepassen"
      },
      {
        "iso": "en",
        "value": "Apply account number"
      }
    ]
  },
  {
    "key": "UI_SET_GLOBAL_ACCOUNT_NUMBER_TO_SUBLINES",
    "values": [
      {
        "iso": "nl",
        "value": "Bestaande sublijnen aanpassen naar het gekozen boekhoudkundig rekeningnummer?"
      },
      {
        "iso": "en",
        "value": "Adjust existing sublines to the chosen ledger accountnumber?"
      }
    ]
  },
  {
    "key": "UI_SET_GLOBAL_LEDGER_TO_SUBLINES",
    "values": [
      {
        "iso": "nl",
        "value": "Bestaande sublijnen aanpassen naar het gekozen grootboeknummer?"
      },
      {
        "iso": "en",
        "value": "Adjust existing sublines to the chosen ledger number?"
      }
    ]
  },
  {
    "key": "UI_LEDGER_ACCOUNTS",
    "values": [
      {
        "iso": "nl",
        "value": "Boekhoud rekeningen"
      },
      {
        "iso": "en",
        "value": "Ledger accounts"
      }
    ]
  },
  {
    "key": "UI_LEDGER_ACCOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Boekhoudrekening"
      },
      {
        "iso": "en",
        "value": "Ledger account"
      }
    ]
  },
  {
    "key": "UI_ACCOUNT_NUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "Boekhoudkundig rekeningnummer"
      },
      {
        "iso": "en",
        "value": "Ledger account number"
      }
    ]
  },
  {
    "key": "UI_ADD_ACCOUNT_NUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "Rekeningnummer toevoegen"
      },
      {
        "iso": "en",
        "value": "Add account number"
      }
    ]
  },
  {
    "key": "UI_DELETE_ACCOUNT_NUMBERS",
    "values": [
      {
        "iso": "nl",
        "value": "Rekeningnummers verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete account numbers"
      }
    ]
  },
  {
    "key": "UI_ACCOUNT_NUMBER_CLASS",
    "values": [
      {
        "iso": "nl",
        "value": "Rekening Klasse"
      },
      {
        "iso": "en",
        "value": "Ledger class"
      }
    ]
  },
  {
    "key": "UI_ACCOUNT_NUMBER_CATEGORY",
    "values": [
      {
        "iso": "nl",
        "value": "Rekening categorie"
      },
      {
        "iso": "en",
        "value": "Ledger category"
      }
    ]
  },
  {
    "key": "UI_ACCOUNT_NUMBER_ALREADY_EXISTS",
    "values": [
      {
        "iso": "nl",
        "value": "De ingevoerde boekhoud rekening {{0}} bestaat reeds in uw systeem onder de naam '{{1}}'. Een rekening kan slechts 1 maal voorkomen in het systeem."
      },
      {
        "iso": "en",
        "value": "The entered account {{0}} already exists in your system under the name '{{1}}'. An account can only appear once in the system."
      }
    ]
  },
  {
    "key": "UI_EX_TRANSLOCATIONS_NONE_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Geen verplaatsingen gevonden!"
      },
      {
        "iso": "en",
        "value": "No translocations found!"
      }
    ]
  },
  {
    "key": "UI_EX_PARAMETERS_NONE_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Geen parameters gevonden!"
      },
      {
        "iso": "en",
        "value": "No parameters found!"
      }
    ]
  },
  {
    "key": "UI_ADD_PARAMETER",
    "values": [
      {
        "iso": "nl",
        "value": "Parameter toevoegen"
      },
      {
        "iso": "en",
        "value": "Add parameter"
      }
    ]
  },
  {
    "key": "UI_DELETE_PARAMETER",
    "values": [
      {
        "iso": "nl",
        "value": "Parameter verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete parameter"
      }
    ]
  },
  {
    "key": "UI_PARAMETER_KEY",
    "values": [
      {
        "iso": "nl",
        "value": "Parameter"
      },
      {
        "iso": "en",
        "value": "Parameter"
      }
    ]
  },
  {
    "key": "UI_PARAMETER_VALUE",
    "values": [
      {
        "iso": "nl",
        "value": "Waarde"
      },
      {
        "iso": "en",
        "value": "Value"
      }
    ]
  },
  {
    "key": "UI_EX_DOCUMENTS_NONE_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Geen documenten gevonden!"
      },
      {
        "iso": "en",
        "value": "No documents found!"
      }
    ]
  },
  {
    "key": "UI_EX_INVOICE_SUBLINE_NONE_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Deze verkoopfactuur heeft geen sublijnen!"
      },
      {
        "iso": "en",
        "value": "This sales invoice has no sublines!"
      }
    ]
  },
  {
    "key": "UI_EX_PRODUCT_SUPPLIERS_NONE_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Dit product heeft geen gekende leveranciers!"
      },
      {
        "iso": "en",
        "value": "This product has no known suppliers!"
      }
    ]
  },
  {
    "key": "UI_EX_COMBINED_ARTICLE_SUBLINE_NONE_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Dit gecombineerd artikel heeft geen sublijnen!"
      },
      {
        "iso": "en",
        "value": "This combined article has no sublines!"
      }
    ]
  },
  {
    "key": "UI_EX_INVOICE_WORKPERFORMANCE_NONE_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Deze verkoopfactuur heeft geen gelinkte prestaties!"
      },
      {
        "iso": "en",
        "value": "This sales invoice has no linked work performances!"
      }
    ]
  },
  {
    "key": "UI_EX_EMAIL_NONE_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Geen emails gevonden!"
      },
      {
        "iso": "en",
        "value": "No emails found!"
      }
    ]
  },
  {
    "key": "UI_EX_PEPPOL_NONE_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Geen Peppol transacties gevonden!"
      },
      {
        "iso": "en",
        "value": "No peppol transactions found!"
      }
    ]
  },
  {
    "key": "UI_EX_ORDER_SUBLINE_NONE_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Deze verkoopsorder heeft geen sublijnen!"
      },
      {
        "iso": "en",
        "value": "This sales order has no sublines!"
      }
    ]
  },
  {
    "key": "UI_EX_QUOTE_SUBLINE_NONE_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Deze offerte heeft geen sublijnen!"
      },
      {
        "iso": "en",
        "value": "This quote has no sublines!"
      }
    ]
  },
  {
    "key": "UI_EX_PURCHASE_INVOICE_SUBLINE_NONE_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Deze aankoop factuur heeft geen sublijnen!"
      },
      {
        "iso": "en",
        "value": "This purchase invoice has no sublines!"
      }
    ]
  },
  {
    "key": "UI_EX_PURCHASE_ORDER_SUBLINE_NONE_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Dit inkoop order heeft geen sublijnen!"
      },
      {
        "iso": "en",
        "value": "This purchase order has no sublines!"
      }
    ]
  },
  {
    "key": "UI_PURCHASE_DOCUMENTS",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoop documenten"
      },
      {
        "iso": "en",
        "value": "Purchase documents"
      }
    ]
  },
  {
    "key": "UI_SALES_DOCUMENTS",
    "values": [
      {
        "iso": "nl",
        "value": "Verkoop documenten"
      },
      {
        "iso": "en",
        "value": "Sales documents"
      }
    ]
  },
  {
    "key": "UI_PRODUCT_RECEPTIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Receptie van goederen"
      },
      {
        "iso": "en",
        "value": "Reception of products"
      }
    ]
  },
  {
    "key": "UI_ADD_PRODUCT_RECEPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Receptie toevoegen"
      },
      {
        "iso": "en",
        "value": "Add reception"
      }
    ]
  },
  {
    "key": "UI_DELETE_PRODUCT_RECEPTIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Recepties verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete receptions"
      }
    ]
  },
  {
    "key": "UI_PURCHASE_INVOICE_CONVERTED_TO_PRODUCT_RECEPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Receptie van goederen opgemaakt via aankoopfactuur '{{0}}' van leverancier '{{1}}'."
      },
      {
        "iso": "en",
        "value": "Product recption created by purchase invoice '{{0}}' of supplier '{{1}}'."
      }
    ]
  },
  {
    "key": "UI_SALES_INVOICE_CONVERTED_TO_PRODUCT_DELIVERY",
    "values": [
      {
        "iso": "nl",
        "value": "Levering van goederen opgemaakt volgens verkoopfactuur '{{0}}'."
      },
      {
        "iso": "en",
        "value": "Product delivery created by sales invoice '{{0}}'."
      }
    ]
  },
  {
    "key": "UI_CONVERT_PURCHASE_INVOICE_TO_PRODUCT_RECEPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Producten receptioneren"
      },
      {
        "iso": "en",
        "value": "Receive products"
      }
    ]
  },
  {
    "key": "UI_LEDGER_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Grootboek informatie"
      },
      {
        "iso": "en",
        "value": "Ledger information"
      }
    ]
  },
  {
    "key": "UI_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Informatie"
      },
      {
        "iso": "en",
        "value": "Information"
      }
    ]
  },
  {
    "key": "UI_INTEGRATIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Integraties"
      },
      {
        "iso": "en",
        "value": "Integrations"
      }
    ]
  },
  {
    "key": "UI_OCTOPUS",
    "values": [
      {
        "iso": "nl",
        "value": "Octopus boekhouden"
      },
      {
        "iso": "en",
        "value": "Octopus accountancy"
      }
    ]
  },
  {
    "key": "UI_LOGIN_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Login informatie"
      },
      {
        "iso": "en",
        "value": "Login information"
      }
    ]
  },
  {
    "key": "UI_ADMISOL_INTEGRATION_MAILBOXES",
    "values": [
      {
        "iso": "nl",
        "value": "Admisol mailboxen"
      },
      {
        "iso": "en",
        "value": "Admisol mailboxes"
      }
    ]
  },
  {
    "key": "UI_OCTOPUS_INTEGRATION",
    "values": [
      {
        "iso": "nl",
        "value": "Octopus integratie"
      },
      {
        "iso": "en",
        "value": "Octopus integration"
      }
    ]
  },
  {
    "key": "UI_ADMISOL_INTEGRATION",
    "values": [
      {
        "iso": "nl",
        "value": "Admisol integratie"
      },
      {
        "iso": "en",
        "value": "Admisol integration"
      }
    ]
  },
  {
    "key": "UI_PEPPOL_INTEGRATION",
    "values": [
      {
        "iso": "nl",
        "value": "Peppol integratie"
      },
      {
        "iso": "en",
        "value": "Peppol integration"
      }
    ]
  },
  {
    "key": "UI_ZOOMIT_INTEGRATION",
    "values": [
      {
        "iso": "nl",
        "value": "Zoomit integratie"
      },
      {
        "iso": "en",
        "value": "Zoomit integration"
      }
    ]
  },
  {
    "key": "UI_EXACT_ONLINE_INTEGRATION",
    "values": [
      {
        "iso": "nl",
        "value": "Exact online integratie"
      },
      {
        "iso": "en",
        "value": "Exact online integration"
      }
    ]
  },
  {
    "key": "UI_YUKI_INTEGRATION",
    "values": [
      {
        "iso": "nl",
        "value": "Yuki integratie"
      },
      {
        "iso": "en",
        "value": "Yuki integration"
      }
    ]
  },
  {
    "key": "UI_SETUP_INTEGRATION_ADMISOL",
    "values": [
      {
        "iso": "nl",
        "value": "Admisol instellen"
      },
      {
        "iso": "en",
        "value": "Setup Admisol"
      }
    ]
  },
  {
    "key": "UI_SETUP_INTEGRATION_PEPPOL",
    "values": [
      {
        "iso": "nl",
        "value": "Peppol instellen"
      },
      {
        "iso": "en",
        "value": "Setup Peppol"
      }
    ]
  },
  {
    "key": "UI_SETUP_INTEGRATION_ZOOMIT",
    "values": [
      {
        "iso": "nl",
        "value": "Zoomit instellen"
      },
      {
        "iso": "en",
        "value": "Setup Zoomit"
      }
    ]
  },
  {
    "key": "UI_OCTOPUS_INTEGRATION_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Octopus gegevens"
      },
      {
        "iso": "en",
        "value": "Octopus information"
      }
    ]
  },
  {
    "key": "UI_YUKI_INTEGRATION_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Yuki gegevens"
      },
      {
        "iso": "en",
        "value": "Yuki information"
      }
    ]
  },
  {
    "key": "UI_SETUP_INTEGRATION_OCTOPUS",
    "values": [
      {
        "iso": "nl",
        "value": "Octopus instellen"
      },
      {
        "iso": "en",
        "value": "Setup Octopus"
      }
    ]
  },
  {
    "key": "UI_ADMISOL_MAILBOXES",
    "values": [
      {
        "iso": "nl",
        "value": "Admisol mailboxen"
      },
      {
        "iso": "en",
        "value": "Admisol mailboxes"
      }
    ]
  },
  {
    "key": "UI_ENABLE_ADMISOL",
    "values": [
      {
        "iso": "nl",
        "value": "Admisol inschakelen"
      },
      {
        "iso": "en",
        "value": "Enable Admisol"
      }
    ]
  },
  {
    "key": "UI_OUTGOING_INVOICES_ADMISOL_MAILBOX",
    "values": [
      {
        "iso": "nl",
        "value": "Mailbox voor uitgaande facturen"
      },
      {
        "iso": "en",
        "value": "Mailbox for outgoing invoices"
      }
    ]
  },
  {
    "key": "UI_INCOMING_INVOICES_ADMISOL_MAILBOX",
    "values": [
      {
        "iso": "nl",
        "value": "Mailbox voor binnenkomende facturen"
      },
      {
        "iso": "en",
        "value": "Mailbox for incoming invoices"
      }
    ]
  },
  {
    "key": "UI_CREDITNOTES_ADMISOL_MAILBOX",
    "values": [
      {
        "iso": "nl",
        "value": "Mailbox voor creditnota's"
      },
      {
        "iso": "en",
        "value": "Mailbox for creditnotes"
      }
    ]
  },
  {
    "key": "UI_PURCHASE_INVOICE_INVALID_OR_INCOMPLETE",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoop factuur is ongeldig of incompleet. Gelieve deze in orde te brengen alvorens te kunnen bevestigen."
      },
      {
        "iso": "en",
        "value": "Purchase invoice is invalid or incomplete. Please correct these before confirming."
      }
    ]
  },
  {
    "key": "UI_SALES_INVOICE_INVALID_OR_INCOMPLETE",
    "values": [
      {
        "iso": "nl",
        "value": "Verkoop factuur is ongeldig of incompleet. Gelieve deze in orde te brengen alvorens te kunnen bevestigen."
      },
      {
        "iso": "en",
        "value": "Sales invoice is invalid or incomplete. Please correct these before confirming."
      }
    ]
  },
  {
    "key": "UI_REPLACE_PRICE_OF_SUBLINE",
    "values": [
      {
        "iso": "nl",
        "value": "Er is reeds een prijs gekend voor deze subline. Deze overschrijven met de opgeslagen prijs?"
      },
      {
        "iso": "en",
        "value": "There is already a price known for this subline. Overwrite this with the saved price?"
      }
    ]
  },
  {
    "key": "UI_CLICK_LOGO_TO_EDIT",
    "values": [
      {
        "iso": "nl",
        "value": "Klik op het logo op deze te wijzigen!"
      },
      {
        "iso": "en",
        "value": "Click on the logo to change it!"
      }
    ]
  },
  {
    "key": "UI_ADDRESS",
    "values": [
      {
        "iso": "nl",
        "value": "Adres"
      },
      {
        "iso": "en",
        "value": "Address"
      }
    ]
  },
  {
    "key": "UI_BANK_INFORMATION_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Bank informatie is gevonden en toegevoegd!"
      },
      {
        "iso": "en",
        "value": "Bank information has been found and added!"
      }
    ]
  },
  {
    "key": "UI_BANK_INFORMATION_SEARCHING",
    "values": [
      {
        "iso": "nl",
        "value": "Bank informatie zoeken..."
      },
      {
        "iso": "en",
        "value": "Searching Bank information..."
      }
    ]
  },
  {
    "key": "UI_ACCOUNT_NUMBER_INVALID",
    "values": [
      {
        "iso": "nl",
        "value": "Bank informatie niet gevonden, controleer uw IBAN nummer."
      },
      {
        "iso": "en",
        "value": "Bank information isn't found, check your iban number."
      }
    ]
  },
  {
    "key": "UI_LINK_BANK_ACCOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Linken met uw bank"
      },
      {
        "iso": "en",
        "value": "Link your financial institution"
      }
    ]
  },
  {
    "key": "UI_RELINK_BANK_ACCOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Bank herlinken"
      },
      {
        "iso": "en",
        "value": "Relink financial institution"
      }
    ]
  },
  {
    "key": "UI_UNLINK_BANK_ACCOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Link met bank verbreken"
      },
      {
        "iso": "en",
        "value": "Remove link between financial institution"
      }
    ]
  },
  {
    "key": "UI_BANK_LINK",
    "values": [
      {
        "iso": "nl",
        "value": "Gelinked met uw bank"
      },
      {
        "iso": "en",
        "value": "Linked with the financial institution"
      }
    ]
  },
  {
    "key": "UI_BANK_LINK_ACTIVE",
    "values": [
      {
        "iso": "nl",
        "value": "Link met de bank is actief"
      },
      {
        "iso": "en",
        "value": "Link with the financial institution is active"
      }
    ]
  },
  {
    "key": "UI_NO_INSTITUTION_FOUND_FOR_GIVEN_BIC",
    "values": [
      {
        "iso": "nl",
        "value": "Geen banken gevonden voor de gegeven BIC!"
      },
      {
        "iso": "en",
        "value": "No banks have been found for the given BIC!"
      }
    ]
  },
  {
    "key": "UI_NO_ACCOUNT_FOUNT_FOR_GIVEN_IBAN",
    "values": [
      {
        "iso": "nl",
        "value": "Geen rekening gevonden voor de opgegeven IBAN!"
      },
      {
        "iso": "en",
        "value": "No account was found for the given IBAN!"
      }
    ]
  },
  {
    "key": "UI_CHANGED",
    "values": [
      {
        "iso": "nl",
        "value": "Aangepast"
      },
      {
        "iso": "en",
        "value": "Adjusted"
      }
    ]
  },
  {
    "key": "UI_BIC_HAS_BEEN_CHANGED",
    "values": [
      {
        "iso": "nl",
        "value": "De opgegeven BIC is aangepast naar de geregistreerde BIC voor het opgegeven IBAN nummer."
      },
      {
        "iso": "en",
        "value": "The given BIC has been changed to the registered BIC for the given IBAN number."
      }
    ]
  },
  {
    "key": "UI_EX_TRANSLOCATIONS_NONE_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn nog geen verplaatsingen geregistreerd."
      },
      {
        "iso": "en",
        "value": "There aren't any translocations registered."
      }
    ]
  },
  {
    "key": "UI_EX_ADDRESSES_NONE_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn nog geen adressen geregistreerd."
      },
      {
        "iso": "en",
        "value": "There aren't any addresses registered."
      }
    ]
  },
  {
    "key": "UI_EX_BANKS_NONE_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn nog geen rekening nummers geregistreerd."
      },
      {
        "iso": "en",
        "value": "There aren't any financial accounts registered."
      }
    ]
  },
  {
    "key": "UI_EX_PHONES_NONE_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn nog geen telefoonnummers geregistreerd."
      },
      {
        "iso": "en",
        "value": "There aren't any phone numbers registered."
      }
    ]
  },
  {
    "key": "UI_EX_EMAILS_NONE_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn nog geen e-mailadressen geregistreerd."
      },
      {
        "iso": "en",
        "value": "There aren't any email addresses registered."
      }
    ]
  },
  {
    "key": "UI_EX_CONTACTS_NONE_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn nog geen contact personen geregistreerd."
      },
      {
        "iso": "en",
        "value": "There aren't any contact persons registered."
      }
    ]
  },
  {
    "key": "UI_SALES_JOURNALS",
    "values": [
      {
        "iso": "nl",
        "value": "Verkoop dagboeken"
      },
      {
        "iso": "en",
        "value": "Sales journals"
      }
    ]
  },
  {
    "key": "UI_PURCHASE_JOURNALS",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoop dagboeken"
      },
      {
        "iso": "en",
        "value": "Purchase journals"
      }
    ]
  },
  {
    "key": "UI_ADD_JOURNAL",
    "values": [
      {
        "iso": "nl",
        "value": "Dagboek toevoegen"
      },
      {
        "iso": "en",
        "value": "Add journal"
      }
    ]
  },
  {
    "key": "UI_DELETE_JOURNALS",
    "values": [
      {
        "iso": "nl",
        "value": "Dagboeken verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete journals"
      }
    ]
  },
  {
    "key": "UI_EXTERNAL_SETTINGS",
    "values": [
      {
        "iso": "nl",
        "value": "Externe instellingen"
      },
      {
        "iso": "en",
        "value": "External settings"
      }
    ]
  },
  {
    "key": "UI_EXTERNAL_NUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "Extern nummer"
      },
      {
        "iso": "en",
        "value": "External number"
      }
    ]
  },
  {
    "key": "UI_JOURNAL",
    "values": [
      {
        "iso": "nl",
        "value": "Dagboek"
      },
      {
        "iso": "en",
        "value": "Journal"
      }
    ]
  },
  {
    "key": "UI_INVOICE_WORKPERFORMANCES_MODAL_CONFIRM",
    "values": [
      {
        "iso": "nl",
        "value": "Factureer selectie"
      },
      {
        "iso": "en",
        "value": "Invoice selection"
      }
    ]
  },
  {
    "key": "UI_SEARCHING_FOR_INVOICE_CREDITNOTE_SETTINGS_CLICK_HERE",
    "values": [
      {
        "iso": "nl",
        "value": "Zoekt u factuur- of creditnotanummer instellingen? Klik hier!"
      },
      {
        "iso": "en",
        "value": "Searching for invoice or creditnote number settings? Click here!"
      }
    ]
  },
  {
    "key": "UI_CANT_DELETE_JOURNAL_EXISTING_DOCUMENTS",
    "values": [
      {
        "iso": "nl",
        "value": "Een dagboek verwijderen met gelinkte verkoop/aankoop documenten is niet mogelijk, gelieve de documenten te wijzigen van dagboek of het dagboek te archiveren."
      },
      {
        "iso": "en",
        "value": "Deleting a journal with linked sales/purchase documents is not possible, please change the documents from journal or archive the journal."
      }
    ]
  },
  {
    "key": "UI_CANT_DELETE_ACCOUNTNUMBER_EXISTING_DOCUMENTS",
    "values": [
      {
        "iso": "nl",
        "value": "Een boekhoudkundig rekeningnummer verwijderen met gelinkte documenten is niet mogelijk, gelieve de documenten te wijzigen, het rekeningnummer te migreren of te archiveren."
      },
      {
        "iso": "en",
        "value": "Deleting a ledger account number with linked documents is not possible, please change the documents, migrate or archive the account number"
      }
    ]
  },
  {
    "key": "UI_EX_RECEPTION_SUBLINE_NONE_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Deze receptie heeft geen sublijnen!"
      },
      {
        "iso": "en",
        "value": "This reception has no sublines!"
      }
    ]
  },
  {
    "key": "UI_EX_DELIVERY_SUBLINE_NONE_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Deze levering heeft geen sublijnen!"
      },
      {
        "iso": "en",
        "value": "This delivery has no sublines!"
      }
    ]
  },
  {
    "key": "UI_EX_CREDITNOTE_SUBLINE_NONE_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Deze creditnota heeft geen sublijnen!"
      },
      {
        "iso": "en",
        "value": "This creditnote has no sublines!"
      }
    ]
  },
  {
    "key": "UI_EX_SUBSCRIPTION_SUBLINE_NONE_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Dit abonnement heeft geen sublijnen!"
      },
      {
        "iso": "en",
        "value": "This subscription has no sublines!"
      }
    ]
  },
  {
    "key": "UI_EXPORT",
    "values": [
      {
        "iso": "nl",
        "value": "Exporteer"
      },
      {
        "iso": "en",
        "value": "Export"
      }
    ]
  },
  {
    "key": "UI_ADD_EXPORT",
    "values": [
      {
        "iso": "nl",
        "value": "Export aanmaken"
      },
      {
        "iso": "en",
        "value": "Create export"
      }
    ]
  },
  {
    "key": "UI_DELETE_EXPORTS",
    "values": [
      {
        "iso": "nl",
        "value": "Exporten verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete exports"
      }
    ]
  },
  {
    "key": "UI_EXPORT_TYPE",
    "values": [
      {
        "iso": "nl",
        "value": "Export type"
      },
      {
        "iso": "en",
        "value": "Export type"
      }
    ]
  },
  {
    "key": "UI_EXPORTS",
    "values": [
      {
        "iso": "nl",
        "value": "Export taken"
      },
      {
        "iso": "en",
        "value": "Export tasks"
      }
    ]
  },
  {
    "key": "UI_EXPORT_TO_EMAIL",
    "values": [
      {
        "iso": "nl",
        "value": "Exporteren via email"
      },
      {
        "iso": "en",
        "value": "Export by email"
      }
    ]
  },
  {
    "key": "UI_EXPORT_TO_OCTOPUS",
    "values": [
      {
        "iso": "nl",
        "value": "Exporteren naar Octopus"
      },
      {
        "iso": "en",
        "value": "Export to Octopus"
      }
    ]
  },
  {
    "key": "UI_EXPORT_TO_ADMISOL",
    "values": [
      {
        "iso": "nl",
        "value": "Exporteren naar Admisol"
      },
      {
        "iso": "en",
        "value": "Export to Admisol"
      }
    ]
  },
  {
    "key": "UI_EXPORT_METHOD",
    "values": [
      {
        "iso": "nl",
        "value": "Exporteermethode"
      },
      {
        "iso": "en",
        "value": "Export method"
      }
    ]
  },
  {
    "key": "UI_START_EXPORT_PERIOD",
    "values": [
      {
        "iso": "nl",
        "value": "Exporteer documenten vanaf"
      },
      {
        "iso": "en",
        "value": "Export documents from"
      }
    ]
  },
  {
    "key": "UI_END_EXPORT_PERIOD",
    "values": [
      {
        "iso": "nl",
        "value": "Exporteer documenten tot"
      },
      {
        "iso": "en",
        "value": "Export documents to"
      }
    ]
  },
  {
    "key": "UI_EXPORT_EXPORTED_DOCUMENTS",
    "values": [
      {
        "iso": "nl",
        "value": "Exporteer reeds geëxporteerde documenten"
      },
      {
        "iso": "en",
        "value": "Export already exported documents"
      }
    ]
  },
  {
    "key": "UI_BLOCK_EXPORTED_DOCUMENTS",
    "values": [
      {
        "iso": "nl",
        "value": "Markeer documenten als geëxporteerd"
      },
      {
        "iso": "en",
        "value": "Mark documents as exported"
      }
    ]
  },
  {
    "key": "UI_EMAIL_RECEIVER",
    "values": [
      {
        "iso": "nl",
        "value": "E-mailadres van de ontvanger"
      },
      {
        "iso": "en",
        "value": "Emailaddress of the receiver"
      }
    ]
  },
  {
    "key": "UI_LEDGER_EXPORT_DOWNLOAD_LOADING",
    "values": [
      {
        "iso": "nl",
        "value": "De export wordt geladen. Even geduld..."
      },
      {
        "iso": "en",
        "value": "The export is being loaded. Please wait..."
      }
    ]
  },
  {
    "key": "UI_EXPORT_DOWNLOAD_VALID",
    "values": [
      {
        "iso": "nl",
        "value": "De export is gevonden en staat klaar om gedownload te worden."
      },
      {
        "iso": "en",
        "value": "Your export has been found and is ready to be downloaded."
      }
    ]
  },
  {
    "key": "UI_DOWNLOAD_ZIP",
    "values": [
      {
        "iso": "nl",
        "value": "Download ZIP bestand"
      },
      {
        "iso": "en",
        "value": "Download ZIP file"
      }
    ]
  },
  {
    "key": "UI_EXPORT_DOWNLOAD_INVALID",
    "values": [
      {
        "iso": "nl",
        "value": "De gevraagde export is niet beschikbaar. Controleer de gebruikte link en vervaldatum."
      },
      {
        "iso": "en",
        "value": "The requested export isn't available. Verify the used link and expiration date."
      }
    ]
  },
  {
    "key": "UI_EXPORT_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Export informatie"
      },
      {
        "iso": "en",
        "value": "Export information"
      }
    ]
  },
  {
    "key": "UI_FAILED_DATE",
    "values": [
      {
        "iso": "nl",
        "value": "Tijdstip van falen"
      },
      {
        "iso": "en",
        "value": "Time of failure"
      }
    ]
  },
  {
    "key": "UI_COMPLETED_DATE",
    "values": [
      {
        "iso": "nl",
        "value": "Tijdstip van afronden"
      },
      {
        "iso": "en",
        "value": "Time of completion"
      }
    ]
  },
  {
    "key": "UI_DOWNLOADED_DATE",
    "values": [
      {
        "iso": "nl",
        "value": "Tijdstip van download"
      },
      {
        "iso": "en",
        "value": "Time of download"
      }
    ]
  },
  {
    "key": "UI_JOURNAL_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Dagboek informatie"
      },
      {
        "iso": "en",
        "value": "Journal information"
      }
    ]
  },
  {
    "key": "UI_DOWNLOADED",
    "values": [
      {
        "iso": "nl",
        "value": "Gedownload"
      },
      {
        "iso": "en",
        "value": "Downloaded"
      }
    ]
  },
  {
    "key": "UI_COMPLETED",
    "values": [
      {
        "iso": "nl",
        "value": "Uitgevoerd"
      },
      {
        "iso": "en",
        "value": "Completed"
      }
    ]
  },
  {
    "key": "UI_STARTED",
    "values": [
      {
        "iso": "nl",
        "value": "Gestart"
      },
      {
        "iso": "en",
        "value": "Started"
      }
    ]
  },
  {
    "key": "UI_NON_CONFORM_JOURNALS_FOR_EXTERNAL_EXPORT",
    "values": [
      {
        "iso": "nl",
        "value": "Dagboeken {{0}} zijn niet compatibel voor de gewenste export. Gelieve deze uit te sluiten van de export of de instellingen van het dagboek te corrigeren."
      },
      {
        "iso": "en",
        "value": "Journals {{0}} are not compatible for the desired export. Please exclude these from the export or correct the settings of the journals."
      }
    ]
  },
  {
    "key": "UI_SETUP",
    "values": [
      {
        "iso": "nl",
        "value": "Instellen"
      },
      {
        "iso": "en",
        "value": "Setup"
      }
    ]
  },
  {
    "key": "UI_INCORRECT_OCTOPUS_CREDENTIALS",
    "values": [
      {
        "iso": "nl",
        "value": "De opgegeven Octopus credentials of het BTW nummer is incorrect!"
      },
      {
        "iso": "en",
        "value": "The entered Octopus credentials or the vat number are incorrect!"
      }
    ]
  },
  {
    "key": "UI_LINKED",
    "values": [
      {
        "iso": "nl",
        "value": "Gelinkt"
      },
      {
        "iso": "en",
        "value": "Linked"
      }
    ]
  },
  {
    "key": "UI_NOT_LINKED",
    "values": [
      {
        "iso": "nl",
        "value": "Niet gelinkt"
      },
      {
        "iso": "en",
        "value": "Not linked"
      }
    ]
  },
  {
    "key": "UI_OCTOPUS_DOSSIER",
    "values": [
      {
        "iso": "nl",
        "value": "Octopus dossier"
      },
      {
        "iso": "en",
        "value": "Octopus dossier"
      }
    ]
  },
  {
    "key": "UI_FISCAL_PROFESSIONAL_PERCENTAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Beroepsgedeelte in percentage"
      },
      {
        "iso": "en",
        "value": "Professional part in percentage"
      }
    ]
  },
  {
    "key": "UI_FISCAL_RECUP_PERCENTAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Fiscaal aftrekbaar gedeelte in percentage"
      },
      {
        "iso": "en",
        "value": "Tax-deductible portion in percentage"
      }
    ]
  },
  {
    "key": "UI_VAT_RECUP_PERCENTAGE",
    "values": [
      {
        "iso": "nl",
        "value": "BTW aftrekbaar gedeelte in percentage"
      },
      {
        "iso": "en",
        "value": "VAT deductible part in percentage"
      }
    ]
  },
  {
    "key": "UI_IMPORT_OCTOUPS_ACCOUNTNUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "Importeer rekeningen"
      },
      {
        "iso": "en",
        "value": "Import accounts"
      }
    ]
  },
  {
    "key": "UI_IMPORT_OCTOUPS_ACCOUNTNUMBER_WARNING",
    "values": [
      {
        "iso": "nl",
        "value": "Bent u zeker dat u de import wilt uitvoeren? Het importeren alsook aanpassen van data is definitief en onomkeerbaar."
      },
      {
        "iso": "en",
        "value": "Are you sure you want to perform the import? Importing and modifying data is final and irreversible."
      }
    ]
  },
  {
    "key": "UI_FAILED_TO_FETCH_OCTOPUS_ACCOUNT_NUMBERS",
    "values": [
      {
        "iso": "nl",
        "value": "Het laden van de Octopus rekeningen is mislukt."
      },
      {
        "iso": "en",
        "value": "Loading the Octopus accounts was not successful."
      }
    ]
  },
  {
    "key": "UI_IMPORT_OCTOPUS_ACCOUNT_NUMBERS_COMPLETED",
    "values": [
      {
        "iso": "nl",
        "value": "Het opladen van de Octopus rekeningen is succesvol. Er zijn {{0}} rekeningen aangemaakt, {{1}} rekeningen aangepast en {{2}} rekeningen zijn niet ingeladen."
      },
      {
        "iso": "en",
        "value": "Het opladen van de Octopus rekeningen is geslaagd. Er zijn {{0}} rekeningen opgesteld, {{1}} rekeningen aangepast en {{2}} rekeningen zijn niet ingeladen."
      }
    ]
  },
  {
    "key": "UI_OCTOPUS_EXPORT_CORNFIRM",
    "values": [
      {
        "iso": "nl",
        "value": "Het Octopus dossier mag niet geopend zijn door uw boekhouder of iemand anders tijdens het lopen van de export. Gelieve te wachten tot de export is afgerond alvorens deze te openen. Bent u zeker dat de export veilig kan verlopen?"
      },
      {
        "iso": "en",
        "value": "The Octopus accountancy may not be openend by your accountant or somebody else while the export is running. Please wait untill the export is completed before opening. Are you sure we run the export safely?"
      }
    ]
  },
  {
    "key": "UI_PRODUCT_DELIVERIES",
    "values": [
      {
        "iso": "nl",
        "value": "Levering van goederen"
      },
      {
        "iso": "en",
        "value": "Delivery of products"
      }
    ]
  },
  {
    "key": "UI_ADD_PRODUCT_DELIVERY",
    "values": [
      {
        "iso": "nl",
        "value": "Levering toevoegen"
      },
      {
        "iso": "en",
        "value": "Add delivery"
      }
    ]
  },
  {
    "key": "UI_DELETE_PRODUCT_DELIVERIES",
    "values": [
      {
        "iso": "nl",
        "value": "Leveringen verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete deliveries"
      }
    ]
  },
  {
    "key": "UI_SHOW_DELIVERED",
    "values": [
      {
        "iso": "nl",
        "value": "Toon geleverde"
      },
      {
        "iso": "en",
        "value": "Show delivered"
      }
    ]
  },
  {
    "key": "UI_PURCHASE_INVOICE_DOESNT_CONTAIN_PRODUCT_LINES",
    "values": [
      {
        "iso": "nl",
        "value": "De aankoopfactuur bevat geen sublijnen met gekende producten."
      },
      {
        "iso": "en",
        "value": "The purchase invoice contains no sublines with known products."
      }
    ]
  },
  {
    "key": "UI_SALES_INVOICE_DOESNT_CONTAIN_PRODUCT_LINES",
    "values": [
      {
        "iso": "nl",
        "value": "De verkoopfactuur bevat geen sublijnen met gekende producten."
      },
      {
        "iso": "en",
        "value": "The sales invoice contains no sublines with known products."
      }
    ]
  },
  {
    "key": "UI_SHOW_ONLY_STOCK_PRODUCTS",
    "values": [
      {
        "iso": "nl",
        "value": "Toon enkel producten in stock"
      },
      {
        "iso": "en",
        "value": "Show only products in stock"
      }
    ]
  },
  {
    "key": "UI_IMPORT_STOCK_DATA",
    "values": [
      {
        "iso": "nl",
        "value": "Importeer stock informatie"
      },
      {
        "iso": "en",
        "value": "Import stock data"
      }
    ]
  },
  {
    "key": "UI_IMPORT_STOCK_DATA",
    "values": [
      {
        "iso": "nl",
        "value": "Importeer stock informatie"
      },
      {
        "iso": "en",
        "value": "Import stock data"
      }
    ]
  },
  {
    "key": "UI_DELIVERED",
    "values": [
      {
        "iso": "nl",
        "value": "Geleverd"
      },
      {
        "iso": "en",
        "value": "Delivered"
      }
    ]
  },
  {
    "key": "UI_HOURS",
    "values": [
      {
        "iso": "nl",
        "value": "Uren"
      },
      {
        "iso": "en",
        "value": "Hours"
      }
    ]
  },
  {
    "key": "UI_WORKPERFORMANCE_IS_INVOICED_ON_INVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "De werkprestatie is gekopeld aan factuur {{0}}"
      },
      {
        "iso": "en",
        "value": "The workperformance is linked to invoice {{0}}"
      }
    ]
  },
  {
    "key": "UI_GO_TO_INVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "Ga naar factuur"
      },
      {
        "iso": "en",
        "value": "Go to invoice"
      }
    ]
  },
  {
    "key": "UI_HOUR",
    "values": [
      {
        "iso": "nl",
        "value": "Uur"
      },
      {
        "iso": "en",
        "value": "Hour"
      }
    ]
  },
  {
    "key": "UI_UNINVOICED_HOURS",
    "values": [
      {
        "iso": "nl",
        "value": "Niet gefactureerde uren"
      },
      {
        "iso": "en",
        "value": "Uninvoiced hours"
      }
    ]
  },
  {
    "key": "UI_UNINVOICED_AMOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Niet gefactureerd bedrag"
      },
      {
        "iso": "en",
        "value": "Uninvoiced amount"
      }
    ]
  },
  {
    "key": "UI_RESET_PASSWORD_USER_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "Wachtwoord resetten"
      },
      {
        "iso": "en",
        "value": "Reset password"
      }
    ]
  },
  {
    "key": "UI_TOTAL",
    "values": [
      {
        "iso": "nl",
        "value": "Totaal"
      },
      {
        "iso": "en",
        "value": "Total"
      }
    ]
  },
  {
    "key": "UI_WRITEOFF",
    "values": [
      {
        "iso": "nl",
        "value": "Afschrijving"
      },
      {
        "iso": "en",
        "value": "Write off"
      }
    ]
  },
  {
    "key": "UI_WRITEOFFS",
    "values": [
      {
        "iso": "nl",
        "value": "Afschrijvingen"
      },
      {
        "iso": "en",
        "value": "Write offs"
      }
    ]
  },
  {
    "key": "UI_ADD_WRITEOFF",
    "values": [
      {
        "iso": "nl",
        "value": "Afschrijving aanmaken"
      },
      {
        "iso": "en",
        "value": "Create write off"
      }
    ]
  },
  {
    "key": "UI_DELETE_WRITEOFFS",
    "values": [
      {
        "iso": "nl",
        "value": "Afschrijvingen verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete write offs"
      }
    ]
  },
  {
    "key": "UI_WRITEOFF_SET_ARTICLE_AS_WRITEOFF_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "'{{0}}' overnemen als naam voor deze afschrijving?"
      },
      {
        "iso": "en",
        "value": "Use '{{0}}' as name for this write-off?"
      }
    ]
  },
  {
    "key": "UI_PURCHASE_INVOICE_SUBLINE",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoopfactuur sublijn"
      },
      {
        "iso": "en",
        "value": "Purchase invoice subline"
      }
    ]
  },
  {
    "key": "UI_INVOICE_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Facturatie gegevens"
      },
      {
        "iso": "en",
        "value": "Invoice information"
      }
    ]
  },
  {
    "key": "UI_LINEAR_WRITEOFF",
    "values": [
      {
        "iso": "nl",
        "value": "Lineaire afschrijving"
      },
      {
        "iso": "en",
        "value": "Linear depreciation"
      }
    ]
  },
  {
    "key": "UI_DEGRESSIVE_WRITEOFF",
    "values": [
      {
        "iso": "nl",
        "value": "Degressieve afschrijving"
      },
      {
        "iso": "en",
        "value": "Degressive depreciation"
      }
    ]
  },
  {
    "key": "UI_BIDIRECTIONAL_TRANSLOCATION",
    "values": [
      {
        "iso": "nl",
        "value": "Heenrit toevoegen"
      },
      {
        "iso": "en",
        "value": "Add reverse translocation"
      }
    ]
  },
  {
    "key": "UI_RETOUR_TRANSLOCATION",
    "values": [
      {
        "iso": "nl",
        "value": "Heenrit genereren"
      },
      {
        "iso": "en",
        "value": "Retour translocation"
      }
    ]
  },
  {
    "key": "UI_PDF_SHOW_BANK_DETAILS",
    "values": [
      {
        "iso": "nl",
        "value": "Toon financiële gegevens bij de bedrijfgegevens"
      },
      {
        "iso": "en",
        "value": "Show financial information with the company details"
      }
    ]
  },
  {
    "key": "UI_PDF_SHOW_BANKS_ON_PAYMENTLINE",
    "values": [
      {
        "iso": "nl",
        "value": "Alle banken vermelden bij betaalreferentie"
      },
      {
        "iso": "en",
        "value": "Mention all banks on payment reference"
      }
    ]
  },
  {
    "key": "UI_PDF_SHOW_SELECTED_BANK_ON_PAYMENTLINE",
    "values": [
      {
        "iso": "nl",
        "value": "Geselecteerde bank vermelden bij betaalreferentie"
      },
      {
        "iso": "en",
        "value": "Mention selected bank on payment reference"
      }
    ]
  },
  {
    "key": "UI_PDF_SHOW_DISCOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Toon kortingen?"
      },
      {
        "iso": "en",
        "value": "Show discounts?"
      }
    ]
  },
  {
    "key": "UI_PDF_SHOW_INCL_PRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Toon prijs inclusief BTW voor sublijnen?"
      },
      {
        "iso": "en",
        "value": "Show price include VAT for sublines?"
      }
    ]
  },
  {
    "key": "UI_PDF_SHOW_UNIT_PRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Toon eenheidsprijzen voor sublijnen?"
      },
      {
        "iso": "en",
        "value": "Show unit prices for sublines?"
      }
    ]
  },
  {
    "key": "UI_PDF_SHOW_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Toon BTW voor sublijnen?"
      },
      {
        "iso": "en",
        "value": "Show VAT for sublijnen?"
      }
    ]
  },
  {
    "key": "UI_PDF_LOGO_LOCATION",
    "values": [
      {
        "iso": "nl",
        "value": "Logo locatie"
      },
      {
        "iso": "en",
        "value": "Logo location"
      }
    ]
  },
  {
    "key": "UI_LOGO_LOCATION_NONE",
    "values": [
      {
        "iso": "nl",
        "value": "Logo verbergen"
      },
      {
        "iso": "en",
        "value": "Hide logo"
      }
    ]
  },
  {
    "key": "UI_LOGO_LOCATION_BACKGROUND",
    "values": [
      {
        "iso": "nl",
        "value": "Logo op de achtergrond"
      },
      {
        "iso": "en",
        "value": "Logo on the background"
      }
    ]
  },
  {
    "key": "UI_LOGO_LOCATION_TOP",
    "values": [
      {
        "iso": "nl",
        "value": "Logo boven details"
      },
      {
        "iso": "en",
        "value": "Logo above details"
      }
    ]
  },
  {
    "key": "UI_LOGO_LOCATION_ABOVE_COMPANY",
    "values": [
      {
        "iso": "nl",
        "value": "Logo boven bedrijfsinformatie"
      },
      {
        "iso": "en",
        "value": "Logo above company information"
      }
    ]
  },
  {
    "key": "UI_LOGO_LOCATION_LEFT",
    "values": [
      {
        "iso": "nl",
        "value": "Logo links van bedrijfsinformatie"
      },
      {
        "iso": "en",
        "value": "Logo left of company information"
      }
    ]
  },
  {
    "key": "UI_REST_VALUE",
    "values": [
      {
        "iso": "nl",
        "value": "Restwaarde"
      },
      {
        "iso": "en",
        "value": "Residual value"
      }
    ]
  },
  {
    "key": "UI_YEARLY_OVERVIEW",
    "values": [
      {
        "iso": "nl",
        "value": "Jaarlijks overzicht"
      },
      {
        "iso": "en",
        "value": "Yearly overview"
      }
    ]
  },
  {
    "key": "UI_MONTHLY_OVERVIEW",
    "values": [
      {
        "iso": "nl",
        "value": "Maandelijks overzicht"
      },
      {
        "iso": "en",
        "value": "Monthly overview"
      }
    ]
  },
  {
    "key": "UI_DAILY_OVERVIEW",
    "values": [
      {
        "iso": "nl",
        "value": "Dagelijks overzicht"
      },
      {
        "iso": "en",
        "value": "Daily overview"
      }
    ]
  },
  {
    "key": "UI_LEDGER_SETTINGS",
    "values": [
      {
        "iso": "nl",
        "value": "Grootboek instellingen"
      },
      {
        "iso": "en",
        "value": "Ledger settings"
      }
    ]
  },
  {
    "key": "UI_WRITEOFF_OPEN",
    "values": [
      {
        "iso": "nl",
        "value": "Lopend"
      },
      {
        "iso": "en",
        "value": "Running"
      }
    ]
  },
  {
    "key": "UI_WRITEOFF_COMPLETED",
    "values": [
      {
        "iso": "nl",
        "value": "Afgeboekt"
      },
      {
        "iso": "en",
        "value": "Written off"
      }
    ]
  },
  {
    "key": "UI_SHOW_RUNNING",
    "values": [
      {
        "iso": "nl",
        "value": "Toon lopende"
      },
      {
        "iso": "en",
        "value": "Show running"
      }
    ]
  },
  {
    "key": "UI_SHOW_WRITTENOFF",
    "values": [
      {
        "iso": "nl",
        "value": "Toon afgeboekte"
      },
      {
        "iso": "en",
        "value": "Show written off"
      }
    ]
  },
  {
    "key": "UI_PRORATA",
    "values": [
      {
        "iso": "nl",
        "value": "Pro rata temporisch"
      },
      {
        "iso": "en",
        "value": "Pro rata temporisch"
      }
    ]
  },
  {
    "key": "UI_DAY",
    "values": [
      {
        "iso": "nl",
        "value": "Dag"
      },
      {
        "iso": "en",
        "value": "Day"
      }
    ]
  },
  {
    "key": "UI_CURRENT_REMAINING_VALUE",
    "values": [
      {
        "iso": "nl",
        "value": "Huidige resterende waarde"
      },
      {
        "iso": "en",
        "value": "Current remaining value"
      }
    ]
  },
  {
    "key": "THERE_IS_ONE_NUMBER_OF_USER_MESSAGES",
    "values": [
      {
        "iso": "nl",
        "value": "Er is {{0}} ongelezen bericht"
      },
      {
        "iso": "en",
        "value": "There is {{0}} unread message"
      }
    ]
  },
  {
    "key": "THERE_ARE_A_NUMBER_OF_USER_MESSAGES",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn {{0}} ongelezen berichten"
      },
      {
        "iso": "en",
        "value": "There are {{0}} unread messages"
      }
    ]
  },
  {
    "key": "THERE_ARE_A_NUMBER_OF_NOTIFICATIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn {{0}} meldingen"
      },
      {
        "iso": "en",
        "value": "There are {{0}} notifications"
      }
    ]
  },
  {
    "key": "THERE_IS_A_NOTIFICATION",
    "values": [
      {
        "iso": "nl",
        "value": "Er is {{0}} melding"
      },
      {
        "iso": "en",
        "value": "There is {{0}} notification"
      }
    ]
  },
  {
    "key": "UI_NEW_POSSIBLE_FINANCIAL_TRANSACTION_MATCHES_CREATED",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn {{0}} transacties gevonden voor automatische koppeling."
      },
      {
        "iso": "en",
        "value": "There are {{0}} transactions found that can be linked automaticly."
      }
    ]
  },
  {
    "key": "UI_NEW_FINANCIAL_TRANSACTION_MATCHED_TO_DOCUMENT",
    "values": [
      {
        "iso": "nl",
        "value": "Er is een transactie gekoppeld aan {{0}} '{{1}}' van {{2}}."
      },
      {
        "iso": "en",
        "value": "There is a transaction linked with {{0}} '{{1}}' of {{3}}."
      }
    ]
  },
  {
    "key": "UI_FINANCIAL_ACCOUNT_DISABLED",
    "values": [
      {
        "iso": "nl",
        "value": "De directe koppeling tussen het systeem en je bankrekening ({{0}}) is uitgeschakeld. Herbevestig deze via je bedrijfsinstelling!"
      },
      {
        "iso": "en",
        "value": "The direct link between the system and your bank account ({{0}}) is disabled. Reconfirm the connection from your company settings!"
      }
    ]
  },
  {
    "key": "UI_FINANCIAL_ACCOUNT_SUSPENDED",
    "values": [
      {
        "iso": "nl",
        "value": "De directe koppeling tussen het systeem en je bankrekening ({{0}}) is uitgeschakeld door de bank. Herbevestig deze via je bedrijfsinstelling!"
      },
      {
        "iso": "en",
        "value": "The direct link between the system and your bank account ({{0}}) is disabled by the bank. Reconfirm the connection from your company settings!"
      }
    ]
  },
  {
    "key": "UI_FINANCIAL_ACCOUNT_EXPIRED",
    "values": [
      {
        "iso": "nl",
        "value": "De directe koppeling tussen het systeem en je bankrekening ({{0}}) is vervallen. Herbevestig deze via je bedrijfsinstelling!"
      },
      {
        "iso": "en",
        "value": "The direct link between the system and your bank account ({{0}}) is expired. Reconfirm the connection from your company settings!"
      }
    ]
  },
  {
    "key": "UI_COMING_SOON",
    "values": [
      {
        "iso": "nl",
        "value": "Binnenkort beschikbaar"
      },
      {
        "iso": "en",
        "value": "Coming soon"
      }
    ]
  },
  {
    "key": "UI_IMPORT_DOCUMENT_INFORMATION_FROM_OCTOPUS",
    "values": [
      {
        "iso": "nl",
        "value": "Importeer document informatie"
      },
      {
        "iso": "en",
        "value": "Import document information"
      }
    ]
  },
  {
    "key": "UI_SYNC_RELATIONS_WITH_OCTOPUS",
    "values": [
      {
        "iso": "nl",
        "value": "Syncroniseer relaties"
      },
      {
        "iso": "en",
        "value": "Syncronise relations"
      }
    ]
  },
  {
    "key": "UI_IMPORT_DOCUMENT_INFORMATION_FROM_OCTOPUS_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "<p><b><u>Documenten</u></b><br />De gesyncroniseerde verkoop/aankoop facturen/creditnota's kunnen opnieuw worden ingelezen van Octopus naar het systeem. Hierbij worden de BTW codes alsook rekeningnummers ingevuld of correct geplaatst zodat de statestieken en rapporten correct berekend worden.<br /><b>Opgelet:</b> BTW codes worden slechts gewijzigd wanneer de BTW waarde gelijk blijft. Het is niet mogelijk om via deze functie de BTW waarde aan te passen. Deze dient door de gebruiker of door de Support aangepast te worden op het document waar nodig!</p><p><b><u>Benodigdheden</u></b><br />Bij het exporteren van uw documenten naar Octopus word er aan het begin van iedere sublijnen een \"Tag\" toegevoegd. Deze tag dient ongewijzigd en aanwezig te blijven om de syncronisatie succesvol te kunnen uitvoeren!<br />Indien deze tag aangepast of verwijderd wordt kan dit resulteren in verkeerde informatie tot het overslaan van de syncronisatie voor deze lijn.</p>"
      },
      {
        "iso": "en",
        "value": "<p><b><u>Documents</u></b><br />The syncronized sales/purchase invoices/credit notes can be re-read from Octopus to the system. The VAT codes as well as account numbers are filled in or placed correctly so that the statistics and reports are calculated correctly.<br /><b>Note:</b> VAT codes are only changed when the VAT value remains the same. It is not possible to adjust the VAT value via this function. This must be adjusted by the user or by the Support on the document where necessary!</p><p><b><u>Requirements</u></b><br />When exporting your documents to Octopus, a \"Tag\" is added at the beginning of each sublines. This tag must remain unaltered and present in order to successfully perform the synchronization!<br />If this tag is modified or removed, this may result in incorrect information to skip the synchronization for this line.</p>"
      }
    ]
  },
  {
    "key": "UI_SYNC_RELATIONS_WITH_OCTOPUS_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "<p><b><u>Syncronisatie</u></b><br />De relaties kunnen weerzijdig aangepast worden en doormiddel van deze functie gesyncroniseerd worden in Octopus en het systeem. Doormiddel van de verschillende opties kan een syncronistiegraad ingesteld worden om het gewenste resultaat te bekomen.</p><p><b><u>Benodigdheden</u></b><br />De syncronisatie gebeurt doormiddel van de externe ID parameter op uw Octopus relatie. Deze dient dus correct en ongewijzigd te blijven. Bij het exporteren van documenten wordt deze parameter automatisch ingevuld bij het aanmaken van de relatie in Octopus. Indien men deze handmatig wenst aan te maken, kan support gecontacteerd worden om de nodige id's binnen het systeem te verkrijgen.</p>"
      },
      {
        "iso": "en",
        "value": "<p><b><u>Syncronization</u></b><br />The relationships can be adjusted on both sides and be synchronized in Octopus and the system by means of this function. By means of the different options, a degree of synchronization can be set to obtain the desired result.</p><p><b><u>Requirements</u></b><br />The synchronization is done by means of the external ID parameter on your Octopus relationship. It must therefore remain correct and unchanged. When exporting documents, this parameter is automatically filled in when the relationship is created in Octopus. If you wish to create these manually, support can be contacted to obtain the necessary ids within the system.</p>"
      }
    ]
  },
  {
    "key": "UI_ACCOUNT_NUMBERS",
    "values": [
      {
        "iso": "nl",
        "value": "Boekhoudkundige rekeningnummers"
      },
      {
        "iso": "en",
        "value": "Ledger account numbers"
      }
    ]
  },
  {
    "key": "UI_JANUARY",
    "values": [
      {
        "iso": "nl",
        "value": "Januari"
      },
      {
        "iso": "en",
        "value": "January"
      }
    ]
  },
  {
    "key": "UI_FEBRUARY",
    "values": [
      {
        "iso": "nl",
        "value": "Februari"
      },
      {
        "iso": "en",
        "value": "February"
      }
    ]
  },
  {
    "key": "UI_MARCH",
    "values": [
      {
        "iso": "nl",
        "value": "Maart"
      },
      {
        "iso": "en",
        "value": "March"
      }
    ]
  },
  {
    "key": "UI_APRIL",
    "values": [
      {
        "iso": "nl",
        "value": "April"
      },
      {
        "iso": "en",
        "value": "April"
      }
    ]
  },
  {
    "key": "UI_MAY",
    "values": [
      {
        "iso": "nl",
        "value": "Mei"
      },
      {
        "iso": "en",
        "value": "May"
      }
    ]
  },
  {
    "key": "UI_JUNE",
    "values": [
      {
        "iso": "nl",
        "value": "Juni"
      },
      {
        "iso": "en",
        "value": "June"
      }
    ]
  },
  {
    "key": "UI_JULY",
    "values": [
      {
        "iso": "nl",
        "value": "Juli"
      },
      {
        "iso": "en",
        "value": "July"
      }
    ]
  },
  {
    "key": "UI_AUGUST",
    "values": [
      {
        "iso": "nl",
        "value": "Augustus"
      },
      {
        "iso": "en",
        "value": "August"
      }
    ]
  },
  {
    "key": "UI_SEPTEMBER",
    "values": [
      {
        "iso": "nl",
        "value": "September"
      },
      {
        "iso": "en",
        "value": "September"
      }
    ]
  },
  {
    "key": "UI_OCTOBER",
    "values": [
      {
        "iso": "nl",
        "value": "Oktober"
      },
      {
        "iso": "en",
        "value": "October"
      }
    ]
  },
  {
    "key": "UI_NOVEMBER",
    "values": [
      {
        "iso": "nl",
        "value": "November"
      },
      {
        "iso": "en",
        "value": "November"
      }
    ]
  },
  {
    "key": "UI_DECEMBER",
    "values": [
      {
        "iso": "nl",
        "value": "December"
      },
      {
        "iso": "en",
        "value": "December"
      }
    ]
  },
  {
    "key": "UI_SHOW",
    "values": [
      {
        "iso": "nl",
        "value": "Toon"
      },
      {
        "iso": "en",
        "value": "Show"
      }
    ]
  },
  {
    "key": "UI_COMPARE_WITH_PREVIOUS_YEAR",
    "values": [
      {
        "iso": "nl",
        "value": "Vergelijken met vorig jaar"
      },
      {
        "iso": "en",
        "value": "Compare with previous year"
      }
    ]
  },
  {
    "key": "UI_BAR_CHART",
    "values": [
      {
        "iso": "nl",
        "value": "Staafdiagram"
      },
      {
        "iso": "en",
        "value": "Bar chart"
      }
    ]
  },
  {
    "key": "UI_LINE_CHART",
    "values": [
      {
        "iso": "nl",
        "value": "Lijndiagram"
      },
      {
        "iso": "en",
        "value": "Line chart"
      }
    ]
  },
  {
    "key": "UI_HIDE_FUTURE_DATE_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Verberg toekomstige data"
      },
      {
        "iso": "en",
        "value": "Hide future dates"
      }
    ]
  },
  {
    "key": "UI_DASHBOARD",
    "values": [
      {
        "iso": "nl",
        "value": "Dashboard"
      },
      {
        "iso": "en",
        "value": "Dashboard"
      }
    ]
  },
  {
    "key": "UI_EMAIL_SERVER_TYPE",
    "values": [
      {
        "iso": "nl",
        "value": "Server type"
      },
      {
        "iso": "en",
        "value": "Server type"
      }
    ]
  },
  {
    "key": "UI_USER_OUTBOUNDING_EMAIL_SERVER",
    "values": [
      {
        "iso": "nl",
        "value": "Uitgaande e-mailserver"
      },
      {
        "iso": "en",
        "value": "Outbounding email server"
      }
    ]
  },
  {
    "key": "UI_DASHBOARD_FINANCIAL_WITHOUT_ACCOUNT_NUMBER_WARNING",
    "values": [
      {
        "iso": "nl",
        "value": "Het systeem heeft {{0}} gedetecteerd aan niet toegewezen kosten voor het jaar {{1}}. Deze waarde word berekend als kost en kan een afwijking in de effectieve berekeningen veroorzaken!"
      },
      {
        "iso": "en",
        "value": "The system has detected {{0}} of unallocated costs for the year {{1}}. This value is calculated as a cost and can cause a deviation in the effective calculations!"
      }
    ]
  },
  {
    "key": "UI_DASHBOARD_FINANCIAL_NOT_WRITTEN_OFF_WARNING",
    "values": [
      {
        "iso": "nl",
        "value": "Het systeem heeft {{0}} gedetecteerd aan niet afgeschreven afschrijvingen binnen het jaar {{1}}. Deze waarde wordt overgeslagen in de berekeningen!"
      },
      {
        "iso": "en",
        "value": "The system has detected {{0}} of non-depreciated depreciation within the year {{1}}. This value is skipped in the calculations!"
      }
    ]
  },
  {
    "key": "UI_ARTICLE",
    "values": [
      {
        "iso": "nl",
        "value": "Artikel"
      },
      {
        "iso": "en",
        "value": "Article"
      }
    ]
  },
  {
    "key": "UI_WRITEOFF_VALUE",
    "values": [
      {
        "iso": "nl",
        "value": "Afschrijf waarde"
      },
      {
        "iso": "en",
        "value": "Writoff value"
      }
    ]
  },
  {
    "key": "UI_WRITEOFF_PERCENTAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Afschrijf percentage"
      },
      {
        "iso": "en",
        "value": "Writoff percentage"
      }
    ]
  },
  {
    "key": "UI_SET_ARTICLE_INFORMATION_AS_WRITEOFF_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Artikel informatie gebruiken als afschrijvingsnaam?"
      },
      {
        "iso": "en",
        "value": "Use article information as writeoff name?"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_INFORMATION_IMPORT_STARTED",
    "values": [
      {
        "iso": "nl",
        "value": "Importeren van document informatie is uitgevoerd."
      },
      {
        "iso": "en",
        "value": "Import of document information has been requested."
      }
    ]
  },
  {
    "key": "UI_NO_DOCUMENTS_FOUND_FOR_THIS_RELATION",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen aankoop- of verkoop documenten gekend voor deze relatie."
      },
      {
        "iso": "en",
        "value": "There aren't any known purchase or sales documents known for this relation."
      }
    ]
  },
  {
    "key": "UI_CONVERT_RECEPTION_TO_INVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "Receptie naar factuur omzetten"
      },
      {
        "iso": "en",
        "value": "Convert reception to invoice"
      }
    ]
  },
  {
    "key": "UI_CONVERT_DELIVERY_TO_INVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "Levering naar factuur omzetten"
      },
      {
        "iso": "en",
        "value": "Convert delivery to invoice"
      }
    ]
  },
  {
    "key": "UI_ALL_SUBLINES_ARE_ALREADY_INVOICED",
    "values": [
      {
        "iso": "nl",
        "value": "Alle sublijnen zijn reeds gefactureerd!"
      },
      {
        "iso": "en",
        "value": "All sublines are already invoiced!"
      }
    ]
  },
  {
    "key": "UI_INVOICE_UNINVOICED_WORKPERFORMANCES",
    "values": [
      {
        "iso": "nl",
        "value": "Factureer open prestaties"
      },
      {
        "iso": "en",
        "value": "Invoice open performances"
      }
    ]
  },
  {
    "key": "UI_BANK_BALANCE",
    "values": [
      {
        "iso": "nl",
        "value": "Bank saldo"
      },
      {
        "iso": "en",
        "value": "Bank balance"
      }
    ]
  },
  {
    "key": "UI_EXPECTED_INCOME_PAYMENTS",
    "values": [
      {
        "iso": "nl",
        "value": "Verwachte binnenkomende betalingen"
      },
      {
        "iso": "en",
        "value": "Expected incoming payments"
      }
    ]
  },
  {
    "key": "UI_EXPECTED_OUTGOING_PAYMENTS",
    "values": [
      {
        "iso": "nl",
        "value": "Verwachte uitgaande betalingen"
      },
      {
        "iso": "en",
        "value": "Expected outgoing payments"
      }
    ]
  },
  {
    "key": "UI_INCOME_PAYMENTS",
    "values": [
      {
        "iso": "nl",
        "value": "Binnenkomende betalingen"
      },
      {
        "iso": "en",
        "value": "Incoming payments"
      }
    ]
  },
  {
    "key": "UI_OUTGOING_PAYMENTS",
    "values": [
      {
        "iso": "nl",
        "value": "Uitgaande betalingen"
      },
      {
        "iso": "en",
        "value": "Outgoing payments"
      }
    ]
  },
  {
    "key": "UI_OVERDUE_SALES_INVOICES",
    "values": [
      {
        "iso": "nl",
        "value": "Openstaande verkoopfacturen"
      },
      {
        "iso": "en",
        "value": "Overdue sales invoices"
      }
    ]
  },
  {
    "key": "UI_SUCCESS",
    "values": [
      {
        "iso": "nl",
        "value": "Geslaagd"
      },
      {
        "iso": "en",
        "value": "Success"
      }
    ]
  },
  {
    "key": "UI_SALES_INVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "Verkoopsfactuur"
      },
      {
        "iso": "en",
        "value": "Sales invoice"
      }
    ]
  },
  {
    "key": "UI_SALES_CREDITNOTE",
    "values": [
      {
        "iso": "nl",
        "value": "Verkoop creditnota"
      },
      {
        "iso": "en",
        "value": "Sales creditnote"
      }
    ]
  },
  {
    "key": "UI_PURCHASE_CREDITNOTES",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoopcreditnota"
      },
      {
        "iso": "en",
        "value": "Purchase creditnote"
      }
    ]
  },
  {
    "key": "UI_FINISHED_ON",
    "values": [
      {
        "iso": "nl",
        "value": "Afgerond op"
      },
      {
        "iso": "en",
        "value": "Finished on"
      }
    ]
  },
  {
    "key": "UI_EXPORTED",
    "values": [
      {
        "iso": "nl",
        "value": "Geëxporteerd"
      },
      {
        "iso": "en",
        "value": "Exported"
      }
    ]
  },
  {
    "key": "UI_DELETE_TRANSACTIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Verwijder transacties"
      },
      {
        "iso": "en",
        "value": "Delete transactions"
      }
    ]
  },
  {
    "key": "UI_DELETE_TRANSACTION",
    "values": [
      {
        "iso": "nl",
        "value": "Verwijder transactie"
      },
      {
        "iso": "en",
        "value": "Delete transaction"
      }
    ]
  },
  {
    "key": "UI_GENERAL_TERMS_AND_CONDITIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Algemene verkoopsvoorwaarden"
      },
      {
        "iso": "en",
        "value": "General terms and conditions"
      }
    ]
  },
  {
    "key": "UI_ADD_GENERAL_TERMS_AND_CONDITIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Verkoopsvoorwaarden toevoegen"
      },
      {
        "iso": "en",
        "value": "Add terms and conditions"
      }
    ]
  },
  {
    "key": "UI_DELETE_GENERAL_TERMS_AND_CONDITIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Verkoopsvoorwaarden verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete terms and conditions"
      }
    ]
  },
  {
    "key": "UI_VIEW_GENERAL_TERMS_AND_CONDITIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Verkoopsvoorwaarden bekijken"
      },
      {
        "iso": "en",
        "value": "View terms and conditions"
      }
    ]
  },
  {
    "key": "UI_OPTIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Opties"
      },
      {
        "iso": "en",
        "value": "Options"
      }
    ]
  },
  {
    "key": "UI_BANK_LINK_EXPIRED",
    "values": [
      {
        "iso": "nl",
        "value": "Het systeem heeft een vervallen bank-link gedetecteerd!"
      },
      {
        "iso": "en",
        "value": "The system has encountered an expired bank-link!"
      }
    ]
  },
  {
    "key": "UI_BANK_LINK_SUSPENDED",
    "values": [
      {
        "iso": "nl",
        "value": "Het systeem heeft een probleem met een bank-link gedetecteerd, gelieve deze bank-link opnieuw in te stellen!"
      },
      {
        "iso": "en",
        "value": "The system has detected a problem with a bank link, please reset this bank link!"
      }
    ]
  },
  {
    "key": "UI_IMPORT_VAT_RATES_FROM_OCTOPUS",
    "values": [
      {
        "iso": "nl",
        "value": "Importeer BTW tarieven"
      },
      {
        "iso": "en",
        "value": "Import VAT rates"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_YEAR",
    "values": [
      {
        "iso": "nl",
        "value": "Jaartal in document nummer"
      },
      {
        "iso": "en",
        "value": "Year in document number"
      }
    ]
  },
  {
    "key": "UI_FULL_YEAR",
    "values": [
      {
        "iso": "nl",
        "value": "Volledig jaartal"
      },
      {
        "iso": "en",
        "value": "Full year"
      }
    ]
  },
  {
    "key": "UI_SHORT_YEAR",
    "values": [
      {
        "iso": "nl",
        "value": "Verkort jaartal"
      },
      {
        "iso": "en",
        "value": "Short year"
      }
    ]
  },
  {
    "key": "UI_OVERRULE_DOCUMENT_LOGIC",
    "values": [
      {
        "iso": "nl",
        "value": "Document logica overschrijven"
      },
      {
        "iso": "en",
        "value": "Overrule document logic"
      }
    ]
  },
  {
    "key": "UI_OVERRULE_NEXT_INVOICE_NUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "Volgend factuurnummer overschrijven"
      },
      {
        "iso": "en",
        "value": "Overrule next invoice number"
      }
    ]
  },
  {
    "key": "UI_OVERRULE_NEXT_CREDITNOTE_NUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "Volgend creditnota nummer overschrijven"
      },
      {
        "iso": "en",
        "value": "Overrule next creditnote number"
      }
    ]
  },
  {
    "key": "UI_CONFIRM_INVOICE_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Bent u zeker dat u deze factuur wenst te bevestigen? Na het bevestigen van de factuur ontvangt u een definitief document nummer en kan het wijzigen van deze factuur legale en functionele gevolgen veroorzaken!"
      },
      {
        "iso": "en",
        "value": "Are you sure you want to confirm this invoice? After confirming the invoice you will receive a final document number and changing this invoice can have legal and functional consequences!"
      }
    ]
  },
  {
    "key": "UI_CONFIRM_INVOICE_WITH_NON_LATEST_DATE_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "De gebruikte datum is niet opvolgend op oudere facturen! Voor legale reden raden we aan om opeenvolgende datums te gebruiken."
      },
      {
        "iso": "en",
        "value": "The date used is not consecutive on older invoices! For legal reasons, we recommend using consecutive dates."
      }
    ]
  },
  {
    "key": "UI_CONFIRM_CREDITNOTE_WITH_NON_LATEST_DATE_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "De gebruikte datum is niet opvolgend op oudere creditnota's! Voor legale reden raden we aan om opeenvolgende datums te gebruiken."
      },
      {
        "iso": "en",
        "value": "The date used is not consecutive on older creditnotes! For legal reasons, we recommend using consecutive dates."
      }
    ]
  },
  {
    "key": "UI_CONFIRM_CREDITNOTE_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Bent u zeker dat u deze creditnota wenst te bevestigen? Na het bevestigen van de creditnota ontvangt u een definitief document nummer en kan het wijzigen van deze creditnota legale en functionele gevolgen veroorzaken!"
      },
      {
        "iso": "en",
        "value": "Are you sure you want to confirm this creditnote? After confirming the creditnote you will receive a final document number and changing this creditenote can have legal and functional consequences!"
      }
    ]
  },
  {
    "key": "UI_CREDITNOTE_CONFIRM_ERROR",
    "values": [
      {
        "iso": "nl",
        "value": "De creditnota kan niet worden bevestigd wegens aanwezige founten!"
      },
      {
        "iso": "en",
        "value": "The creditnote can't be confirmed due to found issues!"
      }
    ]
  },
  {
    "key": "UI_CONFIRM_CREDITNOTE_WITH_NON_LATEST_DATE_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Er is een jongere datum gevonden bij een ouder bevestigde creditnota. Het gebruik van niet-opvolgende datums bij opeenvolgende creditnotanummers kan legale problemen met zich meebrengen. Bent u zeker dat u deze creditnote wenst te bevestigen?"
      },
      {
        "iso": "en",
        "value": "A younger date was found on an older confirmed creditnote. Using non-consecutive dates with consecutive creditnote numbers can cause legal problems. Are you sure you want to confirm this creditnote?"
      }
    ]
  },
  {
    "key": "UI_CONFIRM_PURCHASE_INVOICE_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Bent u zeker dat u deze factuur wenst te bevestigen? Na het bevestigen van de factuur ontvangt u een definitief document nummer en kan het wijzigen van deze factuur legale en functionele gevolgen veroorzaken!"
      },
      {
        "iso": "en",
        "value": "Are you sure you want to confirm this invoice? After confirming the invoice you will receive a final document number and changing this invoice can have legal and functional consequences!"
      }
    ]
  },
  {
    "key": "UI_CONFIRM_PURCHASE_CREDITNOTE_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Bent u zeker dat u deze creditnota wenst te bevestigen? Na het bevestigen van de creditnota ontvangt u een definitief document nummer en kan het wijzigen van deze creditnota legale en functionele gevolgen veroorzaken!"
      },
      {
        "iso": "en",
        "value": "Are you sure you want to confirm this creditnote? After confirming the creditnote you will receive a final document number and changing this creditenote can have legal and functional consequences!"
      }
    ]
  },
  {
    "key": "UI_CONFIRM_PRODUCT_DELIVERY_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Bent u zeker dat u deze levering wenst te bevestigen? Na het bevestigen van de levering ontvangt u een definitief document nummer en kunt u de levering niet meer wijzigen!"
      },
      {
        "iso": "en",
        "value": "Are you sure you want to confirm this delivery? After confirming the delivery you will receive a final document number and you can no longer change the delivery!"
      }
    ]
  },
  {
    "key": "UI_CONFIRM_PRODUCT_RECEPTION_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Bent u zeker dat u deze receptie wenst te bevestigen? Na het bevestigen van de receptie ontvangt u een definitief document nummer en kunt u de receptie niet meer wijzigen!"
      },
      {
        "iso": "en",
        "value": "Are you sure you want to confirm this reception? After confirming the reception you will receive a final document number and you can no longer change the reception!"
      }
    ]
  },
  {
    "key": "UI_TRANSLOCATION_EXPENSES",
    "values": [
      {
        "iso": "nl",
        "value": "Verplaatsing vergoedingen"
      },
      {
        "iso": "en",
        "value": "Translocation expenses"
      }
    ]
  },
  {
    "key": "UI_TRANSLOCATION_EXPENSE",
    "values": [
      {
        "iso": "nl",
        "value": "Verplaatsingsvergoeding"
      },
      {
        "iso": "en",
        "value": "Translocation expense"
      }
    ]
  },
  {
    "key": "UI_DUPLICATE_TRANSLOCATION_EXPENSE",
    "values": [
      {
        "iso": "nl",
        "value": "Er bestaat reeds een vergoeding binnen de huidige datum range. Er kan slechts één vergoeding bestaan per datum!"
      },
      {
        "iso": "en",
        "value": "An expense already exists within the current date range. Only one expense can exist per date!"
      }
    ]
  },
  {
    "key": "UI_CALCULATE_TIME_BY_START_AND_END_TIME",
    "values": [
      {
        "iso": "nl",
        "value": "Bereken de tijd op basis van een start en eind tijdstip"
      },
      {
        "iso": "en",
        "value": "Calculate the time on base of the start and end time."
      }
    ]
  },
  {
    "key": "UI_START_TIME",
    "values": [
      {
        "iso": "nl",
        "value": "Starttijd"
      },
      {
        "iso": "en",
        "value": "Start time"
      }
    ]
  },
  {
    "key": "UI_END_TIME",
    "values": [
      {
        "iso": "nl",
        "value": "Eindtijd"
      },
      {
        "iso": "en",
        "value": "End time"
      }
    ]
  },
  {
    "key": "UI_SKIP",
    "values": [
      {
        "iso": "nl",
        "value": "Overslaan"
      },
      {
        "iso": "en",
        "value": "Skip"
      }
    ]
  },
  {
    "key": "UI_ADD_AS_NEW",
    "values": [
      {
        "iso": "nl",
        "value": "Als nieuw toevoegen"
      },
      {
        "iso": "en",
        "value": "Add as new"
      }
    ]
  },
  {
    "key": "UI_CHOOSE_AN_OPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Kies een optie"
      },
      {
        "iso": "en",
        "value": "Choose an option"
      }
    ]
  },
  {
    "key": "UI_CHOOSE_ALL_OPTIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Wijzig alle optie"
      },
      {
        "iso": "en",
        "value": "Change all option"
      }
    ]
  },
  {
    "key": "UI_VATRATE_IMPORT_COMPLETED",
    "values": [
      {
        "iso": "nl",
        "value": "Importeren van BTW tarieven is voltooid!"
      },
      {
        "iso": "en",
        "value": "Import of vat rates has been completed!"
      }
    ]
  },
  {
    "key": "UI_CONTACT_PERSON",
    "values": [
      {
        "iso": "nl",
        "value": "Contactpersoon"
      },
      {
        "iso": "en",
        "value": "Contact person"
      }
    ]
  },
  {
    "key": "UI_SALES_PERSON",
    "values": [
      {
        "iso": "nl",
        "value": "Verkoper"
      },
      {
        "iso": "en",
        "value": "Sales person"
      }
    ]
  },
  {
    "key": "UI_PDF_SHOW_COMPANY_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Toon handelsnaam"
      },
      {
        "iso": "en",
        "value": "Show Trade name"
      }
    ]
  },
  {
    "key": "UI_PDF_SHOW_COMPANY_EMAIL",
    "values": [
      {
        "iso": "nl",
        "value": "Toon bedrijfs e-mail"
      },
      {
        "iso": "en",
        "value": "Show company email"
      }
    ]
  },
  {
    "key": "UI_PDF_SHOW_COMPANY_TEL",
    "values": [
      {
        "iso": "nl",
        "value": "Toon bedrijfs telefoonnummer"
      },
      {
        "iso": "en",
        "value": "Show company phone number"
      }
    ]
  },
  {
    "key": "UI_PDF_SHOW_COMPANY_ADDRESS",
    "values": [
      {
        "iso": "nl",
        "value": "Toon bedrijfs adres"
      },
      {
        "iso": "en",
        "value": "Show company address"
      }
    ]
  },
  {
    "key": "UI_PDF_SHOW_COMPANY_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Toon bedrijfs BTW nummer"
      },
      {
        "iso": "en",
        "value": "Show company VAT number"
      }
    ]
  },
  {
    "key": "UI_PDF_SHOW_COMPANY_RPR",
    "values": [
      {
        "iso": "nl",
        "value": "Toon bedrijfs RPR"
      },
      {
        "iso": "en",
        "value": "Show company RPR"
      }
    ]
  },
  {
    "key": "UI_PDF_SHOW_COMPANY_WEB",
    "values": [
      {
        "iso": "nl",
        "value": "Toon bedrijfs website"
      },
      {
        "iso": "en",
        "value": "Show company website"
      }
    ]
  },
  {
    "key": "UI_PDF_SHOW_SALES_PERSON_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Toon contactgegevens van de verkoper"
      },
      {
        "iso": "en",
        "value": "Show contact infromation from the sales person"
      }
    ]
  },
  {
    "key": "UI_PDF_SHOW_CONTACT_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Toon naam van de contactpersoon"
      },
      {
        "iso": "en",
        "value": "Show name of the contact person"
      }
    ]
  },
  {
    "key": "UI_PDF_SHOW_CONTACT_EMAIL",
    "values": [
      {
        "iso": "nl",
        "value": "Toon e-mailadres van de contactpersoon"
      },
      {
        "iso": "en",
        "value": "Show email address of the contact person"
      }
    ]
  },
  {
    "key": "UI_PDF_SHOW_CONTACT_TEL",
    "values": [
      {
        "iso": "nl",
        "value": "Toon telefoon nummer van de contactpersoon"
      },
      {
        "iso": "en",
        "value": "Show phone number of the contact person"
      }
    ]
  },
  {
    "key": "UI_PDF_SHOW_GENERATION_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Toon generatie melding"
      },
      {
        "iso": "en",
        "value": "Show generation message"
      }
    ]
  },
  {
    "key": "UI_PDF_SHOW_PAGE_NUMBERS",
    "values": [
      {
        "iso": "nl",
        "value": "Toon pagina nummers"
      },
      {
        "iso": "en",
        "value": "Show page numbers"
      }
    ]
  },
  {
    "key": "UI_CANT_DELETE_CONFIRMED_INVOICES_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Verwijderen niet mogelijk door reeds bevestigde facturen in uw selectie: {{0}}."
      },
      {
        "iso": "en",
        "value": "Deletion isn't possible due to confirmed invoices in your selection: {{0}}."
      }
    ]
  },
  {
    "key": "UI_CANT_DELETE_CONFIRMED_CREDITNOTES_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Verwijderen niet mogelijk door reeds bevestigde creditnota's in uw selectie: {{0}}."
      },
      {
        "iso": "en",
        "value": "Deletion isn't possible due to confirmed creditnotes in your selection: {{0}}."
      }
    ]
  },
  {
    "key": "UI_LICENSE",
    "values": [
      {
        "iso": "nl",
        "value": "Licentie"
      },
      {
        "iso": "en",
        "value": "License"
      }
    ]
  },
  {
    "key": "UI_MY_LICENSE",
    "values": [
      {
        "iso": "nl",
        "value": "Mijn licentie"
      },
      {
        "iso": "en",
        "value": "My license"
      }
    ]
  },
  {
    "key": "UI_TOTAL_PRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Totale prijs"
      },
      {
        "iso": "en",
        "value": "Total price"
      }
    ]
  },
  {
    "key": "UI_LICENSE_PRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Pakket prijs"
      },
      {
        "iso": "en",
        "value": "packet price"
      }
    ]
  },
  {
    "key": "UI_OPTIONS_PRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Opties prijs"
      },
      {
        "iso": "en",
        "value": "Option price"
      }
    ]
  },
  {
    "key": "UI_USERS_PRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Gebruikers prijs"
      },
      {
        "iso": "en",
        "value": "User price"
      }
    ]
  },
  {
    "key": "UI_MORE_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Meer informatie"
      },
      {
        "iso": "en",
        "value": "More information"
      }
    ]
  },
  {
    "key": "UI_SYNCHRONISE",
    "values": [
      {
        "iso": "nl",
        "value": "Synchroniseren"
      },
      {
        "iso": "en",
        "value": "Synchronise"
      }
    ]
  },
  {
    "key": "UI_COMPLETE_SYNRONISATION",
    "values": [
      {
        "iso": "nl",
        "value": "Synchronisatie voltooien"
      },
      {
        "iso": "en",
        "value": "Complete synchronisation"
      }
    ]
  },
  {
    "key": "UI_NOT_IN_SYSTEM",
    "values": [
      {
        "iso": "nl",
        "value": "Niet gevonden in het systeem"
      },
      {
        "iso": "en",
        "value": "Not found in the system"
      }
    ]
  },
  {
    "key": "UI_NOT_IN_OCTOPUS",
    "values": [
      {
        "iso": "nl",
        "value": "Niet gevonden in Octopus"
      },
      {
        "iso": "en",
        "value": "Not found in octopus"
      }
    ]
  },
  {
    "key": "UI_TO_OCTOPUS",
    "values": [
      {
        "iso": "nl",
        "value": "Naar Octopus"
      },
      {
        "iso": "en",
        "value": "To Octopus"
      }
    ]
  },
  {
    "key": "UI_TO_SYSTEM",
    "values": [
      {
        "iso": "nl",
        "value": "Naar het systeem"
      },
      {
        "iso": "en",
        "value": "To the system"
      }
    ]
  },
  {
    "key": "UI_ACTION",
    "values": [
      {
        "iso": "nl",
        "value": "Actie"
      },
      {
        "iso": "en",
        "value": "Action"
      }
    ]
  },
  {
    "key": "UI_ONLY_WHEN_NO_CONTACT_SELECTED",
    "values": [
      {
        "iso": "nl",
        "value": "Alleen gebruiken wanneer geen contactpersoon is gebruikt"
      },
      {
        "iso": "en",
        "value": "Only use this e-mail when no contactperson is used"
      }
    ]
  },
  {
    "key": "UI_EX_EMAIL_RECEIVERS_NONE_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn nog geen ontvangers geregistreerd."
      },
      {
        "iso": "en",
        "value": "No receivers have been registered yet."
      }
    ]
  },
  {
    "key": "UI_EX_EMAIL_ATTACHMENT_NONE_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn nog geen bijlages geregistreerd."
      },
      {
        "iso": "en",
        "value": "No attachments have been registered yet."
      }
    ]
  },
  {
    "key": "UI_INVOICE_CREATED_FROM_SUBSCRIPTION_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "Abonnement"
      },
      {
        "iso": "en",
        "value": "Subscription"
      }
    ]
  },
  {
    "key": "UI_INVOICE_CREATED_FROM_SUBSCRIPTION_BODY",
    "values": [
      {
        "iso": "nl",
        "value": "Deze factuur is opgemaakt vanuit abonnement {{0}} '{{1}}'"
      },
      {
        "iso": "en",
        "value": "This invoice has been created from subscription {{0}} '{{1}}'"
      }
    ]
  },
  {
    "key": "UI_ARE_YOU_SURE_CREATE_INVOICE_REMINDER",
    "values": [
      {
        "iso": "nl",
        "value": "Bent u zeker dat u een herinnering voor de relatie '{{0}}' wenst aan te maken? Dit heeft effect op alle openstaande verkoop facturen van deze relatie."
      },
      {
        "iso": "en",
        "value": "Are you sure you want to create a reminder for the relation '{{0}}'? This has an effect on all outstanding sales invoices of this relation."
      }
    ]
  },
  {
    "key": "UI_CONSULT",
    "values": [
      {
        "iso": "nl",
        "value": "Raadpleeg"
      },
      {
        "iso": "en",
        "value": "Consult"
      }
    ]
  },
  {
    "key": "UI_UNLINK",
    "values": [
      {
        "iso": "nl",
        "value": "Link verbreken"
      },
      {
        "iso": "en",
        "value": "Unlink"
      }
    ]
  },
  {
    "key": "UI_INVOICE_REMINDERS",
    "values": [
      {
        "iso": "nl",
        "value": "Herinneringen"
      },
      {
        "iso": "en",
        "value": "Reminders"
      }
    ]
  },
  {
    "key": "UI_SALES_INVOICE_REMINDERS",
    "values": [
      {
        "iso": "nl",
        "value": "Herinneringen voor verkoop facturen"
      },
      {
        "iso": "en",
        "value": "Reminders for sales invoices"
      }
    ]
  },
  {
    "key": "UI_TABLE_VARIABLES",
    "values": [
      {
        "iso": "nl",
        "value": "Filters"
      },
      {
        "iso": "en",
        "value": "Filters"
      }
    ]
  },
  {
    "key": "UI_REMINDER",
    "values": [
      {
        "iso": "nl",
        "value": "Herinnering"
      },
      {
        "iso": "en",
        "value": "Reminder"
      }
    ]
  },
  {
    "key": "UI_REMINDER_LEVEL",
    "values": [
      {
        "iso": "nl",
        "value": "Herinneringsniveau"
      },
      {
        "iso": "en",
        "value": "Reminders level"
      }
    ]
  },
  {
    "key": "UI_OVERDUE",
    "values": [
      {
        "iso": "nl",
        "value": "Onbetaald"
      },
      {
        "iso": "en",
        "value": "Overdue"
      }
    ]
  },
  {
    "key": "UI_OUTSTANDING_SALES_INVOICES",
    "values": [
      {
        "iso": "nl",
        "value": "Openstaande verkoop facturen"
      },
      {
        "iso": "en",
        "value": "Outstanding sales invoices"
      }
    ]
  },
  {
    "key": "UI_TOTAL_VALUE",
    "values": [
      {
        "iso": "nl",
        "value": "Totaal bedrag"
      },
      {
        "iso": "en",
        "value": "Total value"
      }
    ]
  },
  {
    "key": "UI_TOTAL_VALUE_OUTSTANDING",
    "values": [
      {
        "iso": "nl",
        "value": "Openstaand bedrag"
      },
      {
        "iso": "en",
        "value": "Outstanding value"
      }
    ]
  },
  {
    "key": "UI_TOTAL_VALUE_OVERDUE",
    "values": [
      {
        "iso": "nl",
        "value": "Totaal vervallen bedrag"
      },
      {
        "iso": "en",
        "value": "Total overdue value"
      }
    ]
  },
  {
    "key": "UI_LOADING_PDF",
    "values": [
      {
        "iso": "nl",
        "value": "Document aan het laden..."
      },
      {
        "iso": "en",
        "value": "Loading document..."
      }
    ]
  },
  {
    "key": "UI_LETS_START",
    "values": [
      {
        "iso": "nl",
        "value": "Laten we beginnen"
      },
      {
        "iso": "en",
        "value": "Let's start"
      }
    ]
  },
  {
    "key": "UI_ONBOARDING_VAT_NUMBER_INVALID",
    "values": [
      {
        "iso": "nl",
        "value": "Uw BTW nummer '{{0}}' wordt niet automatisch herkent door het europese VIES systeem. Controlleer uw BTW nummer op fouten en ga door om de gegevens handmatig in te vullen. Wenst u door te gaan?"
      },
      {
        "iso": "en",
        "value": "Your VAT number '{{0}}' is not automatically recognized by the European VIES system. Please check your VAT number for errors and proceed to enter the details manually. Do you wish to continue?"
      }
    ]
  },
  {
    "key": "UI_ONBOARDING_SUBSCRIBER_COMPANY_GENERAL",
    "values": [
      {
        "iso": "nl",
        "value": "<p>Zonder bedrijf, geen werk. Laten we dan ook even beginnen met de basisgegevens van uw zaak.<br />Alle overige instellingen alsook meerdere zaken toevoegen zijn aanpasbaar binnen de HR instellingen van de applicatie.</p>"
      },
      {
        "iso": "en",
        "value": "<p>No company, no work. So let's start with the basic details of your case.<br />All other settings as well as adding multiple cases are adjustable within the HR settings of the application.</p>"
      }
    ]
  },
  {
    "key": "UI_ONBOARDING_SUBSCRIBER_COMPANY_ADDRESS",
    "values": [
      {
        "iso": "nl",
        "value": "<p>We slaagden er niet in uw BTW nummer te valideren. Hierdoor vragen we nog even uw adres in te vullen.<br />De adresgegevens zullen gebruikt worden voor uw facturen en andere officiele documenten.</p>"
      },
      {
        "iso": "en",
        "value": "<p>We were unable to validate your VAT number. We therefore ask you to fill in your address.<br />The address details will be used for your invoices and other official documents.</p>"
      }
    ]
  },
  {
    "key": "UI_ONBOARDING_SUBSCRIBER_ADMIN_USER",
    "values": [
      {
        "iso": "nl",
        "value": "<p>Nu is het tijd voor uzelf! Laten we uw account aanmaken. <br />Met dit account kunt u inloggen in het programma. Opgelet, dit account krijgt de volledige administrator rechten tot uw bedrijfsdossier en kan alles beheren.</p>"
      },
      {
        "iso": "en",
        "value": "<p>Now it's time for yourself! Let's create your account. <br />With this account you can log in to the program. Attention, this account gets full administrator rights to your company file and can manage everything.</p>"
      }
    ]
  },
  {
    "key": "UI_ONBOARDING_SUBSCRIBER_ADMIN_USER_EMAIL",
    "values": [
      {
        "iso": "nl",
        "value": "<p>Het versturen van e-mails kan gemakkelijk via het systeem gebeuren, wenst u toch een betere en veiligere mailervaring?<br />Dan kunt u hieronder geavanceerde e-mail gegevens instellen om ervoor te zorgen dat e-mails via uw mail server verstuurd worden.</p>"
      },
      {
        "iso": "en",
        "value": "<p>Sending e-mails can easily be done via the system, do you still want a better and more secure e-mail experience?<br />Then you can set advanced e-mail data below to ensure that e-mails are sent via your e-mail. server.</p>"
      }
    ]
  },
  {
    "key": "UI_ONBOARDING_SUBSCRIBER_FINISH",
    "values": [
      {
        "iso": "nl",
        "value": "<p>Bedankt voor het invullen van de installatie wizard!<br />We zijn momenteel druk bezig uw bedrijfsdossier aan te maken en in te stellen. Even geduld...</p>"
      },
      {
        "iso": "en",
        "value": "<p>Thank you for completing the installation wizard!<br />We are currently busy creating and setting up your company file. Please wait...</p>"
      }
    ]
  },
  {
    "key": "UI_ONBOARDING_SUBSCRIBER_FINISH_NOTIFICATION",
    "values": [
      {
        "iso": "nl",
        "value": "De registratie van uw bedrijfsdossier is voltooid. U kunt nu inloggen met uw gekozen e-mailadres en wachtwoord."
      },
      {
        "iso": "en",
        "value": "The registration of your company file has been completed. You can now log in with your chosen e-mail address and password."
      }
    ]
  },
  {
    "key": "UI_ONBOARDING_USER_INTRO",
    "values": [
      {
        "iso": "nl",
        "value": "<p>Hey {{0}}, <br /><br />We zijn blij u te mogen verwelkomen tot het bedrijfsprofiel van '{{1}}'! <br />Laten we direct van start gaan. Met behulp van deze installatiewizard zullen we uw gebruiker aanmaken.</p>"
      },
      {
        "iso": "en",
        "value": "<p>Hi {{0}}, <br /><br />We are pleased to welcome you in the compony profile of '{{1}}'! <br />Let's get started right away. With the help of this installation wizard we will create your account.</p>"
      }
    ]
  },
  {
    "key": "UI_ONBOARDING_USER_PASSWORD",
    "values": [
      {
        "iso": "nl",
        "value": "<p>Zoals in vele online portalen wordt uw account beveiligd doormiddel van een geheim wachtwoord.<br />Om de veiligheid van uw account en de bedrijfsgegevens te garanderen vragen we een lang en complex wachtwoord te gebruiken.<br />Een lang wachtwoord zoals 'IkHouVanKippensoepEnEetDit2KeerPerWeek!' is veiliger dan een kort complex wachtoord '1Q1asDq'<br />Een combinatie van beide is het beste.</p>"
      },
      {
        "iso": "en",
        "value": "<p>As in many online portals, your account is protected by a secret password.<br />To guarantee the security of your account and company data, we ask you to use a long and complex password.<br />A long password such as 'I LoveChicken SoupAndEatThis2TimesA Week!' is more secure than a short complex password '1Q1asDq'<br />A combination of both is best.</p>"
      }
    ]
  },
  {
    "key": "UI_ONBOARDING_USER_EMAIL",
    "values": [
      {
        "iso": "nl",
        "value": "<p>Het versturen van e-mails kan gemakkelijk via het systeem gebeuren, wenst u toch een betere en veiligere mailervaring?<br />Dan kunt u hieronder geavanceerde e-mail gegevens instellen om ervoor te zorgen dat e-mails via uw mail server verstuurd worden.</p>"
      },
      {
        "iso": "en",
        "value": "<p>Sending e-mails can easily be done via the system, do you still want a better and more secure e-mail experience?<br />Then you can set advanced e-mail data below to ensure that e-mails are sent via your e-mail. server.</p>"
      }
    ]
  },
  {
    "key": "UI_ONBOARDING_USER_FINISH",
    "values": [
      {
        "iso": "nl",
        "value": "<p>Bedankt voor het invullen van de installatie wizard!<br />We zijn momenteel druk bezig uw gebruiker in te stellen. Even geduld...</p>"
      },
      {
        "iso": "en",
        "value": "<p>Thank you for completing the installation wizard!<br />We are currently busy setting up your account. Please wait...</p>"
      }
    ]
  },
  {
    "key": "UI_ONBOARDING_USER_FINISH_NOTIFICATION",
    "values": [
      {
        "iso": "nl",
        "value": "De registratie van uw gebruiker is voltooid. U kunt nu inloggen met uw gekozen wachtwoord op het bedrijf '{{0}}'."
      },
      {
        "iso": "en",
        "value": "The registration of your account has been completed. You can now log in with your chosen password for the company '{{0}}'."
      }
    ]
  },
  {
    "key": "UI_ENTITY_TYPE_DEFAULT_PRIVATE_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Een particuliere relatie."
      },
      {
        "iso": "en",
        "value": "A private relation"
      }
    ]
  },
  {
    "key": "UI_ENTITY_TYPE_DEFAULT_PRIVATE_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Particulier"
      },
      {
        "iso": "en",
        "value": "Private"
      }
    ]
  },
  {
    "key": "UI_ENTITY_TYPE_DEFAULT_PROFESSIONAL_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Een professionele relatie die beschikt over een geldig BTW nummer in zijn land."
      },
      {
        "iso": "en",
        "value": "A professional relation with a valid VAT number in it's country."
      }
    ]
  },
  {
    "key": "UI_ENTITY_TYPE_DEFAULT_PROFESSIONAL_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Professioneel"
      },
      {
        "iso": "en",
        "value": "Professional"
      }
    ]
  },
  {
    "key": "UI_ENTITY_TYPE_DEFAULT_BV_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Besloten vennootschap"
      },
      {
        "iso": "en",
        "value": "Private company"
      }
    ]
  },
  {
    "key": "UI_ENTITY_TYPE_DEFAULT_BV_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "BV"
      },
      {
        "iso": "en",
        "value": "BV"
      }
    ]
  },
  {
    "key": "UI_ENTITY_TYPE_DEFAULT_NV_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Naamloze vennootschap"
      },
      {
        "iso": "en",
        "value": "Limited company"
      }
    ]
  },
  {
    "key": "UI_ENTITY_TYPE_DEFAULT_NV_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "NV"
      },
      {
        "iso": "en",
        "value": "NV"
      }
    ]
  },
  {
    "key": "UI_ENTITY_TYPE_DEFAULT_CV_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Coöperatieve vennootschap"
      },
      {
        "iso": "en",
        "value": "Cooperative company"
      }
    ]
  },
  {
    "key": "UI_ENTITY_TYPE_DEFAULT_CV_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "CV"
      },
      {
        "iso": "en",
        "value": "CV"
      }
    ]
  },
  {
    "key": "UI_VATRATE_DEFAULT_21_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Het standaard BTW tarief in België van 21%."
      },
      {
        "iso": "en",
        "value": "The default VAT rate in Belgium of 21%"
      }
    ]
  },
  {
    "key": "UI_VATRATE_DEFAULT_21_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Standaard BTW tarief"
      },
      {
        "iso": "en",
        "value": "Default VAT rate"
      }
    ]
  },
  {
    "key": "UI_VATRATE_DEFAULT_12_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Catering, Sociale woningbouw, margarine, ..."
      },
      {
        "iso": "en",
        "value": "Catering, Social building, ..."
      }
    ]
  },
  {
    "key": "UI_VATRATE_DEFAULT_12_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "BTW tarief aan 12%"
      },
      {
        "iso": "en",
        "value": "VAT rate at 12%"
      }
    ]
  },
  {
    "key": "UI_VATRATE_DEFAULT_6_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Voedingsproducten, water, medicijnen, boeken, transport, ..."
      },
      {
        "iso": "en",
        "value": "Food, water, medicin, books, transport, ..."
      }
    ]
  },
  {
    "key": "UI_VATRATE_DEFAULT_6_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "BTW tarief aan 6%"
      },
      {
        "iso": "en",
        "value": "VAT rate at 6%"
      }
    ]
  },
  {
    "key": "UI_VATRATE_DEFAULT_0_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Periodieke publicaties, ..."
      },
      {
        "iso": "en",
        "value": "Periodic publications, ..."
      }
    ]
  },
  {
    "key": "UI_VATRATE_DEFAULT_0_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Vrijgesteld van BTW"
      },
      {
        "iso": "en",
        "value": "VAT exempt"
      }
    ]
  },
  {
    "key": "UI_VATRATE_DEFAULT_INTRA_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Intracommunautaire BTW van 0% voor professionele verkopers / aankopers van de Europese unie leden buiten eigen land."
      },
      {
        "iso": "en",
        "value": "Inta VAT of 0% for professional sales / purchases of European unie members outside of your own country."
      }
    ]
  },
  {
    "key": "UI_VATRATE_DEFAULT_INTRA_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Intracommunautaire BTW"
      },
      {
        "iso": "en",
        "value": "Intra VAT"
      }
    ]
  },
  {
    "key": "UI_VATRATE_DEFAULT_RENOVATION_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Verminderd BTW tarief van 6% voor renovaties."
      },
      {
        "iso": "en",
        "value": "Reduced VAT rate of 6% for renovations."
      }
    ]
  },
  {
    "key": "UI_VATRATE_DEFAULT_RENOVATION_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "6% Renovatie"
      },
      {
        "iso": "en",
        "value": "6% Renovation"
      }
    ]
  },
  {
    "key": "UI_VATRATE_DEFAULT_COCONTRACTOR_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Verlegging van heffing wegens medecontractant."
      },
      {
        "iso": "en",
        "value": "Deferral of vat due to co-contractor."
      }
    ]
  },
  {
    "key": "UI_VATRATE_DEFAULT_COCONTRACTOR_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Medecontractant"
      },
      {
        "iso": "en",
        "value": "Co-Contractor"
      }
    ]
  },
  {
    "key": "UI_QUARTER",
    "values": [
      {
        "iso": "nl",
        "value": "Kwartaal"
      },
      {
        "iso": "en",
        "value": "Quarter"
      }
    ]
  },
  {
    "key": "UI_ALL",
    "values": [
      {
        "iso": "nl",
        "value": "Alle"
      },
      {
        "iso": "en",
        "value": "All"
      }
    ]
  },
  {
    "key": "UI_SALES_INVOICEREMINDERS",
    "values": [
      {
        "iso": "nl",
        "value": "Herinneringen"
      },
      {
        "iso": "en",
        "value": "Reminders"
      }
    ]
  },
  {
    "key": "UI_SHOW_OPEN",
    "values": [
      {
        "iso": "nl",
        "value": "Toon openstaande"
      },
      {
        "iso": "en",
        "value": "Show open"
      }
    ]
  },
  {
    "key": "UI_LICENSES",
    "values": [
      {
        "iso": "nl",
        "value": "Licenties"
      },
      {
        "iso": "en",
        "value": "Licenses"
      }
    ]
  },
  {
    "key": "UI_EDIT_CONFIRMED_SALES_INVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "De verkoop factuur is reeds bevestigd. Sommige aanpassingen kunnen legale en functionele gevolgen hebben, gelieve deze factuur enkel aan te passen na goedkeuring van uw boekhoudkundig adviseur! Doorgaan?"
      },
      {
        "iso": "en",
        "value": "The sales invoice has already been confirmed. Some adjustments may have legal and functional consequences, please only adjust this invoice after approval from your accounting advisor! Continue?"
      }
    ]
  },
  {
    "key": "UI_EDIT_CONFIRMED_PURCHASE_INVOICE_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoopfactuur bewerken"
      },
      {
        "iso": "en",
        "value": "Edit purchase invoice"
      }
    ]
  },
  {
    "key": "UI_EDIT_CONFIRMED_PURCHASE_INVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "De aankoop factuur is reeds bevestigd. Sommige aanpassingen kunnen legale en functionele gevolgen hebben, gelieve deze factuur enkel aan te passen na goedkeuring van uw boekhoudkundig adviseur! Doorgaan?"
      },
      {
        "iso": "en",
        "value": "The purchase invoice has already been confirmed. Some adjustments may have legal and functional consequences, please only adjust this invoice after approval from your accounting advisor! Continue?"
      }
    ]
  },
  {
    "key": "UI_EDIT_CONFIRMED_SALES_CREDITNOTE_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoopcreditnota bewerken"
      },
      {
        "iso": "en",
        "value": "Edit credit note"
      }
    ]
  },
  {
    "key": "UI_EDIT_CONFIRMED_SALES_CREDITNOTE",
    "values": [
      {
        "iso": "nl",
        "value": "De verkoop creditnota is reeds bevestigd. Sommige aanpassingen kunnen legale en functionele gevolgen hebben, gelieve deze creditnota enkel aan te passen na goedkeuring van uw boekhoudkundig adviseur! Doorgaan?"
      },
      {
        "iso": "en",
        "value": "The sales creditnote has already been confirmed. Some adjustments may have legal and functional consequences, please only adjust this creditnote after approval from your accounting advisor! Continue?"
      }
    ]
  },
  {
    "key": "UI_EDIT_CONFIRMED_PURCHASE_CREDITNOTE",
    "values": [
      {
        "iso": "nl",
        "value": "De aankoop creditnota is reeds bevestigd. Sommige aanpassingen kunnen legale en functionele gevolgen hebben, gelieve deze creditnota enkel aan te passen na goedkeuring van uw boekhoudkundig adviseur! Doorgaan?"
      },
      {
        "iso": "en",
        "value": "The purchase creditnote has already been confirmed. Some adjustments may have legal and functional consequences, please only adjust this creditnote after approval from your accounting advisor! Continue?"
      }
    ]
  },
  {
    "key": "UI_EDIT_CONFIRMED_PURCHASE_CREDITNOTE_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "Creditnota bewerken"
      },
      {
        "iso": "en",
        "value": "Edit creditnote"
      }
    ]
  },
  {
    "key": "UI_RESELLER",
    "values": [
      {
        "iso": "nl",
        "value": "Reseller"
      },
      {
        "iso": "en",
        "value": "Reseller"
      }
    ]
  },
  {
    "key": "UI_ONBOARDINGS",
    "values": [
      {
        "iso": "nl",
        "value": "Aanmeldingen"
      },
      {
        "iso": "en",
        "value": "Onboardings"
      }
    ]
  },
  {
    "key": "UI_SUBSCRIBERS",
    "values": [
      {
        "iso": "nl",
        "value": "Abonnee's"
      },
      {
        "iso": "en",
        "value": "Subscribers"
      }
    ]
  },
  {
    "key": "UI_EXPIRATION_DATE",
    "values": [
      {
        "iso": "nl",
        "value": "Vervaldatum"
      },
      {
        "iso": "en",
        "value": "Expiration date"
      }
    ]
  },
  {
    "key": "UI_USED_ON",
    "values": [
      {
        "iso": "nl",
        "value": "Gebruikt op"
      },
      {
        "iso": "en",
        "value": "Used on"
      }
    ]
  },
  {
    "key": "UI_SUBSCRIBER_ONBOARDINGS",
    "values": [
      {
        "iso": "nl",
        "value": "Abonee aanmeldingen"
      },
      {
        "iso": "en",
        "value": "Subscriber onboardings"
      }
    ]
  },
  {
    "key": "UI_SUBSCRIBER_ONBOARDING",
    "values": [
      {
        "iso": "nl",
        "value": "Aanmeldingen"
      },
      {
        "iso": "en",
        "value": "Onboardings"
      }
    ]
  },
  {
    "key": "UI_ADD_SUBSCRIBER_ONBOARDING",
    "values": [
      {
        "iso": "nl",
        "value": "Aanmelding toevoegen"
      },
      {
        "iso": "en",
        "value": "Add onboarding"
      }
    ]
  },
  {
    "key": "UI_DELETE_SUBSCRIBER_ONBOARDINGS",
    "values": [
      {
        "iso": "nl",
        "value": "Aanmeldingen verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete onboarding"
      }
    ]
  },
  {
    "key": "UI_SEND_ONBOARDING_EMAIL",
    "values": [
      {
        "iso": "nl",
        "value": "Verstuur onboarding email"
      },
      {
        "iso": "en",
        "value": "Send onboarding email"
      }
    ]
  },
  {
    "key": "UI_SET_ACCOUNTANCY_AS_YOURSELF",
    "values": [
      {
        "iso": "nl",
        "value": "Mijzelf instellen als boekhouder"
      },
      {
        "iso": "en",
        "value": "Set myself as accountant"
      }
    ]
  },
  {
    "key": "UI_VALIDATION_PERIOD",
    "values": [
      {
        "iso": "nl",
        "value": "Validatie period"
      },
      {
        "iso": "en",
        "value": "Validation period"
      }
    ]
  },
  {
    "key": "UI_ONBOARDING_LINK",
    "values": [
      {
        "iso": "nl",
        "value": "Aanmeldings url"
      },
      {
        "iso": "en",
        "value": "Onboarding url"
      }
    ]
  },
  {
    "key": "UI_COPIED_TO_CLIPBOARD",
    "values": [
      {
        "iso": "nl",
        "value": "Gekopieerd naar klembord"
      },
      {
        "iso": "en",
        "value": "Copied to clipboard"
      }
    ]
  },
  {
    "key": "UI_FAILED_TO_COPY_TO_CLIPBOARD",
    "values": [
      {
        "iso": "nl",
        "value": "Kopieren naar klembord mislukt!"
      },
      {
        "iso": "en",
        "value": "Copy to clipboard failed!"
      }
    ]
  },
  {
    "key": "UI_SHOW_USED",
    "values": [
      {
        "iso": "nl",
        "value": "Toon gebruikte"
      },
      {
        "iso": "en",
        "value": "Show used"
      }
    ]
  },
  {
    "key": "UI_LANGUAGE_OF_EMAIL",
    "values": [
      {
        "iso": "nl",
        "value": "Taal van de e-mail"
      },
      {
        "iso": "en",
        "value": "Language of the email"
      }
    ]
  },
  {
    "key": "UI_NAVIGATE_TO_PAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Navigeren naar hoofdpagina"
      },
      {
        "iso": "en",
        "value": "Navigate to main page"
      }
    ]
  },
  {
    "key": "UI_SIGNATURE",
    "values": [
      {
        "iso": "nl",
        "value": "Handtekening"
      },
      {
        "iso": "en",
        "value": "Signature"
      }
    ]
  },
  {
    "key": "UI_ADD_SIGNATURE",
    "values": [
      {
        "iso": "nl",
        "value": "Handtekening"
      },
      {
        "iso": "en",
        "value": "Signature"
      }
    ]
  },
  {
    "key": "UI_ARE_YOU_SURE_TO_DELETE_THE_SIGNATURE",
    "values": [
      {
        "iso": "nl",
        "value": "Bent u zeker dat u de handtekening wenst te verwijderen? Deze actie kan niet ongedaan gemaakt worden!"
      },
      {
        "iso": "en",
        "value": "Ãre you sure you want to delete this signature? This action can't be made undone!"
      }
    ]
  },
  {
    "key": "UI_RESET_PASSWORD_ACTIVE_USER_REQUEST",
    "values": [
      {
        "iso": "nl",
        "value": "Bevestig het resetten van jouw eigen wachtwoord. Je zal automatisch uitgelogd worden na het bevestigen en een e-mail ontvangen om de reset procedure te voltooien."
      },
      {
        "iso": "en",
        "value": "Confirm the reset of your own password. You will be automatically logged out after confirming and will receive an email to complete the reset procedure."
      }
    ]
  },
  {
    "key": "UI_ACTIVE_PSD2_LINK",
    "values": [
      {
        "iso": "nl",
        "value": "Active PSD2 link"
      },
      {
        "iso": "en",
        "value": "Active PSD2 link"
      }
    ]
  },
  {
    "key": "UI_RESET_PASSWORD_USER_REQUEST",
    "values": [
      {
        "iso": "nl",
        "value": "Bevestig het resetten van het wachtoord voor deze gebruiker. De gebruiker krijgt een e-mail om de reset procedure te voltooien."
      },
      {
        "iso": "en",
        "value": "Confirm the reset of the password for this user. The user will receive an email to complete the reset procedure."
      }
    ]
  },
  {
    "key": "UI_SYSTEM_PREFERENCES",
    "values": [
      {
        "iso": "nl",
        "value": "Systeem voorkeuren"
      },
      {
        "iso": "en",
        "value": "System preferences"
      }
    ]
  },
  {
    "key": "UI_THEME",
    "values": [
      {
        "iso": "nl",
        "value": "Thema"
      },
      {
        "iso": "en",
        "value": "Theme"
      }
    ]
  },
  {
    "key": "UI_DARK",
    "values": [
      {
        "iso": "nl",
        "value": "Donker"
      },
      {
        "iso": "en",
        "value": "Dark"
      }
    ]
  },
  {
    "key": "UI_LIGHT",
    "values": [
      {
        "iso": "nl",
        "value": "Licht"
      },
      {
        "iso": "en",
        "value": "Light"
      }
    ]
  },
  {
    "key": "UI_ACCEPT_QUOTE",
    "values": [
      {
        "iso": "nl",
        "value": "Offerte goedkeuren"
      },
      {
        "iso": "en",
        "value": "Accept quote"
      }
    ]
  },
  {
    "key": "UI_ACCEPT_QUOTE_MODAL_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "Offerte '{{0}}' goedkeuren"
      },
      {
        "iso": "en",
        "value": "Accept quote '{{0}}'"
      }
    ]
  },
  {
    "key": "UI_DECLINE_QUOTE_MODAL_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "Offerte '{{0}}' weigeren"
      },
      {
        "iso": "en",
        "value": "Decline quote '{{0}}'"
      }
    ]
  },
  {
    "key": "UI_DECLINE_QUOTE",
    "values": [
      {
        "iso": "nl",
        "value": "Offerte weigeren"
      },
      {
        "iso": "en",
        "value": "Decline quote"
      }
    ]
  },
  {
    "key": "UI_NAME_AND_SIGNATURE_IS_REQUIRED_FOR_GUEST_ACCEPTING_A_QUOTE",
    "values": [
      {
        "iso": "nl",
        "value": "Het is verplicht om uw naam in te vullen en de offerte te ondertekenen om deze goed te keuren!"
      },
      {
        "iso": "en",
        "value": "You are required to enter your name and sign the quote to accept it!"
      }
    ]
  },
  {
    "key": "UI_NAME_AND_SIGNATURE_IS_REQUIRED_FOR_GUEST_DECLINING_A_QUOTE",
    "values": [
      {
        "iso": "nl",
        "value": "Het is verplicht om uw naam in te vullen en de offerte te ondertekenen om deze te weigeren!"
      },
      {
        "iso": "en",
        "value": "You are required to enter your name and sign the quote to decline it!"
      }
    ]
  },
  {
    "key": "UI_QUOTE_HAS_BEEN_ACCEPTED",
    "values": [
      {
        "iso": "nl",
        "value": "De offerte is bevestigd."
      },
      {
        "iso": "en",
        "value": "The quotation has been accepted."
      }
    ]
  },
  {
    "key": "UI_QUOTE_HAS_BEEN_DECLINED",
    "values": [
      {
        "iso": "nl",
        "value": "De offerte is geweigerd."
      },
      {
        "iso": "en",
        "value": "The quotation has been declined."
      }
    ]
  },
  {
    "key": "UI_GUEST_QUOTE_ACCEPTED_BY",
    "values": [
      {
        "iso": "nl",
        "value": "De offerte is geaccepteerd door {{0}} op {{1}}."
      },
      {
        "iso": "en",
        "value": "The quotation has been accepted by {{0}} on {{1}}."
      }
    ]
  },
  {
    "key": "UI_GUEST_QUOTE_ACCEPTED",
    "values": [
      {
        "iso": "nl",
        "value": "De offerte is geaccepteerd."
      },
      {
        "iso": "en",
        "value": "The quotation has been accepted."
      }
    ]
  },
  {
    "key": "UI_GUEST_QUOTE_DECLINED_BY",
    "values": [
      {
        "iso": "nl",
        "value": "De offerte is geweigerd door {{0}} op {{1}}."
      },
      {
        "iso": "en",
        "value": "The quotation has been declined by {{0}} on {{1}}."
      }
    ]
  },
  {
    "key": "UI_GUEST_QUOTE_DECLINED",
    "values": [
      {
        "iso": "nl",
        "value": "De offerte is geweigerd."
      },
      {
        "iso": "en",
        "value": "The quotation has been declined."
      }
    ]
  },
  {
    "key": "UI_CREATE_INVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "Factuur aanmaken"
      },
      {
        "iso": "en",
        "value": "Create invoice"
      }
    ]
  },
  {
    "key": "UI_ARE_YOU_SURE_TO_CREATE_INVOICE_FROM_SUBSCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Factuur creëren op basis van dit abonnement? De volgende factuurdatum blijft ongewijzigd."
      },
      {
        "iso": "en",
        "value": "Create an invoice based on this subscription? The next invoice date remains unchanged."
      }
    ]
  },
  {
    "key": "UI_ACCOUNTANCY",
    "values": [
      {
        "iso": "nl",
        "value": "Boekhouding"
      },
      {
        "iso": "en",
        "value": "Accountancy"
      }
    ]
  },
  {
    "key": "UI_PAYMENT",
    "values": [
      {
        "iso": "nl",
        "value": "Betaling"
      },
      {
        "iso": "en",
        "value": "Payment"
      }
    ]
  },
  {
    "key": "UI_LEGEND",
    "values": [
      {
        "iso": "nl",
        "value": "Legende"
      },
      {
        "iso": "en",
        "value": "Legend"
      }
    ]
  },
  {
    "key": "UI_EMAIL_LEGEND_CONTACT_NAME_DEFAULTS_TO_COMPANY_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Wanneer geen contactpersoon beschikbaar is wordt de klantnaam gebruikt."
      },
      {
        "iso": "en",
        "value": "When no contact person is available, the customer name will be used."
      }
    ]
  },
  {
    "key": "UI_ADDRESS_STREET",
    "values": [
      {
        "iso": "nl",
        "value": "Straatnaam"
      },
      {
        "iso": "en",
        "value": "Streetname"
      }
    ]
  },
  {
    "key": "UI_ADDRESS_NUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "Huisnummer"
      },
      {
        "iso": "en",
        "value": "house number"
      }
    ]
  },
  {
    "key": "UI_ADDRESS_ZIP",
    "values": [
      {
        "iso": "nl",
        "value": "Postcode"
      },
      {
        "iso": "en",
        "value": "Postal code"
      }
    ]
  },
  {
    "key": "UI_ADDRESS_CITY",
    "values": [
      {
        "iso": "nl",
        "value": "Stad"
      },
      {
        "iso": "en",
        "value": "City"
      }
    ]
  },
  {
    "key": "UI_LOGO",
    "values": [
      {
        "iso": "nl",
        "value": "Logo"
      },
      {
        "iso": "en",
        "value": "Logo"
      }
    ]
  },
  {
    "key": "UI_GUEST_PUBLIC_LINK",
    "values": [
      {
        "iso": "nl",
        "value": "Publieke gasten url"
      },
      {
        "iso": "en",
        "value": "Public guest link"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT",
    "values": [
      {
        "iso": "nl",
        "value": "Document"
      },
      {
        "iso": "en",
        "value": "Document"
      }
    ]
  },
  {
    "key": "UI_GUEST_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Gast informatie"
      },
      {
        "iso": "en",
        "value": "Guest information"
      }
    ]
  },
  {
    "key": "UI_GUEST_LINK",
    "values": [
      {
        "iso": "nl",
        "value": "Externe gasten url"
      },
      {
        "iso": "en",
        "value": "External guest url"
      }
    ]
  },
  {
    "key": "UI_GUEST_REMARK",
    "values": [
      {
        "iso": "nl",
        "value": "Interne opmerking van klant voor verkoper"
      },
      {
        "iso": "en",
        "value": "Internal remark of customer for supplier"
      }
    ]
  },
  {
    "key": "UI_GUEST_REMARK",
    "values": [
      {
        "iso": "nl",
        "value": "Interne opmerking van klant voor verkoper"
      },
      {
        "iso": "en",
        "value": "Internal remark of customer for supplier"
      }
    ]
  },
  {
    "key": "UI_ACCEPTED_BY",
    "values": [
      {
        "iso": "nl",
        "value": "Geaccepteerd door"
      },
      {
        "iso": "en",
        "value": "Accepted by"
      }
    ]
  },
  {
    "key": "UI_DECLINED_BY",
    "values": [
      {
        "iso": "nl",
        "value": "Afgewezen door"
      },
      {
        "iso": "en",
        "value": "Declined by"
      }
    ]
  },
  {
    "key": "UI_QUOTE_ACCEPTED_BY",
    "values": [
      {
        "iso": "nl",
        "value": "De offerte {{0}} is goedgekeurd door {{1}}."
      },
      {
        "iso": "en",
        "value": "The quote {{0}} has been accepted by {{1}}."
      }
    ]
  },
  {
    "key": "UI_QUOTE_DECLINED_BY",
    "values": [
      {
        "iso": "nl",
        "value": "De offerte {{0}} is afgewezen door {{1}}."
      },
      {
        "iso": "en",
        "value": "The quote {{0}} has been declined by {{1}}."
      }
    ]
  },
  {
    "key": "UI_DEFAULT_RELATION_INVOICE_REMINDER",
    "values": [
      {
        "iso": "nl",
        "value": "Factuur herinnering instellingen"
      },
      {
        "iso": "en",
        "value": "Invoice reminder settings"
      }
    ]
  },
  {
    "key": "UI_INVOICE_REMINDER_PROFORMA",
    "values": [
      {
        "iso": "nl",
        "value": "Proforma betaalherinnering"
      },
      {
        "iso": "en",
        "value": "Proforma payment reminder"
      }
    ]
  },
  {
    "key": "UI_INVOICE_REMINDER",
    "values": [
      {
        "iso": "nl",
        "value": "Betaalherinnering"
      },
      {
        "iso": "en",
        "value": "Payment reminder"
      }
    ]
  },
  {
    "key": "UI_INVOICE_REMINDER_LEVEL_ONE",
    "values": [
      {
        "iso": "nl",
        "value": "Eerste herinneringsniveau"
      },
      {
        "iso": "en",
        "value": "First Reminder level"
      }
    ]
  },
  {
    "key": "UI_INVOICE_REMINDER_LEVEL_TWO",
    "values": [
      {
        "iso": "nl",
        "value": "Tweede herinneringsniveau"
      },
      {
        "iso": "en",
        "value": "Second reminder level"
      }
    ]
  },
  {
    "key": "UI_INVOICE_REMINDER_LEVEL_THREE",
    "values": [
      {
        "iso": "nl",
        "value": "Derde en laatste herinneringsniveau"
      },
      {
        "iso": "en",
        "value": "third and last reminder level"
      }
    ]
  },
  {
    "key": "UI_RELATION_INVOICE_REMINDER_SURPRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Bijkomende vaste administratiekost"
      },
      {
        "iso": "en",
        "value": "Additional fixed administrationcost"
      }
    ]
  },
  {
    "key": "UI_RELATION_INVOICE_REMINDER_SURPERCENTAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Bijkomende variabele administratiekost in percentage"
      },
      {
        "iso": "en",
        "value": "Additional variable administrationcost in percentage"
      }
    ]
  },
  {
    "key": "UI_RELATION_INVOICE_REMINDER_MIN",
    "values": [
      {
        "iso": "nl",
        "value": "Minimale administratiekost"
      },
      {
        "iso": "en",
        "value": "Minimum administrationcost"
      }
    ]
  },
  {
    "key": "UI_RELATION_INVOICE_REMINDER_MAX",
    "values": [
      {
        "iso": "nl",
        "value": "Maximale administratiekost"
      },
      {
        "iso": "en",
        "value": "Maximum administrationcost"
      }
    ]
  },
  {
    "key": "UI_RELATION_INVOICE_REMINDER_GRACE_PERIOD",
    "values": [
      {
        "iso": "nl",
        "value": "Respijtperiode"
      },
      {
        "iso": "en",
        "value": "Grace period"
      }
    ]
  },
  {
    "key": "UI_RELATION_INVOICE_REMINDER_TIME_SPAN",
    "values": [
      {
        "iso": "nl",
        "value": "Aantal dagen tegen automatische niveauverhoging"
      },
      {
        "iso": "en",
        "value": "Amount of days until automatic level increasement"
      }
    ]
  },
  {
    "key": "UI_DEFAULT_RELATION_INVOICE_REMINDER_FORCE_TO_RELATIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Standaard waardes toepassen op meerdere of alle realties"
      },
      {
        "iso": "en",
        "value": "Force default values to multiple or all relations"
      }
    ]
  },
  {
    "key": "UI_UPDATE_ALL_RELATIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Alle relaties wijzigen"
      },
      {
        "iso": "en",
        "value": "Update all relations"
      }
    ]
  },
  {
    "key": "UI_UPDATE_SELECTED_RELATIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Geselecteerde relaties wijzigen"
      },
      {
        "iso": "en",
        "value": "Update selected relations"
      }
    ]
  },
  {
    "key": "UI_UPDATED_AMOUNT_OF_RELATIONS",
    "values": [
      {
        "iso": "nl",
        "value": "{{0}} relaties zijn bewerkt."
      },
      {
        "iso": "en",
        "value": "{{0}} relations has been updated."
      }
    ]
  },
  {
    "key": "UI_AUTO_CREATE_INVOICE_REMINDERS_FOR_OVERDUE_INVOICES",
    "values": [
      {
        "iso": "nl",
        "value": "Creëer automatisch factuur herinneringen aan voor vervallen facturen"
      },
      {
        "iso": "en",
        "value": "Automatically create invoice reminders for overdue invoices"
      }
    ]
  },
  {
    "key": "UI_ADMINISTRATION_COST",
    "values": [
      {
        "iso": "nl",
        "value": "Administratieve kost"
      },
      {
        "iso": "en",
        "value": "Administration cost"
      }
    ]
  },
  {
    "key": "UI_ARE_YOU_SURE_TO_CONFIRM_INVOICE_REMINDER_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "Herinnering bevestigen"
      },
      {
        "iso": "en",
        "value": "Confirm reminder"
      }
    ]
  },
  {
    "key": "UI_ARE_YOU_SURE_TO_CONFIRM_INVOICE_REMINDER",
    "values": [
      {
        "iso": "nl",
        "value": "Eenmaal bevestigd kan de herinnering niet langer gewist worden en krijgt deze een gekoppeld herinneringsnummer ter beschikking."
      },
      {
        "iso": "en",
        "value": "Once confirmed, the reminder cannot be erased anymore and is assigned a linked reminder number."
      }
    ]
  },
  {
    "key": "UI_ARE_YOU_SURE_TO_SUCCEED_INVOICE_REMINDER_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "Herinneringsniveau verhogen"
      },
      {
        "iso": "en",
        "value": "Increase reminder level"
      }
    ]
  },
  {
    "key": "UI_ARE_YOU_SURE_TO_SUCCEED_INVOICE_REMINDER",
    "values": [
      {
        "iso": "nl",
        "value": "Het niveau zal (vroegtijdig) verhoogd worden. Deze actie kan niet meer ongedaan gemaakt worden!"
      },
      {
        "iso": "en",
        "value": "The level will be raised (prematurely). This action cannot be undone anymore!"
      }
    ]
  },
  {
    "key": "UI_INCREASE_LEVEL",
    "values": [
      {
        "iso": "nl",
        "value": "Verhoog niveau"
      },
      {
        "iso": "en",
        "value": "Increase level"
      }
    ]
  },
  {
    "key": "UI_EX_TRANSACTIONS_NONE_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Geen transacties gevonden."
      },
      {
        "iso": "en",
        "value": "No transactions found."
      }
    ]
  },
  {
    "key": "UI_SUBLINE_SAVE_INVALID",
    "values": [
      {
        "iso": "nl",
        "value": "Sublijn '{{0}}' bevat fouten."
      },
      {
        "iso": "en",
        "value": "Subline '{{0}}' contains errors."
      }
    ]
  },
  {
    "key": "UI_SUBLINE_SAVE_INVALID_UNKNOWN",
    "values": [
      {
        "iso": "nl",
        "value": "Sublijn bevat fouten."
      },
      {
        "iso": "en",
        "value": "Subline contains errors."
      }
    ]
  },
  {
    "key": "UI_CONTACT_SAVE_INVALID",
    "values": [
      {
        "iso": "nl",
        "value": "Contactpersoon '{{0}}' bevat fouten."
      },
      {
        "iso": "en",
        "value": "Contact person '{{0}}' contains errors."
      }
    ]
  },
  {
    "key": "UI_CONTACT_SAVE_INVALID_UNKNOWN",
    "values": [
      {
        "iso": "nl",
        "value": "Contactpersoon bevat fouten."
      },
      {
        "iso": "en",
        "value": "Contact person contains errors."
      }
    ]
  },
  {
    "key": "UI_BANKACCOUNT_SAVE_INVALID",
    "values": [
      {
        "iso": "nl",
        "value": "Rekeningnummer '{{0}}' bevat fouten."
      },
      {
        "iso": "en",
        "value": "Bank account '{{0}}' contains errors."
      }
    ]
  },
  {
    "key": "UI_BANKACCOUNT_SAVE_INVALID_UNKNOWN",
    "values": [
      {
        "iso": "nl",
        "value": "Rekeningnummer bevat fouten."
      },
      {
        "iso": "en",
        "value": "Bank account contains errors."
      }
    ]
  },
  {
    "key": "UI_EMAIL_SAVE_INVALID",
    "values": [
      {
        "iso": "nl",
        "value": "E-mailadres bevat fouten."
      },
      {
        "iso": "en",
        "value": "Emailaddress contains errors."
      }
    ]
  },
  {
    "key": "UI_PHONE_SAVE_INVALID",
    "values": [
      {
        "iso": "nl",
        "value": "Telefoonnummer bevat fouten."
      },
      {
        "iso": "en",
        "value": "Phone number person contains errors."
      }
    ]
  },
  {
    "key": "UI_ADDRESS_SAVE_INVALID",
    "values": [
      {
        "iso": "nl",
        "value": "Adres bevat fouten."
      },
      {
        "iso": "en",
        "value": "Address person contains errors."
      }
    ]
  },
  {
    "key": "UI_TRANSLOCATION_SAVE_INVALID",
    "values": [
      {
        "iso": "nl",
        "value": "Verplaatsing met datum '{{0}}' bevat fouten."
      },
      {
        "iso": "en",
        "value": "Translocation with date '{{0}}' contains errors."
      }
    ]
  },
  {
    "key": "UI_TRANSLOCATION_SAVE_INVALID_UNKNOWN",
    "values": [
      {
        "iso": "nl",
        "value": "Verplaatsing bevat fouten."
      },
      {
        "iso": "en",
        "value": "Translocation contains errors."
      }
    ]
  },
  {
    "key": "UI_TRANSACTION_SAVE_INVALID",
    "values": [
      {
        "iso": "nl",
        "value": "Transactie met datum '{{0}}' bevat fouten."
      },
      {
        "iso": "en",
        "value": "Transaction with date '{{0}}' contains errors."
      }
    ]
  },
  {
    "key": "UI_TRANSACTION_SAVE_INVALID_UNKNOWN",
    "values": [
      {
        "iso": "nl",
        "value": "Transactie bevat fouten."
      },
      {
        "iso": "en",
        "value": "Transaction contains errors."
      }
    ]
  },
  {
    "key": "UI_SUBLINE_SAVE_NONE",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen sublijnen gedetecteerd!"
      },
      {
        "iso": "en",
        "value": "No sublines have been found!"
      }
    ]
  },
  {
    "key": "UI_CREDITNOTE_SAVE_ERROR",
    "values": [
      {
        "iso": "nl",
        "value": "De creditnota kan niet worden opgeslagen in het systeem."
      },
      {
        "iso": "en",
        "value": "The creditnote can't be saved in the system."
      }
    ]
  },
  {
    "key": "UI_CREDITNOTE_SAVE_WARNING",
    "values": [
      {
        "iso": "nl",
        "value": "De creditnota bevat enkele waarschuwingen die gevaarlijk kunnen zijn. Bent u zeker dat u de creditnota wilt opslaan?"
      },
      {
        "iso": "en",
        "value": "The creditnote contains errors that can be dangerous. Are you sure you want to save this creditnote?"
      }
    ]
  },
  {
    "key": "UI_RELATION_SAVE_ERROR",
    "values": [
      {
        "iso": "nl",
        "value": "De relatie kan niet worden opgeslagen in het systeem."
      },
      {
        "iso": "en",
        "value": "The relation can't be saved in the system."
      }
    ]
  },
  {
    "key": "UI_RELATION_SAVE_WARNING",
    "values": [
      {
        "iso": "nl",
        "value": "De relatie bevat enkele waarschuwingen die gevaarlijk kunnen zijn. Bent u zeker dat u de relatie wilt opslaan?"
      },
      {
        "iso": "en",
        "value": "The relation contains errors that can be dangerous. Are you sure you want to save this relation?"
      }
    ]
  },
  {
    "key": "UI_COMPANY_SAVE_ERROR",
    "values": [
      {
        "iso": "nl",
        "value": "Het bedrijf kan niet worden opgeslagen in het systeem."
      },
      {
        "iso": "en",
        "value": "The company can't be saved in the system."
      }
    ]
  },
  {
    "key": "UI_COMPANY_SAVE_WARNING",
    "values": [
      {
        "iso": "nl",
        "value": "Het bedrijf bevat enkele waarschuwingen die gevaarlijk kunnen zijn. Bent u zeker dat u het bedrijf wilt opslaan?"
      },
      {
        "iso": "en",
        "value": "The company contains errors that can be dangerous. Are you sure you want to save this company?"
      }
    ]
  },
  {
    "key": "UI_INVOICE_SAVE_ERROR",
    "values": [
      {
        "iso": "nl",
        "value": "De factuur kan niet worden opgeslagen in het systeem."
      },
      {
        "iso": "en",
        "value": "The invoice can't be saved in the system."
      }
    ]
  },
  {
    "key": "UI_INVOICE_CONFIRM_ERROR",
    "values": [
      {
        "iso": "nl",
        "value": "De factuur kan niet worden bevestigd wegens fouten."
      },
      {
        "iso": "en",
        "value": "The invoice can't be confirmed due to errors."
      }
    ]
  },
  {
    "key": "UI_INVOICE_SAVE_WARNING",
    "values": [
      {
        "iso": "nl",
        "value": "De factuur bevat enkele waarschuwingen die gevaarlijk kunnen zijn. Bent u zeker dat u de factuur wilt opslaan?"
      },
      {
        "iso": "en",
        "value": "The invoice contains errors that can be dangerous. Are you sure you want to save this invoice?"
      }
    ]
  },
  {
    "key": "UI_ORDER_SAVE_ERROR",
    "values": [
      {
        "iso": "nl",
        "value": "De bestellingen kan niet worden opgeslagen in het systeem."
      },
      {
        "iso": "en",
        "value": "The order can't be saved in the system."
      }
    ]
  },
  {
    "key": "UI_ORDER_SAVE_WARNING",
    "values": [
      {
        "iso": "nl",
        "value": "De bestelling bevat enkele waarschuwingen die gevaarlijk kunnen zijn. Bent u zeker dat u de bestelling wilt opslaan?"
      },
      {
        "iso": "en",
        "value": "The order contains errors that can be dangerous. Are you sure you want to save this order?"
      }
    ]
  },
  {
    "key": "UI_PRODUCTDELIVERY_SAVE_ERROR",
    "values": [
      {
        "iso": "nl",
        "value": "De levering kan niet worden opgeslagen in het systeem."
      },
      {
        "iso": "en",
        "value": "The delivery can't be saved in the system."
      }
    ]
  },
  {
    "key": "UI_PRODUCTDELIVERY_CONFIRM_ERROR",
    "values": [
      {
        "iso": "nl",
        "value": "De levering kan niet worden bevestigd wegens fouten."
      },
      {
        "iso": "en",
        "value": "The delivery can't be confirmed due to errors."
      }
    ]
  },
  {
    "key": "UI_PRODUCTDELIVERY_SAVE_WARNING",
    "values": [
      {
        "iso": "nl",
        "value": "De levering bevat enkele waarschuwingen die gevaarlijk kunnen zijn. Bent u zeker dat u de levering wilt opslaan?"
      },
      {
        "iso": "en",
        "value": "The delivery contains errors that can be dangerous. Are you sure you want to save this delivery?"
      }
    ]
  },
  {
    "key": "UI_PRODUCTRECEPTION_SAVE_ERROR",
    "values": [
      {
        "iso": "nl",
        "value": "De receptie kan niet worden opgeslagen in het systeem."
      },
      {
        "iso": "en",
        "value": "The reception can't be saved in the system."
      }
    ]
  },
  {
    "key": "UI_PRODUCTRECEPTION_CONFIRM_ERROR",
    "values": [
      {
        "iso": "nl",
        "value": "De receptie kan niet worden bevestigd wegens fouten."
      },
      {
        "iso": "en",
        "value": "The reception can't be confirmed due to errors."
      }
    ]
  },
  {
    "key": "UI_PRODUCTRECEPTION_SAVE_WARNING",
    "values": [
      {
        "iso": "nl",
        "value": "De receptie bevat enkele waarschuwingen die gevaarlijk kunnen zijn. Bent u zeker dat u de receptie wilt opslaan?"
      },
      {
        "iso": "en",
        "value": "The reception contains errors that can be dangerous. Are you sure you want to save this reception?"
      }
    ]
  },
  {
    "key": "UI_QUOTE_SAVE_ERROR",
    "values": [
      {
        "iso": "nl",
        "value": "De offete kan niet worden opgeslagen in het systeem."
      },
      {
        "iso": "en",
        "value": "The quotation can't be saved in the system."
      }
    ]
  },
  {
    "key": "UI_QUOTE_SAVE_WARNING",
    "values": [
      {
        "iso": "nl",
        "value": "De offerte bevat enkele waarschuwingen die gevaarlijk kunnen zijn. Bent u zeker dat u de offerte wilt opslaan?"
      },
      {
        "iso": "en",
        "value": "The quotation contains errors that can be dangerous. Are you sure you want to save this quotation?"
      }
    ]
  },
  {
    "key": "UI_COMBINEDARTICLE_SAVE_ERROR",
    "values": [
      {
        "iso": "nl",
        "value": "Het gecombineerde artikel kan niet worden opgeslagen in het systeem."
      },
      {
        "iso": "en",
        "value": "The combined article can't be saved in the system."
      }
    ]
  },
  {
    "key": "UI_COMBINEDARTICLE_SAVE_WARNING",
    "values": [
      {
        "iso": "nl",
        "value": "Het gecombineerde artikel bevat enkele waarschuwingen die gevaarlijk kunnen zijn. Bent u zeker dat u het gecombineerde artikel wilt opslaan?"
      },
      {
        "iso": "en",
        "value": "The combined article contains errors that can be dangerous. Are you sure you want to save this combined article?"
      }
    ]
  },
  {
    "key": "UI_SUBSCRIPTION_SAVE_ERROR",
    "values": [
      {
        "iso": "nl",
        "value": "Het abonnement kan niet worden opgeslagen in het systeem."
      },
      {
        "iso": "en",
        "value": "The subscription can't be saved in the system."
      }
    ]
  },
  {
    "key": "UI_SUBSCRIPTION_SAVE_WARNING",
    "values": [
      {
        "iso": "nl",
        "value": "Het abonnement bevat enkele waarschuwingen die gevaarlijk kunnen zijn. Bent u zeker dat u het abonnement wilt opslaan?"
      },
      {
        "iso": "en",
        "value": "The subscription contains errors that can be dangerous. Are you sure you want to save this subscription?"
      }
    ]
  },
  {
    "key": "UI_WORKPERFORMANCE_SAVE_ERROR",
    "values": [
      {
        "iso": "nl",
        "value": "De werkprestatie kan niet worden opgeslagen in het systeem."
      },
      {
        "iso": "en",
        "value": "The workperformance can't be saved in the system."
      }
    ]
  },
  {
    "key": "UI_WORKPERFORMANCE_SAVE_WARNING",
    "values": [
      {
        "iso": "nl",
        "value": "De werkprestatie bevat enkele waarschuwingen die gevaarlijk kunnen zijn. Bent u zeker dat u de werkprestatie wilt opslaan?"
      },
      {
        "iso": "en",
        "value": "The workperformance contains errors that can be dangerous. Are you sure you want to save this workperformance?"
      }
    ]
  },
  {
    "key": "UI_OK",
    "values": [
      {
        "iso": "nl",
        "value": "Ok"
      },
      {
        "iso": "en",
        "value": "Ok"
      }
    ]
  },
  {
    "key": "UI_WORKPERFORMANCE_SAVE_WITHOUT_RELATION",
    "values": [
      {
        "iso": "nl",
        "value": "Er is geen relatie gevonden voor deze werkprestatie. Een relatie is verplicht en werkprestatie zonder relatie word enke aangeraden voor concept doeleindes."
      },
      {
        "iso": "en",
        "value": "No relationship was found for this work performance. A relationship is mandatory and work performance without a relationship is recommended for concept purposes only."
      }
    ]
  },
  {
    "key": "UI_WORKPERFORMANCE_SAVE_INCOMPLETE",
    "values": [
      {
        "iso": "nl",
        "value": "De werkprestatie is incompleet en kan hierdoor enkel als concept gebruikt worden."
      },
      {
        "iso": "en",
        "value": "The work performance is incomplete and can therefore only be used as a concept."
      }
    ]
  },
  {
    "key": "UI_PRODUCT_DELIVERY_SUBLINE_SAVE_NO_LINKED_STOCK",
    "values": [
      {
        "iso": "nl",
        "value": "Sublijn '{{0}}' is niet geregistreerd als stock en zal hierdoor het stock aantal van dit product niet aanpassen!"
      },
      {
        "iso": "en",
        "value": "Subline '{{0}}' isn't registered as stock and will not update the stock of this product!"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_TYPE",
    "values": [
      {
        "iso": "nl",
        "value": "Document type"
      },
      {
        "iso": "en",
        "value": "Document type"
      }
    ]
  },
  {
    "key": "UI_PURCHASE_ORDER",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoop bestelling"
      },
      {
        "iso": "en",
        "value": "Purchase order"
      }
    ]
  },
  {
    "key": "UI_PRODUCT_DELIVERY",
    "values": [
      {
        "iso": "nl",
        "value": "Levering van een product"
      },
      {
        "iso": "en",
        "value": "Product delivery"
      }
    ]
  },
  {
    "key": "UI_DEVELOPER",
    "values": [
      {
        "iso": "nl",
        "value": "Ontwikkelaar"
      },
      {
        "iso": "en",
        "value": "Developer"
      }
    ]
  },
  {
    "key": "UI_API_KEY",
    "values": [
      {
        "iso": "nl",
        "value": "API sleutel"
      },
      {
        "iso": "en",
        "value": "API key"
      }
    ]
  },
  {
    "key": "UI_AUTHENTICATION",
    "values": [
      {
        "iso": "nl",
        "value": "Authenticatie"
      },
      {
        "iso": "en",
        "value": "Authentication"
      }
    ]
  },
  {
    "key": "UI_MODAL_APIKEY_SECRET_KEY_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "Geheime sleutel"
      },
      {
        "iso": "en",
        "value": "Secret key"
      }
    ]
  },
  {
    "key": "UI_MODAL_APIKEY_SECRET_KEY_BODY",
    "values": [
      {
        "iso": "nl",
        "value": "De geheime sleutel wordt slechts één maal getoond. Gelieve deze veilig te noteren alvorens op te slaan."
      },
      {
        "iso": "en",
        "value": "The secret key is displayed only once. Please make sure to securely note it before saving"
      }
    ]
  },
  {
    "key": "UI_MODAL_REMOVE_DATA_DOUBLE_CONFIRM",
    "values": [
      {
        "iso": "nl",
        "value": "Ik ben zeker dat ik deze data definitief wil wissen"
      },
      {
        "iso": "en",
        "value": "I am certain that I want to permanently erase this data."
      }
    ]
  },
  {
    "key": "UI_API_KEYS",
    "values": [
      {
        "iso": "nl",
        "value": "API sleutels"
      },
      {
        "iso": "en",
        "value": "API keys"
      }
    ]
  },
  {
    "key": "UI_ADD_API_KEY",
    "values": [
      {
        "iso": "nl",
        "value": "API sleutel toevoegen"
      },
      {
        "iso": "en",
        "value": "Add API key"
      }
    ]
  },
  {
    "key": "UI_DELETE_API_KEYS",
    "values": [
      {
        "iso": "nl",
        "value": "API sleutels verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete API key"
      }
    ]
  },
  {
    "key": "UI_LAST_USED",
    "values": [
      {
        "iso": "nl",
        "value": "Laatst gebruikt"
      },
      {
        "iso": "en",
        "value": "Last used"
      }
    ]
  },
  {
    "key": "UI_SAVE_WORKPERFORMANCE_WITHOUT_CUSTOMER",
    "values": [
      {
        "iso": "nl",
        "value": "Een prestatie dient altijd gelinked te worden aan een klant, het opslaan van een prestatie zonder klant is dan ook enkel voor concept doeleindes waarvan verplicht is deze op een later tijdstip in orde te brengen. Indien u geen klant aangeeft voor deze prestatie kunt u hem ook niet gebruiken in overige documenten. Opgelet, de statistieken rekenen deze prestatie mee in alle berekeningen!"
      },
      {
        "iso": "en",
        "value": "A performance must always be linked to a customer, so saving a performance without a customer is only for draft purposes for which it is mandatory to correct it at a later date. If you do not indicate a customer for this service, you cannot use him in other documents. Attention, the statistics include this performance in all calculations!"
      }
    ]
  },
  {
    "key": "UI_UNKNOWN",
    "values": [
      {
        "iso": "nl",
        "value": "Onbekend"
      },
      {
        "iso": "en",
        "value": "Unknown"
      }
    ]
  },
  {
    "key": "UI_PUBLIC_KEY",
    "values": [
      {
        "iso": "nl",
        "value": "Publieke sleutel"
      },
      {
        "iso": "en",
        "value": "Public key"
      }
    ]
  },
  {
    "key": "UI_SECRET_KEY",
    "values": [
      {
        "iso": "nl",
        "value": "Geheime sleutel"
      },
      {
        "iso": "en",
        "value": "Secret key"
      }
    ]
  },
  {
    "key": "UI_INTEGRATION_API_KEY_WARNING",
    "values": [
      {
        "iso": "nl",
        "value": "De geheime sleutel is slechts nu beschikbaar. Eenmaal opgeslagen kan deze niet meer bezichtigd worden! Zorg dus dat de geheime sleutel veilig bewaard is alvorens verder te gaan. Wenst u de API sleutel te bewaren?"
      },
      {
        "iso": "en",
        "value": "The secret key is only available now. Once saved, it can no longer be viewed! So make sure the secret key is kept safe before continuing. Do you want to save the API key?"
      }
    ]
  },
  {
    "key": "UI_IMPORT_SELECTED",
    "values": [
      {
        "iso": "nl",
        "value": "Importeer selectie"
      },
      {
        "iso": "en",
        "value": "Import selection"
      }
    ]
  },
  {
    "key": "UI_REPLACE_INVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "Factuur wijzigen"
      },
      {
        "iso": "en",
        "value": "Replace invoice"
      }
    ]
  },
  {
    "key": "UI_ADD_PDF_INVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "PDF factuur toevoegen"
      },
      {
        "iso": "en",
        "value": "Add PDF invoice"
      }
    ]
  },
  {
    "key": "UI_REPLACE_PDF_CREDITNOTE",
    "values": [
      {
        "iso": "nl",
        "value": "Creditnota wijzigen"
      },
      {
        "iso": "en",
        "value": "Replace creditnote"
      }
    ]
  },
  {
    "key": "UI_ADD_PDF_CREDITNOTE",
    "values": [
      {
        "iso": "nl",
        "value": "Creditnota toevoegen"
      },
      {
        "iso": "en",
        "value": "Add creditnote"
      }
    ]
  },
  {
    "key": "UI_SAVE_WORKPERFORMANCE_INCOMPLETE",
    "values": [
      {
        "iso": "nl",
        "value": "Deze werkprestatie is incompleet, het gebruik van een incomplete prestatie is gevaarlijk en wordt enkel aangeraden als lopende opdracht die bij voltooing wordt afgewerkt te gebruiken."
      },
      {
        "iso": "en",
        "value": "This work performance is incomplete, the use of an incomplete performance is dangerous and is recommended to be used only as an ongoing job to be completed upon completion."
      }
    ]
  },
  {
    "key": "UI_SET_START_TIME",
    "values": [
      {
        "iso": "nl",
        "value": "Starttijd instellen"
      },
      {
        "iso": "en",
        "value": "Set start time"
      }
    ]
  },
  {
    "key": "UI_SET_END_TIME",
    "values": [
      {
        "iso": "nl",
        "value": "Eindtijd instellen"
      },
      {
        "iso": "en",
        "value": "Set end time"
      }
    ]
  },
  {
    "key": "UI_ACCOUNTANT_HAS_SETUP_YOUR_OCTOPUS_ACCOUNTANCY_INTIGRATION",
    "values": [
      {
        "iso": "nl",
        "value": "Uw boekhouder heeft de integratie voor \"Octopus boekhouden\" ingesteld voor uw abonnement."
      },
      {
        "iso": "en",
        "value": "Your accountant has set up the integration for \"Octopus accountancy\" for your subscription."
      }
    ]
  },
  {
    "key": "UI_ACCOUNTANT_HAS_UNLINKED_YOUR_OCTOPUS_ACCOUNTANCY_INTIGRATION",
    "values": [
      {
        "iso": "nl",
        "value": "Uw boekhouder heeft de integratie voor \"Octopus boekhouden\" verwijderd voor uw abonnement."
      },
      {
        "iso": "en",
        "value": "Your accountant has unlinked the integration for \"Octopus accountancy\" for your subscription."
      }
    ]
  },
  {
    "key": "UI_ACCOUNTANT_HAS_LINKED_YOUR_ADMISOL_INTEGRATION",
    "values": [
      {
        "iso": "nl",
        "value": "Uw boekhouder heeft de integratie voor \"Admisol\" ingesteld voor uw abonnement."
      },
      {
        "iso": "en",
        "value": "Your accountant has set up the integration for \"Admisol\" for your subscription."
      }
    ]
  },
  {
    "key": "UI_ACCOUNTANT_HAS_UNLINKED_YOUR_ADMISOL_INTEGRATION",
    "values": [
      {
        "iso": "nl",
        "value": "Uw boekhouder heeft de integratie voor \"Admisol\" verwijderd voor uw abonnement."
      },
      {
        "iso": "en",
        "value": "Your accountant has unlinked the integration for \"Admisol\" for your subscription."
      }
    ]
  },
  {
    "key": "UI_ACCOUNTANT_REQUESTED_A_LEDGER_EXPORT",
    "values": [
      {
        "iso": "nl",
        "value": "Uw boekhouder heeft een boekhoud export aangevraagd."
      },
      {
        "iso": "en",
        "value": "Your accountant has requested a ledger export."
      }
    ]
  },
  {
    "key": "UI_NO_ACTIVE_LICENSE_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Geen actieve licentie gevonden"
      },
      {
        "iso": "en",
        "value": "No active license found"
      }
    ]
  },
  {
    "key": "UI_ACTIVE_LICENSE_UNTIL",
    "values": [
      {
        "iso": "nl",
        "value": "Licentie vervalt op {{0}}"
      },
      {
        "iso": "en",
        "value": "License expires on {{0}}"
      }
    ]
  },
  {
    "key": "UI_LICENSE_EXPIRED_NO_RIGHTS_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Er is geen actieve licentie gevonden en dit account beschikt niet over administrator rechten om een nieuwe licentie aan te kopen. Gelieve in te loggen met een administrator account of uw verkooppunt te contacteren om de licentie te verlengen."
      },
      {
        "iso": "en",
        "value": "No active license was found and this account does not have administrator rights to purchase a new license. Please log in with an administrator account or contact your seller to renew the license."
      }
    ]
  },
  {
    "key": "UI_LICENSE_USERLIMIT_EXCEEDED",
    "values": [
      {
        "iso": "nl",
        "value": "Gebruikerslimiet overschreden"
      },
      {
        "iso": "en",
        "value": "Userlimit exceeded"
      }
    ]
  },
  {
    "key": "UI_USERS_AMOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Gebruikersaantal"
      },
      {
        "iso": "en",
        "value": "Amount of users"
      }
    ]
  },
  {
    "key": "UI_CURRENT_EXPIRATION_DATE",
    "values": [
      {
        "iso": "nl",
        "value": "Huidige vervaldatum"
      },
      {
        "iso": "en",
        "value": "Current expirationdate"
      }
    ]
  },
  {
    "key": "UI_NEW_EXPIRATION_DATE",
    "values": [
      {
        "iso": "nl",
        "value": "Nieuwe vervaldatum"
      },
      {
        "iso": "en",
        "value": "New expirationdate"
      }
    ]
  },
  {
    "key": "UI_TOTAL_PRICE_EXCL_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Totale prijs excl. BTW"
      },
      {
        "iso": "en",
        "value": "Total price excl. VAT"
      }
    ]
  },
  {
    "key": "UI_TOTAL_PRICE_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "BTW bedrag"
      },
      {
        "iso": "en",
        "value": "VAT amount"
      }
    ]
  },
  {
    "key": "UI_TOTAL_PRICE_INCL_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Totale prijs incl. BTW"
      },
      {
        "iso": "en",
        "value": "Total price incl. VAT"
      }
    ]
  },
  {
    "key": "UI_PROLONGUE_LICENSE",
    "values": [
      {
        "iso": "nl",
        "value": "Huidige licentie verlengen"
      },
      {
        "iso": "en",
        "value": "Prolongue current license"
      }
    ]
  },
  {
    "key": "UI_CHANGE_LICENSE",
    "values": [
      {
        "iso": "nl",
        "value": "Licentie wijzigen"
      },
      {
        "iso": "en",
        "value": "Change license"
      }
    ]
  },
  {
    "key": "UI_CONFIRM_CHANGED_LICENSE",
    "values": [
      {
        "iso": "nl",
        "value": "Gewijzigde licentie bevestigen"
      },
      {
        "iso": "en",
        "value": "Confirm changed license"
      }
    ]
  },
  {
    "key": "UI_LICENSE_PAYMENTS_AND_INVOICES",
    "values": [
      {
        "iso": "nl",
        "value": "Betalingen & facturen"
      },
      {
        "iso": "en",
        "value": "Payments & invoices"
      }
    ]
  },
  {
    "key": "UI_ACTIVATED_LICENSE_OPTIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Geactiveerde opties"
      },
      {
        "iso": "en",
        "value": "Activated options"
      }
    ]
  },
  {
    "key": "UI_DISABLED_LICENSE_OPTIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Uitgeschakelde opties"
      },
      {
        "iso": "en",
        "value": "Disabled options"
      }
    ]
  },
  {
    "key": "UI_PAY",
    "values": [
      {
        "iso": "nl",
        "value": "Betalen"
      },
      {
        "iso": "en",
        "value": "Pay"
      }
    ]
  },
  {
    "key": "UI_BUY_LICENSE",
    "values": [
      {
        "iso": "nl",
        "value": "Licentie kopen"
      },
      {
        "iso": "en",
        "value": "Buy license"
      }
    ]
  },
  {
    "key": "UI_TOTAL_PRICE_BRUTO",
    "values": [
      {
        "iso": "nl",
        "value": "Totale prijs zonder korting"
      },
      {
        "iso": "en",
        "value": "Total price without discount"
      }
    ]
  },
  {
    "key": "UI_TOTAL_DISCOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Totale korting"
      },
      {
        "iso": "en",
        "value": "Total discount"
      }
    ]
  },
  {
    "key": "UI_ACTIVE_DISCOUNTS",
    "values": [
      {
        "iso": "nl",
        "value": "Actieve kortingen"
      },
      {
        "iso": "en",
        "value": "Active discounts"
      }
    ]
  },
  {
    "key": "UI_NONE",
    "values": [
      {
        "iso": "nl",
        "value": "Geen"
      },
      {
        "iso": "en",
        "value": "None"
      }
    ]
  },
  {
    "key": "UI_LIMITS",
    "values": [
      {
        "iso": "nl",
        "value": "Limieten"
      },
      {
        "iso": "en",
        "value": "Limits"
      }
    ]
  },
  {
    "key": "UI_REMAINING_USAGES",
    "values": [
      {
        "iso": "nl",
        "value": "Resterende toepassingen"
      },
      {
        "iso": "en",
        "value": "Remaining usages"
      }
    ]
  },
  {
    "key": "UI_CLIENT_PORTAL",
    "values": [
      {
        "iso": "nl",
        "value": "Klantenportaal"
      },
      {
        "iso": "en",
        "value": "Client portal"
      }
    ]
  },
  {
    "key": "UI_CLIENT_PORTAL_HAS_OPENEND_IN_NEW_TABLET",
    "values": [
      {
        "iso": "nl",
        "value": "Het klantenportaal is geopend in een nieuw tablad. Opgelet, u kunt slechts één klantenportaal per keer bezichtigen."
      },
      {
        "iso": "en",
        "value": "The client portal has been opened on a new tab. Be aware, you can only open one client portal at a time."
      }
    ]
  },
  {
    "key": "UI_VATCODE",
    "values": [
      {
        "iso": "nl",
        "value": "BTW code"
      },
      {
        "iso": "en",
        "value": "VAT code"
      }
    ]
  },
  {
    "key": "UI_EX_WORKPERFORMANCE_USERS_NONE_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Deze werkprestatie heeft geen meerdere gebruikers"
      },
      {
        "iso": "en",
        "value": "This workprestation has no multiple users!"
      }
    ]
  },
  {
    "key": "UI_EX_WORKPERFORMANCE_EXTRAS_NONE_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Deze werkprestatie heeft geen extra's!"
      },
      {
        "iso": "en",
        "value": "This workprestation has no extras!"
      }
    ]
  },
  {
    "key": "UI_WORKPERFORMANCE_EXTRAS",
    "values": [
      {
        "iso": "nl",
        "value": "Extra's"
      },
      {
        "iso": "en",
        "value": "Extras"
      }
    ]
  },
  {
    "key": "UI_NO_VALID_RECEIVERS_FOUND_TO_CREATE_EMAIL",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen geldige ontvangers gevonden voor het aanmaken van deze e-mail! Gelieve ontvangers toe te voegen of de bestaande ontvangers te controleren op fouten."
      },
      {
        "iso": "en",
        "value": "No valid recipients were found for creating this email! Please add recipients or check existing recipients for errors."
      }
    ]
  },
  {
    "key": "UI_REGISTER",
    "values": [
      {
        "iso": "nl",
        "value": "Registeren"
      },
      {
        "iso": "en",
        "value": "Register"
      }
    ]
  },
  {
    "key": "UI_CLIENT_REGISTRATION_COMPLETED",
    "values": [
      {
        "iso": "nl",
        "value": "We hebben uw aanmelding goed ontvangen! Hou de inbox van '{{0}}' in de gaten, u ontvangt binnen enkele minuten een mailtje met de volgende stappen. Vergeet zeker niet uw spam folder te bekijken indien u de e-mail niet ontvangt binnen enkele minuten."
      },
      {
        "iso": "en",
        "value": "We have successfully received your application! Keep an eye on the inbox of '{{0}}', you will receive an email within a few minutes with the next steps. Don't forget to check your spam folder if you don't receive the email within a few minutes."
      }
    ]
  },
  {
    "key": "UI_ACTIVE_USERS",
    "values": [
      {
        "iso": "nl",
        "value": "Actieve gebruikers"
      },
      {
        "iso": "en",
        "value": "Active users"
      }
    ]
  },
  {
    "key": "UI_FREE_USERS",
    "values": [
      {
        "iso": "nl",
        "value": "Vrije gebruikers"
      },
      {
        "iso": "en",
        "value": "Free users"
      }
    ]
  },
  {
    "key": "UI_TOTAAL_AMOUNT_OF_USERS",
    "values": [
      {
        "iso": "nl",
        "value": "Totaal aantal gebruikers"
      },
      {
        "iso": "en",
        "value": "Total amount of users"
      }
    ]
  },
  {
    "key": "UI_SELECTED",
    "values": [
      {
        "iso": "nl",
        "value": "Geselecteerd"
      },
      {
        "iso": "en",
        "value": "Selected"
      }
    ]
  },
  {
    "key": "UI_SELECT",
    "values": [
      {
        "iso": "nl",
        "value": "Selecteren"
      },
      {
        "iso": "en",
        "value": "Select"
      }
    ]
  },
  {
    "key": "UI_SELECTED",
    "values": [
      {
        "iso": "nl",
        "value": "Geselecteerd"
      },
      {
        "iso": "en",
        "value": "Selected"
      }
    ]
  },
  {
    "key": "UI_PAYMENT_OVERVIEW",
    "values": [
      {
        "iso": "nl",
        "value": "Betaal overzicht"
      },
      {
        "iso": "en",
        "value": "Payment overview"
      }
    ]
  },
  {
    "key": "UI_LICENSE_DOWNGRADE_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Uw licentie wordt vanaf {{0}} aangepast naar '{{1}}' voor {{2}} gebruikers met de opties {{3}}."
      },
      {
        "iso": "en",
        "value": "Your license will be changed to '{{1}}' on {{0}} for {{2}} users with the options {{3}}."
      }
    ]
  },
  {
    "key": "UI_AND",
    "values": [
      {
        "iso": "nl",
        "value": "En"
      },
      {
        "iso": "en",
        "value": "And"
      }
    ]
  },
  {
    "key": "UI_UPGRADE_CURRENT_LICENSE",
    "values": [
      {
        "iso": "nl",
        "value": "Huidige licentie upgraden"
      },
      {
        "iso": "en",
        "value": "Upgrade current license"
      }
    ]
  },
  {
    "key": "UI_UPGRADE_LICENSE",
    "values": [
      {
        "iso": "nl",
        "value": "Licentie upgraden"
      },
      {
        "iso": "en",
        "value": "Upgrade license"
      }
    ]
  },
  {
    "key": "UI_LICENSE_DOWNGRADE_NOT_ALLOWED",
    "values": [
      {
        "iso": "nl",
        "value": "Downgrade niet toegestaan"
      },
      {
        "iso": "en",
        "value": "Downgrade not allowed"
      }
    ]
  },
  {
    "key": "UI_LICENSE_UPGRADE_NOT_POSSIBLE_DUE_TO_PLANNED_DOWNGRADE",
    "values": [
      {
        "iso": "nl",
        "value": "Wegens een geplande downgrade kunt u geen upgrade aanvragen voor uw huidige licentie. Gelieve support te contacteren voor meer informatie of uw opties te bekijken."
      },
      {
        "iso": "en",
        "value": "Due to a planned downgrade, you cannot request an upgrade for your current license. Please contact support for more information or to review your options."
      }
    ]
  },
  {
    "key": "UI_LICENSE_REFUND_BY_UPGRADE",
    "values": [
      {
        "iso": "nl",
        "value": "Refund - Actieve licenties"
      },
      {
        "iso": "en",
        "value": "Refund - Active licenses"
      }
    ]
  },
  {
    "key": "UI_LICENSE_UPGRADE_DISCOUNT_PASSED_PERIOD",
    "values": [
      {
        "iso": "nl",
        "value": "Verlopen periode voor geselecteerde upgrade"
      },
      {
        "iso": "en",
        "value": "Expired period for selected upgrade"
      }
    ]
  },
  {
    "key": "UI_REPEAT_PASSWORD",
    "values": [
      {
        "iso": "nl",
        "value": "Herhaal uw wachtwoord"
      },
      {
        "iso": "en",
        "value": "Repeat your password"
      }
    ]
  },
  {
    "key": "UI_PASSWORD_CHECK_VALID",
    "values": [
      {
        "iso": "nl",
        "value": ""
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "UI_PASSWORD_CHECK_INVALID",
    "values": [
      {
        "iso": "nl",
        "value": "Uw wachtwoorden komen niet overeen"
      },
      {
        "iso": "en",
        "value": "Your passwords do not match"
      }
    ]
  },
  {
    "key": "UI_SAVE_FAILED",
    "values": [
      {
        "iso": "nl",
        "value": "Opslaan mislukt"
      },
      {
        "iso": "en",
        "value": "Saving failed"
      }
    ]
  },
  {
    "key": "UI_DELETE_FAILED",
    "values": [
      {
        "iso": "nl",
        "value": "Verwijderen is mislukt"
      },
      {
        "iso": "en",
        "value": "Delete has failed"
      }
    ]
  },
  {
    "key": "UI_DUPLICATE_FAILED",
    "values": [
      {
        "iso": "nl",
        "value": "Kopieëren is mislukt"
      },
      {
        "iso": "en",
        "value": "Duplication has failed"
      }
    ]
  },
  {
    "key": "UI_CONFIRM_FAILED",
    "values": [
      {
        "iso": "nl",
        "value": "Bevestigen is mislukt"
      },
      {
        "iso": "en",
        "value": "Confirmation has failed"
      }
    ]
  },
  {
    "key": "UI_USER_MESSAGES_READ_ALL_AND_CLOSE",
    "values": [
      {
        "iso": "nl",
        "value": "Alles lezen en sluiten"
      },
      {
        "iso": "en",
        "value": "Read all and close"
      }
    ]
  },
  {
    "key": "UI_CONVERTION_FAILED",
    "values": [
      {
        "iso": "nl",
        "value": "Omzetten is mislukt"
      },
      {
        "iso": "en",
        "value": "Convertion has failed"
      }
    ]
  },
  {
    "key": "UI_INVOICE_EXPORTED_ON_DATE_BY_TYPE_BODY",
    "values": [
      {
        "iso": "nl",
        "value": "Factuur is geëxporteerd op {{0}} via '{{1}}'"
      },
      {
        "iso": "en",
        "value": "Invoice has been exported on {{0}} by '{{1}}'"
      }
    ]
  },
  {
    "key": "UI_INVOICE_EXPORTED_ON_DATE_BY_TYPE_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "Geëxporteerd"
      },
      {
        "iso": "en",
        "value": "Exported"
      }
    ]
  },
  {
    "key": "UI_CREDITNOTE_EXPORTED_ON_DATE_BY_TYPE_BODY",
    "values": [
      {
        "iso": "nl",
        "value": "Creditnota is geëxporteerd op {{0}} via '{{1}}'"
      },
      {
        "iso": "en",
        "value": "Creditnote has been exported on {{0}} by '{{1}}'"
      }
    ]
  },
  {
    "key": "UI_CREDITNOTE_EXPORTED_ON_DATE_BY_TYPE_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "Geëxporteerd"
      },
      {
        "iso": "en",
        "value": "Exported"
      }
    ]
  },
  {
    "key": "UI_PURCHASE_CREDITNOTE",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoopcreditnota"
      },
      {
        "iso": "en",
        "value": "Purchase creditnote"
      }
    ]
  },
  {
    "key": "UI_MEMO",
    "values": [
      {
        "iso": "nl",
        "value": "Memo"
      },
      {
        "iso": "en",
        "value": "Memo"
      }
    ]
  },
  {
    "key": "UI_DEFAULT_TRANSLOCATION_VALUES",
    "values": [
      {
        "iso": "nl",
        "value": "Standaard verplaatsingsvergoeding"
      },
      {
        "iso": "en",
        "value": "Default translocationcost"
      }
    ]
  },
  {
    "key": "UI_IMPORT_TRANSLOCATIONS_AS_ONE_ON_INVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "Verplaatsing als één lijn importeren op verkoopfacturen"
      },
      {
        "iso": "en",
        "value": "Import translocations as one line on sales invoices"
      }
    ]
  },
  {
    "key": "UI_CONVERT_TRANSLOCATIONS_TO_SUBLINE",
    "values": [
      {
        "iso": "nl",
        "value": "Omzetten naar sublijn(en)"
      },
      {
        "iso": "en",
        "value": "Convert to subline(s)"
      }
    ]
  },
  {
    "key": "UI_REPORT",
    "values": [
      {
        "iso": "nl",
        "value": "Rapport"
      },
      {
        "iso": "en",
        "value": "Report"
      }
    ]
  },
  {
    "key": "UI_REPORT_VALUES",
    "values": [
      {
        "iso": "nl",
        "value": "Raport waardes"
      },
      {
        "iso": "en",
        "value": "Report values"
      }
    ]
  },
  {
    "key": "UI_REPORT_WORKPERFORMANCE_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Gedetailleerd prestatie rapport"
      },
      {
        "iso": "en",
        "value": "Detailed prestation report"
      }
    ]
  },
  {
    "key": "UI_REPORT_WORKPERFORMANCE_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Via het gedetailleerde prestatie rapport kunt u al uw prestaties overzichtelijk opvragen in een gegeven periode met onderverdeling binnen de relaties alsook totaal overzichten en extra's."
      },
      {
        "iso": "en",
        "value": "Via the detailed performance report you can view all your performances in a given period with subdivision within the relations as well as total overviews and extras."
      }
    ]
  },
  {
    "key": "UI_REPORT_REVENUE_COMPANIES_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Omzet, kosten en winst per bedrijf"
      },
      {
        "iso": "en",
        "value": "Revenue, costs and gains per company"
      }
    ]
  },
  {
    "key": "UI_DOWNLOAD",
    "values": [
      {
        "iso": "nl",
        "value": "Downloaden"
      },
      {
        "iso": "en",
        "value": "Download"
      }
    ]
  },
  {
    "key": "UI_REPORT_REVENUE_COMPANIES_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Via dit rapport krijgt u een gedetaileerde omzet, kosten en winst per bedrijf alsook de gecombineerde waarde."
      },
      {
        "iso": "en",
        "value": "Through this report you will get a detailed sales, costs and profit per company combined value."
      }
    ]
  },
  {
    "key": "UI_REPORT_CURRENT_STOCK_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Huidige stock"
      },
      {
        "iso": "en",
        "value": "Current stock"
      }
    ]
  },
  {
    "key": "UI_REPORT_CURRENT_STOCK_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Via dit rapport krijgt u een gedetailleerde informatie van uw huidige stock producten."
      },
      {
        "iso": "en",
        "value": "Through this report you will get detailed information about your current stock."
      }
    ]
  },
  {
    "key": "UI_REPORT_INVOICED_ARTICLES_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Gedetaileerd overzicht van gefactureerde artikelen"
      },
      {
        "iso": "en",
        "value": "Detailed overview of invoiced articles"
      }
    ]
  },
  {
    "key": "UI_REPORT_INVOICED_ARTICLES_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Geef een totaaloverzicht van het aantal gefactureerde artikelen en hun totaal prijzen. Naast het totaaloverzicht krijgt je ook een overzicht per relatie. Het rapport wordt opgemaakt doormiddel van een start- en einddatum."
      },
      {
        "iso": "en",
        "value": "Provide a total overview of the number of invoiced items and their total prices. In addition to the total overview, you also get an overview per relationship. The report is formatted by means of a start and end date."
      }
    ]
  },
  {
    "key": "UI_INCLUDE_INVOICED",
    "values": [
      {
        "iso": "nl",
        "value": "Inclusief gefactureerde"
      },
      {
        "iso": "en",
        "value": "Include invoiced"
      }
    ]
  },
  {
    "key": "UI_INCLUDE_NON_INVOICED",
    "values": [
      {
        "iso": "nl",
        "value": "Inclusief niet gefactureerde"
      },
      {
        "iso": "en",
        "value": "Include non invoiced"
      }
    ]
  },
  {
    "key": "UI_REQUEST",
    "values": [
      {
        "iso": "nl",
        "value": "Aanvragen"
      },
      {
        "iso": "en",
        "value": "Request"
      }
    ]
  },
  {
    "key": "UI_REPORT_UNVALID_OPTIONAL_VALUES_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Gelieve alle verplichte waardes in te vullen alvorens het rapport aan te vragen!"
      },
      {
        "iso": "en",
        "value": "Please add all required values before requesting the report!"
      }
    ]
  },
  {
    "key": "UI_REQUEST_REPORT",
    "values": [
      {
        "iso": "nl",
        "value": "Rapport aanvragen"
      },
      {
        "iso": "en",
        "value": "Request report"
      }
    ]
  },
  {
    "key": "UI_MENU_CONVERT_TO_FIXED",
    "values": [
      {
        "iso": "nl",
        "value": "Vaste menu gebruiken"
      },
      {
        "iso": "en",
        "value": "Use fixed menu"
      }
    ]
  },
  {
    "key": "UI_MENU_CONVERT_TO_MINIMAL",
    "values": [
      {
        "iso": "nl",
        "value": "Verborgen menu gebruiken"
      },
      {
        "iso": "en",
        "value": "Use hidden menu"
      }
    ]
  },
  {
    "key": "UI_REPORTS",
    "values": [
      {
        "iso": "nl",
        "value": "Rapporten"
      },
      {
        "iso": "en",
        "value": "Reports"
      }
    ]
  },
  {
    "key": "UI_REPORT_REQUESTS",
    "values": [
      {
        "iso": "nl",
        "value": "Aanvragen"
      },
      {
        "iso": "en",
        "value": "Requests"
      }
    ]
  },
  {
    "key": "UI_REPORT_REPORT_REQUESTS",
    "values": [
      {
        "iso": "nl",
        "value": "Raport aanvragen"
      },
      {
        "iso": "en",
        "value": "Requests reports"
      }
    ]
  },
  {
    "key": "UI_REQUESTED",
    "values": [
      {
        "iso": "nl",
        "value": "Aangevraagd"
      },
      {
        "iso": "en",
        "value": "Requested"
      }
    ]
  },
  {
    "key": "UI_REQUEST_REPORT",
    "values": [
      {
        "iso": "nl",
        "value": "Rapport aanvragen"
      },
      {
        "iso": "en",
        "value": "Request a report"
      }
    ]
  },
  {
    "key": "UI_DELETE_REQUESTS",
    "values": [
      {
        "iso": "nl",
        "value": "Aanvragen verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete requests"
      }
    ]
  },
  {
    "key": "UI_PER_RELATION",
    "values": [
      {
        "iso": "nl",
        "value": "Per relatie"
      },
      {
        "iso": "en",
        "value": "Per relation"
      }
    ]
  },
  {
    "key": "UI_RELATION",
    "values": [
      {
        "iso": "nl",
        "value": "Relatie"
      },
      {
        "iso": "en",
        "value": "Relation"
      }
    ]
  },
  {
    "key": "UI_TIME_IN_HOURS",
    "values": [
      {
        "iso": "nl",
        "value": "Tijd in uren"
      },
      {
        "iso": "en",
        "value": "Time in hours"
      }
    ]
  },
  {
    "key": "UI_BREAK_IN_HOURS",
    "values": [
      {
        "iso": "nl",
        "value": "Pauze in uren"
      },
      {
        "iso": "en",
        "value": "Pauze in hours"
      }
    ]
  },
  {
    "key": "UI_ESTIMATED_EARNINGS",
    "values": [
      {
        "iso": "nl",
        "value": "Verwachte opbrengst"
      },
      {
        "iso": "en",
        "value": "Estimated earnings"
      }
    ]
  },
  {
    "key": "UI_EXTRAS",
    "values": [
      {
        "iso": "nl",
        "value": "Extra's"
      },
      {
        "iso": "en",
        "value": "Extras"
      }
    ]
  },
  {
    "key": "UI_ESTIMATED_TOTAL_EARNINGS",
    "values": [
      {
        "iso": "nl",
        "value": "Totaal verwachte opbrengst"
      },
      {
        "iso": "en",
        "value": "Total estimated earnings"
      }
    ]
  },
  {
    "key": "UI_DOWNLOAD_CSV",
    "values": [
      {
        "iso": "nl",
        "value": "CSV bestanden"
      },
      {
        "iso": "en",
        "value": "CSV files"
      }
    ]
  },
  {
    "key": "UI_DOWNLOAD_XLS",
    "values": [
      {
        "iso": "nl",
        "value": "Excel bestand"
      },
      {
        "iso": "en",
        "value": "Excel file"
      }
    ]
  },
  {
    "key": "UI_MANUAL",
    "values": [
      {
        "iso": "nl",
        "value": "Handleiding"
      },
      {
        "iso": "en",
        "value": "Manual"
      }
    ]
  },
  {
    "key": "UI_IN_STOCK_AMOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Stock aantal"
      },
      {
        "iso": "en",
        "value": "Stock amount"
      }
    ]
  },
  {
    "key": "UI_SHOW_IN_STOCK_ONLY",
    "values": [
      {
        "iso": "nl",
        "value": "Toon enkel producten in stock"
      },
      {
        "iso": "en",
        "value": "Show only product in stock"
      }
    ]
  },
  {
    "key": "UI_ENTITIES",
    "values": [
      {
        "iso": "nl",
        "value": "Entiteiten"
      },
      {
        "iso": "en",
        "value": "Entities"
      }
    ]
  },
  {
    "key": "UI_MULTIPLE_INSTITUTION_FOUND_FOR_GIVEN_BIC",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn meerdere banken gevonden voor BIC '{{0}}'. Gelieve de bank te selecteren die u wenst te linken."
      },
      {
        "iso": "en",
        "value": "Multiple banks were found for BIC '{{0}}'. Please select the bank you wish to link."
      }
    ]
  },
  {
    "key": "UI_CHOSE_A_BANK",
    "values": [
      {
        "iso": "nl",
        "value": "Kies een bank"
      },
      {
        "iso": "en",
        "value": "Chose a bank"
      }
    ]
  },
  {
    "key": "UI_CHANGE_PASSWORD",
    "values": [
      {
        "iso": "nl",
        "value": "Wachtwoord wijzigen"
      },
      {
        "iso": "en",
        "value": "Change password"
      }
    ]
  },
  {
    "key": "UI_CURRENT_PASSWORD",
    "values": [
      {
        "iso": "nl",
        "value": "Huidig wachtwoord"
      },
      {
        "iso": "en",
        "value": "Current password"
      }
    ]
  },
  {
    "key": "UI_NEW_PASSWORD",
    "values": [
      {
        "iso": "nl",
        "value": "Nieuw wachtwoord"
      },
      {
        "iso": "en",
        "value": "New password"
      }
    ]
  },
  {
    "key": "UI_CONFIRM_NEW_PASSWORD",
    "values": [
      {
        "iso": "nl",
        "value": "Bevestig nieuw wachtwoord"
      },
      {
        "iso": "en",
        "value": "Confirm new password"
      }
    ]
  },
  {
    "key": "UI_PASSWORD_UPDATED",
    "values": [
      {
        "iso": "nl",
        "value": "Het wachtwoord is succesvol gewijzigd!"
      },
      {
        "iso": "en",
        "value": "The password has succesfull been updated!"
      }
    ]
  },
  {
    "key": "UI_INCORRECT_OLD_PASSWORD_ON_PARTNER_PASSWORD_UPDATE",
    "values": [
      {
        "iso": "nl",
        "value": "Het huidige wachtwoord is incorrect. Gelieve opnieuw te proberen."
      },
      {
        "iso": "en",
        "value": "The current password is invalid. Please try again."
      }
    ]
  },
  {
    "key": "UI_ADMISOL",
    "values": [
      {
        "iso": "nl",
        "value": "Admisol"
      },
      {
        "iso": "en",
        "value": "Admisol"
      }
    ]
  },
  {
    "key": "UI_ADD_LICENSE",
    "values": [
      {
        "iso": "nl",
        "value": "Licentie aanmaken"
      },
      {
        "iso": "en",
        "value": "Create license"
      }
    ]
  },
  {
    "key": "UI_RECOMMENDED_TOTAL_PRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Aanbevolen totale prijs"
      },
      {
        "iso": "en",
        "value": "Recommended total price"
      }
    ]
  },
  {
    "key": "UI_RESELLER_PRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Reseller prijs"
      },
      {
        "iso": "en",
        "value": "Reseller price"
      }
    ]
  },
  {
    "key": "UI_WORK_TIME",
    "values": [
      {
        "iso": "nl",
        "value": "Werktijd"
      },
      {
        "iso": "en",
        "value": "Work time"
      }
    ]
  },
  {
    "key": "UI_USER_ROLES",
    "values": [
      {
        "iso": "nl",
        "value": "Gebruikersrollen"
      },
      {
        "iso": "en",
        "value": "User roles"
      }
    ]
  },
  {
    "key": "UI_PURCHASE_PRICES",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoopprijzen"
      },
      {
        "iso": "en",
        "value": "Purchase prices"
      }
    ]
  },
  {
    "key": "UI_PREPAID_SERVICES",
    "values": [
      {
        "iso": "nl",
        "value": "vooruitbetaalde diensten"
      },
      {
        "iso": "en",
        "value": "Prepaid services"
      }
    ]
  },
  {
    "key": "UI_PREPAID_SERVICE",
    "values": [
      {
        "iso": "nl",
        "value": "vooruitbetaalde dienst"
      },
      {
        "iso": "en",
        "value": "Prepaid service"
      }
    ]
  },
  {
    "key": "UI_SCHEDULE",
    "values": [
      {
        "iso": "nl",
        "value": "Planning"
      },
      {
        "iso": "en",
        "value": "Schedule"
      }
    ]
  },
  {
    "key": "UI_ASSIGNMENTS",
    "values": [
      {
        "iso": "nl",
        "value": "Opdrachten"
      },
      {
        "iso": "en",
        "value": "Assignments"
      }
    ]
  },
  {
    "key": "UI_ADD_ASSIGNMENT",
    "values": [
      {
        "iso": "nl",
        "value": "Opdracht toevoegen"
      },
      {
        "iso": "en",
        "value": "Add assignment"
      }
    ]
  },
  {
    "key": "UI_DELETE_ASSIGNMENTS",
    "values": [
      {
        "iso": "nl",
        "value": "Opdrachten verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete assignments"
      }
    ]
  },
  {
    "key": "UI_ADD_TRANSACTION",
    "values": [
      {
        "iso": "nl",
        "value": "Transactie toevoegen"
      },
      {
        "iso": "en",
        "value": "Add transaction"
      }
    ]
  },
  {
    "key": "UI_PREPAID_SERVICE_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Vooruitbetaalde diensten"
      },
      {
        "iso": "en",
        "value": "Pre-paid services"
      }
    ]
  },
  {
    "key": "UI_TRANSACTION",
    "values": [
      {
        "iso": "nl",
        "value": "Transactie"
      },
      {
        "iso": "en",
        "value": "Transaction"
      }
    ]
  },
  {
    "key": "UI_NAVIGATE_TO_DOCUMENT",
    "values": [
      {
        "iso": "nl",
        "value": "Navigeren naar document"
      },
      {
        "iso": "en",
        "value": "Navigate to document"
      }
    ]
  },
  {
    "key": "UI_WORKPERFORMANCE_COMPLETED_BY_INVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "De prestatie is afgepunt via factuur \"{{0}}\""
      },
      {
        "iso": "en",
        "value": "This prestation has been conciled by invoice \"{{0}}\""
      }
    ]
  },
  {
    "key": "UI_WORKPERFORMANCE_COMPLETED_BY_PROFORMA_INVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "De prestatie is afgepunt via een proforma factuur"
      },
      {
        "iso": "en",
        "value": "This prestation has been conciled by proforma invoice"
      }
    ]
  },
  {
    "key": "UI_WORKPERFORMANCE_COMPLETED_BY_PREPAIDSERVICE",
    "values": [
      {
        "iso": "nl",
        "value": "Deze prestatie is afgepunt via een vooruitbetaalde dienst"
      },
      {
        "iso": "en",
        "value": "This prestation has been conciled by a pre-paid service"
      }
    ]
  },
  {
    "key": "UI_CONCILE_BY_PREPAID_SERVICE",
    "values": [
      {
        "iso": "nl",
        "value": "Afpunten via vooruitbetaalde dienst"
      },
      {
        "iso": "en",
        "value": "Concile by pre-paid service"
      }
    ]
  },
  {
    "key": "UI_WRITEOFF_FREE_INPUT",
    "values": [
      {
        "iso": "nl",
        "value": "Afschrijving linken aan factuur"
      },
      {
        "iso": "en",
        "value": "Link writeoff to invoice"
      }
    ]
  },
  {
    "key": "UI_PDF_LOGO_OPACITY",
    "values": [
      {
        "iso": "nl",
        "value": "Logo zichtbaarheid (in %)"
      },
      {
        "iso": "en",
        "value": "Logo opacity (in %)"
      }
    ]
  },
  {
    "key": "UI_INVALID_UBL_CANT_BE_LOADED",
    "values": [
      {
        "iso": "nl",
        "value": "De UBL is ongeldig voor de gevraagde toepassing en kan niet worden ingelezen."
      },
      {
        "iso": "en",
        "value": "The UBL is invalid for the requested application and connot be read."
      }
    ]
  },
  {
    "key": "UI_REVENUE_EXCL_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Omzet excl. BTW"
      },
      {
        "iso": "en",
        "value": "Revenue excl. VAT"
      }
    ]
  },
  {
    "key": "UI_REVENUE_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "BTW bedrag van de omzet"
      },
      {
        "iso": "en",
        "value": "VAT amount of the revenue"
      }
    ]
  },
  {
    "key": "UI_REVENUE_INCL_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Omzet incl. BTW"
      },
      {
        "iso": "en",
        "value": "Revenue incl. VAT"
      }
    ]
  },
  {
    "key": "UI_COSTS_EXCL_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Kosten excl. BTW"
      },
      {
        "iso": "en",
        "value": "Costs excl. VAT"
      }
    ]
  },
  {
    "key": "UI_COSTS_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "BTW bedrag van de kosten"
      },
      {
        "iso": "en",
        "value": "VAT amount of the costs"
      }
    ]
  },
  {
    "key": "UI_COSTS_INCL_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Kosten incl. BTW"
      },
      {
        "iso": "en",
        "value": "Costs incl. VAT"
      }
    ]
  },
  {
    "key": "UI_GAIN_EXCL_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Winst excl. BTW"
      },
      {
        "iso": "en",
        "value": "Gain excl. VAT"
      }
    ]
  },
  {
    "key": "UI_GAIN_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "BTW bedrag van de winsten"
      },
      {
        "iso": "en",
        "value": "VAT amount of the gains"
      }
    ]
  },
  {
    "key": "UI_GAIN_INCL_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Winst incl. BTW"
      },
      {
        "iso": "en",
        "value": "Gain incl. VAT"
      }
    ]
  },
  {
    "key": "UI_ARCHIVED_COMPANIES",
    "values": [
      {
        "iso": "nl",
        "value": "Gearchiveerde bedrijven"
      },
      {
        "iso": "en",
        "value": "Archived companies"
      }
    ]
  },
  {
    "key": "UI_COMBINED",
    "values": [
      {
        "iso": "nl",
        "value": "Gecombineerd"
      },
      {
        "iso": "en",
        "value": "Combined"
      }
    ]
  },
  {
    "key": "UI_STOCK_AMOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Aantal stock producten"
      },
      {
        "iso": "en",
        "value": "Amount of stock products"
      }
    ]
  },
  {
    "key": "UI_STOCK_VALUE",
    "values": [
      {
        "iso": "nl",
        "value": "Waarde van stock"
      },
      {
        "iso": "en",
        "value": "Value of stock"
      }
    ]
  },
  {
    "key": "UI_PLANNED",
    "values": [
      {
        "iso": "nl",
        "value": "Gepland"
      },
      {
        "iso": "en",
        "value": "Planned"
      }
    ]
  },
  {
    "key": "UI_FINISHED",
    "values": [
      {
        "iso": "nl",
        "value": "Afgerond"
      },
      {
        "iso": "en",
        "value": "Finished"
      }
    ]
  },
  {
    "key": "UI_CLOSED",
    "values": [
      {
        "iso": "nl",
        "value": "Gesloten"
      },
      {
        "iso": "en",
        "value": "Closed"
      }
    ]
  },
  {
    "key": "UI_DASHBOARD_PREFERENCES",
    "values": [
      {
        "iso": "nl",
        "value": "Dashboard voorkeuren"
      },
      {
        "iso": "en",
        "value": "Dashboard preferences"
      }
    ]
  },
  {
    "key": "UI_SHOW_LEDGER_NUMBER_WARNINGS",
    "values": [
      {
        "iso": "nl",
        "value": "Toon waarschuwingen voor grootboek rekeningen"
      },
      {
        "iso": "en",
        "value": "Show warnings for ledger numbers"
      }
    ]
  },
  {
    "key": "UI_INVOICE_ALL_WORKPERFORMANCES",
    "values": [
      {
        "iso": "nl",
        "value": "Alles factureren"
      },
      {
        "iso": "en",
        "value": "Invoice all"
      }
    ]
  },
  {
    "key": "UI_INVOICE_WORKPERFORMANCES_FOR_SELECTED_PARAMETERS",
    "values": [
      {
        "iso": "nl",
        "value": "Factureren volgens parameters"
      },
      {
        "iso": "en",
        "value": "Invoice according to parameters"
      }
    ]
  },
  {
    "key": "UI_SUBSCRIBER_FREE_FROM_TAX_BELGIUM",
    "values": [
      {
        "iso": "nl",
        "value": "Bijzondere vrijstellingsregeling kleine ondernemingen, BTW niet van toepassing"
      },
      {
        "iso": "en",
        "value": "Special exemption scheme for small businesses, VAT not applicable"
      }
    ]
  },
  {
    "key": "UI_ALL_INVOICED_ARTICLES",
    "values": [
      {
        "iso": "nl",
        "value": "Alle gefactureerde artikelen"
      },
      {
        "iso": "en",
        "value": "All invoiced articles"
      }
    ]
  },
  {
    "key": "UI_ARTICLE_TYPE",
    "values": [
      {
        "iso": "nl",
        "value": "Artikel type"
      },
      {
        "iso": "en",
        "value": "Article type"
      }
    ]
  },
  {
    "key": "UI_ARTICLE_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Artikelnaam"
      },
      {
        "iso": "en",
        "value": "Article name"
      }
    ]
  },
  {
    "key": "UI_ARTICLE_QUANTITY",
    "values": [
      {
        "iso": "nl",
        "value": "Artikel aantal"
      },
      {
        "iso": "en",
        "value": "Article quantity"
      }
    ]
  },
  {
    "key": "UI_PRICE_EXCL_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Prijs excl. BTW"
      },
      {
        "iso": "en",
        "value": "Price excl VAT"
      }
    ]
  },
  {
    "key": "UI_PRICE_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "BTW bedrag"
      },
      {
        "iso": "en",
        "value": "VAT amount"
      }
    ]
  },
  {
    "key": "UI_PRICE_INCL_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Prijs incl. BTW"
      },
      {
        "iso": "en",
        "value": "Price incl. VAT"
      }
    ]
  },
  {
    "key": "UI_ANALYTIC_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Analytische informatie"
      },
      {
        "iso": "en",
        "value": "Analytic information"
      }
    ]
  },
  {
    "key": "UI_TAGS",
    "values": [
      {
        "iso": "nl",
        "value": "Tags"
      },
      {
        "iso": "en",
        "value": "Tags"
      }
    ]
  },
  {
    "key": "UI_RPR",
    "values": [
      {
        "iso": "nl",
        "value": "RPR"
      },
      {
        "iso": "en",
        "value": "RPR"
      }
    ]
  },
  {
    "key": "UI_HIDE_SUBLINES_ON_INVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "Sublijnen verbergen op factuur"
      },
      {
        "iso": "en",
        "value": "Hide sublines on invoice"
      }
    ]
  },
  {
    "key": "UI_ADD_SUBLINES",
    "values": [
      {
        "iso": "nl",
        "value": "Sublijnen toevoegen"
      },
      {
        "iso": "en",
        "value": "Add sublines"
      }
    ]
  },
  {
    "key": "UI_HIDE_ON_PDF",
    "values": [
      {
        "iso": "nl",
        "value": "Verbergen op PDF"
      },
      {
        "iso": "en",
        "value": "Hide on PDF"
      }
    ]
  },
  {
    "key": "UI_AUTHENTICATION_FAILED",
    "values": [
      {
        "iso": "nl",
        "value": "Authenticatie mislukt"
      },
      {
        "iso": "en",
        "value": "Authentication failed"
      }
    ]
  },
  {
    "key": "UI_HOW_DO_YOU_KNOW_US",
    "values": [
      {
        "iso": "nl",
        "value": "Hoe ken je ons?"
      },
      {
        "iso": "en",
        "value": "How do you know us?"
      }
    ]
  },
  {
    "key": "UI_SUBSCRIBER_HAS_NO_ACTIVE_LICENSES",
    "values": [
      {
        "iso": "nl",
        "value": "Deze abonnee beschikt niet over actieve licenties."
      },
      {
        "iso": "en",
        "value": "This subscriber does not have active licenses."
      }
    ]
  },
  {
    "key": "UI_SUBSCRIBER_HAS_NO_UPGRADED_LICENSES",
    "values": [
      {
        "iso": "nl",
        "value": "Deze abonnee beschikt niet over geüpgrade licenties."
      },
      {
        "iso": "en",
        "value": "This subscriber does not have upgraded licenses."
      }
    ]
  },
  {
    "key": "UI_SUBSCRIBER_HAS_NO_EXPIRED_LICENSES",
    "values": [
      {
        "iso": "nl",
        "value": "Deze abonnee beschikt niet over vervallen licenties."
      },
      {
        "iso": "en",
        "value": "This subscriber does not have expired licenses."
      }
    ]
  },
  {
    "key": "UI_HOW_DO_YOU_KNOW_US_EXTRA_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Meer uitleg"
      },
      {
        "iso": "en",
        "value": "More information"
      }
    ]
  },
  {
    "key": "UI_HOW",
    "values": [
      {
        "iso": "nl",
        "value": "Hoe?"
      },
      {
        "iso": "en",
        "value": "How?"
      }
    ]
  },
  {
    "key": "UI_REGISTER_FREE",
    "values": [
      {
        "iso": "nl",
        "value": "GRATIS registreren"
      },
      {
        "iso": "en",
        "value": "Register free"
      }
    ]
  },
  {
    "key": "UI_REGISTRATION_HOW_DO_YOU_KNOW_US_FRIEND",
    "values": [
      {
        "iso": "nl",
        "value": "Via vrienden"
      },
      {
        "iso": "en",
        "value": "By friends"
      }
    ]
  },
  {
    "key": "UI_REGISTRATION_ONBOARDING_REQUEST_RECEIVED",
    "values": [
      {
        "iso": "nl",
        "value": "We hebben jouw aanmelding goed ontvangen!"
      },
      {
        "iso": "en",
        "value": "We have received your registration successfully!"
      }
    ]
  },
  {
    "key": "UI_REGISTRATION_ONBOARDING_CONFIRM_YOUR_EMAIL",
    "values": [
      {
        "iso": "nl",
        "value": "Bevestig jouw aanmelding via de link in de e-mail."
      },
      {
        "iso": "en",
        "value": "Confirm your registration through the link in the email."
      }
    ]
  },
  {
    "key": "UI_REGISTRATION_ONBOARDING_CONFIRM_YOUR_EMAIL_SPAM_WARNING",
    "values": [
      {
        "iso": "nl",
        "value": "Bekijk ook de SPAM box!"
      },
      {
        "iso": "en",
        "value": "Also, check the SPAM box!"
      }
    ]
  },
  {
    "key": "UI_REGISTRATION_ONBOARDING_KEEP_READY",
    "values": [
      {
        "iso": "nl",
        "value": "Hou dit paraat:"
      },
      {
        "iso": "en",
        "value": "Keep this ready at hand:"
      }
    ]
  },
  {
    "key": "UI_REGISTRATION_ONBOARDING_KEEP_READY_VAT_NUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "BTW nummer"
      },
      {
        "iso": "en",
        "value": "VAT number"
      }
    ]
  },
  {
    "key": "UI_REGISTRATION_ONBOARDING_KEEP_READY_ACCOUNT_NUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "Bankgegevens voor klanten"
      },
      {
        "iso": "en",
        "value": "Bank account number for customers."
      }
    ]
  },
  {
    "key": "UI_REGISTRATION_ONBOARDING_KEEP_READY_LOGO",
    "values": [
      {
        "iso": "nl",
        "value": "Logo (optioneel)"
      },
      {
        "iso": "en",
        "value": "Logo (optional)"
      }
    ]
  },
  {
    "key": "UI_REGISTRATION_HOW_DO_YOU_KNOW_US_ACCOUNTANT",
    "values": [
      {
        "iso": "nl",
        "value": "Via mijn boekhouder / accountant"
      },
      {
        "iso": "en",
        "value": "By my accountant"
      }
    ]
  },
  {
    "key": "UI_REGISTRATION_HOW_DO_YOU_KNOW_US_SOCIALMEDIA",
    "values": [
      {
        "iso": "nl",
        "value": "Via sociale media"
      },
      {
        "iso": "en",
        "value": "By social media"
      }
    ]
  },
  {
    "key": "UI_REGISTRATION_HOW_DO_YOU_KNOW_US_GOOGLE",
    "values": [
      {
        "iso": "nl",
        "value": "Via google"
      },
      {
        "iso": "en",
        "value": "By google"
      }
    ]
  },
  {
    "key": "UI_REGISTRATION_HOW_DO_YOU_KNOW_US_OTHER",
    "values": [
      {
        "iso": "nl",
        "value": "Andere"
      },
      {
        "iso": "en",
        "value": "Other"
      }
    ]
  },
  {
    "key": "UI_REGISTER_FREE_COMPLETED",
    "values": [
      {
        "iso": "nl",
        "value": "Registratie voltooid"
      },
      {
        "iso": "en",
        "value": "Register completed"
      }
    ]
  },
  {
    "key": "UI_REPORT_SALES_INVOICES_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Overzicht verkoopfacturen"
      },
      {
        "iso": "en",
        "value": "Overview sales invoices"
      }
    ]
  },
  {
    "key": "UI_REPORT_SALES_INVOICES_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Via dit rapport krijgt u een gedetaileerd overzicht van uw verkoop facturen."
      },
      {
        "iso": "en",
        "value": "This report gives you a detailed overview of your sales invoices."
      }
    ]
  },
  {
    "key": "UI_REPORT_SALES_CREDITNOTE_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Overzicht creditnota's"
      },
      {
        "iso": "en",
        "value": "Overview sales creditnotes"
      }
    ]
  },
  {
    "key": "UI_REPORT_SALES_CREDITNOTE_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Via dit rapport krijgt u een gedetaileerd overzicht van uw verkoop creditnota's."
      },
      {
        "iso": "en",
        "value": "This report gives you a detailed overview of your sales creditnotes."
      }
    ]
  },
  {
    "key": "UI_REPORT_RELATION_EXPORT_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Relaties overzicht"
      },
      {
        "iso": "en",
        "value": "Relation overview"
      }
    ]
  },
  {
    "key": "UI_REPORT_RELATION_EXPORT_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "<b>Omschrijving</b><br />Via dit rapport krijgt u een gedetaileerd overzicht van uw relaties."
      },
      {
        "iso": "en",
        "value": "<b>Description</b><br />This report gives you a detailed overview of your relations."
      }
    ]
  },
  {
    "key": "UI_ADD_CUSTOMERS",
    "values": [
      {
        "iso": "nl",
        "value": "Klanten toevoegen"
      },
      {
        "iso": "en",
        "value": "Add customers"
      }
    ]
  },
  {
    "key": "UI_ADD_PROSPECTS",
    "values": [
      {
        "iso": "nl",
        "value": "Prospected toevoegen"
      },
      {
        "iso": "en",
        "value": "Add prospects"
      }
    ]
  },
  {
    "key": "UI_ADD_SUPPLIERS",
    "values": [
      {
        "iso": "nl",
        "value": "Leveranciers toevoegen"
      },
      {
        "iso": "en",
        "value": "Add suppliers"
      }
    ]
  },
  {
    "key": "UI_ADD_ARCHIVED",
    "values": [
      {
        "iso": "nl",
        "value": "Gearchiveerde toevoegen"
      },
      {
        "iso": "en",
        "value": "Add archived"
      }
    ]
  },
  {
    "key": "UI_REPORT_SUBSCRIPTION_OVERVIEW_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Abonnementen overzicht"
      },
      {
        "iso": "en",
        "value": "Subscription overview"
      }
    ]
  },
  {
    "key": "UI_REPORT_SUBSCRIPTION_OVERVIEW_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "<b>Omschrijving</b><br />Via dit rapport krijgt u een gedetaileerd overzicht van uw abonnementen."
      },
      {
        "iso": "en",
        "value": "<b>Description</b><br />This report gives you a detailed overview of your subscriptions."
      }
    ]
  },
  {
    "key": "UI_COUNTRY_CODE",
    "values": [
      {
        "iso": "nl",
        "value": "Landcode"
      },
      {
        "iso": "en",
        "value": "Country code"
      }
    ]
  },
  {
    "key": "UI_INVOICE_NUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "Factuur nummer"
      },
      {
        "iso": "en",
        "value": "Invoice number"
      }
    ]
  },
  {
    "key": "UI_INVOICE_DATE",
    "values": [
      {
        "iso": "nl",
        "value": "Factuur datum"
      },
      {
        "iso": "en",
        "value": "Invoice date"
      }
    ]
  },
  {
    "key": "UI_PAYED_ON",
    "values": [
      {
        "iso": "nl",
        "value": "Betaald op"
      },
      {
        "iso": "en",
        "value": "Payed on"
      }
    ]
  },
  {
    "key": "UI_VAT_PERCENTAGE",
    "values": [
      {
        "iso": "nl",
        "value": "BTW percentage"
      },
      {
        "iso": "en",
        "value": "VAT percentage"
      }
    ]
  },
  {
    "key": "UI_ACCOUNT_NUMBER_FISCAL",
    "values": [
      {
        "iso": "nl",
        "value": "Fiscaal"
      },
      {
        "iso": "en",
        "value": "Fiscal"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_NOTICES",
    "values": [
      {
        "iso": "nl",
        "value": "Document meldingen"
      },
      {
        "iso": "en",
        "value": "Document notices"
      }
    ]
  },
  {
    "key": "UI_INTEGRATION_SETTINGS",
    "values": [
      {
        "iso": "nl",
        "value": "Integratie instellingen"
      },
      {
        "iso": "en",
        "value": "Integration settings"
      }
    ]
  },
  {
    "key": "UI_ARTICLE_CATEGORY",
    "values": [
      {
        "iso": "nl",
        "value": "Artikel categorie"
      },
      {
        "iso": "en",
        "value": "Article category"
      }
    ]
  },
  {
    "key": "UI_SALES_INVOICE_SUBLINES",
    "values": [
      {
        "iso": "nl",
        "value": "Verkoop factuurlijnen"
      },
      {
        "iso": "en",
        "value": "Sales invoicesublines"
      }
    ]
  },
  {
    "key": "UI_YUKI",
    "values": [
      {
        "iso": "nl",
        "value": "Yuki"
      },
      {
        "iso": "en",
        "value": "Yuki"
      }
    ]
  },
  {
    "key": "UI_YUKI_CONFIGURATION",
    "values": [
      {
        "iso": "nl",
        "value": "Yuki configuratie"
      },
      {
        "iso": "en",
        "value": "Yuki configuratie"
      }
    ]
  },
  {
    "key": "UI_EXACT_ONLINE",
    "values": [
      {
        "iso": "nl",
        "value": "Exact online"
      },
      {
        "iso": "en",
        "value": "Exact online"
      }
    ]
  },
  {
    "key": "UI_SUBSCRIBER_COMPANY_TYPE",
    "values": [
      {
        "iso": "nl",
        "value": "Ondernemingsvorm"
      },
      {
        "iso": "en",
        "value": "Form of enterprise"
      }
    ]
  },
  {
    "key": "UI_SUBSCRIBER_TYPE_VATFREESMALLCOMPANY",
    "values": [
      {
        "iso": "nl",
        "value": "Eenmanszaak vrijgesteld van BTW"
      },
      {
        "iso": "en",
        "value": "Sole proprietorship exempt from VAT"
      }
    ]
  },
  {
    "key": "UI_SUBSCRIBER_TYPE_ONDEMANBUSINESS",
    "values": [
      {
        "iso": "nl",
        "value": "Eenmanszaak"
      },
      {
        "iso": "en",
        "value": "Sole proprietorship"
      }
    ]
  },
  {
    "key": "UI_SUBSCRIBER_TYPE_ASSOCIATION",
    "values": [
      {
        "iso": "nl",
        "value": "Vennootschap"
      },
      {
        "iso": "en",
        "value": "Corperation"
      }
    ]
  },
  {
    "key": "UI_PEPPOL_CONFIGURATION",
    "values": [
      {
        "iso": "nl",
        "value": "Peppol configuratie"
      },
      {
        "iso": "en",
        "value": "Peppol configuration"
      }
    ]
  },
  {
    "key": "UI_ZOOMIT_CONFIGURATION",
    "values": [
      {
        "iso": "nl",
        "value": "Zoomit configuratie"
      },
      {
        "iso": "en",
        "value": "Zoomit configuration"
      }
    ]
  },
  {
    "key": "UI_CONTACT_EMAIL",
    "values": [
      {
        "iso": "nl",
        "value": "Contact e-mail"
      },
      {
        "iso": "en",
        "value": "Contact mail"
      }
    ]
  },
  {
    "key": "UI_YUKI_API_KEY",
    "values": [
      {
        "iso": "nl",
        "value": "API sleutel"
      },
      {
        "iso": "en",
        "value": "API key"
      }
    ]
  },
  {
    "key": "UI_YUKI_ADMINISTRATION_ID",
    "values": [
      {
        "iso": "nl",
        "value": "Administratie ID"
      },
      {
        "iso": "en",
        "value": "Administration ID"
      }
    ]
  },
  {
    "key": "UI_EXPORT_TO_YUKI",
    "values": [
      {
        "iso": "nl",
        "value": "Exporteren naar Yuki"
      },
      {
        "iso": "en",
        "value": "Export to Yuki"
      }
    ]
  },
  {
    "key": "UI_EXPORT_TO_EXACT_ONLINE",
    "values": [
      {
        "iso": "nl",
        "value": "Exporteren naar Exact Online"
      },
      {
        "iso": "en",
        "value": "Export to Exact Online"
      }
    ]
  },
  {
    "key": "UI_MINIMUM_ORDER_QUANTITY",
    "values": [
      {
        "iso": "nl",
        "value": "Minimum bestelhoeveelheid"
      },
      {
        "iso": "en",
        "value": "Minimum order quantity"
      }
    ]
  },
  {
    "key": "UI_JOURNALS",
    "values": [
      {
        "iso": "nl",
        "value": "Dagboeken"
      },
      {
        "iso": "en",
        "value": "Journals"
      }
    ]
  },
  {
    "key": "UI_ACCOUNTING",
    "values": [
      {
        "iso": "nl",
        "value": "Boekhouding"
      },
      {
        "iso": "en",
        "value": "Accounting"
      }
    ]
  },
  {
    "key": "UI_RELATION_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Relatie informatie"
      },
      {
        "iso": "en",
        "value": "Relation information"
      }
    ]
  },
  {
    "key": "UI_RELATION_REFERENCE",
    "values": [
      {
        "iso": "nl",
        "value": "Relatie referentie"
      },
      {
        "iso": "en",
        "value": "Relation reference"
      }
    ]
  },
  {
    "key": "UI_CUSTOMER_SPECIFIC_WORKPERFORMANCE_SERVICE",
    "values": [
      {
        "iso": "nl",
        "value": "Afwijkende werkprestatie dienst"
      },
      {
        "iso": "en",
        "value": "Deviating workperformance service"
      }
    ]
  },
  {
    "key": "UI_CUSTOMER_SPECIFIC_WORKPERFORMANCE_PRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Afwijkende werkprestatie prijs"
      },
      {
        "iso": "en",
        "value": "Deviating workperformance price"
      }
    ]
  },
  {
    "key": "UI_CUSTOMER_SPECIFIC_DISCOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Standaard gebruikers korting"
      },
      {
        "iso": "en",
        "value": "Default user discount"
      }
    ]
  },
  {
    "key": "UI_ADDITIONAL_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Aanvullende informatie"
      },
      {
        "iso": "en",
        "value": "Additional information"
      }
    ]
  },
  {
    "key": "UI_TO_INVOICE_REMINDER",
    "values": [
      {
        "iso": "nl",
        "value": "naar betaalherinnering"
      },
      {
        "iso": "en",
        "value": "To payment reminder"
      }
    ]
  },
  {
    "key": "UI_RELATION_TAGS",
    "values": [
      {
        "iso": "nl",
        "value": "Relatie tags"
      },
      {
        "iso": "en",
        "value": "Relations tags"
      }
    ]
  },
  {
    "key": "UI_IMPORT_PDF_DATA_OCR",
    "values": [
      {
        "iso": "nl",
        "value": "Analyseer en importeer PDF data"
      },
      {
        "iso": "en",
        "value": "Analyse and import PDF data"
      }
    ]
  },
  {
    "key": "UI_IMPORT_UBL_DATA",
    "values": [
      {
        "iso": "nl",
        "value": "Importeer UBL data"
      },
      {
        "iso": "en",
        "value": "Import UBL data"
      }
    ]
  },
  {
    "key": "UI_CLICK_TO_SELECT_PLACEHOLDER",
    "values": [
      {
        "iso": "nl",
        "value": "Klik hier om een {{0}} te selecteren"
      },
      {
        "iso": "en",
        "value": "Click here to select an {{0}}"
      }
    ]
  },
  {
    "key": "UI_SELECT_PLACEHOLDER",
    "values": [
      {
        "iso": "nl",
        "value": "Selecteer een {{0}}"
      },
      {
        "iso": "en",
        "value": "Select a {{0}}"
      }
    ]
  },
  {
    "key": "UI_ANALYSE_PDF_DOCUMENT",
    "values": [
      {
        "iso": "nl",
        "value": "Bezig met analyseren van het PDF document..."
      },
      {
        "iso": "en",
        "value": "Analysing the PDF document..."
      }
    ]
  },
  {
    "key": "UI_BANK_ACCOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Bank rekeningnummer"
      },
      {
        "iso": "en",
        "value": "Bank account"
      }
    ]
  },
  {
    "key": "UI_COMBINE_SUBLINES_ON_IMPORT",
    "values": [
      {
        "iso": "nl",
        "value": "Sublijnen combineren bij het importeren"
      },
      {
        "iso": "en",
        "value": "Combine sublines on import"
      }
    ]
  },
  {
    "key": "UI_RELATION_SPECIFIC_TRANSLOCATION_PRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Afwijkende verplaatsingskost per kilometer"
      },
      {
        "iso": "en",
        "value": "Deviating translocation cost per kilometer"
      }
    ]
  },
  {
    "key": "UI_DEFAULT_UNIT",
    "values": [
      {
        "iso": "nl",
        "value": "Standaard eenheid"
      },
      {
        "iso": "en",
        "value": "Default unit"
      }
    ]
  },
  {
    "key": "UI_UNIT",
    "values": [
      {
        "iso": "nl",
        "value": "Eenheid"
      },
      {
        "iso": "en",
        "value": "Unit"
      }
    ]
  },
  {
    "key": "UI_BANK_BALANCE_AFTER_PAYMENTS",
    "values": [
      {
        "iso": "nl",
        "value": "Verzekerd saldo"
      },
      {
        "iso": "en",
        "value": "Assured balance"
      }
    ]
  },
  {
    "key": "UI_EXPECTED_BANK_BALANCE",
    "values": [
      {
        "iso": "nl",
        "value": "Verwacht saldo"
      },
      {
        "iso": "en",
        "value": "Expected balance"
      }
    ]
  },
  {
    "key": "UI_DETAILS_ASSURED_BANK_BALANCE",
    "values": [
      {
        "iso": "nl",
        "value": "Verkoopfacturen: {{0}}<br />Aankoopfacturen: {{1}}<br /><br />Totaal: {{2}}"
      },
      {
        "iso": "en",
        "value": "Sales invoices: {{0}}<br />Purchase invoices: {{1}}<br /><br />Total: {{2}}"
      }
    ]
  },
  {
    "key": "UI_DETAILS_EXPECTED_BANK_BALANCE",
    "values": [
      {
        "iso": "nl",
        "value": "Verkoopfacturen: {{0}}<br />Niet gefactureerde leveringen: {{1}}<br />Niet gefactureerde werkprestaties: {{2}}<br /><br />Aankoopfacturen: {{3}}<br />Niet gefactureerde recepties: {{4}}<br /><br />Totaal: {{5}}"
      },
      {
        "iso": "en",
        "value": "Sales invoices: {{0}}<br />Uninvoiced deliveries: {{1}}<br />Uninvoiced work prestations: {{2}}<br /><br />Purchase invoices: {{3}}<br />Uninvoiced receptions: {{4}}<br /><br />Total: {{5}}"
      }
    ]
  },
  {
    "key": "UI_DETAILS_EXPECTED_INCOME",
    "values": [
      {
        "iso": "nl",
        "value": "Facturen: {{0}}<br />Niet gefactureerde leveringen: {{1}}<br />Niet gefactureerde werkprestaties: {{2}}<br /><br />Totaal: {{3}}"
      },
      {
        "iso": "en",
        "value": "Invoices: {{0}}<br />Uninvoiced deliveries: {{1}}<br />Uninvoiced workprestations: {{2}}<br /><br />Total: {{2}}"
      }
    ]
  },
  {
    "key": "UI_DETAILS_EXPECTED_OUTGOING",
    "values": [
      {
        "iso": "nl",
        "value": "Facturen: {{0}}<br />Niet gefactureerde recepties: {{1}}<br /><br />Totaal: {{2}}"
      },
      {
        "iso": "en",
        "value": "Invoices: {{0}}<br />Uninvoiced receptions: {{1}}<br /><br />Total: {{2}}"
      }
    ]
  },
  {
    "key": "UI_ADMINISTRATION",
    "values": [
      {
        "iso": "nl",
        "value": "Administratie"
      },
      {
        "iso": "en",
        "value": "Administration"
      }
    ]
  },
  {
    "key": "UI_SEND_RENEWAL_NOTIFICATIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Verstuur e-mail voor licentieverlenging"
      },
      {
        "iso": "en",
        "value": "Send license renewal email"
      }
    ]
  },
  {
    "key": "UI_FIXED_MENU",
    "values": [
      {
        "iso": "nl",
        "value": "Vaste menu"
      },
      {
        "iso": "en",
        "value": "Fixed menu"
      }
    ]
  },
  {
    "key": "UI_HIDDEN_MENU",
    "values": [
      {
        "iso": "nl",
        "value": "Verborgen menu"
      },
      {
        "iso": "en",
        "value": "Hidden menu"
      }
    ]
  },
  {
    "key": "UI_VAT_DECLARATION",
    "values": [
      {
        "iso": "nl",
        "value": "BTW aangifte"
      },
      {
        "iso": "en",
        "value": "Vat declaration"
      }
    ]
  },
  {
    "key": "UI_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "BTW"
      },
      {
        "iso": "en",
        "value": "Vat"
      }
    ]
  },
  {
    "key": "UI_FINANCIAL_SETTINGS",
    "values": [
      {
        "iso": "nl",
        "value": "Financiële instellingen"
      },
      {
        "iso": "en",
        "value": "Financial settings"
      }
    ]
  },
  {
    "key": "UI_AUTOMATIC_PROCESS_OF_MATCHED_PAYMENTS",
    "values": [
      {
        "iso": "nl",
        "value": "Gekoppelde transacties automatisch linken"
      },
      {
        "iso": "en",
        "value": "Automatic link linked transactions"
      }
    ]
  },
  {
    "key": "UI_AUTOMATIC_ADD_BANK_DETAILS_OF_MATCHED_PAYMENTS",
    "values": [
      {
        "iso": "nl",
        "value": "Onbekende bankgegevens van een relatie opslaan"
      },
      {
        "iso": "en",
        "value": "Save unknown bank details of a relation"
      }
    ]
  },
  {
    "key": "UI_SUPPORT",
    "values": [
      {
        "iso": "nl",
        "value": "Support"
      },
      {
        "iso": "en",
        "value": "Support"
      }
    ]
  },
  {
    "key": "UI_RELEASE_NOTES",
    "values": [
      {
        "iso": "nl",
        "value": "Release notes"
      },
      {
        "iso": "en",
        "value": "Release notes"
      }
    ]
  },
  {
    "key": "UI_EMAIL_TO_SUPPORT",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail naar support"
      },
      {
        "iso": "en",
        "value": "Email to support"
      }
    ]
  },
  {
    "key": "UI_CHAT_ON_WHATSAPP",
    "values": [
      {
        "iso": "nl",
        "value": "Chatten via Whatsapp"
      },
      {
        "iso": "en",
        "value": "Chat via Whatsapp"
      }
    ]
  },
  {
    "key": "UI_CHAT_ON_FACEBOOK",
    "values": [
      {
        "iso": "nl",
        "value": "Chatten via Messenger"
      },
      {
        "iso": "en",
        "value": "Chat via Messenger"
      }
    ]
  },
  {
    "key": "UI_ENABLE_GUEST_ACCESS",
    "values": [
      {
        "iso": "nl",
        "value": "Gast toegang toestaan"
      },
      {
        "iso": "en",
        "value": "Enable guest access"
      }
    ]
  },
  {
    "key": "UI_ENABLE_GUEST_ACCESS_FOR_CREATED_INVOICES",
    "values": [
      {
        "iso": "nl",
        "value": "Gast toegang toestaan voor gegenereerde facturen"
      },
      {
        "iso": "en",
        "value": "Enable guest access for generated invoices"
      }
    ]
  },
  {
    "key": "UI_DOWNLOAD_UBL_FILE",
    "values": [
      {
        "iso": "nl",
        "value": "UBL bestand downloaden"
      },
      {
        "iso": "en",
        "value": "Download UBL file"
      }
    ]
  },
  {
    "key": "UI_PAY_BY_BANK_TRANSFER",
    "values": [
      {
        "iso": "nl",
        "value": "Betalen via overschrijving"
      },
      {
        "iso": "en",
        "value": "Pay by bank transfer"
      }
    ]
  },
  {
    "key": "UI_PAYMENT_INVOICE_COMPLETED",
    "values": [
      {
        "iso": "nl",
        "value": "De betaling voor deze factuur is voldaan."
      },
      {
        "iso": "en",
        "value": "Payment for this invoice has been made."
      }
    ]
  },
  {
    "key": "UI_PAYMENT_CREDITNOTE_COMPLETED",
    "values": [
      {
        "iso": "nl",
        "value": "De betaling voor deze creditnota is voldaan."
      },
      {
        "iso": "en",
        "value": "Payment for this creditnote has been made."
      }
    ]
  },
  {
    "key": "UI_PAYMENT_CREDITNOTE_INCOMPLETED",
    "values": [
      {
        "iso": "nl",
        "value": "De betaling voor deze creditnota is nog niet voldaan."
      },
      {
        "iso": "en",
        "value": "Payment for this creditnote hasn't been made."
      }
    ]
  },
  {
    "key": "UI_GUEST_STATUS_ORDER_CONCEPT",
    "values": [
      {
        "iso": "nl",
        "value": "De bestelbon is momenteel in opmaak en niet definitief."
      },
      {
        "iso": "en",
        "value": "The order is currently in draft and not final."
      }
    ]
  },
  {
    "key": "UI_GUEST_STATUS_ORDER_UNPROCESSED",
    "values": [
      {
        "iso": "nl",
        "value": "De bestelbon is nog niet verwerkt."
      },
      {
        "iso": "en",
        "value": "The order isn't processed."
      }
    ]
  },
  {
    "key": "UI_GUEST_STATUS_ORDER_PROCESSED",
    "values": [
      {
        "iso": "nl",
        "value": "De bestelbon is verwerkt."
      },
      {
        "iso": "en",
        "value": "The order is processed."
      }
    ]
  },
  {
    "key": "UI_GUEST_STATUS_ORDER_INVOICED",
    "values": [
      {
        "iso": "nl",
        "value": "De bestelbon is reeds gefactureerd."
      },
      {
        "iso": "en",
        "value": "The order is invoiced."
      }
    ]
  },
  {
    "key": "UI_GUEST_TOKEN_INVALID_DOCUMENT_NOT_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Het document is niet gevonden. Controleer het website-adres of neem contact op met de aanbieder."
      },
      {
        "iso": "en",
        "value": "The document wasn't found. Please check the website address or contact the provider."
      }
    ]
  },
  {
    "key": "UI_NO_UNPROCESSED_MATCHED_TRANSACTIONS_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen onverwerkte gekoppelde transacties gevonden."
      },
      {
        "iso": "en",
        "value": "No unprocessed transaction proposals were found."
      }
    ]
  },
  {
    "key": "UI_INVALID_GUEST_TOKEN_REDIRECT",
    "values": [
      {
        "iso": "nl",
        "value": "De identificatie token wordt niet herkent. Controleer het website-adres of neem contact op met de aanbieder."
      },
      {
        "iso": "en",
        "value": "The identification token isn't recognized. Please check the website address or contact the provider."
      }
    ]
  },
  {
    "key": "UI_FILTER_BY_DATE_RANGE",
    "values": [
      {
        "iso": "nl",
        "value": "Filter op datum"
      },
      {
        "iso": "en",
        "value": "Filter by date"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_NOT_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Document niet gevonden"
      },
      {
        "iso": "en",
        "value": "Document not found"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_DATE",
    "values": [
      {
        "iso": "nl",
        "value": "Document datum"
      },
      {
        "iso": "en",
        "value": "Document date"
      }
    ]
  },
  {
    "key": "UI_ADD_NON_OVERDUE_INVOICES",
    "values": [
      {
        "iso": "nl",
        "value": "Voeg niet vervallen openstaande facturen toe"
      },
      {
        "iso": "en",
        "value": "Add non overdue open invoices"
      }
    ]
  },
  {
    "key": "UI_UNTILL",
    "values": [
      {
        "iso": "nl",
        "value": "tot"
      },
      {
        "iso": "en",
        "value": "untill"
      }
    ]
  },
  {
    "key": "UI_ON_INVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "Op factuur"
      },
      {
        "iso": "en",
        "value": "On invoice"
      }
    ]
  },
  {
    "key": "UI_STRIPE",
    "values": [
      {
        "iso": "nl",
        "value": "Stripe"
      },
      {
        "iso": "en",
        "value": "Stripe"
      }
    ]
  },
  {
    "key": "UI_MOLLIE",
    "values": [
      {
        "iso": "nl",
        "value": "Mollie"
      },
      {
        "iso": "en",
        "value": "Mollie"
      }
    ]
  },
  {
    "key": "UI_USE_REMINDER_GRACE_PERIOD",
    "values": [
      {
        "iso": "nl",
        "value": "Respijtperiode"
      },
      {
        "iso": "en",
        "value": "Grace period"
      }
    ]
  },
  {
    "key": "UI_TIMESPAN_OF_REMINDER_UNTILL_LEVELUP",
    "values": [
      {
        "iso": "nl",
        "value": "Level verhogen na aantal dagen"
      },
      {
        "iso": "en",
        "value": "Raise level after amount of days"
      }
    ]
  },
  {
    "key": "UI_PSD2_LINKED_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Bankrekening informatie"
      },
      {
        "iso": "en",
        "value": "Bankaccount information"
      }
    ]
  },
  {
    "key": "UI_BANK_TRANSACTIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Bank transacties"
      },
      {
        "iso": "en",
        "value": "Bank transactions"
      }
    ]
  },
  {
    "key": "UI_CREATE_REMINDER",
    "values": [
      {
        "iso": "nl",
        "value": "Herinnering aanmaken"
      },
      {
        "iso": "en",
        "value": "Create reminder"
      }
    ]
  },
  {
    "key": "UI_INVOICE_REMINDER_NOTIFICATION_ON_INVOICE_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "Herinnering"
      },
      {
        "iso": "en",
        "value": "Reminder"
      }
    ]
  },
  {
    "key": "UI_INVOICE_REMINDER_NOTIFICATION_ON_INVOICE_CONCEPT",
    "values": [
      {
        "iso": "nl",
        "value": "Deze factuur is op {{0}} opgenomen in een concept herinnering."
      },
      {
        "iso": "en",
        "value": "This invoice is included in a draft reminder on {{0}}."
      }
    ]
  },
  {
    "key": "UI_INVOICE_REMINDER_NOTIFICATION_ON_INVOICE_PAID",
    "values": [
      {
        "iso": "nl",
        "value": "Deze factuur is opgenomen in een betaalde herinnering {{0}}."
      },
      {
        "iso": "en",
        "value": "This invoice is included in a paid reminder {{0}}."
      }
    ]
  },
  {
    "key": "UI_INVOICE_REMINDER_NOTIFICATION_ON_INVOICE_FINISHED",
    "values": [
      {
        "iso": "nl",
        "value": "Deze factuur was opgenomen in een afgesloten herinnering {{0}}."
      },
      {
        "iso": "en",
        "value": "This invoice was included in a finished reminder {{0}}."
      }
    ]
  },
  {
    "key": "UI_INVOICE_REMINDER_NOTIFICATION_ON_INVOICE_OPEN",
    "values": [
      {
        "iso": "nl",
        "value": "Deze factuur is opgenomen in een open herinnering {{0}}."
      },
      {
        "iso": "en",
        "value": "This invoice is included in a open reminder {{0}}."
      }
    ]
  },
  {
    "key": "UI_AUTO_CREATE_INVOICE_REMINDER",
    "values": [
      {
        "iso": "nl",
        "value": "Automatisch aanmaken van betaalherinneringen"
      },
      {
        "iso": "en",
        "value": "Automatic creation of payment reminders"
      }
    ]
  },
  {
    "key": "UI_AUTO_CONFIRM_AND_SEND_INVOICE_REMIDNER",
    "values": [
      {
        "iso": "nl",
        "value": "Automatisch bevestigen en versturen van betaalherinneringen"
      },
      {
        "iso": "en",
        "value": "Automatically confirm and send payment reminders"
      }
    ]
  },
  {
    "key": "UI_TOTAL_AMOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Totaal bedrag"
      },
      {
        "iso": "en",
        "value": "Total amount"
      }
    ]
  },
  {
    "key": "UI_PREVIOUS",
    "values": [
      {
        "iso": "nl",
        "value": "Vorige"
      },
      {
        "iso": "en",
        "value": "Previous"
      }
    ]
  },
  {
    "key": "UI_VIDEO_MANUAL",
    "values": [
      {
        "iso": "nl",
        "value": "Video handleiding"
      },
      {
        "iso": "en",
        "value": "Video manual"
      }
    ]
  },
  {
    "key": "UI_FAILED_EXPORT_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Informatie over gefaalde export"
      },
      {
        "iso": "en",
        "value": "Information about failed export"
      }
    ]
  },
  {
    "key": "UI_ACTIVE_LICENSES",
    "values": [
      {
        "iso": "nl",
        "value": "Actieve licenties"
      },
      {
        "iso": "en",
        "value": "Active licenses"
      }
    ]
  },
  {
    "key": "UI_UPGRADED_LICENSES",
    "values": [
      {
        "iso": "nl",
        "value": "Geüpgrade licenties"
      },
      {
        "iso": "en",
        "value": "Upgraded licenses"
      }
    ]
  },
  {
    "key": "UI_EXPIRED_LICENSES",
    "values": [
      {
        "iso": "nl",
        "value": "Vervallen licenties"
      },
      {
        "iso": "en",
        "value": "Expired licenses"
      }
    ]
  },
  {
    "key": "UI_BUY_USERS",
    "values": [
      {
        "iso": "nl",
        "value": "Gebruikers kopen"
      },
      {
        "iso": "en",
        "value": "Buy users"
      }
    ]
  },
  {
    "key": "UI_MANAGE_MODULES",
    "values": [
      {
        "iso": "nl",
        "value": "Modules beheren"
      },
      {
        "iso": "en",
        "value": "Manage modules"
      }
    ]
  },
  {
    "key": "UI_DISCOUNT_BY_YEAR_LICENSE",
    "values": [
      {
        "iso": "nl",
        "value": "{{0}}% korting bij jaarlicentie"
      },
      {
        "iso": "en",
        "value": "{{0}}% discount for a year license"
      }
    ]
  },
  {
    "key": "UI_ENTER_DISCOUNT_CODE",
    "values": [
      {
        "iso": "nl",
        "value": "Kortingscode"
      },
      {
        "iso": "en",
        "value": "Discount code"
      }
    ]
  },
  {
    "key": "UI_USE_CREDIT",
    "values": [
      {
        "iso": "nl",
        "value": "Gebruik krediet"
      },
      {
        "iso": "en",
        "value": "Use credit"
      }
    ]
  },
  {
    "key": "UI_CODE",
    "values": [
      {
        "iso": "nl",
        "value": "Code"
      },
      {
        "iso": "en",
        "value": "Code"
      }
    ]
  },
  {
    "key": "UI_LIMIT",
    "values": [
      {
        "iso": "nl",
        "value": "Limiet"
      },
      {
        "iso": "en",
        "value": "Limit"
      }
    ]
  },
  {
    "key": "UI_NO_ACTIVE_DISCOUNTS",
    "values": [
      {
        "iso": "nl",
        "value": "Geen kortingen actief"
      },
      {
        "iso": "en",
        "value": "No discounts active"
      }
    ]
  },
  {
    "key": "UI_DISCOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Korting"
      },
      {
        "iso": "en",
        "value": "Discount"
      }
    ]
  },
  {
    "key": "UI_EXCL_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Excl. BTW"
      },
      {
        "iso": "en",
        "value": "Excl. VAT"
      }
    ]
  },
  {
    "key": "UI_EXCL",
    "values": [
      {
        "iso": "nl",
        "value": "Excl."
      },
      {
        "iso": "en",
        "value": "Excl."
      }
    ]
  },
  {
    "key": "UI_INCL",
    "values": [
      {
        "iso": "nl",
        "value": "Incl."
      },
      {
        "iso": "en",
        "value": "Incl."
      }
    ]
  },
  {
    "key": "UI_INCL_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Incl. BTW"
      },
      {
        "iso": "en",
        "value": "Incl. vat"
      }
    ]
  },
  {
    "key": "UI_LICENSE_STATISTICS_USAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Verbruik"
      },
      {
        "iso": "en",
        "value": "Consumption"
      }
    ]
  },
  {
    "key": "UI_REQUESTED_LICENSE_NOT_POSSIBLE_TO_BUY",
    "values": [
      {
        "iso": "nl",
        "value": "De gekozen licentie kan niet aangekocht worden voor deze abonnee"
      },
      {
        "iso": "en",
        "value": "The chosen license can't be bought for this susbcriber"
      }
    ]
  },
  {
    "key": "UI_REQUESTED_LICENSE_NOT_POSSIBLE_TO_MANY_USERS",
    "values": [
      {
        "iso": "nl",
        "value": "Licentielimiet van {{1}} gebruikers overschreden met een huidig verbruik van {{0}} gebruikers."
      },
      {
        "iso": "en",
        "value": "License limit of {{1}} users exceeded with current usage of {{0}} users."
      }
    ]
  },
  {
    "key": "UI_REQUESTED_LICENSE_NOT_POSSIBLE_TO_MANY_DOCUMENT_USAGES",
    "values": [
      {
        "iso": "nl",
        "value": "Licentielimiet van {{1}} documenten overschreden met een huidig verbruik van {{0}} documenten."
      },
      {
        "iso": "en",
        "value": "License limit of {{1}} documents exceeded with current consumption of {{0}} documents."
      }
    ]
  },
  {
    "key": "UI_ACTIVATE_LICENSE",
    "values": [
      {
        "iso": "nl",
        "value": "Licentie activeren"
      },
      {
        "iso": "en",
        "value": "Activate license"
      }
    ]
  },
  {
    "key": "UI_LICENSE_ACTIVATED",
    "values": [
      {
        "iso": "nl",
        "value": "Licentie geactiveerd"
      },
      {
        "iso": "en",
        "value": "License activated"
      }
    ]
  },
  {
    "key": "UI_LICENSE_UPGRADED",
    "values": [
      {
        "iso": "nl",
        "value": "Licentie geüpgrade"
      },
      {
        "iso": "en",
        "value": "License upgraded"
      }
    ]
  },
  {
    "key": "UI_RESELLER_SUBSCRIBER_LICENSE_MIN_USERS",
    "values": [
      {
        "iso": "nl",
        "value": "Minimum aantal gebruikers"
      },
      {
        "iso": "en",
        "value": "Minimum number of users"
      }
    ]
  },
  {
    "key": "UI_NEW_LICENSE",
    "values": [
      {
        "iso": "nl",
        "value": "Nieuwe licentie"
      },
      {
        "iso": "en",
        "value": "New license"
      }
    ]
  },
  {
    "key": "UI_ADVISED_RESELLER_PRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Adviesprijs"
      },
      {
        "iso": "en",
        "value": "Advised price"
      }
    ]
  },
  {
    "key": "UI_REAL_RESELLER_PRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Reseller prijs"
      },
      {
        "iso": "en",
        "value": "Reseller price"
      }
    ]
  },
  {
    "key": "UI_BASEPRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Basisprijs"
      },
      {
        "iso": "en",
        "value": "Base price"
      }
    ]
  },
  {
    "key": "UI_LICENSE_DISCOUNT_MESSAGE_RESELLER",
    "values": [
      {
        "iso": "nl",
        "value": "De gekozen periode bevat een standaard korting van {{0}}%"
      },
      {
        "iso": "en",
        "value": "The chosen period has a default discount of {{0}}%"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_USAGE_EXCEEDED_RESELLER",
    "values": [
      {
        "iso": "nl",
        "value": "De limiet van {{0}} documenten voor deze licentie is overschreden"
      },
      {
        "iso": "en",
        "value": "The limit of {{0}} documents for this license has been exceeded"
      }
    ]
  },
  {
    "key": "UI_MIN_USERS_NOT_REACHED_RESELLER",
    "values": [
      {
        "iso": "nl",
        "value": "Het minimum aantal gebruikers is {{0}} gebruikers"
      },
      {
        "iso": "en",
        "value": "The minimal amount of users are {{0}} users"
      }
    ]
  },
  {
    "key": "UI_MAX_USERS_REACHED_RESELLER",
    "values": [
      {
        "iso": "nl",
        "value": "Het maximum aantal gebruikers is {{0}} gebruikers"
      },
      {
        "iso": "en",
        "value": "The maximal amount of users are {{0}} users"
      }
    ]
  },
  {
    "key": "UI_LICENSE_REQUEST_FAILED_CONTACT_SUPPORT",
    "values": [
      {
        "iso": "nl",
        "value": "Licentie aanvragen mislukt. Gelieve support te contacteren."
      },
      {
        "iso": "en",
        "value": "Failed to create license. Please contact support."
      }
    ]
  },
  {
    "key": "UI_SUBSCRIBER_ALPHA_MODULES",
    "values": [
      {
        "iso": "nl",
        "value": "Gebruik \"ALPHA\" modules"
      },
      {
        "iso": "en",
        "value": "Use \"ALPHA\" modules"
      }
    ]
  },
  {
    "key": "UI_SUBSCRIBER_ALPHA_MODULES_WARNING",
    "values": [
      {
        "iso": "nl",
        "value": "Alpha modules zijn modules die nog niet compleet afgewerkt zijn en bepaalde core elementen missen. Gebruik deze modules op eigen risico!"
      },
      {
        "iso": "en",
        "value": "Alpha modules are modules that aren't finished and are missing core components. Use them at your own risk!"
      }
    ]
  },
  {
    "key": "UI_SUBSCRIBER_ALPHA_MODULES_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "\"Alpha\" modules"
      },
      {
        "iso": "en",
        "value": "\"Alpha\" modules"
      }
    ]
  },
  {
    "key": "UI_SUBSCRIBER_BETA_MODULES",
    "values": [
      {
        "iso": "nl",
        "value": "Gebruik \"BETA\" modules"
      },
      {
        "iso": "en",
        "value": "Use \"ALPHA\" modules"
      }
    ]
  },
  {
    "key": "UI_SUBSCRIBER_BETA_MODULES_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "\"Beta\" modules"
      },
      {
        "iso": "en",
        "value": "\"Beta\" modules"
      }
    ]
  },
  {
    "key": "UI_SYSTEM_SETTINGS",
    "values": [
      {
        "iso": "nl",
        "value": "Systeem instellingen"
      },
      {
        "iso": "en",
        "value": "System settings"
      }
    ]
  },
  {
    "key": "UI_SUBSCRIBER_BETA_MODULES_WARNING",
    "values": [
      {
        "iso": "nl",
        "value": "Beta modules zijn modules die nog niet compleet afgewerkt zijn en bepaalde niet-belangrijke elementen kunnen missen. Gebruik deze modules op eigen risico!"
      },
      {
        "iso": "en",
        "value": "Beta modules are modules that aren't finished and are missing non-important components. Use them at your own risk!"
      }
    ]
  },
  {
    "key": "UI_REFERRAL_LINK",
    "values": [
      {
        "iso": "nl",
        "value": "Referral link"
      },
      {
        "iso": "en",
        "value": "Referral link"
      }
    ]
  },
  {
    "key": "UI_MY_REFERRALS",
    "values": [
      {
        "iso": "nl",
        "value": "Mijn referrals"
      },
      {
        "iso": "en",
        "value": "My referrals"
      }
    ]
  },
  {
    "key": "UI_SUBSCRIBER_SETTINGS_TIMEZONE",
    "values": [
      {
        "iso": "nl",
        "value": "Tijdzone voor geautomatiseerde taken"
      },
      {
        "iso": "en",
        "value": "Timezone for automated tasks"
      }
    ]
  },
  {
    "key": "UI_FUNCTION_NOT_YET_IMPLEMENTED",
    "values": [
      {
        "iso": "nl",
        "value": "Deze functie is momenteel nog niet beschikbaar. Probeer later opnieuw of contacteer support voor meer informatie."
      },
      {
        "iso": "en",
        "value": "This function isn't available at this moment. Please try again later or contact support for more information."
      }
    ]
  },
  {
    "key": "UI_ERROR_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Foutmelding"
      },
      {
        "iso": "en",
        "value": "Error message"
      }
    ]
  },
  {
    "key": "UI_DELETED",
    "values": [
      {
        "iso": "nl",
        "value": "Gewist"
      },
      {
        "iso": "en",
        "value": "Deleted"
      }
    ]
  },
  {
    "key": "UI_NO_TEMPLATES_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen sjablonen in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no templates in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_PARAMS",
    "values": [
      {
        "iso": "nl",
        "value": "Parameters"
      },
      {
        "iso": "en",
        "value": "Params"
      }
    ]
  },
  {
    "key": "UI_NO_METHODS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen methoden in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no methods in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_OUTSTANDING_FINANCIAL_INCOMES_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen inkomen in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no incomes in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_OUTSTANDING_FINANCIAL_PAYMENTS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen betalingen in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no payments in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_BANKS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen banken in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no banks in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_SELECT_BANK_ACCOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Rekening selecteren"
      },
      {
        "iso": "en",
        "value": "Select account"
      }
    ]
  },
  {
    "key": "UI_NO_ACCOUNT_NUMBER_CLASSES_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen klasses in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no classes in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_TRADEMARK_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen handelsnamen in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no trade marks in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_LANGUAGES_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen talen in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no languages in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_USED",
    "values": [
      {
        "iso": "nl",
        "value": "Gebruikt"
      },
      {
        "iso": "en",
        "value": "Used"
      }
    ]
  },
  {
    "key": "UI_NO_COUNTRIES_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen landen in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no countries in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_ACCOUNT_NUMBER_CATEGORIES_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen categoriën in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no categories in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_RELATIONS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen relaties in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no relationships in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_API_KEYS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen api sleutels in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no api keys in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_BANK_ACCOUNT_TRANSACTIONS_ON_TABLE",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen transacties in het systeem die aan de huidige filters voldoen voor deze bankrekening."
      },
      {
        "iso": "en",
        "value": "There are no transactions in the system that meet the current filters for this bank account."
      }
    ]
  },
  {
    "key": "UI_NO_COMBINED_ARTICLES_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen gecombineerde artikelen in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no combined articles in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_PREPAID_SERVICE_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen vooruitbetaalde diensten in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no pre-paid services in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_ARTICLES_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen artikelen in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no articles in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_PRODUCTS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen producten in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no products in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_SERVICES_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen diensten in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no services in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_STOCK_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er is geen stock in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no stock in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_COMPANIES_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen bedrijven in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no companies in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_USERS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen gebruikers in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no users in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_ACCOUNTNUMBERS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen rekeningnummers in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no account numbers in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_ADDRESSES_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen adressen in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no addresses in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_EMAILS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen e-mails in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no emails in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_TRANSLOCATIONS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen verplaatsingen in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no translocations in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_TRANSLOCATION_EXPENSES_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen verplaatsingskosten in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no translocation expenses in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_APIKEYS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen api sleutels in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no api keys in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_CUSTOMERLISTINGS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen klantenlistings in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no customer listings in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_START",
    "values": [
      {
        "iso": "nl",
        "value": "Start"
      },
      {
        "iso": "en",
        "value": "Start"
      }
    ]
  },
  {
    "key": "UI_END",
    "values": [
      {
        "iso": "nl",
        "value": "Einde"
      },
      {
        "iso": "en",
        "value": "End"
      }
    ]
  },
  {
    "key": "UI_NO_EXPORTS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen exports in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no exports in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_JOURNALS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen journalen in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no journals in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_VAT_PERIODS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen BTW periodes in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no VAT periods in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_WRITEOFFS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen afschrijvingen in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no write offs in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_ACCOUNT_NUMBERS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen boekhoudrekeningen in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no account numbers in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_ARTICLE_CATEGORIES_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen artikel categorieën in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no article categories in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_BANK_ACCOUNTS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen bankrekeningen in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no bankaccounts in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_BANK_ACCOUNT_TRANSACTIONS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen transacties voor deze bankrekening in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no transactions for this bank account in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_PSD2",
    "values": [
      {
        "iso": "nl",
        "value": "PSD2"
      },
      {
        "iso": "en",
        "value": "PSD2"
      }
    ]
  },
  {
    "key": "UI_SYNCED",
    "values": [
      {
        "iso": "nl",
        "value": "Gesynchroniseerd"
      },
      {
        "iso": "en",
        "value": "Synced"
      }
    ]
  },
  {
    "key": "UI_LINKED_BY_PSD2",
    "values": [
      {
        "iso": "nl",
        "value": "Gelinkt via PSD2"
      },
      {
        "iso": "en",
        "value": "Lnked by PSD2"
      }
    ]
  },
  {
    "key": "UI_NO_RELATION_TYPE_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen relatie types in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no relation types in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_TRANSACTION_GATEWAY_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen betaling methodes in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no payment gateways in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_VATRATE_IN_LIST_MAIN",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen BTW tarieven in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no VAT rates in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_PRODUCT_RECEPTIONS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen recepties in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no receptions in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_PURCHASE_CREDITNOTES_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen aankoop creditnota's in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no purchase creditnotes in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_PURCHASE_INVOICES_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen aankoop facturen in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no purchase invoices in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_PURCHASE_ORDER_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen aankoop bestellingen in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no purchase orders in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_DELIVERED_DOCUMENTS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen geleverde documenten in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no delivered documents in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_REPORT_REQUEST_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen aangevraagde rapporten in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no requested reports in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_SALES_CREDITNOTES_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen verkoop creditnota's in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no sales creditnotes in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_SALES_INVOICES_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen verkoop facturen in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no sales invoices in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_SALES_INVOICE_REMINDERS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen betaalherinneringen in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no payment reminders in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_SALES_ORDERS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen bestelbonnen in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no sales orders in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_PRODUCT_DELIVERIES_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen leveringen in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no deliveries in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_SALES_QUOTES_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen offertes in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no quotations in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_SALES_SUBSCRIPTIONS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen abonnementen in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no subscriptions in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_WORK_PERFORMANCES_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen werkprestaties in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no work prestations in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_ASSIGNMENTS_IN_LIST_MAIN",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen opdrachten in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no assignments in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_EMAIL_TEMPLATES_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen templates in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no templates in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_BODY_TEMPLATE",
    "values": [
      {
        "iso": "nl",
        "value": "Bericht template"
      },
      {
        "iso": "en",
        "value": "Body template"
      }
    ]
  },
  {
    "key": "UI_NO_EMAIL_RECEIVERS_FOR_THIS_EMAIL",
    "values": [
      {
        "iso": "nl",
        "value": "Geen ontvangers geregistreerd voor deze e-mail."
      },
      {
        "iso": "en",
        "value": "No recipients registered for this email."
      }
    ]
  },
  {
    "key": "UI_FOOTER_TEMPLATE",
    "values": [
      {
        "iso": "nl",
        "value": "Voettekst template"
      },
      {
        "iso": "en",
        "value": "Footer template"
      }
    ]
  },
  {
    "key": "UI_PARTNERS",
    "values": [
      {
        "iso": "nl",
        "value": "Partners"
      },
      {
        "iso": "en",
        "value": "Partners"
      }
    ]
  },
  {
    "key": "UI_IS_RESELLER",
    "values": [
      {
        "iso": "nl",
        "value": "Reseller"
      },
      {
        "iso": "en",
        "value": "Reseller"
      }
    ]
  },
  {
    "key": "UI_IS_ACCOUNTANCY",
    "values": [
      {
        "iso": "nl",
        "value": "Accountant"
      },
      {
        "iso": "en",
        "value": "Accountant"
      }
    ]
  },
  {
    "key": "UI_UPDATE_OLDER_INVOICE_DATES_ON_CONFIRM",
    "values": [
      {
        "iso": "nl",
        "value": "Verouderde factuurdatums updaten bij bevestigen"
      },
      {
        "iso": "en",
        "value": "Update older invoicedates on confirm"
      }
    ]
  },
  {
    "key": "UI_UNINVOICED_LICENSES",
    "values": [
      {
        "iso": "nl",
        "value": "Niet gefactureerde licenties"
      },
      {
        "iso": "en",
        "value": "Uninvoiced licenses"
      }
    ]
  },
  {
    "key": "UI_IS_SUPPORT",
    "values": [
      {
        "iso": "nl",
        "value": "Support"
      },
      {
        "iso": "en",
        "value": "Support"
      }
    ]
  },
  {
    "key": "UI_DISCOUNT_RATE",
    "values": [
      {
        "iso": "nl",
        "value": "Kortingspercentage"
      },
      {
        "iso": "en",
        "value": "Discount rate"
      }
    ]
  },
  {
    "key": "UI_NO_PARTNERS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen partners in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no partners in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_SUBSCRIBERS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen abonee's in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no subscribers in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_SUBSCRIBER_ONBOARDINGS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen aanmeldingen in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no onboardings in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_TRANSACTION_GATEWAYS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen betaalmethodes in het systeem die aan de huidige fiters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no payment methods in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_NO_UNPROCESSED_WRITEOFF_ARTICLES_IN_LIST_SUB",
    "values": [
      {
        "iso": "nl",
        "value": "Er zitten geen producten geschikt voor nieuwe afschrijvingen in het systeem."
      },
      {
        "iso": "en",
        "value": "There are no products for new write offs in the system."
      }
    ]
  },
  {
    "key": "UI_NO_CUSTOMERS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zitten geen klanten in het systeem die voldoen aan de huidige filter."
      },
      {
        "iso": "en",
        "value": "There are no customers in the system for the current filter."
      }
    ]
  },
  {
    "key": "UI_RELATION_VAT_SETTINGS",
    "values": [
      {
        "iso": "nl",
        "value": "BTW instellingen"
      },
      {
        "iso": "en",
        "value": "VAT settings"
      }
    ]
  },
  {
    "key": "UI_NO_SUPPLIERS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zitten geen leveranciers in het systeem die voldoen aan de huidige filter."
      },
      {
        "iso": "en",
        "value": "There are no suppliers in the system for the current filter."
      }
    ]
  },
  {
    "key": "UI_NO_CONTACTS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zitten geen contactpersonen in het systeem die voldoen aan de huidige filter."
      },
      {
        "iso": "en",
        "value": "There are no contact persons in the system for the current filter."
      }
    ]
  },
  {
    "key": "UI_NO_PROSPECTS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zitten geen prospecten in het systeem die voldoen aan de huidige filter."
      },
      {
        "iso": "en",
        "value": "There are no prospects in the system for the current filter."
      }
    ]
  },
  {
    "key": "UI_NO_SUPPLIERS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zitten geen leveranciers in het systeem die voldoen aan de huidige filter"
      },
      {
        "iso": "en",
        "value": "There are no suppliers in the system for the current filter."
      }
    ]
  },
  {
    "key": "UI_NO_COMPANIES_IN_LIST_SUB",
    "values": [
      {
        "iso": "nl",
        "value": "Er zitten geen bedrijven in het systeem. Maak een bedrijf aan via Entiteiten."
      },
      {
        "iso": "en",
        "value": "There are no companies in the system. Create a company via Entities."
      }
    ]
  },
  {
    "key": "UI_NO_BANKS_IN_LIST_SUB",
    "values": [
      {
        "iso": "nl",
        "value": "Er zitten geen banken in het systeem. Maak een bank aan via Entiteiten."
      },
      {
        "iso": "en",
        "value": "There are no banks in the system. Create a bank via Entities."
      }
    ]
  },
  {
    "key": "UI_NO_VAT_RATES_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen BTW tarieven in het systeem die aan de huidige filters voldoen."
      },
      {
        "iso": "en",
        "value": "There are no VAT rates in the system that meet the current filters."
      }
    ]
  },
  {
    "key": "UI_DEFAULT_VATRATE",
    "values": [
      {
        "iso": "nl",
        "value": "Standaard BTW tarief"
      },
      {
        "iso": "en",
        "value": "Default VAT rate"
      }
    ]
  },
  {
    "key": "UI_DEFAULT_ACCOUNTNUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "Standaard boekhoudkundig rekeningnummer"
      },
      {
        "iso": "en",
        "value": "Default ledger account number"
      }
    ]
  },
  {
    "key": "UI_INFO_SUBLINE_ARTICLE_TYPE_SWITCH",
    "values": [
      {
        "iso": "nl",
        "value": "Klik op het artikel type om deze te wijzigen"
      },
      {
        "iso": "en",
        "value": "Click on the article type to change it"
      }
    ]
  },
  {
    "key": "UI_INFO_SUBLINE_VAT_PRICE_SWITCH",
    "values": [
      {
        "iso": "nl",
        "value": "Klik op excl. / incl. om deze te wijzigen"
      },
      {
        "iso": "en",
        "value": "Click on excl. / incl. to change it"
      }
    ]
  },
  {
    "key": "UI_CHANGE_CONTENT_WIDTH",
    "values": [
      {
        "iso": "nl",
        "value": "Inhoudsbreedte aanpassen"
      },
      {
        "iso": "en",
        "value": "Adjust content width"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Document informatie"
      },
      {
        "iso": "en",
        "value": "Document information"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_COMPANY_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Bedrijfsinformatie"
      },
      {
        "iso": "en",
        "value": "Company information"
      }
    ]
  },
  {
    "key": "UI_TRADEMARK_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Handelsnaam informatie"
      },
      {
        "iso": "en",
        "value": "Trademark information"
      }
    ]
  },
  {
    "key": "UI_TRADEMARK",
    "values": [
      {
        "iso": "nl",
        "value": "Handelsnaam"
      },
      {
        "iso": "en",
        "value": "Trademark"
      }
    ]
  },
  {
    "key": "UI_TRADEMARKS",
    "values": [
      {
        "iso": "nl",
        "value": "Handelsnamen"
      },
      {
        "iso": "en",
        "value": "Trademarks"
      }
    ]
  },
  {
    "key": "UI_EXTRA_DOCUMENT_INFORMATION",
    "values": [
      {
        "iso": "nl",
        "value": "Aanvullende informatie"
      },
      {
        "iso": "en",
        "value": "Additional information"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_CUSTOMER",
    "values": [
      {
        "iso": "nl",
        "value": "Klant"
      },
      {
        "iso": "en",
        "value": "Customer"
      }
    ]
  },
  {
    "key": "UI_NO_DOCUMENTS_FOUND_FOR_THIS_DOCUMENT",
    "values": [
      {
        "iso": "nl",
        "value": "Geen gekoppelde documenten gevonden"
      },
      {
        "iso": "en",
        "value": "No linked documents found."
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_SUPPLIER",
    "values": [
      {
        "iso": "nl",
        "value": "Leverancier"
      },
      {
        "iso": "en",
        "value": "Supplier"
      }
    ]
  },
  {
    "key": "UI_NO_PURCHASE_ORDERS_IN_LIST_SUB",
    "values": [
      {
        "iso": "nl",
        "value": "Er zitten geen aankoop bestellingen in het systeem."
      },
      {
        "iso": "en",
        "value": "There are no purchase orders in the system."
      }
    ]
  },
  {
    "key": "UI_SHOW_SUBSCRIBERS_WITHOUT_LICENSE",
    "values": [
      {
        "iso": "nl",
        "value": "Toon abonnee's zonder actieve licentie"
      },
      {
        "iso": "en",
        "value": "Show subscribers without active license"
      }
    ]
  },
  {
    "key": "UI_SHOW_SUBSCRIBERS_WITH_LICENSE",
    "values": [
      {
        "iso": "nl",
        "value": "Toon abonnee's met actieve licentie"
      },
      {
        "iso": "en",
        "value": "Show subscribers with active license"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_USAGE_AMOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Documenten verbruik"
      },
      {
        "iso": "en",
        "value": "Documents usage"
      }
    ]
  },
  {
    "key": "UI_USER_USAGE_AMOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Gebruikers verbruik"
      },
      {
        "iso": "en",
        "value": "Users usage"
      }
    ]
  },
  {
    "key": "UI_SHOW_EXPORTED",
    "values": [
      {
        "iso": "nl",
        "value": "Toon geëxporteerde"
      },
      {
        "iso": "en",
        "value": "Show exported"
      }
    ]
  },
  {
    "key": "UI_SHOW_NONEXPORTED",
    "values": [
      {
        "iso": "nl",
        "value": "Toon niet geëxporteerde"
      },
      {
        "iso": "en",
        "value": "Show non exported"
      }
    ]
  },
  {
    "key": "UI_EINVOICING",
    "values": [
      {
        "iso": "nl",
        "value": "E-facturering"
      },
      {
        "iso": "en",
        "value": "E-Invoicing"
      }
    ]
  },
  {
    "key": "UI_PEPPOL",
    "values": [
      {
        "iso": "nl",
        "value": "Peppol"
      },
      {
        "iso": "en",
        "value": "Peppol"
      }
    ]
  },
  {
    "key": "UI_ZOOMIT",
    "values": [
      {
        "iso": "nl",
        "value": "Zoomit"
      },
      {
        "iso": "en",
        "value": "Zoomit"
      }
    ]
  },
  {
    "key": "UI_PEPPOL_CHANGE_PASSPORT",
    "values": [
      {
        "iso": "nl",
        "value": "Paspoort wijzigen"
      },
      {
        "iso": "en",
        "value": "Change passport"
      }
    ]
  },
  {
    "key": "UI_PEPPOL_UPLOAD_PASSPORT",
    "values": [
      {
        "iso": "nl",
        "value": "Paspoort uploaden"
      },
      {
        "iso": "en",
        "value": "Upload passport"
      }
    ]
  },
  {
    "key": "UI_LOAD_EMAIL_TEMPLATES",
    "values": [
      {
        "iso": "nl",
        "value": "Sjabloon inladen"
      },
      {
        "iso": "en",
        "value": "Load template"
      }
    ]
  },
  {
    "key": "UI_LOAD_EMAIL_SIGNATURES",
    "values": [
      {
        "iso": "nl",
        "value": "Handtekening inladen"
      },
      {
        "iso": "en",
        "value": "Load signature"
      }
    ]
  },
  {
    "key": "UI_LOAD_EMAIL_SIGNATURES",
    "values": [
      {
        "iso": "nl",
        "value": "Handtekening inladen"
      },
      {
        "iso": "en",
        "value": "Load signature"
      }
    ]
  },
  {
    "key": "UI_INVOICE_REMINDER_CREATED",
    "values": [
      {
        "iso": "nl",
        "value": "Betaalherinnering voor relatie '{{0}}' is opgemaakt."
      },
      {
        "iso": "en",
        "value": "Payment reminder for relation '{{0}}' has been created."
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_EXAMPLE_COMPANY",
    "values": [
      {
        "iso": "nl",
        "value": "Bedrijf / Handelsnaam"
      },
      {
        "iso": "en",
        "value": "Company / Trademark"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_WIZARD",
    "values": [
      {
        "iso": "nl",
        "value": "Document stijl"
      },
      {
        "iso": "en",
        "value": "Document wizard"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_EXAMPLE_EXAMPLE",
    "values": [
      {
        "iso": "nl",
        "value": "Document voorbeeld"
      },
      {
        "iso": "en",
        "value": "Document example"
      }
    ]
  },
  {
    "key": "UI_USER_EMAIL_TEMPLATES",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail sjablonen"
      },
      {
        "iso": "en",
        "value": "USer email templates"
      }
    ]
  },
  {
    "key": "UI_EMAIL_TEMPLATE_BODY",
    "values": [
      {
        "iso": "nl",
        "value": "Bericht sjabloon"
      },
      {
        "iso": "en",
        "value": "Body template"
      }
    ]
  },
  {
    "key": "UI_EMAIL_TEMPLATE_BODIES",
    "values": [
      {
        "iso": "nl",
        "value": "Bericht sjablonen"
      },
      {
        "iso": "en",
        "value": "Body templates"
      }
    ]
  },
  {
    "key": "UI_GLOBAL",
    "values": [
      {
        "iso": "nl",
        "value": "Globaal"
      },
      {
        "iso": "en",
        "value": "Global"
      }
    ]
  },
  {
    "key": "UI_GLOBAL_TEMPLATE",
    "values": [
      {
        "iso": "nl",
        "value": "Globaal sjabloon"
      },
      {
        "iso": "en",
        "value": "Global template"
      }
    ]
  },
  {
    "key": "UI_WORKHOURS",
    "values": [
      {
        "iso": "nl",
        "value": "Werkuren"
      },
      {
        "iso": "en",
        "value": "Work hours"
      }
    ]
  },
  {
    "key": "UI_EMAIL_TEMPLATE_FOOTER",
    "values": [
      {
        "iso": "nl",
        "value": "Voettekst sjabloon"
      },
      {
        "iso": "en",
        "value": "Footer template"
      }
    ]
  },
  {
    "key": "UI_EMAIL_TEMPLATE_FOOTERS",
    "values": [
      {
        "iso": "nl",
        "value": "Voettekst sjablonen"
      },
      {
        "iso": "en",
        "value": "Footer templates"
      }
    ]
  },
  {
    "key": "UI_EMAIL_BODY_FOR_AUTOMATIC_CREATED_EMAIL_INVOICE_REMINDER_LEVEL_ONE",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail voor automatisch versturen"
      },
      {
        "iso": "en",
        "value": "E-mail for automatic sending"
      }
    ]
  },
  {
    "key": "UI_EMAIL_BODY_FOR_AUTOMATIC_CREATED_EMAIL_INVOICE_REMINDER_LEVEL_TWO",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail voor automatisch versturen"
      },
      {
        "iso": "en",
        "value": "E-mail for automatic sending"
      }
    ]
  },
  {
    "key": "UI_EMAIL_BODY_FOR_AUTOMATIC_CREATED_EMAIL_INVOICE_REMINDER_LEVEL_THREE",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail voor automatisch versturen"
      },
      {
        "iso": "en",
        "value": "E-mail for automatic sending"
      }
    ]
  },
  {
    "key": "UI_INVOICE_REMINDER_CREATED_AND_CONFIRMED",
    "values": [
      {
        "iso": "nl",
        "value": "Betaalherinnering voor relatie '{{0}}' is opgemaakt en bevestigd."
      },
      {
        "iso": "en",
        "value": "Payment reminder for relation '{{0}}' has been created and confirmed."
      }
    ]
  },
  {
    "key": "UI_IS_UPGRADE",
    "values": [
      {
        "iso": "nl",
        "value": "Is een upgrade?"
      },
      {
        "iso": "en",
        "value": "Is an upgrade?"
      }
    ]
  },
  {
    "key": "UI_AUTO_CONFIRM_INVOICE_REMINDERS",
    "values": [
      {
        "iso": "nl",
        "value": "Herinneringen automatisch bevestigen"
      },
      {
        "iso": "en",
        "value": "Automatically confirm reminders"
      }
    ]
  },
  {
    "key": "UI_AUTO_EMAIL_INVOICE_REMINDERS_ON_CONFIRM",
    "values": [
      {
        "iso": "nl",
        "value": "Herinneringen automatisch e-mailen"
      },
      {
        "iso": "en",
        "value": "Automatically email reminders"
      }
    ]
  },
  {
    "key": "UI_AUTO_EMAIL_INVOICE_REMIDNERS_ON_SUCCEED",
    "values": [
      {
        "iso": "nl",
        "value": "Herinneringen automatisch e-mailen na automatische niveauverhoging"
      },
      {
        "iso": "en",
        "value": "Automatically email reminders after automatic level increase"
      }
    ]
  },
  {
    "key": "UI_INVOICE_REMINDER_CREATED_AND_SEND",
    "values": [
      {
        "iso": "nl",
        "value": "Betaalherinnering voor relatie '{{0}}' is opgemaakt, bevestigd en verzonden."
      },
      {
        "iso": "en",
        "value": "Payment reminder for relation '{{0}}' has been created, confirmed and send."
      }
    ]
  },
  {
    "key": "UI_ADD_PAYMENT",
    "values": [
      {
        "iso": "nl",
        "value": "Betaling toevoegen"
      },
      {
        "iso": "en",
        "value": "Add payment"
      }
    ]
  },
  {
    "key": "UI_ANALYSING_PDF_INVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "Factuur analyseren..."
      },
      {
        "iso": "en",
        "value": "Analyze invoice..."
      }
    ]
  },
  {
    "key": "UI_ANALYSING_PDF_INVOICE_COMPLETED",
    "values": [
      {
        "iso": "nl",
        "value": "Analyseren voltooid. De waardes zijn ingevuld."
      },
      {
        "iso": "en",
        "value": "Analyze completed. The values have been entered."
      }
    ]
  },
  {
    "key": "UI_PEPPOL_CONTACT_EMAIL",
    "values": [
      {
        "iso": "nl",
        "value": "Contact e-mail"
      },
      {
        "iso": "en",
        "value": "Contact e-mail"
      }
    ]
  },
  {
    "key": "UI_PEPPOL_CONTACT_PHONE",
    "values": [
      {
        "iso": "nl",
        "value": "Contact telefoonnummer"
      },
      {
        "iso": "en",
        "value": "Contact phonen umber"
      }
    ]
  },
  {
    "key": "UI_PEPPOL_HOMEPAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Bedrijfswebsite"
      },
      {
        "iso": "en",
        "value": "Company website"
      }
    ]
  },
  {
    "key": "UI_REMINDER_SET_AS_FINISHED",
    "values": [
      {
        "iso": "nl",
        "value": "Herinnering afsluiten"
      },
      {
        "iso": "en",
        "value": "Finish reminder"
      }
    ]
  },
  {
    "key": "UI_OPEN",
    "values": [
      {
        "iso": "nl",
        "value": "Openstaand"
      },
      {
        "iso": "en",
        "value": "Open"
      }
    ]
  },
  {
    "key": "UI_INVOICEREMINDER_AUTOMATIC_SUCCEEDED_AND_EMAIL_SEND",
    "values": [
      {
        "iso": "nl",
        "value": "De betaalherinnering {{0}} van '{{1}}' is gestegen naar level {{2}} en verzonden via e-mail naar \"{{3}}\"."
      },
      {
        "iso": "en",
        "value": "The payment reminder {{0}} of '{{1}}' has succeeded to level {{2}} and send by e-mail to \"{{3}}\"."
      }
    ]
  },
  {
    "key": "UI_INVOICEREMINDER_AUTOMATIC_SUCCEEDED",
    "values": [
      {
        "iso": "nl",
        "value": "De betaalherinnering {{0}} van '{{1}}' is gestegen naar level {{2}}."
      },
      {
        "iso": "en",
        "value": "The payment reminder {{0}} of '{{1}}' has succeeded to level {{2}}."
      }
    ]
  },
  {
    "key": "UI_VIEW_LICENSE",
    "values": [
      {
        "iso": "nl",
        "value": "Licentie bekijken"
      },
      {
        "iso": "en",
        "value": "View license"
      }
    ]
  },
  {
    "key": "UI_USER_LIMIT_REACHED_CANT_ADD_NEW_USER",
    "values": [
      {
        "iso": "nl",
        "value": "Gebruikerslimiet bereikt. Je kan jouw licentie upgraden of je verkooppunt contacteren om de limiet te verhogen."
      },
      {
        "iso": "en",
        "value": "User limit reached. You can upgrade your license or contact your point of sale to increase the limit."
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_LIMIT_REACHED_CANT_CONFIRM_DOCUMENT",
    "values": [
      {
        "iso": "nl",
        "value": "Documentenlimiet bereikt. Je kan jouw licentie upgraden of je verkooppunt contacteren om de limiet te verhogen."
      },
      {
        "iso": "en",
        "value": "Document limit reached. You can upgrade your license or contact your point of sale to increase the limit."
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_LIMIT_REACHED_CANT_CONFIRM_DOCUMENT",
    "values": [
      {
        "iso": "nl",
        "value": "Documentenlimiet bereikt. Je kan jouw licentie upgraden of je verkooppunt contacteren om de limiet te verhogen."
      },
      {
        "iso": "en",
        "value": "Document limit reached. You can upgrade your license or contact your point of sale to increase the limit."
      }
    ]
  },
  {
    "key": "UI_SEND_BY_PEPPOL_BUT_PEPPOL_IS_DISABLED",
    "values": [
      {
        "iso": "nl",
        "value": "Peppol is niet geactiveerd voor jouw account. Gelieve de integratie in te stellen alvorens te versturen via het Peppol netwerk."
      },
      {
        "iso": "en",
        "value": "Peppol is not activated for your account. Please set up integration before sending through the Peppol network."
      }
    ]
  },
  {
    "key": "UI_ADD_LINE",
    "values": [
      {
        "iso": "nl",
        "value": "Lijn toevoegen"
      },
      {
        "iso": "en",
        "value": "Add line"
      }
    ]
  },
  {
    "key": "UI_DELETE_LINE",
    "values": [
      {
        "iso": "nl",
        "value": "Lijn verwijderen"
      },
      {
        "iso": "en",
        "value": "Delete line"
      }
    ]
  },
  {
    "key": "UI_GO_TO_SETUP_PEPPOL",
    "values": [
      {
        "iso": "nl",
        "value": "Peppol instellen"
      },
      {
        "iso": "en",
        "value": "Set-up Peppol"
      }
    ]
  },
  {
    "key": "UI_UNKNOWN_ERROR",
    "values": [
      {
        "iso": "nl",
        "value": "Onbekende fout"
      },
      {
        "iso": "en",
        "value": "Unknown error"
      }
    ]
  },
  {
    "key": "UI_DOWNLOAD_FILES",
    "values": [
      {
        "iso": "nl",
        "value": "Bestanden downloaden"
      },
      {
        "iso": "en",
        "value": "Download files"
      }
    ]
  },
  {
    "key": "UI_LOADING_DOCUMENT",
    "values": [
      {
        "iso": "nl",
        "value": "Document laden..."
      },
      {
        "iso": "en",
        "value": "Loading document..."
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_LOADED",
    "values": [
      {
        "iso": "nl",
        "value": "Document geladen"
      },
      {
        "iso": "en",
        "value": "Document loaded"
      }
    ]
  },
  {
    "key": "UI_INVOICE_TRANSLOCATIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Verplaatsingen toevoegen op factuur"
      },
      {
        "iso": "en",
        "value": "Add translocations to invoice"
      }
    ]
  },
  {
    "key": "UI_AS_SINGLE_LINE",
    "values": [
      {
        "iso": "nl",
        "value": "Als één lijn"
      },
      {
        "iso": "en",
        "value": "As a single line"
      }
    ]
  },
  {
    "key": "UI_LINE_PER_TRANSLOCATION",
    "values": [
      {
        "iso": "nl",
        "value": "Als één lijn per verplaatsing"
      },
      {
        "iso": "en",
        "value": "As a line per translocation"
      }
    ]
  },
  {
    "key": "UI_DELETE_WORKPERFORMANCE",
    "values": [
      {
        "iso": "nl",
        "value": "Prestatie wissen"
      },
      {
        "iso": "en",
        "value": "Delete performance"
      }
    ]
  },
  {
    "key": "UI_IMPORT_WORKPERFORMANCES",
    "values": [
      {
        "iso": "nl",
        "value": "Importeer prestaties"
      },
      {
        "iso": "en",
        "value": "Import performances"
      }
    ]
  },
  {
    "key": "UI_IMPORT_TRANSLOCATIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Importeer verplaatsingen"
      },
      {
        "iso": "en",
        "value": "Import translocations"
      }
    ]
  },
  {
    "key": "UI_ARE_YOU_SURE_TO_DELETE_PEPPOL_INTEGRATION",
    "values": [
      {
        "iso": "nl",
        "value": "Ben je er zeker van dat je de Peppol integratie wenst te onderbreken? Je kan geen peppol facturen meer versturen of ontvangen."
      },
      {
        "iso": "en",
        "value": "Are you sure you wish to disconnect the Peppol integration? You can no longer send or receive Peppol invoices."
      }
    ]
  },
  {
    "key": "UI_SENT",
    "values": [
      {
        "iso": "nl",
        "value": "Verzonden"
      },
      {
        "iso": "en",
        "value": "Sent"
      }
    ]
  },
  {
    "key": "UI_SET_AS_DEFAULT_SUBSCRIBER",
    "values": [
      {
        "iso": "nl",
        "value": "Abonnee instellen als standaard"
      },
      {
        "iso": "en",
        "value": "Set subscriber as default"
      }
    ]
  },
  {
    "key": "UI_REGISTERING_SUBSCRIBER_TO_PEPPOL_NETWORK",
    "values": [
      {
        "iso": "nl",
        "value": "Abonnee registreren bij het Peppol netwerk"
      },
      {
        "iso": "en",
        "value": "Register subscriber with the Peppol network"
      }
    ]
  },
  {
    "key": "UI_SUBSCRIBER_REGISTERED_TO_PEPPOL_NETWORK",
    "values": [
      {
        "iso": "nl",
        "value": "Abonnee is geregistreerd bij het Peppol network"
      },
      {
        "iso": "en",
        "value": "Subscriber is registered with the Peppol network"
      }
    ]
  },
  {
    "key": "UI_SUBSCRIBER_REGISTRATION_FAILED_TO_PEPPOL_NETWORK",
    "values": [
      {
        "iso": "nl",
        "value": "Abonnee kon niet geregistreerd worden bij het Peppol netwerk. Gelieve ingevoerde gegevens na te kijken of support te contacteren."
      },
      {
        "iso": "en",
        "value": "Subscriber could not be registered with Peppol network. Please check entered data or contact support."
      }
    ]
  },
  {
    "key": "UI_NO_BANK_ACCOUNTS_FOUND_FOR_RELATION",
    "values": [
      {
        "iso": "nl",
        "value": "Er zijn geen bankrekeningen geregistreerd voor de relatie"
      },
      {
        "iso": "en",
        "value": "No bank accounts are registered for the relation"
      }
    ]
  },
  {
    "key": "UI_OCTOPUS_RELATION_SYNC_SET_ALL",
    "values": [
      {
        "iso": "nl",
        "value": "Import actie aanpassen voor alle relaties"
      },
      {
        "iso": "en",
        "value": "Adjust import action for all relations"
      }
    ]
  },
  {
    "key": "UI_PEPPOL_STATUS",
    "values": [
      {
        "iso": "nl",
        "value": "Peppol status"
      },
      {
        "iso": "en",
        "value": "Peppol status"
      }
    ]
  },
  {
    "key": "UI_PEPPOL_STATUS_UNKNOWN",
    "values": [
      {
        "iso": "nl",
        "value": "Onbekend"
      },
      {
        "iso": "en",
        "value": "Unknown"
      }
    ]
  },
  {
    "key": "UI_PEPPOL_STATUS_CREATED",
    "values": [
      {
        "iso": "nl",
        "value": "Aangemaakt"
      },
      {
        "iso": "en",
        "value": "Created"
      }
    ]
  },
  {
    "key": "UI_PEPPOL_STATUS_APPROVED",
    "values": [
      {
        "iso": "nl",
        "value": "Gevalideerd"
      },
      {
        "iso": "en",
        "value": "Approved"
      }
    ]
  },
  {
    "key": "UI_PEPPOL_STATUS_REJECTED",
    "values": [
      {
        "iso": "nl",
        "value": "Afgekeurd"
      },
      {
        "iso": "en",
        "value": "Rejected"
      }
    ]
  },
  {
    "key": "UI_PEPPOL_STATUS_ONBOARDED",
    "values": [
      {
        "iso": "nl",
        "value": "Actief"
      },
      {
        "iso": "en",
        "value": "Onboarded"
      }
    ]
  },
  {
    "key": "UI_PEPPOL_STATUS_OFFBOARDED",
    "values": [
      {
        "iso": "nl",
        "value": "Afgemeld"
      },
      {
        "iso": "en",
        "value": "Offboarded"
      }
    ]
  },
  {
    "key": "UI_SUBSCRIBER_LEGAL_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Legale bedrijfsnaam"
      },
      {
        "iso": "en",
        "value": "Legal company name"
      }
    ]
  },
  {
    "key": "UI_SUBSCRIBER_VAT_NUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "BTW nummer"
      },
      {
        "iso": "en",
        "value": "VAT number"
      }
    ]
  },
  {
    "key": "UI_NA",
    "values": [
      {
        "iso": "nl",
        "value": "nvt"
      },
      {
        "iso": "en",
        "value": "N/A"
      }
    ]
  },
  {
    "key": "UI_SHOW_VALUES_AS_POSITIVE_VALUES",
    "values": [
      {
        "iso": "nl",
        "value": "Toon de waardes als een positief getal"
      },
      {
        "iso": "en",
        "value": "Show the values as a positive number"
      }
    ]
  },
  {
    "key": "MSG_DOCUMENT_GENERATION_STARTED",
    "values": [
      {
        "iso": "nl",
        "value": "Uw document wordt aangemaakt..."
      },
      {
        "iso": "en",
        "value": "Genrating your document..."
      }
    ]
  },
  {
    "key": "MSG_DOCUMENT_GENERATION_FAILED",
    "values": [
      {
        "iso": "nl",
        "value": "Er is een fout opgetreden tijdens het genereren van uw document"
      },
      {
        "iso": "en",
        "value": "An error has occured while generating your document"
      }
    ]
  },
  {
    "key": "MSG_DOCUMENT_GENERATION_COMPLETED",
    "values": [
      {
        "iso": "nl",
        "value": "Document is aangemaakt"
      },
      {
        "iso": "en",
        "value": "Document has been generated"
      }
    ]
  },
  {
    "key": "MSG_CHECK_VAT_IN_PROGRESS",
    "values": [
      {
        "iso": "nl",
        "value": "BTW nummer controleren..."
      },
      {
        "iso": "en",
        "value": "Checking VAT number..."
      }
    ]
  },
  {
    "key": "MSG_CHECK_VAT_UNVALID_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Het BTW number is niet geldig of gelimiteerd!"
      },
      {
        "iso": "en",
        "value": "The VAT number is unvalid or limited!"
      }
    ]
  },
  {
    "key": "MSG_CHECK_VAT_ERROR",
    "values": [
      {
        "iso": "nl",
        "value": "Het BTW number kon niet gecontroleerd worden!"
      },
      {
        "iso": "en",
        "value": "The VAT number could not be checked!"
      }
    ]
  },
  {
    "key": "MSG_CHECK_VAT_VALID_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Het BTW number is geldig!"
      },
      {
        "iso": "en",
        "value": "The VAT number is valid!"
      }
    ]
  },
  {
    "key": "MSG_PEPPOL_SENDING_FOR_INVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "Factuur {{0}} versturen via het peppol netwerk"
      },
      {
        "iso": "en",
        "value": "Sending invoice {{0}} through the peppol network"
      }
    ]
  },
  {
    "key": "MSG_PEPPOL_SENDING_FOR_INVOICE_COMPLETED",
    "values": [
      {
        "iso": "nl",
        "value": "Factuur {{0}} verstuurd via het peppol network"
      },
      {
        "iso": "en",
        "value": "Sended invoice {{0}} through the peppol network"
      }
    ]
  },
  {
    "key": "MSG_PEPPOL_SENDING_FOR_INVOICE_FAILED",
    "values": [
      {
        "iso": "nl",
        "value": "Het versturen van factuur {{0}} via het peppol network is mislukt"
      },
      {
        "iso": "en",
        "value": "Sending invoice {{0}} via the peppol network failed"
      }
    ]
  },
  {
    "key": "MSG_PEPPOL_SENDING_FOR_CREDITNOTE",
    "values": [
      {
        "iso": "nl",
        "value": "Creditnota {{0}} versturen via het peppol netwerk"
      },
      {
        "iso": "en",
        "value": "Sending creditnote {{0}} through the peppol network"
      }
    ]
  },
  {
    "key": "MSG_PEPPOL_SENDING_FOR_CREDITNOTE_COMPLETED",
    "values": [
      {
        "iso": "nl",
        "value": "Creditnota {{0}} verstuurd via het peppol network"
      },
      {
        "iso": "en",
        "value": "Sended creditnote {{0}} through the peppol network"
      }
    ]
  },
  {
    "key": "MSG_PEPPOL_SENDING_FOR_CREDITNOTE_FAILED",
    "values": [
      {
        "iso": "nl",
        "value": "Het versturen van creditnota {{0}} via het peppol network is mislukt"
      },
      {
        "iso": "en",
        "value": "Sending creditnote {{0}} via the peppol network failed"
      }
    ]
  },
  {
    "key": "MSG_EMAIL_SENDING_FOR_WORKPERFORMANCE",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail versturen voor werkbon"
      },
      {
        "iso": "en",
        "value": "Sending email for work slip"
      }
    ]
  },
  {
    "key": "MSG_EMAIL_SENDING_FOR_WORKPERFORMANCE_COMPLETED",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail versturen voor werkbon geslaagd"
      },
      {
        "iso": "en",
        "value": "Sending email for work slip succeeded"
      }
    ]
  },
  {
    "key": "MSG_EMAIL_SENDING_FOR_WORKPERFORMANCE_FAILED",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail versturen voor werkbon mislukt"
      },
      {
        "iso": "en",
        "value": "Sending email for work slip failed"
      }
    ]
  },
  {
    "key": "MSG_EMAIL_SENDING_FOR_INVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail versturen voor factuur {{0}}"
      },
      {
        "iso": "en",
        "value": "Sending email for invoice {{0}}"
      }
    ]
  },
  {
    "key": "MSG_EMAIL_SENDING_FOR_INVOICE_COMPLETED",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail versturen voor factuur {{0}} geslaagd"
      },
      {
        "iso": "en",
        "value": "Sending email for invoice {{0}} succeeded"
      }
    ]
  },
  {
    "key": "MSG_EMAIL_SENDING_FOR_INVOICE_FAILED",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail versturen voor factuur {{0}} mislukt"
      },
      {
        "iso": "en",
        "value": "Sending email for invoice {{0}} failed"
      }
    ]
  },
  {
    "key": "MSG_EMAIL_SENDING_FOR_INVOICE_REMINDER_COMPLETED",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail versturen voor herinnering {{0}} geslaagd"
      },
      {
        "iso": "en",
        "value": "Sending email for reminder {{0}} succeeded"
      }
    ]
  },
  {
    "key": "MSG_EMAIL_SENDING_FOR_INVOICE_REMINDER_FAILED",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail versturen voor herinnering {{0}} mislukt"
      },
      {
        "iso": "en",
        "value": "Sending email for reminder {{0}} failed"
      }
    ]
  },
  {
    "key": "MSG_EMAIL_SENDING_FOR_QUOTE",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail versturen voor offerte {{0}}"
      },
      {
        "iso": "en",
        "value": "Sending email for quote {{0}}"
      }
    ]
  },
  {
    "key": "MSG_EMAIL_SENDING_FOR_QUOTE_COMPLETED",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail versturen voor offerte {{0}} geslaagd"
      },
      {
        "iso": "en",
        "value": "Sending email for quote {{0}} succeeded"
      }
    ]
  },
  {
    "key": "MSG_EMAIL_SENDING_FOR_QUOTE_FAILED",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail versturen voor offerte {{0}} mislukt"
      },
      {
        "iso": "en",
        "value": "Sending email for quote {{0}} failed"
      }
    ]
  },
  {
    "key": "MSG_EMAIL_SENDING_FOR_WORKPERFORMANCE_COMPLETED",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail versturen voor werkprestatie geslaagd"
      },
      {
        "iso": "en",
        "value": "Sending email for workperformance succeeded"
      }
    ]
  },
  {
    "key": "MSG_EMAIL_SENDING_FOR_WORKPERFORMANCE_FAILED",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail versturen voor werkprestatie mislukt"
      },
      {
        "iso": "en",
        "value": "Sending email for workperformance failed"
      }
    ]
  },
  {
    "key": "MSG_EMAIL_SENDING_FOR_ORDER_COMPLETED",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail versturen voor bestelling {{0}} geslaagd"
      },
      {
        "iso": "en",
        "value": "Sending email for order {{0}} succeeded"
      }
    ]
  },
  {
    "key": "MSG_EMAIL_SENDING_FOR_ORDER_FAILED",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail versturen voor bestelling {{0}} mislukt"
      },
      {
        "iso": "en",
        "value": "Sending email for order {{0}} failed"
      }
    ]
  },
  {
    "key": "MSG_EMAIL_SENDING_FOR_CREDITNOTE",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail versturen voor creditnota {{0}}"
      },
      {
        "iso": "en",
        "value": "Sending email for creditnote {{0}}"
      }
    ]
  },
  {
    "key": "MSG_EMAIL_SENDING_FOR_CREDITNOTE_COMPLETED",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail versturen voor creditnota {{0}} geslaagd"
      },
      {
        "iso": "en",
        "value": "Sending email for creditnote {{0}} succeeded"
      }
    ]
  },
  {
    "key": "MSG_EMAIL_SENDING_FOR_CREDITNOTE_FAILED",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail versturen voor creditnota {{0}} mislukt"
      },
      {
        "iso": "en",
        "value": "Sending email for creditnote {{0}} failed"
      }
    ]
  },
  {
    "key": "MSG_EMAIL_SENDING_FOR_SALES_INVOICE_REMINDER",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail versturen voor betaalherinnering {{0}}"
      },
      {
        "iso": "en",
        "value": "Sending email for payment reminder {{0}}"
      }
    ]
  },
  {
    "key": "MSG_EMAIL_SENDING_FOR_SALES_INVOICE_REMINDER_COMPLETED",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail versturen voor betaalherinnering {{0}} geslaagd"
      },
      {
        "iso": "en",
        "value": "Sending email for payment reminder {{0}} succeeded"
      }
    ]
  },
  {
    "key": "MSG_EMAIL_SENDING_FOR_SALES_INVOICE_REMINDER_FAILED",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail versturen voor betaalherinnering {{0}} mislukt"
      },
      {
        "iso": "en",
        "value": "Sending email for payment reminder {{0}} failed"
      }
    ]
  },
  {
    "key": "MSG_EMAIL_SENDING_FOR_SALES_ORDER",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail versturen voor bestelbon {{0}}"
      },
      {
        "iso": "en",
        "value": "Sending email for order {{0}}"
      }
    ]
  },
  {
    "key": "MSG_EMAIL_SENDING_FOR_SALES_ORDER_COMPLETED",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail versturen voor bestelbon {{0}} geslaagd"
      },
      {
        "iso": "en",
        "value": "Sending email for order {{0}} succeeded"
      }
    ]
  },
  {
    "key": "MSG_EMAIL_SENDING_FOR_SALES_ORDER_FAILED",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail versturen voor bestelbon {{0}} mislukt"
      },
      {
        "iso": "en",
        "value": "Sending email for order {{0}} failed"
      }
    ]
  },
  {
    "key": "MSG_TRANSLOCATION_CALCULATE_DISTANCE",
    "values": [
      {
        "iso": "nl",
        "value": "Afstand berekenen..."
      },
      {
        "iso": "en",
        "value": "Calculating distance..."
      }
    ]
  },
  {
    "key": "MSG_TRANSLOCATION_CALCULATED_DISTANCE",
    "values": [
      {
        "iso": "nl",
        "value": "Afstand berekend."
      },
      {
        "iso": "en",
        "value": "Distance calculated."
      }
    ]
  },
  {
    "key": "MSG_RESYNC_STARTED",
    "values": [
      {
        "iso": "nl",
        "value": "Syncronisatie wordt uitgevoerd..."
      },
      {
        "iso": "en",
        "value": "Syncronisation is being processed..."
      }
    ]
  },
  {
    "key": "MSG_RESYNC_COMPLETED",
    "values": [
      {
        "iso": "nl",
        "value": "Syncronisatie uitgevoerd."
      },
      {
        "iso": "en",
        "value": "Syncronisation completed."
      }
    ]
  },
  {
    "key": "MSG_RESYNC_FAILED",
    "values": [
      {
        "iso": "nl",
        "value": "Syncronistie gefaald."
      },
      {
        "iso": "en",
        "value": "Syncronisation failed."
      }
    ]
  },
  {
    "key": "EX_COMPANY",
    "values": [
      {
        "iso": "nl",
        "value": "Selecteer een bedrijf"
      },
      {
        "iso": "en",
        "value": "Select a company"
      }
    ]
  },
  {
    "key": "EX_LOGIN",
    "values": [
      {
        "iso": "nl",
        "value": "naam@bedrijf.be"
      },
      {
        "iso": "en",
        "value": "name@company.com"
      }
    ]
  },
  {
    "key": "EX_EMAIL",
    "values": [
      {
        "iso": "nl",
        "value": "voorbeeld@email.be"
      },
      {
        "iso": "en",
        "value": "example@email.com"
      }
    ]
  },
  {
    "key": "EX_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Jan Jansens"
      },
      {
        "iso": "en",
        "value": "John doe"
      }
    ]
  },
  {
    "key": "EX_PASSWORD",
    "values": [
      {
        "iso": "nl",
        "value": "SuperGeheimWachtwoord123!"
      },
      {
        "iso": "en",
        "value": "SuperSecretPassword123!"
      }
    ]
  },
  {
    "key": "EX_SELECT_ADDRESS_COUNTRY",
    "values": [
      {
        "iso": "nl",
        "value": "Selecteer een land"
      },
      {
        "iso": "en",
        "value": "Select a country"
      }
    ]
  },
  {
    "key": "LICENSE_NAME_START",
    "values": [
      {
        "iso": "nl",
        "value": "Start"
      },
      {
        "iso": "en",
        "value": "Start"
      }
    ]
  },
  {
    "key": "LICENSE_NAME_STANDARD",
    "values": [
      {
        "iso": "nl",
        "value": "Standard"
      },
      {
        "iso": "en",
        "value": "Standard"
      }
    ]
  },
  {
    "key": "LICENSE_NAME_UNLIMITED",
    "values": [
      {
        "iso": "nl",
        "value": "Unlimited"
      },
      {
        "iso": "en",
        "value": "Unlimited"
      }
    ]
  },
  {
    "key": "LICENSE_DESCRIPTION_START",
    "values": [
      {
        "iso": "nl",
        "value": "<p>Het &quot;Start&quot; abonnement is ideaal voor <strong>beginnende ondernemers</strong> en <strong>bijberoepers </strong>die nog volop bezig zijn met groeien. Het &quot;Start&quot; abonnement geeft je de mogelijkheid tot <strong>60 aankoop- en verkoopfacturen</strong> per <strong>jaar </strong>toe te voegen, zonder andere beperkingen. Groeit jouw onderneming sneller dan verwacht? Geen zorgen, je kunt <strong>eenvoudig upgraden</strong> naar een &quot;Standard&quot; of &quot;Unlimited&quot; abonnement.&nbsp;<br />Het Start abonnement geeft gratis toegang voor <strong>&eacute;&eacute;n gebruiker</strong>, die uitgebreid kan worden met nog een <strong>extra gebruiker</strong> voor slechts <strong>6&euro;</strong>. Je <strong>boekhouder </strong>kan bovendien jouw dossier <strong>gratis </strong>raadplegen om advies geven waar nodig.&nbsp;<br />Dankzij de <strong>lage maandelijkse kosten</strong> heb je alle middelen ter beschikking om uw bedrijf te laten groeien. Zo kun je al je aandacht richten op je passie in plaats van de vervelende administratie!</p><p>● 60 aankoop- en verkoop facturen per jaar. (incl. creditnota&#39;s)<br />● Alle functies ter beschikking&nbsp;<br />● Extra gebruiker kost &euro;6<br />● Maximum 2 gebruikers</p>"
      },
      {
        "iso": "en",
        "value": "<p>The &quot;Start&quot; subscription is ideal for <strong>starting entrepreneurs</strong> and <strong>secondary professionals</strong> who are still growing. The &quot;Start&quot; subscription allows you to add up to <strong>60 purchase and sales invoices</strong> per <strong>year</strong>, without any other restrictions. Is your business growing faster than expected? Don't worry, you can <strong>easily upgrade</strong> to a &quot;Standard&quot; or &quot;Unlimited&quot; subscription.&nbsp;<br />The Start subscription gives free access for <strong>&eacute;&eacute;one user</strong>, which can be extended with an <strong>additional user</strong> for only <strong> 6&euro;</strong>. Your <strong>bookkeeper</strong> can also consult your files <strong>free of charge</strong> to give advice where necessary.&nbsp;<br />Thanks to the <strong>low monthly costs</strong> you have all the resources at your disposal. available to grow your business. This way you can focus all your attention on your passion instead of the annoying administration!</p><p>● 60 purchase and sales invoices per year. (incl. credit notes)<br />● All functions available&nbsp;<br />● Extra user costs &euro;6<br />● Maximum 2 users</p>"
      }
    ]
  },
  {
    "key": "LICENSE_DESCRIPTION_STANDARD",
    "values": [
      {
        "iso": "nl",
        "value": "<p>Het &quot;Standard&quot; abonnement is ideaal voor <strong>eenmanszaken </strong>en <strong>kleine venootschappen&nbsp;</strong>die al een gevestigde waarde hebben opgebouwd. Het &quot;Standard&quot; abonnement geeft je de mogelijkheid tot <strong>240 aankoop- en verkoopfacturen</strong> per <strong>jaar </strong>toe te voegen, zonder andere beperkingen. Groeit jouw onderneming sneller dan verwacht? Geen zorgen, je kunt <strong>eenvoudig upgraden</strong> naar een &quot;Unlimited&quot; abonnement.&nbsp;<br />Het Standard abonnement geeft gratis toegang voor <strong>&eacute;&eacute;n gebruiker</strong>, die uitgebreid kan worden met meerdere <strong>extra gebruikers</strong> voor slechts <strong>5&euro; per gebruiker</strong>. Je <strong>boekhouder </strong>kan jouw dossier <strong>gratis </strong>raadplegen en advies geven waar nodig.&nbsp;<br />Dankzij de <strong>lage maandelijkse kosten</strong> heb je alle middelen ter beschikking om uw bedrijf te laten groeien. Zo kun je al je aandacht richten op je passie in plaats van de vervelende administratie!</p><p>● 240 aankoop- en verkoop facturen per jaar. (incl. creditnota&#39;s)<br />● Alle functies ter beschikking&nbsp;<br />● Extra gebruiker kost &euro;5</p>"
      },
      {
        "iso": "en",
        "value": "<p>The &quot;Standard&quot; subscription is ideal for <strong>one-man businesses</strong>and <strong>small companies&nbsp;</strong>that have already built up an established value. The &quot;Standard&quot; subscription allows you to add up to <strong>240 purchase and sales invoices</strong> per <strong>year</strong>, without any other restrictions. Is your business growing faster than expected? Don't worry, you can <strong>easily upgrade</strong> to an &quot;Unlimited&quot; subscription.&nbsp;<br />The Standard subscription gives free access for <strong>&eacute;&eacute;one user</strong>, which can be extended with multiple <strong>additional user</strong> for just <strong> 5 Euro; per user</strong>. Your <strong>bookkeeper</strong> can also consult your files <strong>free of charge</strong> to give advice where necessary.&nbsp;<br />Thanks to the <strong>low monthly costs</strong> you have all the resources at your disposal. available to grow your business. This way you can focus all your attention on your passion instead of the annoying administration!</p><p>● 240 purchase and sales invoices per year. (incl. credit notes)<br />● All functions available&nbsp;<br />● Additional user costs &euro;5</p>"
      }
    ]
  },
  {
    "key": "LICENSE_DESCRIPTION_UNLIMITED",
    "values": [
      {
        "iso": "nl",
        "value": "<p>Het &quot;Unlimited&quot; abonnement is ideaal voor <strong>zelfstandigen&nbsp;</strong>en <strong>vennootschappen&nbsp;</strong>die al een gevestigde waarde en mooi klantenbestand hebben opgebouwd. Het &quot;Unlimited&quot; abonnement geeft je de mogelijkheid te genieten van D&#39;Office&nbsp;<strong>zonder beperkingen</strong>.&nbsp;<br />Het Unlimited abonnement geeft gratis toegang voor <strong>twee gebruiker</strong>, die uitgebreid kan worden met meerdere&nbsp;<strong>extra gebruikers</strong> voor slechts 4<strong>&euro; per gebruiker</strong>. Je <strong>boekhouder </strong>kan jouw dossier bovendien&nbsp;<strong>gratis </strong>raadplegen en advies geven waar nodig.&nbsp;<br />Dankzij het <strong>grenzenloze abonnement</strong>&nbsp;heb je alle middelen ter beschikking om uw bedrijf de kansen te geven die het verdient. Zo kun je al je aandacht richten op je passie in plaats van de vervelende administratie!</p><p>● Alle functies ter beschikking&nbsp;<br />● Extra gebruiker kost &euro;4</p>"
      },
      {
        "iso": "en",
        "value": "<p>The &quot;Unlimited&quot; subscription is ideal for <strong>self-employed persons&nbsp;</strong>and <strong>companies&nbsp;</strong>who have already built up an established value and attractive customer base. The &quot;Unlimited&quot; subscription gives you the opportunity to enjoy D&#39;Office&nbsp;<strong>without restrictions</strong>.&nbsp;<br />The Unlimited subscription gives free access for <strong>two users</strong>, which can be expanded with several&nbsp;<strong>additional users</strong> for only 4<strong>&euro; per user</strong>. Your <strong>bookkeeper</strong> can also consult your files&nbsp;<strong>free of charge</strong> and give advice where necessary.&nbsp;<br />Thanks to the <strong>boundless subscription</strong>&nbsp;you have all resources available to give your company the opportunities it deserves. This way you can focus all your attention on your passion instead of the annoying administration!</p><p>● All functions available&nbsp;<br />● Additional user costs &euro;4</p>"
      }
    ]
  },
  {
    "key": "LICENSE_DESCRIPTION_ATTENTIONS_START",
    "values": [
      {
        "iso": "nl",
        "value": "<p><sup>1</sup> De limieten zijn gecombineerd over aankoop facturen, aankoop creditnota's, verkoop facturen en verkoop creditnota's.<br /><sup>2</sup> De syncronisatie is gebaseerd op het europese PSD2 systeem ondersteund door de meeste europese banken. Voor een volledig overzicht kunt u support raadplegen.<br /><sup>3</sup> Bekijk de huidige boekhoud integraties op onze website. Vind u uw boekhoudprogramma niet terug? Neem contact op voor de mogelijkheden!<br /></p>"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "LICENSE_DESCRIPTION_ATTENTIONS_STANDARD",
    "values": [
      {
        "iso": "nl",
        "value": "<p><sup>1</sup> De limieten zijn gecombineerd over aankoop facturen, aankoop creditnota's, verkoop facturen en verkoop creditnota's.<br /><sup>2</sup> De syncronisatie is gebaseerd op het europese PSD2 systeem ondersteund door de meeste europese banken. Voor een volledig overzicht kunt u support raadplegen.<br /><sup>3</sup> Bekijk de huidige boekhoud integraties op onze website. Vind u uw boekhoudprogramma niet terug? Neem contact op voor de mogelijkheden!<br /></p>"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "LICENSE_DESCRIPTION_ATTENTIONS_UNLIMITED",
    "values": [
      {
        "iso": "nl",
        "value": "<p><sup>1</sup> De limieten zijn gecombineerd over aankoop facturen, aankoop creditnota's, verkoop facturen en verkoop creditnota's.<br /><sup>2</sup> De syncronisatie is gebaseerd op het europese PSD2 systeem ondersteund door de meeste europese banken. Voor een volledig overzicht kunt u support raadplegen.<br /><sup>3</sup> Bekijk de huidige boekhoud integraties op onze website. Vind u uw boekhoudprogramma niet terug? Neem contact op voor de mogelijkheden!<br /></p>"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_FRAME_2",
    "values": [
      {
        "iso": "nl",
        "value": "Kader 2: Uitgaande handelingen"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_FRAME_3",
    "values": [
      {
        "iso": "nl",
        "value": "Kader 3: Inkomende handelingen"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_FRAME_4",
    "values": [
      {
        "iso": "nl",
        "value": "Kader 4: Verschuldigde belastingen"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_FRAME_5",
    "values": [
      {
        "iso": "nl",
        "value": "Kader 5: Aftrekbare belasting"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_FRAME_6",
    "values": [
      {
        "iso": "nl",
        "value": "Kader 6: Saldo"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_FRAME_2_SECTION_A",
    "values": [
      {
        "iso": "nl",
        "value": "A: Handelingen onderworpen aan een bijzondere regeling"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_FRAME_2_SECTION_B",
    "values": [
      {
        "iso": "nl",
        "value": "B: Handelingen waarvan de BTW verschuldigd is door de aangever"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_FRAME_2_SECTION_C",
    "values": [
      {
        "iso": "nl",
        "value": "C: Diensten waarvoor de buitenlandse BTW verschuldigd is door de medecontractant"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_FRAME_2_SECTION_E",
    "values": [
      {
        "iso": "nl",
        "value": "E: Vrijgestelde intracommunautaire leveringen verricht in België en ABC-verkopen"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_FRAME_2_SECTION_G",
    "values": [
      {
        "iso": "nl",
        "value": "G: Bedrag van de uitgereikte creditnota's en de negatieve verbeteringen"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_ROSTER_00",
    "values": [
      {
        "iso": "nl",
        "value": "Aan tarief van 0%"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_ROSTER_00_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Verkopen aan belgische klanten: het bedrag excl. BTW waarop u 0% BTW moet aanrekenen."
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_ROSTER_01",
    "values": [
      {
        "iso": "nl",
        "value": "Aan het tarief van 6%"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_ROSTER_02",
    "values": [
      {
        "iso": "nl",
        "value": "Aan het tarief van 12%"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_ROSTER_03",
    "values": [
      {
        "iso": "nl",
        "value": "Aan het tarief van 21%"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_ROSTER_01_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Verkopen aan belgische klanten: het bedrag excl. BTW waarop u 6% BTW moet aanrekenen."
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_ROSTER_02_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Verkopen aan belgische klanten: het bedrag excl. BTW waarop u 12% BTW moet aanrekenen."
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_ROSTER_03_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Verkopen aan belgische klanten: het bedrag excl. BTW waarop u 21% BTW moet aanrekenen."
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_ROSTER_44_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Verkopen van diensten aan europese BTW plichtige klanten (=Intracommunautaire verkoop): Het bedrag van de verkoop (er wordt geen BTW aangerekend)"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_ROSTER_46_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Verkopen van goederen aan europese BTW plichtige klant welke geleverd worden in dat EU land (=intracommunautaire verkoop): Het bedrag van de verkoop (er wordt geen BTW aangerekend)"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_ROSTER_48",
    "values": [
      {
        "iso": "nl",
        "value": "met betrekking tot de handelingen ingeschreven in de roosten 44 en 46"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_ROSTER_49",
    "values": [
      {
        "iso": "nl",
        "value": "met betrekking tot de andere handelingen van kader II"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_ROSTER_49_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Annulering van verstuurde factuur aan belgische klant via creditnota: Het bedrag excl. BTW."
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_FRAME_3_SECTION_A",
    "values": [
      {
        "iso": "nl",
        "value": "A: Bedrag van de inkomende handelingen rekening houdend met de ontvangen creditnota's en de andere verbeteringen"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_FRAME_3_SECTION_C",
    "values": [
      {
        "iso": "nl",
        "value": "C: Intracommunaitaire verwervingen verricht in België en ABC-verkopen"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_FRAME_3_SECTION_E",
    "values": [
      {
        "iso": "nl",
        "value": "E: Intracommunautaire diensten met verlegging van heffing"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_ROSTER_81",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoop van handelsgoederen (100% beroepsgebruik en geen aftrekbeperking)"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_ROSTER_82",
    "values": [
      {
        "iso": "nl",
        "value": "diensten en diverse goederen"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_ROSTER_83",
    "values": [
      {
        "iso": "nl",
        "value": "bedrijfsmiddelen"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_ROSTER_81_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoop van handelsgoederen (100% beroepsgebruik en geen aftrekbeperking)"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_ROSTER_82_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Bedrag van (beroepsgedeelte) aankoop van diverse goederen (alles behalve de investeringen) en diensten excl aftrekbare BTW"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_ROSTER_83_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Bedrag van (beroepsgedeelte) aankoop van investeringen (= afschrijfbaar over meerdere jaren) excl aftrekbare BTW"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_ROSTER_86_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoop van handelsgoederen/investeringen bij een leverancier in een ander EU land met levering in België (=intracommunautaire aankoop): Bedrag van aankoop"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_ROSTER_88_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoop van diensten bij een leverancier in een ander EU land (=intacommunautaire aankoop): Bedrag van aankoop"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_FRAME_4_SECTION_A",
    "values": [
      {
        "iso": "nl",
        "value": "A: BTW op de handelingen aangegeven in"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_FRAME_4_SECTION_TOTAL",
    "values": [
      {
        "iso": "nl",
        "value": "Totaal van de roosters 54, 55, 56, 57, 61 en 63"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_ROSTER_54",
    "values": [
      {
        "iso": "nl",
        "value": "De roosters 01, 02 en 03"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_ROSTER_55",
    "values": [
      {
        "iso": "nl",
        "value": "De roosters 86 en 88"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_ROSTER_54_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Verkopen aan belgische klanten: Het BTW bedrag"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_ROSTER_55_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoop van diensten of investeringen met levering in belgië bij een leverancier in een ander eu land (intracommunautaire aankoop) = BTW die u normaalgezien op de intracommunautaire aankopen zou betaald hebben."
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_ROSTER_XX_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Totaal van de roosters [54] + [55] + [56] + [57] + [61] + [63] van de aangifteperiode"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_FRAME_5_SECTION_A",
    "values": [
      {
        "iso": "nl",
        "value": "A: Aftrekbare BTW"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_FRAME_5_SECTION_C",
    "values": [
      {
        "iso": "nl",
        "value": "C: Te recupereren BTW vermeld op de uitgereikte creditnota's"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_FRAME_5_SECTION_TOTAL",
    "values": [
      {
        "iso": "nl",
        "value": "Totaal van de roosters 59, 62 en 64"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_ROSTER_59_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Aftrekbare BTW bij aankoop van diensten, goederen en investeringen (incl intracommunaitaire BTW uit rooster 55 en diensten van een bedrijf buiten de EU)"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_ROSTER_64_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Annulering van verstuurde factuur aan belgische klant via creditnota: De BTW die je terug kan trekken naar aanleiding van de creditnota."
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_ROSTER_YY_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Totaal van de roosters [59] + [62] + [64] van de aangifteperiode."
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_FRAME_6_SECTION",
    "values": [
      {
        "iso": "nl",
        "value": "Slechts één van de twee volgende roosters kan worden ingevuld"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_ROSTER_71",
    "values": [
      {
        "iso": "nl",
        "value": "Aan de Staat verschuldigde belasting: rooster XX - roster YY"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "VD_BE_ROSTER_72",
    "values": [
      {
        "iso": "nl",
        "value": "Sommen verschuldigd door de Staat: rooster YY - rooster XX"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "PDF_USER_NAME_WORKPERFORMANCE",
    "values": [
      {
        "iso": "nl",
        "value": "Medewerker"
      },
      {
        "iso": "en",
        "value": "Employee"
      }
    ]
  },
  {
    "key": "PDF_WORKPERFORMANCE_TIME",
    "values": [
      {
        "iso": "nl",
        "value": "Werktijd"
      },
      {
        "iso": "en",
        "value": "Work time"
      }
    ]
  },
  {
    "key": "PDF_ARTICLE_SKU",
    "values": [
      {
        "iso": "nl",
        "value": "SKU"
      },
      {
        "iso": "en",
        "value": "SKU"
      }
    ]
  },
  {
    "key": "PDF_ARTICLE_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Naam"
      },
      {
        "iso": "en",
        "value": "Name"
      }
    ]
  },
  {
    "key": "PDF_ARTICLE_AMOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Aantal"
      },
      {
        "iso": "en",
        "value": "Amount"
      }
    ]
  },
  {
    "key": "PDF_ARTICLE_PRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Prijs excl. BTW"
      },
      {
        "iso": "en",
        "value": "Price excl. VAT"
      }
    ]
  },
  {
    "key": "PDF_WORKPERFORMANCE_START_TIME",
    "values": [
      {
        "iso": "nl",
        "value": "Start"
      },
      {
        "iso": "en",
        "value": "Start"
      }
    ]
  },
  {
    "key": "PDF_WORKPERFORMANCE_END_TIME",
    "values": [
      {
        "iso": "nl",
        "value": "Afsluit"
      },
      {
        "iso": "en",
        "value": "End"
      }
    ]
  },
  {
    "key": "PDF_DESCRIPTION_WORKPERFORMANCE",
    "values": [
      {
        "iso": "nl",
        "value": "Werk omschrijving"
      },
      {
        "iso": "en",
        "value": "Work description"
      }
    ]
  },
  {
    "key": "PDF_WORKPERFORMANCE_BREAK",
    "values": [
      {
        "iso": "nl",
        "value": "Pauze"
      },
      {
        "iso": "en",
        "value": "Break"
      }
    ]
  },
  {
    "key": "PDF_INVOICE_REFERENCE",
    "values": [
      {
        "iso": "nl",
        "value": "Referentie"
      },
      {
        "iso": "en",
        "value": "Reference"
      }
    ]
  },
  {
    "key": "PDF_INVOICE_NUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "Factuurnummer"
      },
      {
        "iso": "en",
        "value": "Invoice number"
      }
    ]
  },
  {
    "key": "PDF_REMINDER_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Geachte heer/mevrouw,<br /><br />Uit onze administratie is gebleken dat er nog geen (volledige) betaling is ontvangen van de onderstaande factu(u)r(en), waarvan de betalingstermijn inmiddels is verstreken.<br />Een kopie van de bovenvermelde factu(u)r(en) treft u onderaan.<br /><br />Vriendelijk verzoek ik u alsnog zorg te dragen voor betaling alvorens de vernoemde vervaldatum verstrijkt.<br />Mocht de betaling inmiddels hebben plaatsgevonden, dan kunt u deze brief als niet verzonden beschouwen."
      },
      {
        "iso": "en",
        "value": "Dear Sir/Madam,<br /><br />Our administration has shown that (full) payment has not yet been received for the following invoice(s), for which the payment term has now expired.<br />You will find a copy of the invoice(s) below.<br /><br />I kindly request you to take care of payment before the mentioned due date expires.<br />If payment has been made in the meantime, please consider this letter unsent."
      }
    ]
  },
  {
    "key": "PDF_INVOICE_DATE",
    "values": [
      {
        "iso": "nl",
        "value": "Factuudatum"
      },
      {
        "iso": "en",
        "value": "Invoice date"
      }
    ]
  },
  {
    "key": "PDF_INVOICE_ENDDATE",
    "values": [
      {
        "iso": "nl",
        "value": "Vervaldatum"
      },
      {
        "iso": "en",
        "value": "Due date"
      }
    ]
  },
  {
    "key": "PDF_INVOICE_PRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Factuurbedrag"
      },
      {
        "iso": "en",
        "value": "Invoice price"
      }
    ]
  },
  {
    "key": "PDF_REMINDER_TOTAL_PRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Totale prijs"
      },
      {
        "iso": "en",
        "value": "Total price"
      }
    ]
  },
  {
    "key": "PDF_PAID_AMOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Betaald bedrag"
      },
      {
        "iso": "en",
        "value": "Paid amount"
      }
    ]
  },
  {
    "key": "PDF_ADMINISTRATION_COST",
    "values": [
      {
        "iso": "nl",
        "value": "Administratiekosten"
      },
      {
        "iso": "en",
        "value": "Administration cost"
      }
    ]
  },
  {
    "key": "PDF_OUTSTANDING_AMOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Openstaand bedrag"
      },
      {
        "iso": "en",
        "value": "Outstanding amount"
      }
    ]
  },
  {
    "key": "PDF_REFERENCE",
    "values": [
      {
        "iso": "nl",
        "value": "Referentie"
      },
      {
        "iso": "en",
        "value": "Reference"
      }
    ]
  },
  {
    "key": "PDF_NOTICE_COCONTRACTOR",
    "values": [
      {
        "iso": "nl",
        "value": "Verlegging van heffing. Bij gebrek aan schriftelijke betwisting binnen een termijn van één maand na de ontvangst van de factuur, wordt de afnemer geacht te erkennen dat hij een belastingplichtige is gehouden tot de indiening van periodieke aangiften. Als die voorwaarde niet vervuld is, is de afnemer ten aanzien van die voorwaarde aansprakelijk voor de betaling van de verschuldigde belasting, interesten en geldboeten."
      },
      {
        "iso": "en",
        "value": "Reversal of charge. In the absence of a written dispute within a period of one month after receipt of the invoice, the customer is deemed to acknowledge that he is a taxable person obliged to submit periodic returns. If that condition is not fulfilled, the customer is liable for the payment of the tax, interest and fines due in respect of that condition."
      }
    ]
  },
  {
    "key": "PDF_SUBSCRIBER_FREE_FROM_TAX_BELGIUM",
    "values": [
      {
        "iso": "nl",
        "value": "Bijzondere vrijstellingsregeling kleine ondernemingen, BTW niet van toepassing."
      },
      {
        "iso": "en",
        "value": "Special exemption scheme for small businesses, VAT not applicable."
      }
    ]
  },
  {
    "key": "PDF_PRODUCTDELIVERY_PROFORMA",
    "values": [
      {
        "iso": "nl",
        "value": "Proforma levering"
      },
      {
        "iso": "en",
        "value": "Proforma delivery"
      }
    ]
  },
  {
    "key": "PDF_PRODUCTDELIVERY",
    "values": [
      {
        "iso": "nl",
        "value": "Levering: {{0}}"
      },
      {
        "iso": "en",
        "value": "Delivery: {{0}}"
      }
    ]
  },
  {
    "key": "PDF_INVOICEREMINDER_FIRST",
    "values": [
      {
        "iso": "nl",
        "value": "Eerste"
      },
      {
        "iso": "en",
        "value": "First"
      }
    ]
  },
  {
    "key": "PDF_INVOICEREMINDER_SECOND",
    "values": [
      {
        "iso": "nl",
        "value": "Tweede"
      },
      {
        "iso": "en",
        "value": "Second"
      }
    ]
  },
  {
    "key": "PDF_INVOICEREMINDER_THIRD",
    "values": [
      {
        "iso": "nl",
        "value": "Derde"
      },
      {
        "iso": "en",
        "value": "Third"
      }
    ]
  },
  {
    "key": "PDF_INVOICEREMINDER_PROFORMA",
    "values": [
      {
        "iso": "nl",
        "value": "Proforma betaalherinnering"
      },
      {
        "iso": "en",
        "value": "Proforma payment reminder"
      }
    ]
  },
  {
    "key": "PDF_INVOICEREMINDER",
    "values": [
      {
        "iso": "nl",
        "value": "{{0}} betaalherinnering"
      },
      {
        "iso": "en",
        "value": "{{0}} payment reminder"
      }
    ]
  },
  {
    "key": "PDF_ACCEPTED_BY",
    "values": [
      {
        "iso": "nl",
        "value": "Geaccepteerd door {{0}},"
      },
      {
        "iso": "en",
        "value": "Accepted by {{0}},"
      }
    ]
  },
  {
    "key": "PDF_QUOTE",
    "values": [
      {
        "iso": "nl",
        "value": "Offerte: {{0}}"
      },
      {
        "iso": "en",
        "value": "Quote: {{0}}"
      }
    ]
  },
  {
    "key": "PDF_DOCUMENT_DATE",
    "values": [
      {
        "iso": "nl",
        "value": "Document datum: {{0}}"
      },
      {
        "iso": "en",
        "value": "Document date: {{0}}"
      }
    ]
  },
  {
    "key": "PDF_DOCUMENT_DUEDATE",
    "values": [
      {
        "iso": "nl",
        "value": "Vervaldatum: {{0}}"
      },
      {
        "iso": "en",
        "value": "Due date: {{0}}"
      }
    ]
  },
  {
    "key": "PDF_DOCUMENT_DELIVERYDATE",
    "values": [
      {
        "iso": "nl",
        "value": "Verwachte leverdatum: {{0}}"
      },
      {
        "iso": "en",
        "value": "Expected deliverydate: {{0}}"
      }
    ]
  },
  {
    "key": "PDF_BANK_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "Financiële gegevens"
      },
      {
        "iso": "en",
        "value": "Financial information"
      }
    ]
  },
  {
    "key": "PDF_BANK_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Bank: {{0}}"
      },
      {
        "iso": "en",
        "value": "Bank: {{0}}"
      }
    ]
  },
  {
    "key": "PDF_BANK_BIC",
    "values": [
      {
        "iso": "nl",
        "value": "BIC: {{0}}"
      },
      {
        "iso": "en",
        "value": "BIC: {{0}}"
      }
    ]
  },
  {
    "key": "PDF_BANK_NUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "Rekeningnummer: {{0}}"
      },
      {
        "iso": "en",
        "value": "Bank: {{0}}"
      }
    ]
  },
  {
    "key": "PDF_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Omschrijving"
      },
      {
        "iso": "en",
        "value": "Description"
      }
    ]
  },
  {
    "key": "PDF_AMOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Aantal"
      },
      {
        "iso": "en",
        "value": "Amount"
      }
    ]
  },
  {
    "key": "PDF_UNIT_PRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Eenheidsprijs"
      },
      {
        "iso": "en",
        "value": "Unit price"
      }
    ]
  },
  {
    "key": "PDF_PRICE_EXCL",
    "values": [
      {
        "iso": "nl",
        "value": "Prijs excl. BTW"
      },
      {
        "iso": "en",
        "value": "Price excl. VAT"
      }
    ]
  },
  {
    "key": "PDF_PRICE_INCL",
    "values": [
      {
        "iso": "nl",
        "value": "Prijs incl. BTW"
      },
      {
        "iso": "en",
        "value": "Price incl. VAT"
      }
    ]
  },
  {
    "key": "PDF_GENERATED_ON",
    "values": [
      {
        "iso": "nl",
        "value": "Document gegenereerd op {{0}}"
      },
      {
        "iso": "en",
        "value": "Document generated on {{0}}"
      }
    ]
  },
  {
    "key": "PDF_DISCOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Korting"
      },
      {
        "iso": "en",
        "value": "Discount"
      }
    ]
  },
  {
    "key": "PDF_GENERATED_BY_SYSTEM",
    "values": [
      {
        "iso": "nl",
        "value": "Gegenereerd via {{0}}"
      },
      {
        "iso": "en",
        "value": "Generated by {{0}}"
      }
    ]
  },
  {
    "key": "PDF_VAT_RATE",
    "values": [
      {
        "iso": "nl",
        "value": "BTW"
      },
      {
        "iso": "en",
        "value": "VAT"
      }
    ]
  },
  {
    "key": "PDF_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "BTW"
      },
      {
        "iso": "en",
        "value": "VAT"
      }
    ]
  },
  {
    "key": "PDF_PHONE_1L",
    "values": [
      {
        "iso": "nl",
        "value": "T"
      },
      {
        "iso": "en",
        "value": "P"
      }
    ]
  },
  {
    "key": "PDF_MAIL_1L",
    "values": [
      {
        "iso": "nl",
        "value": "E"
      },
      {
        "iso": "en",
        "value": "E"
      }
    ]
  },
  {
    "key": "PDF_WEBSITE_1L",
    "values": [
      {
        "iso": "nl",
        "value": "W"
      },
      {
        "iso": "en",
        "value": "W"
      }
    ]
  },
  {
    "key": "PDF_TER_ATTENTION_OF",
    "values": [
      {
        "iso": "nl",
        "value": "t.a.v"
      },
      {
        "iso": "en",
        "value": "att."
      }
    ]
  },
  {
    "key": "PDF_PER_UNIT",
    "values": [
      {
        "iso": "nl",
        "value": "Per eenheid"
      },
      {
        "iso": "en",
        "value": "Per unit"
      }
    ]
  },
  {
    "key": "PDF_TOTAL_EXCL_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Totale prijs excl. BTW"
      },
      {
        "iso": "en",
        "value": "Total price excl. VAT"
      }
    ]
  },
  {
    "key": "PDF_VAT_PRICE",
    "values": [
      {
        "iso": "nl",
        "value": "BTW ({{0}}%)"
      },
      {
        "iso": "en",
        "value": "VAT ({{0}}%)"
      }
    ]
  },
  {
    "key": "PDF_TOTAL_INCL_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Totale prijs incl. BTW"
      },
      {
        "iso": "en",
        "value": "Total price incl. VAT"
      }
    ]
  },
  {
    "key": "PDF_TOTAL_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "Totale BTW"
      },
      {
        "iso": "en",
        "value": "Total VAT"
      }
    ]
  },
  {
    "key": "PDF_ORDER",
    "values": [
      {
        "iso": "nl",
        "value": "Bestelbon: {{0}}"
      },
      {
        "iso": "en",
        "value": "Order: {{0}}"
      }
    ]
  },
  {
    "key": "PDF_INVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "Factuur: {{0}}"
      },
      {
        "iso": "en",
        "value": "Invoice: {{0}}"
      }
    ]
  },
  {
    "key": "PDF_CREDITNOTE",
    "values": [
      {
        "iso": "nl",
        "value": "Creditnota: {{0}}"
      },
      {
        "iso": "en",
        "value": "Creditnote: {{0}}"
      }
    ]
  },
  {
    "key": "PDF_INVOICE_PROFORMA",
    "values": [
      {
        "iso": "nl",
        "value": "Proforma factuur"
      },
      {
        "iso": "en",
        "value": "Proforma invoice"
      }
    ]
  },
  {
    "key": "PDF_CREDITNOTE_PROFORMA",
    "values": [
      {
        "iso": "nl",
        "value": "Proforma creditnota"
      },
      {
        "iso": "en",
        "value": "Proforma creditnote"
      }
    ]
  },
  {
    "key": "PDF_SERIALNUMBERS",
    "values": [
      {
        "iso": "nl",
        "value": "Serienummers: {{0}}"
      },
      {
        "iso": "en",
        "value": "Serialnumbers: {{0}}"
      }
    ]
  },
  {
    "key": "PDF_WARRANTY",
    "values": [
      {
        "iso": "nl",
        "value": "Garantie: {{0}} maanden"
      },
      {
        "iso": "en",
        "value": "Warranty: {{0}} months"
      }
    ]
  },
  {
    "key": "PDF_TRANSACTIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Transacties"
      },
      {
        "iso": "en",
        "value": "Transactions"
      }
    ]
  },
  {
    "key": "PDF_NO_TRANSACTIONS_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Er werden nog geen transacties geregistreerd voor dit document."
      },
      {
        "iso": "en",
        "value": "There where no registered transactions found for this document."
      }
    ]
  },
  {
    "key": "PDF_DATE",
    "values": [
      {
        "iso": "nl",
        "value": "Datum"
      },
      {
        "iso": "en",
        "value": "Date"
      }
    ]
  },
  {
    "key": "PDF_WORKPERFORMANCE_DATE",
    "values": [
      {
        "iso": "nl",
        "value": "Datum: {{0}}"
      },
      {
        "iso": "en",
        "value": "Date: {{0}}"
      }
    ]
  },
  {
    "key": "PDF_WORKPERFORMANCE_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "Werkbon"
      },
      {
        "iso": "en",
        "value": "Work slip"
      }
    ]
  },
  {
    "key": "PDF_TRANSACTION_AMOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Bedrag"
      },
      {
        "iso": "en",
        "value": "Amount"
      }
    ]
  },
  {
    "key": "PDF_TRANSACTION_GATEWAY",
    "values": [
      {
        "iso": "nl",
        "value": "Transactiemethode"
      },
      {
        "iso": "en",
        "value": "Transaction method"
      }
    ]
  },
  {
    "key": "PDF_NOTE",
    "values": [
      {
        "iso": "nl",
        "value": "Opmerking"
      },
      {
        "iso": "en",
        "value": "Note"
      }
    ]
  },
  {
    "key": "PDF_TO_PAY",
    "values": [
      {
        "iso": "nl",
        "value": "Te betalen"
      },
      {
        "iso": "en",
        "value": "To pay"
      }
    ]
  },
  {
    "key": "PDF_PAID",
    "values": [
      {
        "iso": "nl",
        "value": "Betaald"
      },
      {
        "iso": "en",
        "value": "Paid"
      }
    ]
  },
  {
    "key": "PDF_PROFORMA",
    "values": [
      {
        "iso": "nl",
        "value": "Proforma"
      },
      {
        "iso": "en",
        "value": "Proforma"
      }
    ]
  },
  {
    "key": "PDF_PAYMENT_IS_COMPLETED",
    "values": [
      {
        "iso": "nl",
        "value": "De betaling is voldaan."
      },
      {
        "iso": "en",
        "value": "The payment has been fulfilled."
      }
    ]
  },
  {
    "key": "PDF_USE_STRUCTURED_PAYMENT_REFERENCE_NO_BANK",
    "values": [
      {
        "iso": "nl",
        "value": "Gelieve het verschuldigde bedrag voor {{0}} te betalen met de gestructureerde melding {{1}}"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "PDF_USE_PAYMENT_REFERENCE_NO_BANK",
    "values": [
      {
        "iso": "nl",
        "value": "Gelieve het verschuldigde bedrag voor {{0}} te betalen met de melding '{{1}}'"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "PDF_USE_STRUCTURED_PAYMENT_REFERENCE",
    "values": [
      {
        "iso": "nl",
        "value": "Gelieve het verschuldigde bedrag voor {{0}} te betalen op het rekeningnummer {{1}} met de gestructureerde melding {{2}}"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "PDF_USE_PAYMENT_REFERENCE",
    "values": [
      {
        "iso": "nl",
        "value": "Gelieve het verschuldigde bedrag voor {{0}} te betalen op het rekeningnummer {{1}} met de melding '{{2}}'"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "PDF_USE_STRUCTURED_PAYMENT_REFERENCE_ALL_BANKS",
    "values": [
      {
        "iso": "nl",
        "value": "Gelieve het verschuldigde bedrag voor {{0}} te betalen op één van de rekeningnummers {{1}} met de gestructureerde melding {{2}}"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "PDF_USE_PAYMENT_REFERENCE_ALL_BANKS",
    "values": [
      {
        "iso": "nl",
        "value": "Gelieve het verschuldigde bedrag voor {{0}} te betalen op één van de rekeningnummers {{1}} met de melding '{{2}}'"
      },
      {
        "iso": "en",
        "value": ""
      }
    ]
  },
  {
    "key": "PDF_INTRACOMMUNAUTAIRE_NOTICE_BELGIUM",
    "values": [
      {
        "iso": "nl",
        "value": "BTW verlegd"
      },
      {
        "iso": "en",
        "value": "VAT reversed"
      }
    ]
  },
  {
    "key": "PDF_6%VAT_RENOVATION_NOTICE_BELGIUM",
    "values": [
      {
        "iso": "nl",
        "value": "\"BTW-tarief: Bij gebrek aan schriftelijke betwisting binnen een termijn van één maand vanaf de ontvangst van de factuur, wordt de klant geacht te erkennen dat (1) de werken worden verricht aan een woning waarvan de eerste ingebruikneming heeft plaatsgevonden in een kalenderjaar dat ten minste tien jaar voorafgaat aan de datum van de eerste factuur met betrekking tot die werken, (2) de woning, na uitvoering van die werken, uitsluitend of hoofdzakelijk als privéwoning wordt gebruikt en (3) de werken worden verstrekt en gefactureerd aan een eindverbruiker. Wanneer minstens één van die voorwaarden niet is voldaan, zal het normale BTW-tarief van 21% van toepassing zijn en is de afnemer ten aanzien van die voorwaarden aansprakelijk voor de betaling van de verschuldigde belasting, interesten en geldboeten.”"
      },
      {
        "iso": "en",
        "value": "\"VAT rate: In the absence of a written dispute within a period of one month from the receipt of the invoice, the customer is deemed to acknowledge that (1) the works are being carried out on a home of which the first occupation has taken place in a calendar year that at least ten years prior to the date of the first invoice with regard to those works, (2) the house, after the execution of those works, is used exclusively or mainly as a private residence and (3) the works are provided and invoiced to an end consumer If at least one of those conditions is not met, the normal VAT rate of 21% will apply and the customer will be liable for the payment of the tax, interest and fines due in respect of those conditions.”"
      }
    ]
  },
  {
    "key": "API_RECONCILED_BY_PAYMENTREMINDER",
    "values": [
      {
        "iso": "nl",
        "value": "Afgepunt door betaalherinnering {{0}}."
      },
      {
        "iso": "en",
        "value": "Reconciled through payment reminder {{0}}."
      }
    ]
  },
  {
    "key": "API_ERR_PARTNER_DEACTIVATED",
    "values": [
      {
        "iso": "nl",
        "value": "Uw partner account is ge-deactiveerd. Gelieve support te contacteren voor meer informatie."
      },
      {
        "iso": "en",
        "value": "Your partner account has been deactivated. Please contact support for more information."
      }
    ]
  },
  {
    "key": "API_ERR_PARTNER_PASSWORD_INCORRECT",
    "values": [
      {
        "iso": "nl",
        "value": "Het opgegeven wachtwoord is incorrect, gelieve nogmaals te proberen. Indien u uw wachtwoord vergeten bent kunt u support contacteren om de reset procedure te starten."
      },
      {
        "iso": "en",
        "value": "The given password is incorrect, please try again later. If you forget your password, contact support to start the reset procedure."
      }
    ]
  },
  {
    "key": "API_ERR_USER_NOT_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Gebruiker niet gevonden."
      },
      {
        "iso": "en",
        "value": "User not found."
      }
    ]
  },
  {
    "key": "API_ERR_ACTIVATION_KEY_AND_USER_ID_DO_NOT_MATCH",
    "values": [
      {
        "iso": "nl",
        "value": "De gebruikte activatie token is niet geldig met deze gebruiker."
      },
      {
        "iso": "en",
        "value": "The used activation token isn't combinable with this user."
      }
    ]
  },
  {
    "key": "API_ERR_USERONBOARDING_NOT_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Geen geldige onboarding."
      },
      {
        "iso": "en",
        "value": "No valid onboarding."
      }
    ]
  },
  {
    "key": "API_ERR_SUBSCRIBERONBOARDING_NOT_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Geen geldige onboarding."
      },
      {
        "iso": "en",
        "value": "No valid onboarding."
      }
    ]
  },
  {
    "key": "API_ERR_SUBSCRIBERONBOARDING_TOKEN_ALREADY_USED",
    "values": [
      {
        "iso": "nl",
        "value": "Deze onboardingslink is reeds gebruikt."
      },
      {
        "iso": "en",
        "value": "This onboardinglink is used."
      }
    ]
  },
  {
    "key": "API_ERR_SUBSCRIBERONBOARDING_TOKEN_EXPIRED",
    "values": [
      {
        "iso": "nl",
        "value": "Deze onboardingslink is vervallen."
      },
      {
        "iso": "en",
        "value": "This onboardinglink is expired."
      }
    ]
  },
  {
    "key": "API_ERR_DATA_MUTATION_FAILED",
    "values": [
      {
        "iso": "nl",
        "value": "Er is een fout opgetreden! Mutatie is mislukt."
      },
      {
        "iso": "en",
        "value": "An error has occured! Mutation has failed."
      }
    ]
  },
  {
    "key": "API_ERR_INSERTION_NOT_ALLOWED",
    "values": [
      {
        "iso": "nl",
        "value": "Er is een fout opgetreden! Toevoegen van data is mislukt."
      },
      {
        "iso": "en",
        "value": "An error has occured! Insertion of data has failed."
      }
    ]
  },
  {
    "key": "API_ERR_AUTHENTICATION_FAILED_INCORRECT_CREDENTIALS",
    "values": [
      {
        "iso": "nl",
        "value": "Authenticatie mislukt! De opgegeven credentials zijn ongeldig."
      },
      {
        "iso": "en",
        "value": "Authentication failed! The given credentials are invalid."
      }
    ]
  },
  {
    "key": "API_ERR_SUBSCRIBER_DEACTIVATED",
    "values": [
      {
        "iso": "nl",
        "value": "Abonnee is gedeactiveerd!"
      },
      {
        "iso": "en",
        "value": "Subscriber has has been deactivated!"
      }
    ]
  },
  {
    "key": "API_ERR_USER_DEACTIVATED",
    "values": [
      {
        "iso": "nl",
        "value": "Gebruiker is gedeactiveerd!"
      },
      {
        "iso": "en",
        "value": "User has been deactivated!"
      }
    ]
  },
  {
    "key": "API_ERR_PASSWORD_INCORRECT",
    "values": [
      {
        "iso": "nl",
        "value": "Authenticate mislukt! Het opgegeven wachtwoord is ongeldig."
      },
      {
        "iso": "en",
        "value": "Authentication failed! The given password is invalid."
      }
    ]
  },
  {
    "key": "API_ERR_INVALID_TOKEN",
    "values": [
      {
        "iso": "nl",
        "value": "Ongeldige sleutel!"
      },
      {
        "iso": "en",
        "value": "Invalid token!"
      }
    ]
  },
  {
    "key": "API_ERR_TOKEN_REACTIVATION_FAILED",
    "values": [
      {
        "iso": "nl",
        "value": "Heractiveren van de huidige sleutel is mislukt!"
      },
      {
        "iso": "en",
        "value": "Re-activation of the current token has failed!"
      }
    ]
  },
  {
    "key": "API_ERR_DATA_NOT_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "De gevraagde data is niet gevonden!"
      },
      {
        "iso": "en",
        "value": "The requested data isn't found!"
      }
    ]
  },
  {
    "key": "API_ERR_DATA_INCOMPLETE",
    "values": [
      {
        "iso": "nl",
        "value": "het object is incompleet of incorrect!"
      },
      {
        "iso": "en",
        "value": "The object is incomplete or incorrect!"
      }
    ]
  },
  {
    "key": "API_ERR_ALREADY_CONFIRMED",
    "values": [
      {
        "iso": "nl",
        "value": "Bevestiging gefaald! Het document is al bevestigd."
      },
      {
        "iso": "en",
        "value": "Confirmation failed! The document is already confirmed."
      }
    ]
  },
  {
    "key": "API_ERR_BLOCKED_PERIOD",
    "values": [
      {
        "iso": "nl",
        "value": "De periode is geblokeerd!"
      },
      {
        "iso": "en",
        "value": "The period has been blocked!"
      }
    ]
  },
  {
    "key": "API_ERR_ONLY_INSERTION_ALLOWED",
    "values": [
      {
        "iso": "nl",
        "value": "Deze bewerking staat enkel het aanmaken van data toe!"
      },
      {
        "iso": "en",
        "value": "This mutation only allows insertion of data!"
      }
    ]
  },
  {
    "key": "API_ERR_RECEIVERS_REQUIRED",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail is niet aangemaakt! Er zijn geen ontvangers gevonden voor uw e-mail."
      },
      {
        "iso": "en",
        "value": "Email hasn't been made! There are no found receivers for your email."
      }
    ]
  },
  {
    "key": "API_ERR_ATTACHMENTS_REQUIRED",
    "values": [
      {
        "iso": "nl",
        "value": "E-mail is niet aangemaakt! Bijlages zijn verplicht."
      },
      {
        "iso": "en",
        "value": "Email hasn't been made! Attachments are required."
      }
    ]
  },
  {
    "key": "API_ERR_ONLY_UPDATE_ALLOWED",
    "values": [
      {
        "iso": "nl",
        "value": "Deze bewerking staat enkel het wijzigen van data toe!"
      },
      {
        "iso": "en",
        "value": "This mutation only allows mutation of data!"
      }
    ]
  },
  {
    "key": "API_ERR_ACCOUNT_INCORRECT_USER",
    "values": [
      {
        "iso": "nl",
        "value": "Ongeldige gebruiker!"
      },
      {
        "iso": "en",
        "value": "Incorrect user!"
      }
    ]
  },
  {
    "key": "API_ERR_TOKEN_CREATION_FAILED",
    "values": [
      {
        "iso": "nl",
        "value": "Aanmaken van sleutel mislukt!"
      },
      {
        "iso": "en",
        "value": "Created of token has failed!"
      }
    ]
  },
  {
    "key": "API_ERR_DATA_UPDATE_FAILED",
    "values": [
      {
        "iso": "nl",
        "value": "Mutatie van data gefaald!"
      },
      {
        "iso": "en",
        "value": "Mutation of data has failed!"
      }
    ]
  },
  {
    "key": "API_CANT_UPDATE_SUBSCRIBERSETTINGS_OF_UNKNOWN_USER",
    "values": [
      {
        "iso": "nl",
        "value": "Opslaan gefaald! De gebruiker is niet gekend."
      },
      {
        "iso": "en",
        "value": "Save has failed! The user is unknown."
      }
    ]
  },
  {
    "key": "API_CANT_UPDATE_USERSETTINGS_OF_UNKNOWN_USER",
    "values": [
      {
        "iso": "nl",
        "value": "Opslaan gefaald! De gebruiker is niet gekend."
      },
      {
        "iso": "en",
        "value": "Save has failed! The user is unknown."
      }
    ]
  },
  {
    "key": "API_MSG_WRITEOFF_HAS_BEEN_CONFIRMED",
    "values": [
      {
        "iso": "nl",
        "value": "Afschrijving (id: {{0}}) is succesvol bevestigd."
      },
      {
        "iso": "en",
        "value": "Writeoff (id: {{0}}) has been succesfully confirmed."
      }
    ]
  },
  {
    "key": "API_ERR_MISSING_PERMISSIONS",
    "values": [
      {
        "iso": "nl",
        "value": "Ontbrekende permissies: {{0}}"
      },
      {
        "iso": "en",
        "value": "Missing permissions: {{0}}"
      }
    ]
  },
  {
    "key": "API_ERR_HTTP_FAILED",
    "values": [
      {
        "iso": "nl",
        "value": "Een onbekende fout is plaatsgevonden."
      },
      {
        "iso": "en",
        "value": "An unknown error has occurred."
      }
    ]
  },
  {
    "key": "EMAIL_ONBOARDING_COMPLETED_SUBJECT",
    "values": [
      {
        "iso": "nl",
        "value": "{{0}} heeft de onboarding succesvol doorlopen."
      },
      {
        "iso": "en",
        "value": "{{0}} has successfull completed the onboarding process."
      }
    ]
  },
  {
    "key": "EMAIL_ONBOARDING_COMPLETED_BODY",
    "values": [
      {
        "iso": "nl",
        "value": "<p>Beste partner,<br /><br />Via deze mail wensen we u op de hoogte te stellen dat {{0}} de onboarding voor een nieuwe abonnee succesvol heeft doorlopen. Het bedrijf staat vanaf vandaag geregistreerd als {{1}} met BTW nummer {{2}}."
      },
      {
        "iso": "en",
        "value": "<p>Dear partner,<br /><br />With this email we would like to inform you that {{0}} has continued deboarding for another successful successful one. As of today, the company is registered as {{1}} with VAT number {{2}}."
      }
    ]
  },
  {
    "key": "EMAIL_SUBSCRIBER_ONBOARDING_SUBJECT",
    "values": [
      {
        "iso": "nl",
        "value": "Aanmelding bij {{0}}"
      },
      {
        "iso": "en",
        "value": "Onboarding for {{0}}"
      }
    ]
  },
  {
    "key": "EMAIL_SUBSCRIBER_ONBOARDING_BODY",
    "values": [
      {
        "iso": "nl",
        "value": "<p>Beste {{0}},</p><p>Bedankt voor je interesse in {{3}}! We zijn blij te horen dat je geïnteresseerd bent in het gebruik van {{3}} om je bedrijf te verbeteren.</p><p>We willen je graag laten weten dat we de aanvraag hebben ontvangen en we hebben de link naar het aanmeldprocess voor jouw bedrijf bijgevoegd. Klik hier om jouw bedrijf aan te melden: <a href=\"{{1}}\">{{1}}</a></p><p>Mocht je vragen of problemen hebben tijdens het proces van het aanmelden, neem dan gerust contact met ons op. Ons team staat altijd klaar om je te helpen.</p><p>Als je zich heeft aangemeld voor een gratis proefversie, dan wilt je misschien weten wat de volgende stappen zijn. Ons team zal binnenkort contact met jou opnemen om het proces te bespreken en eventuele vragen die je hebt te beantwoorden.</p><p>We kijken ernaar uit om samen met je je bedrijf naar een hoger niveau te tillen met {{0}}!</p><p>Met vriendelijke groeten<br />Het {{3}} team</p>"
      },
      {
        "iso": "en",
        "value": "<p>Dear {{0}},</p><p>Thank you for your interest in {{3}}! We're glad to hear that you're interested in using {{3}} to improve your business.</p><p>We'd like to let you know that we've received the application and we've added the link to the application process for your company attached. Click here to register your company: <a href=\"{{1}}\">{{1}}</a></p><p>If you have any questions or problems during the process of register, please do not hesitate to contact us. Our team is always ready to help.</p><p>If you have signed up for a free trial, you may want to know what the next steps are. Our team will be in touch shortly to discuss the process and answer any questions you may have.</p><p>We look forward to working with you to take your business to the next level with {{0}}! </p><p>Kind regards<br />The {{3}} team</p>"
      }
    ]
  },
  {
    "key": "EMAIL_NEW_SELF_ONBOARDING_REQUEST_SUBJECT",
    "values": [
      {
        "iso": "nl",
        "value": "Nieuwe onboarding aanvraag voor {{0}}"
      },
      {
        "iso": "en",
        "value": "New onboarding request for {{0}}"
      }
    ]
  },
  {
    "key": "EMAIL_NEW_SELF_ONBOARDING_REQUEST_BODY",
    "values": [
      {
        "iso": "nl",
        "value": "<p>{{0}} heeft een onboarding e-mail aangevraagd die verstuurd werd naar '{{1}}'. De onboarding request heeft het interne ID {{2}} gekregen en is bezichtbaar via het partnerportaal.</p>"
      },
      {
        "iso": "en",
        "value": "<p>{{0}} has requested an onboarding e-mail to be send to '{{1}}'. The onboarding request has received the internal ID {{2}} and can be accessed on the partner portal.</p>"
      }
    ]
  },
  {
    "key": "EMAIL_ACTIVATE_ACCOUNT_SUBJECT",
    "values": [
      {
        "iso": "nl",
        "value": "Activeer uw {{1}} account van '{{0}}'"
      },
      {
        "iso": "en",
        "value": "Activate your {{1}} account of '{{0}}'"
      }
    ]
  },
  {
    "key": "EMAIL_ACTIVATE_ACCOUNT_BODY",
    "values": [
      {
        "iso": "nl",
        "value": "Hoi {{0}}!<br /><br />Uw account is geregistreerd op het {{3}} account van '{{2}}'. U kan deze activeren door op de volgende link te klikken of onderstaande link te kopieren!<br /><a href=\"{{1}}\">{{1}}</a>.<br /><br />Met vriendelijke groeten,<br />Het {{3}} team"
      },
      {
        "iso": "en",
        "value": "Hi {{0}}!<br /><br />Your account has been registered on the {{3}} account of '{{2}}'. You can activate it by clicking or copying the fellowing link!<br /><a href=\"{{1}}\">{{1}}</a>.<br /><br />Kind regards,<br />The {{3}} team"
      }
    ]
  },
  {
    "key": "EMAIL_PASSWORD_RESET_ACCOUNT_SUBJECT",
    "values": [
      {
        "iso": "nl",
        "value": "{{0}} wachtwoord reset"
      },
      {
        "iso": "en",
        "value": "{{0}} password reset"
      }
    ]
  },
  {
    "key": "EMAIL_PASSWORD_RESET_ACCOUNT_BODY",
    "values": [
      {
        "iso": "nl",
        "value": "<p>Hi {{0}},</p><p>Er is een verzoek ingediend om jouw wachtwoord te wijzigen voor het abonnement van \"{{1}}\".</p><p><a href=\"{{2}}\">Klik hier om je wachtwoord te wijzigen.</a></p>"
      },
      {
        "iso": "en",
        "value": "<p>Hi {{0}},</p><p>A request has been made to change your password for the subscription \"{{1}}\".</p><p><a href=\"{{2}}\">Click here to change your password.</a></p>"
      }
    ]
  },
  {
    "key": "UI_MESSAGES",
    "values": [
      {
        "iso": "nl",
        "value": "Meldingen"
      },
      {
        "iso": "en",
        "value": "Messages"
      }
    ]
  },
  {
    "key": "UI_SIGN",
    "values": [
      {
        "iso": "nl",
        "value": "Tekenen"
      },
      {
        "iso": "en",
        "value": "Sign"
      }
    ]
  },
  {
    "key": "UI_REMOVE_SIGNATURE",
    "values": [
      {
        "iso": "nl",
        "value": "Handtekening verwijderen"
      },
      {
        "iso": "en",
        "value": "Remove signature"
      }
    ]
  },
  {
    "key": "UI_CLEAR_SIGNATURE",
    "values": [
      {
        "iso": "nl",
        "value": "Wissen"
      },
      {
        "iso": "en",
        "value": "Clear"
      }
    ]
  },
  {
    "key": "UI_OLD_DATE_MODAL_TITEL",
    "values": [
      {
        "iso": "nl",
        "value": "Verouderde datum gedetecteerd"
      },
      {
        "iso": "en",
        "value": "Outdated date detected"
      }
    ]
  },
  {
    "key": "UI_OLD_DATE_MODAL_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Dit document bevat een verouderde datum. Het bevestigen van het document met deze datum kan juridische en fiscale gevaren met zich meebrengen!"
      },
      {
        "iso": "en",
        "value": "This document contains an outdated date. Confirming the document with this date may pose legal and tax risks!"
      }
    ]
  },
  {
    "key": "UI_OLD_DATE_MODAL_DOUBLE_CONFIRM",
    "values": [
      {
        "iso": "nl",
        "value": "Ik herken de gevaren maar wens een oudere datum te kunnen gebruiken"
      },
      {
        "iso": "en",
        "value": "I acknowledge the risks but wish to use an older date"
      }
    ]
  },
  {
    "key": "UI_OLD_DATE_MODAL_USE_OLD_DATE",
    "values": [
      {
        "iso": "nl",
        "value": "Oude datum behouden"
      },
      {
        "iso": "en",
        "value": "Retain the old date"
      }
    ]
  },
  {
    "key": "UI_OLD_DATE_MODAL_UPDATE_DATE",
    "values": [
      {
        "iso": "nl",
        "value": "Datum updaten"
      },
      {
        "iso": "en",
        "value": "Update date"
      }
    ]
  },
  {
    "key": "UI_NO_PARAMETER_KEYS_IN_LIST",
    "values": [
      {
        "iso": "nl",
        "value": "Geen bestaande parameters gevonden"
      },
      {
        "iso": "en",
        "value": "No existing parameters found"
      }
    ]
  },
  {
    "key": "UI_PARAMETER_KEY",
    "values": [
      {
        "iso": "nl",
        "value": "Parameter"
      },
      {
        "iso": "en",
        "value": "Parameter"
      }
    ]
  },
  {
    "key": "UI_PARAMETER_VALUE",
    "values": [
      {
        "iso": "nl",
        "value": "Waarde"
      },
      {
        "iso": "en",
        "value": "Value"
      }
    ]
  },
  {
    "key": "UI_ACTIVE_LICENSE",
    "values": [
      {
        "iso": "nl",
        "value": "Actieve licentie"
      },
      {
        "iso": "en",
        "value": "Active license"
      }
    ]
  },
  {
    "key": "UI_LICENSE_START_DATE",
    "values": [
      {
        "iso": "nl",
        "value": "Begin datum"
      },
      {
        "iso": "en",
        "value": "Start date"
      }
    ]
  },
  {
    "key": "UI_LICENSE_END_DATE",
    "values": [
      {
        "iso": "nl",
        "value": "Vervaldatum"
      },
      {
        "iso": "en",
        "value": "Expirationdate"
      }
    ]
  },
  {
    "key": "UI_NO_DOCUMENT_PARAMETERS_FOUND_FOR_THIS_RELATION",
    "values": [
      {
        "iso": "nl",
        "value": "Geen document parameters gevonden voor deze relatie."
      },
      {
        "iso": "en",
        "value": "No document parameters found for this relation."
      }
    ]
  },
  {
    "key": "UI_NO_DOCUMENT_PARAMETERS_FOUND_FOR_THIS_INVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "Geen document parameters gevonden voor deze factuur."
      },
      {
        "iso": "en",
        "value": "No document parameters found for this invoice."
      }
    ]
  },
  {
    "key": "UI_UNREAD_MESSAGES",
    "values": [
      {
        "iso": "nl",
        "value": "Ongelezen meldingen"
      },
      {
        "iso": "en",
        "value": "Unread Messages"
      }
    ]
  },
  {
    "key": "EMAIL_SEND_ZIP_BY_EMAIL_SUBJECT",
    "values": [
      {
        "iso": "nl",
        "value": "Export van {{0}} staat klaar om te downloaden"
      },
      {
        "iso": "en",
        "value": "Export of {{0}} is ready to be downloaded"
      }
    ]
  },
  {
    "key": "EMAIL_SEND_ZIP_BY_EMAIL_BODY",
    "values": [
      {
        "iso": "nl",
        "value": "De export van {{0}} staat klaar om gedownload te worden via {{2}} op de volgende link <a href=\"{{1}}\">{{1}}</a>.<br />Deze export is 7 dagen beschikbaar.<br /><br />Met vriendelijke groeten,<br />Het {{2}} team"
      },
      {
        "iso": "en",
        "value": "The export of {{0}} is available for download from {{2}} at the following link <a href=\"{{1}}\">{{1}}</a>.<br />This export is available for 7 days.<br /><br />Sincerely,<br />The {{2}} team"
      }
    ]
  },
  {
    "key": "COUNTRY_AW",
    "values": [
      { "iso": "nl", "value": "Aruba" },
      { "iso": "en", "value": "Aruba" }
    ]
  },
  {
    "key": "COUNTRY_AF",
    "values": [
      { "iso": "nl", "value": "Afghanistan" },
      { "iso": "en", "value": "Afghanistan" }
    ]
  },
  {
    "key": "COUNTRY_AO",
    "values": [
      { "iso": "nl", "value": "Angola" },
      { "iso": "en", "value": "Angola" }
    ]
  },
  {
    "key": "COUNTRY_AI",
    "values": [
      { "iso": "nl", "value": "Anguilla" },
      { "iso": "en", "value": "Anguilla" }
    ]
  },
  {
    "key": "COUNTRY_AX",
    "values": [
      { "iso": "nl", "value": "Ålandeilanden" },
      { "iso": "en", "value": "Åland Islands" }
    ]
  },
  {
    "key": "COUNTRY_AL",
    "values": [
      { "iso": "nl", "value": "Albanië" },
      { "iso": "en", "value": "Albania" }
    ]
  },
  {
    "key": "COUNTRY_AD",
    "values": [
      { "iso": "nl", "value": "Andorra" },
      { "iso": "en", "value": "Andorra" }
    ]
  },
  {
    "key": "COUNTRY_AE",
    "values": [
      { "iso": "nl", "value": "Verenigde Arabische Emiraten" },
      { "iso": "en", "value": "United Arab Emirates" }
    ]
  },
  {
    "key": "COUNTRY_AR",
    "values": [
      { "iso": "nl", "value": "Argentinië" },
      { "iso": "en", "value": "Argentina" }
    ]
  },
  {
    "key": "COUNTRY_AM",
    "values": [
      { "iso": "nl", "value": "Armenië" },
      { "iso": "en", "value": "Armenia" }
    ]
  },
  {
    "key": "COUNTRY_AS",
    "values": [
      { "iso": "nl", "value": "Amerikaans Samoa" },
      { "iso": "en", "value": "American Samoa" }
    ]
  },
  {
    "key": "COUNTRY_AQ",
    "values": [
      { "iso": "nl", "value": "Antarctica" },
      { "iso": "en", "value": "Antarctica" }
    ]
  },
  {
    "key": "COUNTRY_TF",
    "values": [
      { "iso": "nl", "value": "Franse Gebieden in de zuidelijke Indische Oceaan" },
      { "iso": "en", "value": "French Southern and Antarctic Lands" }
    ]
  },
  {
    "key": "COUNTRY_AG",
    "values": [
      { "iso": "nl", "value": "Antigua en Barbuda" },
      { "iso": "en", "value": "Antigua and Barbuda" }
    ]
  },
  {
    "key": "COUNTRY_AU",
    "values": [
      { "iso": "nl", "value": "Australië" },
      { "iso": "en", "value": "Australia" }
    ]
  },
  {
    "key": "COUNTRY_AT",
    "values": [
      { "iso": "nl", "value": "Oostenrijk" },
      { "iso": "en", "value": "Austria" }
    ]
  },
  {
    "key": "COUNTRY_AZ",
    "values": [
      { "iso": "nl", "value": "Azerbeidzjan" },
      { "iso": "en", "value": "Azerbaijan" }
    ]
  },
  {
    "key": "COUNTRY_BI",
    "values": [
      { "iso": "nl", "value": "Burundi" },
      { "iso": "en", "value": "Burundi" }
    ]
  },
  {
    "key": "COUNTRY_BE",
    "values": [
      { "iso": "nl", "value": "België" },
      { "iso": "en", "value": "Belgium" }
    ]
  },
  {
    "key": "COUNTRY_BJ",
    "values": [
      { "iso": "nl", "value": "Benin" },
      { "iso": "en", "value": "Benin" }
    ]
  },
  {
    "key": "COUNTRY_BF",
    "values": [
      { "iso": "nl", "value": "Burkina Faso" },
      { "iso": "en", "value": "Burkina Faso" }
    ]
  },
  {
    "key": "COUNTRY_BD",
    "values": [
      { "iso": "nl", "value": "Bangladesh" },
      { "iso": "en", "value": "Bangladesh" }
    ]
  },
  {
    "key": "COUNTRY_BG",
    "values": [
      { "iso": "nl", "value": "Bulgarije" },
      { "iso": "en", "value": "Bulgaria" }
    ]
  },
  {
    "key": "COUNTRY_BH",
    "values": [
      { "iso": "nl", "value": "Bahrein" },
      { "iso": "en", "value": "Bahrain" }
    ]
  },
  {
    "key": "COUNTRY_BS",
    "values": [
      { "iso": "nl", "value": "Bahama’s" },
      { "iso": "en", "value": "Bahamas" }
    ]
  },
  {
    "key": "COUNTRY_BA",
    "values": [
      { "iso": "nl", "value": "Bosnië en Herzegovina" },
      { "iso": "en", "value": "Bosnia and Herzegovina" }
    ]
  },
  {
    "key": "COUNTRY_BL",
    "values": [
      { "iso": "nl", "value": "Saint Barthélemy" },
      { "iso": "en", "value": "Saint Barthélemy" }
    ]
  },
  {
    "key": "COUNTRY_SH",
    "values": [
      { "iso": "nl", "value": "Sint-Helena, Ascension en Tristan da Cunha" },
      { "iso": "en", "value": "Saint Helena, Ascension and Tristan da Cunha" }
    ]
  },
  {
    "key": "COUNTRY_BY",
    "values": [
      { "iso": "nl", "value": "Wit-Rusland" },
      { "iso": "en", "value": "Belarus" }
    ]
  },
  {
    "key": "COUNTRY_BZ",
    "values": [
      { "iso": "nl", "value": "Belize" },
      { "iso": "en", "value": "Belize" }
    ]
  },
  {
    "key": "COUNTRY_BM",
    "values": [
      { "iso": "nl", "value": "Bermuda" },
      { "iso": "en", "value": "Bermuda" }
    ]
  },
  {
    "key": "COUNTRY_BO",
    "values": [
      { "iso": "nl", "value": "Bolivia" },
      { "iso": "en", "value": "Bolivia" }
    ]
  },
  {
    "key": "COUNTRY_BQ",
    "values": [
      { "iso": "nl", "value": "Caribisch Nederland" },
      { "iso": "en", "value": "Caribbean Netherlands" }
    ]
  },
  {
    "key": "COUNTRY_BR",
    "values": [
      { "iso": "nl", "value": "Brazilië" },
      { "iso": "en", "value": "Brazil" }
    ]
  },
  {
    "key": "COUNTRY_BB",
    "values": [
      { "iso": "nl", "value": "Barbados" },
      { "iso": "en", "value": "Barbados" }
    ]
  },
  {
    "key": "COUNTRY_BN",
    "values": [
      { "iso": "nl", "value": "Brunei" },
      { "iso": "en", "value": "Brunei" }
    ]
  },
  {
    "key": "COUNTRY_BT",
    "values": [
      { "iso": "nl", "value": "Bhutan" },
      { "iso": "en", "value": "Bhutan" }
    ]
  },
  {
    "key": "COUNTRY_BV",
    "values": [
      { "iso": "nl", "value": "Bouveteiland" },
      { "iso": "en", "value": "Bouvet Island" }
    ]
  },
  {
    "key": "COUNTRY_BW",
    "values": [
      { "iso": "nl", "value": "Botswana" },
      { "iso": "en", "value": "Botswana" }
    ]
  },
  {
    "key": "COUNTRY_CF",
    "values": [
      { "iso": "nl", "value": "Centraal-Afrikaanse Republiek" },
      { "iso": "en", "value": "Central African Republic" }
    ]
  },
  {
    "key": "COUNTRY_CA",
    "values": [
      { "iso": "nl", "value": "Canada" },
      { "iso": "en", "value": "Canada" }
    ]
  },
  {
    "key": "COUNTRY_CC",
    "values": [
      { "iso": "nl", "value": "Cocoseilanden" },
      { "iso": "en", "value": "Cocos (Keeling) Islands" }
    ]
  },
  {
    "key": "COUNTRY_CH",
    "values": [
      { "iso": "nl", "value": "Zwitserland" },
      { "iso": "en", "value": "Switzerland" }
    ]
  },
  {
    "key": "COUNTRY_CL",
    "values": [
      { "iso": "nl", "value": "Chili" },
      { "iso": "en", "value": "Chile" }
    ]
  },
  {
    "key": "COUNTRY_CN",
    "values": [
      { "iso": "nl", "value": "China" },
      { "iso": "en", "value": "China" }
    ]
  },
  {
    "key": "COUNTRY_CI",
    "values": [
      { "iso": "nl", "value": "Ivoorkust" },
      { "iso": "en", "value": "Ivory Coast" }
    ]
  },
  {
    "key": "COUNTRY_CM",
    "values": [
      { "iso": "nl", "value": "Kameroen" },
      { "iso": "en", "value": "Cameroon" }
    ]
  },
  {
    "key": "COUNTRY_CD",
    "values": [
      { "iso": "nl", "value": "Congo (DRC)" },
      { "iso": "en", "value": "DR Congo" }
    ]
  },
  {
    "key": "COUNTRY_CG",
    "values": [
      { "iso": "nl", "value": "Congo" },
      { "iso": "en", "value": "Republic of the Congo" }
    ]
  },
  {
    "key": "COUNTRY_CK",
    "values": [
      { "iso": "nl", "value": "Cookeilanden" },
      { "iso": "en", "value": "Cook Islands" }
    ]
  },
  {
    "key": "COUNTRY_CO",
    "values": [
      { "iso": "nl", "value": "Colombia" },
      { "iso": "en", "value": "Colombia" }
    ]
  },
  {
    "key": "COUNTRY_KM",
    "values": [
      { "iso": "nl", "value": "Comoren" },
      { "iso": "en", "value": "Comoros" }
    ]
  },
  {
    "key": "COUNTRY_CV",
    "values": [
      { "iso": "nl", "value": "Kaapverdië" },
      { "iso": "en", "value": "Cape Verde" }
    ]
  },
  {
    "key": "COUNTRY_CR",
    "values": [
      { "iso": "nl", "value": "Costa Rica" },
      { "iso": "en", "value": "Costa Rica" }
    ]
  },
  {
    "key": "COUNTRY_CU",
    "values": [
      { "iso": "nl", "value": "Cuba" },
      { "iso": "en", "value": "Cuba" }
    ]
  },
  {
    "key": "COUNTRY_CW",
    "values": [
      { "iso": "nl", "value": "Curaçao" },
      { "iso": "en", "value": "Curaçao" }
    ]
  },
  {
    "key": "COUNTRY_CX",
    "values": [
      { "iso": "nl", "value": "Christmaseiland" },
      { "iso": "en", "value": "Christmas Island" }
    ]
  },
  {
    "key": "COUNTRY_KY",
    "values": [
      { "iso": "nl", "value": "Caymaneilanden" },
      { "iso": "en", "value": "Cayman Islands" }
    ]
  },
  {
    "key": "COUNTRY_CY",
    "values": [
      { "iso": "nl", "value": "Cyprus" },
      { "iso": "en", "value": "Cyprus" }
    ]
  },
  {
    "key": "COUNTRY_CZ",
    "values": [
      { "iso": "nl", "value": "Tsjechië" },
      { "iso": "en", "value": "Czechia" }
    ]
  },
  {
    "key": "COUNTRY_DE",
    "values": [
      { "iso": "nl", "value": "Duitsland" },
      { "iso": "en", "value": "Germany" }
    ]
  },
  {
    "key": "COUNTRY_DJ",
    "values": [
      { "iso": "nl", "value": "Djibouti" },
      { "iso": "en", "value": "Djibouti" }
    ]
  },
  {
    "key": "COUNTRY_DM",
    "values": [
      { "iso": "nl", "value": "Dominica" },
      { "iso": "en", "value": "Dominica" }
    ]
  },
  {
    "key": "COUNTRY_DK",
    "values": [
      { "iso": "nl", "value": "Denemarken" },
      { "iso": "en", "value": "Denmark" }
    ]
  },
  {
    "key": "COUNTRY_DO",
    "values": [
      { "iso": "nl", "value": "Dominicaanse Republiek" },
      { "iso": "en", "value": "Dominican Republic" }
    ]
  },
  {
    "key": "COUNTRY_DZ",
    "values": [
      { "iso": "nl", "value": "Algerije" },
      { "iso": "en", "value": "Algeria" }
    ]
  },
  {
    "key": "COUNTRY_EC",
    "values": [
      { "iso": "nl", "value": "Ecuador" },
      { "iso": "en", "value": "Ecuador" }
    ]
  },
  {
    "key": "COUNTRY_EG",
    "values": [
      { "iso": "nl", "value": "Egypte" },
      { "iso": "en", "value": "Egypt" }
    ]
  },
  {
    "key": "COUNTRY_ER",
    "values": [
      { "iso": "nl", "value": "Eritrea" },
      { "iso": "en", "value": "Eritrea" }
    ]
  },
  {
    "key": "COUNTRY_EH",
    "values": [
      { "iso": "nl", "value": "Westelijke Sahara" },
      { "iso": "en", "value": "Western Sahara" }
    ]
  },
  {
    "key": "COUNTRY_ES",
    "values": [
      { "iso": "nl", "value": "Spanje" },
      { "iso": "en", "value": "Spain" }
    ]
  },
  {
    "key": "COUNTRY_EE",
    "values": [
      { "iso": "nl", "value": "Estland" },
      { "iso": "en", "value": "Estonia" }
    ]
  },
  {
    "key": "COUNTRY_ET",
    "values": [
      { "iso": "nl", "value": "Ethiopië" },
      { "iso": "en", "value": "Ethiopia" }
    ]
  },
  {
    "key": "COUNTRY_FI",
    "values": [
      { "iso": "nl", "value": "Finland" },
      { "iso": "en", "value": "Finland" }
    ]
  },
  {
    "key": "COUNTRY_FJ",
    "values": [
      { "iso": "nl", "value": "Fiji" },
      { "iso": "en", "value": "Fiji" }
    ]
  },
  {
    "key": "COUNTRY_FK",
    "values": [
      { "iso": "nl", "value": "Falklandeilanden" },
      { "iso": "en", "value": "Falkland Islands" }
    ]
  },
  {
    "key": "COUNTRY_FR",
    "values": [
      { "iso": "nl", "value": "Frankrijk" },
      { "iso": "en", "value": "France" }
    ]
  },
  {
    "key": "COUNTRY_FO",
    "values": [
      { "iso": "nl", "value": "Faeröer" },
      { "iso": "en", "value": "Faroe Islands" }
    ]
  },
  {
    "key": "COUNTRY_FM",
    "values": [
      { "iso": "nl", "value": "Micronesië" },
      { "iso": "en", "value": "Micronesia" }
    ]
  },
  {
    "key": "COUNTRY_GA",
    "values": [
      { "iso": "nl", "value": "Gabon" },
      { "iso": "en", "value": "Gabon" }
    ]
  },
  {
    "key": "COUNTRY_GB",
    "values": [
      { "iso": "nl", "value": "Verenigd Koninkrijk" },
      { "iso": "en", "value": "United Kingdom" }
    ]
  },
  {
    "key": "COUNTRY_GE",
    "values": [
      { "iso": "nl", "value": "Georgië" },
      { "iso": "en", "value": "Georgia" }
    ]
  },
  {
    "key": "COUNTRY_GG",
    "values": [
      { "iso": "nl", "value": "Guernsey" },
      { "iso": "en", "value": "Guernsey" }
    ]
  },
  {
    "key": "COUNTRY_GH",
    "values": [
      { "iso": "nl", "value": "Ghana" },
      { "iso": "en", "value": "Ghana" }
    ]
  },
  {
    "key": "COUNTRY_GI",
    "values": [
      { "iso": "nl", "value": "Gibraltar" },
      { "iso": "en", "value": "Gibraltar" }
    ]
  },
  {
    "key": "COUNTRY_GN",
    "values": [
      { "iso": "nl", "value": "Guinee" },
      { "iso": "en", "value": "Guinea" }
    ]
  },
  {
    "key": "COUNTRY_GP",
    "values": [
      { "iso": "nl", "value": "Guadeloupe" },
      { "iso": "en", "value": "Guadeloupe" }
    ]
  },
  {
    "key": "COUNTRY_GM",
    "values": [
      { "iso": "nl", "value": "Gambia" },
      { "iso": "en", "value": "Gambia" }
    ]
  },
  {
    "key": "COUNTRY_GW",
    "values": [
      { "iso": "nl", "value": "Guinee-Bissau" },
      { "iso": "en", "value": "Guinea-Bissau" }
    ]
  },
  {
    "key": "COUNTRY_GQ",
    "values": [
      { "iso": "nl", "value": "Equatoriaal-Guinea" },
      { "iso": "en", "value": "Equatorial Guinea" }
    ]
  },
  {
    "key": "COUNTRY_GR",
    "values": [
      { "iso": "nl", "value": "Griekenland" },
      { "iso": "en", "value": "Greece" }
    ]
  },
  {
    "key": "COUNTRY_GD",
    "values": [
      { "iso": "nl", "value": "Grenada" },
      { "iso": "en", "value": "Grenada" }
    ]
  },
  {
    "key": "COUNTRY_GL",
    "values": [
      { "iso": "nl", "value": "Groenland" },
      { "iso": "en", "value": "Greenland" }
    ]
  },
  {
    "key": "COUNTRY_GT",
    "values": [
      { "iso": "nl", "value": "Guatemala" },
      { "iso": "en", "value": "Guatemala" }
    ]
  },
  {
    "key": "COUNTRY_GF",
    "values": [
      { "iso": "nl", "value": "Frans-Guyana" },
      { "iso": "en", "value": "French Guiana" }
    ]
  },
  {
    "key": "COUNTRY_GU",
    "values": [
      { "iso": "nl", "value": "Guam" },
      { "iso": "en", "value": "Guam" }
    ]
  },
  {
    "key": "COUNTRY_GY",
    "values": [
      { "iso": "nl", "value": "Guyana" },
      { "iso": "en", "value": "Guyana" }
    ]
  },
  {
    "key": "COUNTRY_HK",
    "values": [
      { "iso": "nl", "value": "Hongkong" },
      { "iso": "en", "value": "Hong Kong" }
    ]
  },
  {
    "key": "COUNTRY_HM",
    "values": [
      { "iso": "nl", "value": "Heard-en McDonaldeilanden" },
      { "iso": "en", "value": "Heard Island and McDonald Islands" }
    ]
  },
  {
    "key": "COUNTRY_HN",
    "values": [
      { "iso": "nl", "value": "Honduras" },
      { "iso": "en", "value": "Honduras" }
    ]
  },
  {
    "key": "COUNTRY_HR",
    "values": [
      { "iso": "nl", "value": "Kroatië" },
      { "iso": "en", "value": "Croatia" }
    ]
  },
  {
    "key": "COUNTRY_HT",
    "values": [
      { "iso": "nl", "value": "Haïti" },
      { "iso": "en", "value": "Haiti" }
    ]
  },
  {
    "key": "COUNTRY_HU",
    "values": [
      { "iso": "nl", "value": "Hongarije" },
      { "iso": "en", "value": "Hungary" }
    ]
  },
  {
    "key": "COUNTRY_ID",
    "values": [
      { "iso": "nl", "value": "Indonesië" },
      { "iso": "en", "value": "Indonesia" }
    ]
  },
  {
    "key": "COUNTRY_IM",
    "values": [
      { "iso": "nl", "value": "Isle of Man" },
      { "iso": "en", "value": "Isle of Man" }
    ]
  },
  {
    "key": "COUNTRY_IN",
    "values": [
      { "iso": "nl", "value": "India" },
      { "iso": "en", "value": "India" }
    ]
  },
  {
    "key": "COUNTRY_IO",
    "values": [
      { "iso": "nl", "value": "Britse Gebieden in de Indische Oceaan" },
      { "iso": "en", "value": "British Indian Ocean Territory" }
    ]
  },
  {
    "key": "COUNTRY_IE",
    "values": [
      { "iso": "nl", "value": "Ierland" },
      { "iso": "en", "value": "Ireland" }
    ]
  },
  {
    "key": "COUNTRY_IR",
    "values": [
      { "iso": "nl", "value": "Iran" },
      { "iso": "en", "value": "Iran" }
    ]
  },
  {
    "key": "COUNTRY_IQ",
    "values": [
      { "iso": "nl", "value": "Irak" },
      { "iso": "en", "value": "Iraq" }
    ]
  },
  {
    "key": "COUNTRY_IS",
    "values": [
      { "iso": "nl", "value": "IJsland" },
      { "iso": "en", "value": "Iceland" }
    ]
  },
  {
    "key": "COUNTRY_IL",
    "values": [
      { "iso": "nl", "value": "Israël" },
      { "iso": "en", "value": "Israel" }
    ]
  },
  {
    "key": "COUNTRY_IT",
    "values": [
      { "iso": "nl", "value": "Italië" },
      { "iso": "en", "value": "Italy" }
    ]
  },
  {
    "key": "COUNTRY_JM",
    "values": [
      { "iso": "nl", "value": "Jamaica" },
      { "iso": "en", "value": "Jamaica" }
    ]
  },
  {
    "key": "COUNTRY_JE",
    "values": [
      { "iso": "nl", "value": "Jersey" },
      { "iso": "en", "value": "Jersey" }
    ]
  },
  {
    "key": "COUNTRY_JO",
    "values": [
      { "iso": "nl", "value": "Jordanië" },
      { "iso": "en", "value": "Jordan" }
    ]
  },
  {
    "key": "COUNTRY_JP",
    "values": [
      { "iso": "nl", "value": "Japan" },
      { "iso": "en", "value": "Japan" }
    ]
  },
  {
    "key": "COUNTRY_KZ",
    "values": [
      { "iso": "nl", "value": "Kazachstan" },
      { "iso": "en", "value": "Kazakhstan" }
    ]
  },
  {
    "key": "COUNTRY_KE",
    "values": [
      { "iso": "nl", "value": "Kenia" },
      { "iso": "en", "value": "Kenya" }
    ]
  },
  {
    "key": "COUNTRY_KG",
    "values": [
      { "iso": "nl", "value": "Kirgizië" },
      { "iso": "en", "value": "Kyrgyzstan" }
    ]
  },
  {
    "key": "COUNTRY_KH",
    "values": [
      { "iso": "nl", "value": "Cambodja" },
      { "iso": "en", "value": "Cambodia" }
    ]
  },
  {
    "key": "COUNTRY_KI",
    "values": [
      { "iso": "nl", "value": "Kiribati" },
      { "iso": "en", "value": "Kiribati" }
    ]
  },
  {
    "key": "COUNTRY_KN",
    "values": [
      { "iso": "nl", "value": "Saint Kitts en Nevis" },
      { "iso": "en", "value": "Saint Kitts and Nevis" }
    ]
  },
  {
    "key": "COUNTRY_KR",
    "values": [
      { "iso": "nl", "value": "Zuid-Korea" },
      { "iso": "en", "value": "South Korea" }
    ]
  },
  {
    "key": "COUNTRY_XK",
    "values": [
      { "iso": "nl", "value": "Kosovo" },
      { "iso": "en", "value": "Kosovo" }
    ]
  },
  {
    "key": "COUNTRY_KW",
    "values": [
      { "iso": "nl", "value": "Koeweit" },
      { "iso": "en", "value": "Kuwait" }
    ]
  },
  {
    "key": "COUNTRY_LA",
    "values": [
      { "iso": "nl", "value": "Laos" },
      { "iso": "en", "value": "Laos" }
    ]
  },
  {
    "key": "COUNTRY_LB",
    "values": [
      { "iso": "nl", "value": "Libanon" },
      { "iso": "en", "value": "Lebanon" }
    ]
  },
  {
    "key": "COUNTRY_LR",
    "values": [
      { "iso": "nl", "value": "Liberia" },
      { "iso": "en", "value": "Liberia" }
    ]
  },
  {
    "key": "COUNTRY_LY",
    "values": [
      { "iso": "nl", "value": "Libië" },
      { "iso": "en", "value": "Libya" }
    ]
  },
  {
    "key": "COUNTRY_LC",
    "values": [
      { "iso": "nl", "value": "Saint Lucia" },
      { "iso": "en", "value": "Saint Lucia" }
    ]
  },
  {
    "key": "COUNTRY_LI",
    "values": [
      { "iso": "nl", "value": "Liechtenstein" },
      { "iso": "en", "value": "Liechtenstein" }
    ]
  },
  {
    "key": "COUNTRY_LK",
    "values": [
      { "iso": "nl", "value": "Sri Lanka" },
      { "iso": "en", "value": "Sri Lanka" }
    ]
  },
  {
    "key": "COUNTRY_LS",
    "values": [
      { "iso": "nl", "value": "Lesotho" },
      { "iso": "en", "value": "Lesotho" }
    ]
  },
  {
    "key": "COUNTRY_LT",
    "values": [
      { "iso": "nl", "value": "Litouwen" },
      { "iso": "en", "value": "Lithuania" }
    ]
  },
  {
    "key": "COUNTRY_LU",
    "values": [
      { "iso": "nl", "value": "Luxemburg" },
      { "iso": "en", "value": "Luxembourg" }
    ]
  },
  {
    "key": "COUNTRY_LV",
    "values": [
      { "iso": "nl", "value": "Letland" },
      { "iso": "en", "value": "Latvia" }
    ]
  },
  {
    "key": "COUNTRY_MO",
    "values": [
      { "iso": "nl", "value": "Macao" },
      { "iso": "en", "value": "Macau" }
    ]
  },
  {
    "key": "COUNTRY_MF",
    "values": [
      { "iso": "nl", "value": "Saint-Martin" },
      { "iso": "en", "value": "Saint Martin" }
    ]
  },
  {
    "key": "COUNTRY_MA",
    "values": [
      { "iso": "nl", "value": "Marokko" },
      { "iso": "en", "value": "Morocco" }
    ]
  },
  {
    "key": "COUNTRY_MC",
    "values": [
      { "iso": "nl", "value": "Monaco" },
      { "iso": "en", "value": "Monaco" }
    ]
  },
  {
    "key": "COUNTRY_MD",
    "values": [
      { "iso": "nl", "value": "Moldavië" },
      { "iso": "en", "value": "Moldova" }
    ]
  },
  {
    "key": "COUNTRY_MG",
    "values": [
      { "iso": "nl", "value": "Madagaskar" },
      { "iso": "en", "value": "Madagascar" }
    ]
  },
  {
    "key": "COUNTRY_MV",
    "values": [
      { "iso": "nl", "value": "Maldiven" },
      { "iso": "en", "value": "Maldives" }
    ]
  },
  {
    "key": "COUNTRY_MX",
    "values": [
      { "iso": "nl", "value": "Mexico" },
      { "iso": "en", "value": "Mexico" }
    ]
  },
  {
    "key": "COUNTRY_MH",
    "values": [
      { "iso": "nl", "value": "Marshalleilanden" },
      { "iso": "en", "value": "Marshall Islands" }
    ]
  },
  {
    "key": "COUNTRY_MK",
    "values": [
      { "iso": "nl", "value": "Noord-Macedonië" },
      { "iso": "en", "value": "North Macedonia" }
    ]
  },
  {
    "key": "COUNTRY_ML",
    "values": [
      { "iso": "nl", "value": "Mali" },
      { "iso": "en", "value": "Mali" }
    ]
  },
  {
    "key": "COUNTRY_MT",
    "values": [
      { "iso": "nl", "value": "Malta" },
      { "iso": "en", "value": "Malta" }
    ]
  },
  {
    "key": "COUNTRY_MM",
    "values": [
      { "iso": "nl", "value": "Myanmar" },
      { "iso": "en", "value": "Myanmar" }
    ]
  },
  {
    "key": "COUNTRY_ME",
    "values": [
      { "iso": "nl", "value": "Montenegro" },
      { "iso": "en", "value": "Montenegro" }
    ]
  },
  {
    "key": "COUNTRY_MN",
    "values": [
      { "iso": "nl", "value": "Mongolië" },
      { "iso": "en", "value": "Mongolia" }
    ]
  },
  {
    "key": "COUNTRY_MP",
    "values": [
      { "iso": "nl", "value": "Noordelijke Marianeneilanden" },
      { "iso": "en", "value": "Northern Mariana Islands" }
    ]
  },
  {
    "key": "COUNTRY_MZ",
    "values": [
      { "iso": "nl", "value": "Mozambique" },
      { "iso": "en", "value": "Mozambique" }
    ]
  },
  {
    "key": "COUNTRY_MR",
    "values": [
      { "iso": "nl", "value": "Mauritanië" },
      { "iso": "en", "value": "Mauritania" }
    ]
  },
  {
    "key": "COUNTRY_MS",
    "values": [
      { "iso": "nl", "value": "Montserrat" },
      { "iso": "en", "value": "Montserrat" }
    ]
  },
  {
    "key": "COUNTRY_MQ",
    "values": [
      { "iso": "nl", "value": "Martinique" },
      { "iso": "en", "value": "Martinique" }
    ]
  },
  {
    "key": "COUNTRY_MU",
    "values": [
      { "iso": "nl", "value": "Mauritius" },
      { "iso": "en", "value": "Mauritius" }
    ]
  },
  {
    "key": "COUNTRY_MW",
    "values": [
      { "iso": "nl", "value": "Malawi" },
      { "iso": "en", "value": "Malawi" }
    ]
  },
  {
    "key": "COUNTRY_MY",
    "values": [
      { "iso": "nl", "value": "Maleisië" },
      { "iso": "en", "value": "Malaysia" }
    ]
  },
  {
    "key": "COUNTRY_YT",
    "values": [
      { "iso": "nl", "value": "Mayotte" },
      { "iso": "en", "value": "Mayotte" }
    ]
  },
  {
    "key": "COUNTRY_NA",
    "values": [
      { "iso": "nl", "value": "Namibië" },
      { "iso": "en", "value": "Namibia" }
    ]
  },
  {
    "key": "COUNTRY_NC",
    "values": [
      { "iso": "nl", "value": "Nieuw-Caledonië" },
      { "iso": "en", "value": "New Caledonia" }
    ]
  },
  {
    "key": "COUNTRY_NE",
    "values": [
      { "iso": "nl", "value": "Niger" },
      { "iso": "en", "value": "Niger" }
    ]
  },
  {
    "key": "COUNTRY_NF",
    "values": [
      { "iso": "nl", "value": "Norfolkeiland" },
      { "iso": "en", "value": "Norfolk Island" }
    ]
  },
  {
    "key": "COUNTRY_NG",
    "values": [
      { "iso": "nl", "value": "Nigeria" },
      { "iso": "en", "value": "Nigeria" }
    ]
  },
  {
    "key": "COUNTRY_NI",
    "values": [
      { "iso": "nl", "value": "Nicaragua" },
      { "iso": "en", "value": "Nicaragua" }
    ]
  },
  {
    "key": "COUNTRY_NU",
    "values": [
      { "iso": "nl", "value": "Niue" },
      { "iso": "en", "value": "Niue" }
    ]
  },
  {
    "key": "COUNTRY_NL",
    "values": [
      { "iso": "nl", "value": "Nederland" },
      { "iso": "en", "value": "Netherlands" }
    ]
  },
  {
    "key": "COUNTRY_NO",
    "values": [
      { "iso": "nl", "value": "Noorwegen" },
      { "iso": "en", "value": "Norway" }
    ]
  },
  {
    "key": "COUNTRY_NP",
    "values": [
      { "iso": "nl", "value": "Nepal" },
      { "iso": "en", "value": "Nepal" }
    ]
  },
  {
    "key": "COUNTRY_NR",
    "values": [
      { "iso": "nl", "value": "Nauru" },
      { "iso": "en", "value": "Nauru" }
    ]
  },
  {
    "key": "COUNTRY_NZ",
    "values": [
      { "iso": "nl", "value": "Nieuw-Zeeland" },
      { "iso": "en", "value": "New Zealand" }
    ]
  },
  {
    "key": "COUNTRY_OM",
    "values": [
      { "iso": "nl", "value": "Oman" },
      { "iso": "en", "value": "Oman" }
    ]
  },
  {
    "key": "COUNTRY_PK",
    "values": [
      { "iso": "nl", "value": "Pakistan" },
      { "iso": "en", "value": "Pakistan" }
    ]
  },
  {
    "key": "COUNTRY_PA",
    "values": [
      { "iso": "nl", "value": "Panama" },
      { "iso": "en", "value": "Panama" }
    ]
  },
  {
    "key": "COUNTRY_PN",
    "values": [
      { "iso": "nl", "value": "Pitcairneilanden" },
      { "iso": "en", "value": "Pitcairn Islands" }
    ]
  },
  {
    "key": "COUNTRY_PE",
    "values": [
      { "iso": "nl", "value": "Peru" },
      { "iso": "en", "value": "Peru" }
    ]
  },
  {
    "key": "COUNTRY_PH",
    "values": [
      { "iso": "nl", "value": "Filipijnen" },
      { "iso": "en", "value": "Philippines" }
    ]
  },
  {
    "key": "COUNTRY_PW",
    "values": [
      { "iso": "nl", "value": "Palau" },
      { "iso": "en", "value": "Palau" }
    ]
  },
  {
    "key": "COUNTRY_PG",
    "values": [
      { "iso": "nl", "value": "Papoea-Nieuw-Guinea" },
      { "iso": "en", "value": "Papua New Guinea" }
    ]
  },
  {
    "key": "COUNTRY_PL",
    "values": [
      { "iso": "nl", "value": "Polen" },
      { "iso": "en", "value": "Poland" }
    ]
  },
  {
    "key": "COUNTRY_PR",
    "values": [
      { "iso": "nl", "value": "Puerto Rico" },
      { "iso": "en", "value": "Puerto Rico" }
    ]
  },
  {
    "key": "COUNTRY_KP",
    "values": [
      { "iso": "nl", "value": "Noord-Korea" },
      { "iso": "en", "value": "North Korea" }
    ]
  },
  {
    "key": "COUNTRY_PT",
    "values": [
      { "iso": "nl", "value": "Portugal" },
      { "iso": "en", "value": "Portugal" }
    ]
  },
  {
    "key": "COUNTRY_PY",
    "values": [
      { "iso": "nl", "value": "Paraguay" },
      { "iso": "en", "value": "Paraguay" }
    ]
  },
  {
    "key": "COUNTRY_PS",
    "values": [
      { "iso": "nl", "value": "Palestijnse gebieden" },
      { "iso": "en", "value": "Palestine" }
    ]
  },
  {
    "key": "COUNTRY_PF",
    "values": [
      { "iso": "nl", "value": "Frans-Polynesië" },
      { "iso": "en", "value": "French Polynesia" }
    ]
  },
  {
    "key": "COUNTRY_QA",
    "values": [
      { "iso": "nl", "value": "Qatar" },
      { "iso": "en", "value": "Qatar" }
    ]
  },
  {
    "key": "COUNTRY_RE",
    "values": [
      { "iso": "nl", "value": "Réunion" },
      { "iso": "en", "value": "Réunion" }
    ]
  },
  {
    "key": "COUNTRY_RO",
    "values": [
      { "iso": "nl", "value": "Roemenië" },
      { "iso": "en", "value": "Romania" }
    ]
  },
  {
    "key": "COUNTRY_RU",
    "values": [
      { "iso": "nl", "value": "Rusland" },
      { "iso": "en", "value": "Russia" }
    ]
  },
  {
    "key": "COUNTRY_RW",
    "values": [
      { "iso": "nl", "value": "Rwanda" },
      { "iso": "en", "value": "Rwanda" }
    ]
  },
  {
    "key": "COUNTRY_SA",
    "values": [
      { "iso": "nl", "value": "Saoedi-Arabië" },
      { "iso": "en", "value": "Saudi Arabia" }
    ]
  },
  {
    "key": "COUNTRY_SD",
    "values": [
      { "iso": "nl", "value": "Soedan" },
      { "iso": "en", "value": "Sudan" }
    ]
  },
  {
    "key": "COUNTRY_SN",
    "values": [
      { "iso": "nl", "value": "Senegal" },
      { "iso": "en", "value": "Senegal" }
    ]
  },
  {
    "key": "COUNTRY_SG",
    "values": [
      { "iso": "nl", "value": "Singapore" },
      { "iso": "en", "value": "Singapore" }
    ]
  },
  {
    "key": "COUNTRY_GS",
    "values": [
      { "iso": "nl", "value": "Zuid-Georgia en Zuidelijke Sandwicheilanden" },
      { "iso": "en", "value": "South Georgia" }
    ]
  },
  {
    "key": "COUNTRY_SJ",
    "values": [
      { "iso": "nl", "value": "Svalbard en Jan Mayen" },
      { "iso": "en", "value": "Svalbard and Jan Mayen" }
    ]
  },
  {
    "key": "COUNTRY_SB",
    "values": [
      { "iso": "nl", "value": "Salomonseilanden" },
      { "iso": "en", "value": "Solomon Islands" }
    ]
  },
  {
    "key": "COUNTRY_SL",
    "values": [
      { "iso": "nl", "value": "Sierra Leone" },
      { "iso": "en", "value": "Sierra Leone" }
    ]
  },
  {
    "key": "COUNTRY_SV",
    "values": [
      { "iso": "nl", "value": "El Salvador" },
      { "iso": "en", "value": "El Salvador" }
    ]
  },
  {
    "key": "COUNTRY_SM",
    "values": [
      { "iso": "nl", "value": "San Marino" },
      { "iso": "en", "value": "San Marino" }
    ]
  },
  {
    "key": "COUNTRY_SO",
    "values": [
      { "iso": "nl", "value": "Somalië" },
      { "iso": "en", "value": "Somalia" }
    ]
  },
  {
    "key": "COUNTRY_PM",
    "values": [
      { "iso": "nl", "value": "Saint Pierre en Miquelon" },
      { "iso": "en", "value": "Saint Pierre and Miquelon" }
    ]
  },
  {
    "key": "COUNTRY_RS",
    "values": [
      { "iso": "nl", "value": "Servië" },
      { "iso": "en", "value": "Serbia" }
    ]
  },
  {
    "key": "COUNTRY_SS",
    "values": [
      { "iso": "nl", "value": "Zuid-Soedan" },
      { "iso": "en", "value": "South Sudan" }
    ]
  },
  {
    "key": "COUNTRY_ST",
    "values": [
      { "iso": "nl", "value": "Sao Tomé en Principe" },
      { "iso": "en", "value": "São Tomé and Príncipe" }
    ]
  },
  {
    "key": "COUNTRY_SR",
    "values": [
      { "iso": "nl", "value": "Suriname" },
      { "iso": "en", "value": "Suriname" }
    ]
  },
  {
    "key": "COUNTRY_SK",
    "values": [
      { "iso": "nl", "value": "Slowakije" },
      { "iso": "en", "value": "Slovakia" }
    ]
  },
  {
    "key": "COUNTRY_SI",
    "values": [
      { "iso": "nl", "value": "Slovenië" },
      { "iso": "en", "value": "Slovenia" }
    ]
  },
  {
    "key": "COUNTRY_SE",
    "values": [
      { "iso": "nl", "value": "Zweden" },
      { "iso": "en", "value": "Sweden" }
    ]
  },
  {
    "key": "COUNTRY_SZ",
    "values": [
      { "iso": "nl", "value": "Swaziland" },
      { "iso": "en", "value": "Eswatini" }
    ]
  },
  {
    "key": "COUNTRY_SX",
    "values": [
      { "iso": "nl", "value": "Sint Maarten" },
      { "iso": "en", "value": "Sint Maarten" }
    ]
  },
  {
    "key": "COUNTRY_SC",
    "values": [
      { "iso": "nl", "value": "Seychellen" },
      { "iso": "en", "value": "Seychelles" }
    ]
  },
  {
    "key": "COUNTRY_SY",
    "values": [
      { "iso": "nl", "value": "Syrië" },
      { "iso": "en", "value": "Syria" }
    ]
  },
  {
    "key": "COUNTRY_TC",
    "values": [
      { "iso": "nl", "value": "Turks-en Caicoseilanden" },
      { "iso": "en", "value": "Turks and Caicos Islands" }
    ]
  },
  {
    "key": "COUNTRY_TD",
    "values": [
      { "iso": "nl", "value": "Tsjaad" },
      { "iso": "en", "value": "Chad" }
    ]
  },
  {
    "key": "COUNTRY_TG",
    "values": [
      { "iso": "nl", "value": "Togo" },
      { "iso": "en", "value": "Togo" }
    ]
  },
  {
    "key": "COUNTRY_TH",
    "values": [
      { "iso": "nl", "value": "Thailand" },
      { "iso": "en", "value": "Thailand" }
    ]
  },
  {
    "key": "COUNTRY_TJ",
    "values": [
      { "iso": "nl", "value": "Tadzjikistan" },
      { "iso": "en", "value": "Tajikistan" }
    ]
  },
  {
    "key": "COUNTRY_TK",
    "values": [
      { "iso": "nl", "value": "Tokelau" },
      { "iso": "en", "value": "Tokelau" }
    ]
  },
  {
    "key": "COUNTRY_TM",
    "values": [
      { "iso": "nl", "value": "Turkmenistan" },
      { "iso": "en", "value": "Turkmenistan" }
    ]
  },
  {
    "key": "COUNTRY_TL",
    "values": [
      { "iso": "nl", "value": "Oost-Timor" },
      { "iso": "en", "value": "Timor-Leste" }
    ]
  },
  {
    "key": "COUNTRY_TO",
    "values": [
      { "iso": "nl", "value": "Tonga" },
      { "iso": "en", "value": "Tonga" }
    ]
  },
  {
    "key": "COUNTRY_TT",
    "values": [
      { "iso": "nl", "value": "Trinidad en Tobago" },
      { "iso": "en", "value": "Trinidad and Tobago" }
    ]
  },
  {
    "key": "COUNTRY_TN",
    "values": [
      { "iso": "nl", "value": "Tunesië" },
      { "iso": "en", "value": "Tunisia" }
    ]
  },
  {
    "key": "COUNTRY_TR",
    "values": [
      { "iso": "nl", "value": "Turkije" },
      { "iso": "en", "value": "Turkey" }
    ]
  },
  {
    "key": "COUNTRY_TV",
    "values": [
      { "iso": "nl", "value": "Tuvalu" },
      { "iso": "en", "value": "Tuvalu" }
    ]
  },
  {
    "key": "COUNTRY_TW",
    "values": [
      { "iso": "nl", "value": "Taiwan" },
      { "iso": "en", "value": "Taiwan" }
    ]
  },
  {
    "key": "COUNTRY_TZ",
    "values": [
      { "iso": "nl", "value": "Tanzania" },
      { "iso": "en", "value": "Tanzania" }
    ]
  },
  {
    "key": "COUNTRY_UG",
    "values": [
      { "iso": "nl", "value": "Oeganda" },
      { "iso": "en", "value": "Uganda" }
    ]
  },
  {
    "key": "COUNTRY_UA",
    "values": [
      { "iso": "nl", "value": "Oekraïne" },
      { "iso": "en", "value": "Ukraine" }
    ]
  },
  {
    "key": "COUNTRY_UM",
    "values": [
      { "iso": "nl", "value": "Kleine afgelegen eilanden van de Verenigde Staten" },
      { "iso": "en", "value": "United States Minor Outlying Islands" }
    ]
  },
  {
    "key": "COUNTRY_UY",
    "values": [
      { "iso": "nl", "value": "Uruguay" },
      { "iso": "en", "value": "Uruguay" }
    ]
  },
  {
    "key": "COUNTRY_US",
    "values": [
      { "iso": "nl", "value": "Verenigde Staten" },
      { "iso": "en", "value": "United States" }
    ]
  },
  {
    "key": "COUNTRY_UZ",
    "values": [
      { "iso": "nl", "value": "Oezbekistan" },
      { "iso": "en", "value": "Uzbekistan" }
    ]
  },
  {
    "key": "COUNTRY_VA",
    "values": [
      { "iso": "nl", "value": "Vaticaanstad" },
      { "iso": "en", "value": "Vatican City" }
    ]
  },
  {
    "key": "COUNTRY_VC",
    "values": [
      { "iso": "nl", "value": "Saint Vincent en de Grenadines" },
      { "iso": "en", "value": "Saint Vincent and the Grenadines" }
    ]
  },
  {
    "key": "COUNTRY_VE",
    "values": [
      { "iso": "nl", "value": "Venezuela" },
      { "iso": "en", "value": "Venezuela" }
    ]
  },
  {
    "key": "COUNTRY_VG",
    "values": [
      { "iso": "nl", "value": "Britse Maagdeneilanden" },
      { "iso": "en", "value": "British Virgin Islands" }
    ]
  },
  {
    "key": "COUNTRY_VI",
    "values": [
      { "iso": "nl", "value": "Amerikaanse Maagdeneilanden" },
      { "iso": "en", "value": "United States Virgin Islands" }
    ]
  },
  {
    "key": "COUNTRY_VN",
    "values": [
      { "iso": "nl", "value": "Vietnam" },
      { "iso": "en", "value": "Vietnam" }
    ]
  },
  {
    "key": "COUNTRY_VU",
    "values": [
      { "iso": "nl", "value": "Vanuatu" },
      { "iso": "en", "value": "Vanuatu" }
    ]
  },
  {
    "key": "COUNTRY_WF",
    "values": [
      { "iso": "nl", "value": "Wallis en Futuna" },
      { "iso": "en", "value": "Wallis and Futuna" }
    ]
  },
  {
    "key": "COUNTRY_WS",
    "values": [
      { "iso": "nl", "value": "Samoa" },
      { "iso": "en", "value": "Samoa" }
    ]
  },
  {
    "key": "COUNTRY_YE",
    "values": [
      { "iso": "nl", "value": "Jemen" },
      { "iso": "en", "value": "Yemen" }
    ]
  },
  {
    "key": "COUNTRY_ZA",
    "values": [
      { "iso": "nl", "value": "Zuid-Afrika" },
      { "iso": "en", "value": "South Africa" }
    ]
  },
  {
    "key": "COUNTRY_ZM",
    "values": [
      { "iso": "nl", "value": "Zambia" },
      { "iso": "en", "value": "Zambia" }
    ]
  },
  {
    "key": "COUNTRY_ZW",
    "values": [
      { "iso": "nl", "value": "Zimbabwe" },
      { "iso": "en", "value": "Zimbabwe" }
    ]
  },
  {
    "key": "USER_ROLE_DASHBOARD",
    "values": [
      {
        "iso": "nl",
        "value": "Dashboard"
      },
      {
        "iso": "en",
        "value": "Dashbaord"
      }
    ]
  },
  {
    "key": "USER_ROLE_DASHBOARD_REVENUE",
    "values": [
      {
        "iso": "nl",
        "value": "Omzet"
      },
      {
        "iso": "en",
        "value": "Revenue"
      }
    ]
  },
  {
    "key": "USER_ROLE_DASHBOARD_BANKACCOUNT",
    "values": [
      {
        "iso": "nl",
        "value": "Bank gegevens"
      },
      {
        "iso": "en",
        "value": "Bank accounts"
      }
    ]
  },
  {
    "key": "USER_ROLE_DASHBOARD_VAT_DECLARATION",
    "values": [
      {
        "iso": "nl",
        "value": "BTW aangiftes"
      },
      {
        "iso": "en",
        "value": "VAT declarations"
      }
    ]
  },
  {
    "key": "USER_ROLE_DOCUMENT_DELIVERY",
    "values": [
      {
        "iso": "nl",
        "value": "Aanleveren van documenten"
      },
      {
        "iso": "en",
        "value": "Delivery of documents"
      }
    ]
  },
  {
    "key": "USER_ROLE_OCR_CONSULT",
    "values": [
      {
        "iso": "nl",
        "value": "OCR raadplegen"
      },
      {
        "iso": "en",
        "value": "OCR consult"
      }
    ]
  },
  {
    "key": "USER_ROLE_OCR_CONTRIBUTOR",
    "values": [
      {
        "iso": "nl",
        "value": "OCR Beheren"
      },
      {
        "iso": "en",
        "value": "OCR contributor"
      }
    ]
  },
  {
    "key": "USER_ROLE_OCR_MANAGER",
    "values": [
      {
        "iso": "nl",
        "value": "OCR manager"
      },
      {
        "iso": "en",
        "value": "OCR manager"
      }
    ]
  },
  {
    "key": "USER_ROLE_DOCUMENT_CONSULT",
    "values": [
      {
        "iso": "nl",
        "value": "Documenten raadplegen"
      },
      {
        "iso": "en",
        "value": "Document consult"
      }
    ]
  },
  {
    "key": "USER_ROLE_DOCUMENT_CONTRIBUTOR",
    "values": [
      {
        "iso": "nl",
        "value": "Documenten beheren"
      },
      {
        "iso": "en",
        "value": "Document contributor"
      }
    ]
  },
  {
    "key": "USER_ROLE_DOCUMENT_MANAGER",
    "values": [
      {
        "iso": "nl",
        "value": "Documenten manager"
      },
      {
        "iso": "en",
        "value": "Document manager"
      }
    ]
  },
  {
    "key": "USER_ROLE_CASHBOOK_CONTRIBUTOR",
    "values": [
      {
        "iso": "nl",
        "value": "Kasboek raadplegen"
      },
      {
        "iso": "en",
        "value": "Cashbook consult"
      }
    ]
  },
  {
    "key": "USER_ROLE_CASHBOOK_CONTRIBUTOR",
    "values": [
      {
        "iso": "nl",
        "value": "Kasboek beheren"
      },
      {
        "iso": "en",
        "value": "Cashbook contributor"
      }
    ]
  },
  {
    "key": "USER_ROLE_CASHBOOK_MANAGER",
    "values": [
      {
        "iso": "nl",
        "value": "Kasboek manager"
      },
      {
        "iso": "en",
        "value": "Cashbook manager"
      }
    ]
  },
  {
    "key": "USER_ROLE_GENERAL",
    "values": [
      { "iso": "nl", "value": "Algemeen" },
      { "iso": "en", "value": "General" }
    ]
  },
  {
    "key": "USER_ROLE_RELATION",
    "values": [
      { "iso": "nl", "value": "Relaties" },
      { "iso": "en", "value": "Relations" }
    ]
  },
  {
    "key": "USER_ROLE_ARTICLE",
    "values": [
      { "iso": "nl", "value": "Artikelen" },
      { "iso": "en", "value": "Articles" }
    ]
  },
  {
    "key": "USER_ROLE_PURCHASE_DOCUMENTS",
    "values": [
      { "iso": "nl", "value": "Aankoop documenten" },
      { "iso": "en", "value": "Purchase documents" }
    ]
  },
  {
    "key": "USER_ROLE_SALES_DOCUMENTS",
    "values": [
      { "iso": "nl", "value": "Verkoop documenten" },
      { "iso": "en", "value": "Sales documents" }
    ]
  },
  {
    "key": "USER_ROLE_FINANCIAL",
    "values": [
      { "iso": "nl", "value": "Financieel" },
      { "iso": "en", "value": "Financial" }
    ]
  },
  {
    "key": "USER_ROLE_REPORT",
    "values": [
      { "iso": "nl", "value": "Rapporten" },
      { "iso": "en", "value": "Reports" }
    ]
  },
  {
    "key": "USER_ROLE_LEDGER",
    "values": [
      { "iso": "nl", "value": "Grootbook" },
      { "iso": "en", "value": "Ledger" }
    ]
  },
  {
    "key": "USER_ROLE_INFORMATION",
    "values": [
      { "iso": "nl", "value": "Informatie" },
      { "iso": "en", "value": "Information" }
    ]
  },
  {
    "key": "USER_ROLE_ADMIN",
    "values": [
      { "iso": "nl", "value": "Administrator" },
      { "iso": "en", "value": "Administrator" }
    ]
  },
  {
    "key": "USER_ROLE_OWNER",
    "values": [
      { "iso": "nl", "value": "Abonnee eigenaar" },
      { "iso": "en", "value": "Owner" }
    ]
  },
  {
    "key": "USER_ROLE_RELATION_CONSULT",
    "values": [
      { "iso": "nl", "value": "Relaties raadplegen" },
      { "iso": "en", "value": "Consult relations" }
    ]
  },
  {
    "key": "USER_ROLE_RELATION_CONTRIBUTOR",
    "values": [
      { "iso": "nl", "value": "Relaties beheren" },
      { "iso": "en", "value": "Manage relations" }
    ]
  },
  {
    "key": "USER_ROLE_RELATION_MANAGER",
    "values": [
      { "iso": "nl", "value": "Relatie manager" },
      { "iso": "en", "value": "Relation manager" }
    ]
  },
  {
    "key": "USER_ROLE_ARTICLE_CONSULT",
    "values": [
      { "iso": "nl", "value": "Artikelen raadplegen" },
      { "iso": "en", "value": "Consult articles" }
    ]
  },
  {
    "key": "USER_ROLE_ARTICLE_CONTRIBUTOR",
    "values": [
      { "iso": "nl", "value": "Artikelen beheren" },
      { "iso": "en", "value": "Manage articles" }
    ]
  },
  {
    "key": "USER_ROLE_ARTICLE_MANAGER",
    "values": [
      { "iso": "nl", "value": "Artikel manager" },
      { "iso": "en", "value": "Article manager" }
    ]
  },
  {
    "key": "USER_ROLE_STOCK_CONSULT",
    "values": [
      { "iso": "nl", "value": "Stock raadplegen" },
      { "iso": "en", "value": "Consult stock" }
    ]
  },
  {
    "key": "USER_ROLE_STOCK_CONTRIBUTOR",
    "values": [
      { "iso": "nl", "value": "Stock beheren" },
      { "iso": "en", "value": "Manage stock" }
    ]
  },
  {
    "key": "USER_ROLE_STOCK_MANAGER",
    "values": [
      { "iso": "nl", "value": "Stock manager" },
      { "iso": "en", "value": "Stock manager" }
    ]
  },
  {
    "key": "USER_ROLE_PURCHASE_INVOICE_CONSULT",
    "values": [
      { "iso": "nl", "value": "Aankoop facturen raadplegen" },
      { "iso": "en", "value": "Consult purchase invoices" }
    ]
  },
  {
    "key": "USER_ROLE_PURCHASE_INVOICE_CONTRIBUTOR",
    "values": [
      { "iso": "nl", "value": "Aankoop facturen beheren" },
      { "iso": "en", "value": "Manage purchase invoices" }
    ]
  },
  {
    "key": "USER_ROLE_PURCHASE_INVOICE_MANAGER",
    "values": [
      { "iso": "nl", "value": "Aankoop factuur manager" },
      { "iso": "en", "value": "Purchase invoice manager" }
    ]
  },
  {
    "key": "USER_ROLE_PURCHASE_ORDER_CONSULT",
    "values": [
      { "iso": "nl", "value": "Aankoop bestellingen raadplegen" },
      { "iso": "en", "value": "Consult purchase orders" }
    ]
  },
  {
    "key": "USER_ROLE_PURCHASE_ORDER_CONTRIBUTOR",
    "values": [
      { "iso": "nl", "value": "Aankoop bestellingen beheren" },
      { "iso": "en", "value": "Manage purchase orders" }
    ]
  },
  {
    "key": "USER_ROLE_PURCHASE_ORDER_MANAGER",
    "values": [
      { "iso": "nl", "value": "Aankoop bestelling manager" },
      { "iso": "en", "value": "Purchase order manager" }
    ]
  },
  {
    "key": "USER_ROLE_PRODUCT_RECEPTION_CONSULT",
    "values": [
      { "iso": "nl", "value": "Recepties raadplegen" },
      { "iso": "en", "value": "Consult receptions" }
    ]
  },
  {
    "key": "USER_ROLE_PRODUCT_RECEPTION_CONTRIBUTOR",
    "values": [
      { "iso": "nl", "value": "Recepties beheren" },
      { "iso": "en", "value": "Manage receptions" }
    ]
  },
  {
    "key": "USER_ROLE_PRODUCT_RECEPTION_MANAGER",
    "values": [
      { "iso": "nl", "value": "Receptie manager" },
      { "iso": "en", "value": "Reception manager" }
    ]
  },
  {
    "key": "USER_ROLE_SALES_INVOICE_CONSULT",
    "values": [
      { "iso": "nl", "value": "Verkoop facturen raadplegen" },
      { "iso": "en", "value": "Consult sales invoices" }
    ]
  },
  {
    "key": "USER_ROLE_SALES_INVOICE_CONTRIBUTOR",
    "values": [
      { "iso": "nl", "value": "Verkoop facturen beheren" },
      { "iso": "en", "value": "Manage sales invoices" }
    ]
  },
  {
    "key": "USER_ROLE_SALES_INVOICE_MANAGER",
    "values": [
      { "iso": "nl", "value": "Verkoop factuur manager" },
      { "iso": "en", "value": "Sales invoice manager" }
    ]
  },
  {
    "key": "USER_ROLE_SALES_ORDER_CONSULT",
    "values": [
      { "iso": "nl", "value": "Verkooporders raadplegen" },
      { "iso": "en", "value": "Consult sales orders" }
    ]
  },
  {
    "key": "USER_ROLE_SALES_ORDER_CONTRIBUTOR",
    "values": [
      { "iso": "nl", "value": "Verkooporders beheren" },
      { "iso": "en", "value": "Manage sales orders" }
    ]
  },
  {
    "key": "USER_ROLE_SALES_ORDER_MANAGER",
    "values": [
      { "iso": "nl", "value": "Verkooporder manager" },
      { "iso": "en", "value": "Sales order manager" }
    ]
  },
  {
    "key": "USER_ROLE_SALES_QUOTE_CONSULT",
    "values": [
      { "iso": "nl", "value": "Offertes raadplegen" },
      { "iso": "en", "value": "Consult receptions" }
    ]
  },
  {
    "key": "USER_ROLE_SALES_QUOTE_CONTRIBUTOR",
    "values": [
      { "iso": "nl", "value": "Offertes beheren" },
      { "iso": "en", "value": "Manage receptions" }
    ]
  },
  {
    "key": "USER_ROLE_SALES_QUOTE_MANAGER",
    "values": [
      { "iso": "nl", "value": "Offerte manager" },
      { "iso": "en", "value": "Reception manager" }
    ]
  },
  {
    "key": "USER_ROLE_SALES_SUBSCRIPTION_CONSULT",
    "values": [
      { "iso": "nl", "value": "Abonnementen raadplegen" },
      { "iso": "en", "value": "Consult subscriptions" }
    ]
  },
  {
    "key": "USER_ROLE_SALES_SUBSCRIPTION_CONTRIBUTOR",
    "values": [
      { "iso": "nl", "value": "Abonnementen beheren" },
      { "iso": "en", "value": "Manage subscriptions" }
    ]
  },
  {
    "key": "USER_ROLE_SALES_SUBSCRIPTION_MANAGER",
    "values": [
      { "iso": "nl", "value": "Abonnement manager" },
      { "iso": "en", "value": "Subscription manager" }
    ]
  },
  {
    "key": "USER_ROLE_WORKPERFORMANCE_CONSULT",
    "values": [
      { "iso": "nl", "value": "Werkprestaties raadplegen" },
      { "iso": "en", "value": "Consult workperformances" }
    ]
  },
  {
    "key": "USER_ROLE_WORKPERFORMANCE_CONTRIBUTOR",
    "values": [
      { "iso": "nl", "value": "Werkprestaties beheren" },
      { "iso": "en", "value": "Manage workperformances" }
    ]
  },
  {
    "key": "USER_ROLE_WORKPERFORMANCE_MANAGER",
    "values": [
      { "iso": "nl", "value": "Werkprestatie manager" },
      { "iso": "en", "value": "Workperformance manager" }
    ]
  },
  {
    "key": "USER_ROLE_PRODUCT_DELIVERY_CONSULT",
    "values": [
      { "iso": "nl", "value": "Levering van goederen raadplegen" },
      { "iso": "en", "value": "Consult delivery of products" }
    ]
  },
  {
    "key": "USER_ROLE_PRODUCT_DELIVERY_CONTRIBUTOR",
    "values": [
      { "iso": "nl", "value": "Levering van goederen beheren" },
      { "iso": "en", "value": "Manage delivery of products" }
    ]
  },
  {
    "key": "USER_ROLE_PRODUCT_DELIVERY_MANAGER",
    "values": [
      { "iso": "nl", "value": "Levering van goed manager" },
      { "iso": "en", "value": "Delivery of product manager" }
    ]
  },
  {
    "key": "USER_ROLE_FINANCIAL_TRANSACTIONS_CONSULT",
    "values": [
      { "iso": "nl", "value": "Financiële transacties raadplegen" },
      { "iso": "en", "value": "Consult financial transactions" }
    ]
  },
  {
    "key": "USER_ROLE_FINANCIAL_TRANSACTIONS_CONTRIBUTOR",
    "values": [
      { "iso": "nl", "value": "Financiële transacties beheren" },
      { "iso": "en", "value": "Manage financial transactions" }
    ]
  },
  {
    "key": "USER_ROLE_FINANCIAL_TRANSACTION_MANAGER",
    "values": [
      { "iso": "nl", "value": "Financiële transactie manager" },
      { "iso": "en", "value": "Financial transaction manager" }
    ]
  },
  {
    "key": "USER_ROLE_REPORT_REQUEST_CONSULT",
    "values": [
      { "iso": "nl", "value": "Raportaanvragen raadplegen" },
      { "iso": "en", "value": "Consult requested reports" }
    ]
  },
  {
    "key": "USER_ROLE_REPORT_REQUEST_CONTRIBUTOR",
    "values": [
      { "iso": "nl", "value": "Raportaanvragen beheren" },
      { "iso": "en", "value": "Manage requested reports" }
    ]
  },
  {
    "key": "USER_ROLE_REPORT_REQUEST_MANAGER",
    "values": [
      { "iso": "nl", "value": "Rapport aanvraag manager" },
      { "iso": "en", "value": "Report request manager" }
    ]
  },
  {
    "key": "USER_ROLE_LEDGER_DIARY_CONSULT",
    "values": [
      { "iso": "nl", "value": "Dagboeken raadplegen" },
      { "iso": "en", "value": "Consult ledger diaries" }
    ]
  },
  {
    "key": "USER_ROLE_LEDGER_DIARY_CONTRIBUTOR",
    "values": [
      { "iso": "nl", "value": "Dagboeken beheren" },
      { "iso": "en", "value": "Manage ledger diaries" }
    ]
  },
  {
    "key": "USER_ROLE_LEDGER_DIARY_MANAGER",
    "values": [
      { "iso": "nl", "value": "Dagboek manager" },
      { "iso": "en", "value": "Ledger diary manager" }
    ]
  },
  {
    "key": "USER_ROLE_WRITEOFF_CONSULT",
    "values": [
      { "iso": "nl", "value": "Afschrijvingen raadplegen" },
      { "iso": "en", "value": "Consult write offs" }
    ]
  },
  {
    "key": "USER_ROLE_WRITEOFF_CONTRIBUTOR",
    "values": [
      { "iso": "nl", "value": "Afschrijvingen beheren" },
      { "iso": "en", "value": "Manage write offs" }
    ]
  },
  {
    "key": "USER_ROLE_WRITEOFF_MANAGER",
    "values": [
      { "iso": "nl", "value": "Afschrijvingsmanager" },
      { "iso": "en", "value": "Writeoff manager" }
    ]
  },
  {
    "key": "USER_ROLE_EXPORT_CONSULT",
    "values": [
      { "iso": "nl", "value": "Exporten raadplegen" },
      { "iso": "en", "value": "Consult exports" }
    ]
  },
  {
    "key": "USER_ROLE_EXPORT_CONTRIBUTOR",
    "values": [
      { "iso": "nl", "value": "Exporten beheren" },
      { "iso": "en", "value": "Manage exports" }
    ]
  },
  {
    "key": "USER_ROLE_EXPORT_MANAGER",
    "values": [
      { "iso": "nl", "value": "Export manager" },
      { "iso": "en", "value": "Export manager" }
    ]
  },
  {
    "key": "USER_ROLE_MAIL_CONSULT",
    "values": [
      { "iso": "nl", "value": "E-mail raadplegen" },
      { "iso": "en", "value": "Consult emails" }
    ]
  },
  {
    "key": "USER_ROLE_MAIL_CONTRIBUTOR",
    "values": [
      { "iso": "nl", "value": "E-mail beheren" },
      { "iso": "en", "value": "Manage emails" }
    ]
  },
  {
    "key": "USER_ROLE_MAIL_CONSULT",
    "values": [
      { "iso": "nl", "value": "E-mail raadplegen" },
      { "iso": "en", "value": "Consult emails" }
    ]
  },
  {
    "key": "USER_ROLE_MIKA_CHAT",
    "values": [
      { "iso": "nl", "value": "Mika contacteren" },
      { "iso": "en", "value": "Chat with Mika" }
    ]
  },
  {
    "key": "USER_ROLE_TRANSLOCATION_CONTRIBUTOR",
    "values": [
      { "iso": "nl", "value": "Verplaatsingen beheren" },
      { "iso": "en", "value": "Manage translocations" }
    ]
  },
  {
    "key": "USER_ROLE_TRANSLOCATION_CONSULT",
    "values": [
      { "iso": "nl", "value": "Verplaatsingen raadplegen" },
      { "iso": "en", "value": "Consult translocations" }
    ]
  },
  {
    "key": "USER_ROLE_PROJECT",
    "values": [
      { "iso": "nl", "value": "Projecten" },
      { "iso": "en", "value": "Projects" }
    ]
  },
  {
    "key": "USER_ROLE_AIASSISTANT",
    "values": [
      { "iso": "nl", "value": "AI" },
      { "iso": "en", "value": "AI" }
    ]
  },
  {
    "key": "USER_ROLE_TRANSLOCATION_MANAGER",
    "values": [
      { "iso": "nl", "value": "Verplaatsingsmanager" },
      { "iso": "en", "value": "Translocation manager" }
    ]
  },
  {
    "key": "USER_ROLE_PROJECT_CONSULT",
    "values": [
      { "iso": "nl", "value": "Projecten raadplegen" },
      { "iso": "en", "value": "Consult projects" }
    ]
  },
  {
    "key": "USER_ROLE_PROJECT_CONTRIBUTOR",
    "values": [
      { "iso": "nl", "value": "Projecten beheren" },
      { "iso": "en", "value": "Manage projects" }
    ]
  },
  {
    "key": "USER_ROLE_PROJECT_MANAGER",
    "values": [
      { "iso": "nl", "value": "Projectenmanager" },
      { "iso": "en", "value": "Project manager" }
    ]
  },
  {
    "key": "USER_ROLE_PREPAID_SERVICE_CONSULT",
    "values": [
      { "iso": "nl", "value": "Vooruitbetaalde diensten raadplegen" },
      { "iso": "en", "value": "Consult pre-paid services" }
    ]
  },
  {
    "key": "USER_ROLE_PREPAID_SERVICE_CONTRIBUTOR",
    "values": [
      { "iso": "nl", "value": "Vooruitbetaalde diensten beheren" },
      { "iso": "en", "value": "Manage pre-paid services" }
    ]
  },
  {
    "key": "USER_ROLE_PREPAID_SERVICE_MANAGER",
    "values": [
      { "iso": "nl", "value": "Vooruitbetaalde dienst manager" },
      { "iso": "en", "value": "Pre-paid service manager" }
    ]
  },
  {
    "key": "USER_ROLE_CASHBOOK_CONSULT",
    "values": [
      { "iso": "nl", "value": "Kasbook raadplegen" },
      { "iso": "en", "value": "Consult cashbook" }
    ]
  },
  {
    "key": "USER_ROLE_CASHBOOK_CONTRIBUTOR",
    "values": [
      { "iso": "nl", "value": "Kasboek beheren" },
      { "iso": "en", "value": "Manage cashbook" }
    ]
  },
  {
    "key": "USER_ROLE_CASHBOOK_MANAGER",
    "values": [
      { "iso": "nl", "value": "Kasboek manager" },
      { "iso": "en", "value": "Cashbook manager" }
    ]
  },
  {
    "key": "EMAIL_LICENSE_EXPIRED_LEVEL_ONE_SUBJECT",
    "values": [
      { "iso": "nl", "value": "{{0}} licentie vervalt binnenkort" },
      { "iso": "en", "value": "{{0}} license will expire soon" }
    ]
  },
  {
    "key": "EMAIL_LICENSE_EXPIRED_LEVEL_TWO_SUBJECT",
    "values": [
      { "iso": "nl", "value": "{{0}} licentie vervalt binnenkort" },
      { "iso": "en", "value": "{{0}} license will expire soon" }
    ]
  },
  {
    "key": "EMAIL_LICENSE_EXPIRED_LEVEL_THREE_SUBJECT",
    "values": [
      { "iso": "nl", "value": "{{0}} licentie vervalt morgen!" },
      { "iso": "en", "value": "{{0}} license expires tomorrow!" }
    ]
  },
  {
    "key": "EMAIL_LICENSE_EXPIRED_LEVEL_ONE_SYSTEM_PAYMENT_BODY",
    "values": [
      {
        "iso": "nl",
        "value": "<p>Beste {{0}},<br /><br/>Jouw licentie voor {{2}} vervalt binnen {{1}} dagen. Verleng de licentie online via de link \"<a href=\"https://{{3}}/client/setting/license\">https://{{3}}/client/setting/license</a>\".<br /><br />Met vriendelijke groeten,<br />Het {{2}} team</p>"
      },
      {
        "iso": "en",
        "value": "<p>Dear {{0}},<br /><br/>Your license for {{2}} will expire in {{1}} days. Renew the license online via the link \"<a href=\"https://{{3}}/client/setting/license\">https://{{3}}/client/setting/license</a>\".<br /><br />Kind regards,<br />The {{2}} team</p>"
      }
    ]
  },
  {
    "key": "EMAIL_LICENSE_EXPIRED_LEVEL_TWO_SYSTEM_PAYMENT_BODY",
    "values": [
      {
        "iso": "nl",
        "value": "<p>Beste {{0}},<br /><br/>Jouw licentie voor {{2}} vervalt binnen {{1}} dagen. Verleng de licentie online via de link \"<a href=\"https://{{3}}/client/setting/license\">https://{{3}}/client/setting/license</a>\" om te voorkomen dat je toegang verliest tot jouw omgeving.<br /><br />Met vriendelijke groeten,<br />Het {{2}} team</p>"
      },
      {
        "iso": "en",
        "value": "<p>Dear {{0}},<br /><br/>Your license for {{2}] will expire in {{1}} days. Renew the license online via the link \"<a href=\"https://{{3}}/client/setting/license\">https://{{3}}/client/setting/license</a>\" to prevent you from losing access to your environment.<br /><br />Kind regards,<br />The {{2}} team</p>"
      }
    ]
  },
  {
    "key": "EMAIL_LICENSE_EXPIRED_LEVEL_THREE_SYSTEM_PAYMENT_BODY",
    "values": [
      {
        "iso": "nl",
        "value": "<p>Beste {{0}},<br /><br/>Jouw licentie voor {{1}} is verlopen. Verleng de licentie online via de link <a href=\"https://{{0}}/client/setting/license\">https://{{2}}/client/setting/license</a> om opnieuw toegang te krijgen tot jouw {{1}}-omgeving.</p>"
      },
      {
        "iso": "en",
        "value": "<p>Dear {{0}},<br /><br/>Your license for {{1}} has expired. Renew the license online via the link <a href=\"https://{{0}}/client/setting/license\">https://{{2}}/client/setting/license</a> to regain access to your {{1}} environment.</p>"
      }
    ]
  },
  {
    "key": "EMAIL_LICENSE_EXPIRED_LEVEL_ONE_FULL_RESELLER_PAYMENT_BODY",
    "values": [
      {
        "iso": "nl",
        "value": "<p>Beste {{0}},<br /><br/>Jouw licentie voor {{3}} vervalt binnen {{1}} dagen. Contacteer {{2}} om jouw licentie te verlengen.<br /><br />Met vriendelijke groeten,<br />Het {{3}} team</p>"
      },
      { "iso": "en", "value": "<p>Dear {{0}},<br /><br/>Your license for {{3}} will expire in {{1}} days. Contact {{2}} to renew your license.<br /><br />Sincerely,<br />The {{3}} team</p>" }
    ]
  },
  {
    "key": "EMAIL_LICENSE_EXPIRED_LEVEL_TWO_FULL_RESELLER_PAYMENT_BODY",
    "values": [
      {
        "iso": "nl",
        "value": "<p>Beste {{0}},<br /><br/>Jouw licentie voor {{3}} vervalt binnen {{1}} dagen. Contacteer {{2}} om jou licentie te verlengen en te voorkomen dat je toegang verliest tot jouw omgeving.<br /><br />Met vriendelijke groeten,<br />Het {{3}} team</p>"
      },
      {
        "iso": "en",
        "value": "<p>Dear {{0}},<br /><br/>Your license for {{3}} will expire in {{1}} days. Contact {{2}} to renew your license and avoid losing access to your environment.<br /><br />Kind regards,<br />The {{3}} team</p>"
      }
    ]
  },
  {
    "key": "EMAIL_LICENSE_EXPIRED_LEVEL_THREE_FULL_RESELLER_PAYMENT_BODY",
    "values": [
      {
        "iso": "nl",
        "value": "<p>Beste {{0}},<br /><br/>Jouw licentie voor {{2}} is verlopen. Contacteer {{1}} om weer toegang te krijgen tot jouw {{2}}-omgeving.<br /><br />Met vriendelijke groet,<br />Het {{2}} team</p>"
      },
      {
        "iso": "en",
        "value": "<p>Dear {{0}},<br /><br/>Your license for {{2}} has been completed. Contact {{1}} to regain access to your {{2}} environment.<br /><br />Kind regards,<br />The {{2}} team</p>"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_COMPANY_INFORMATION_VAT",
    "values": [
      {
        "iso": "nl",
        "value": "<p>Laten we even kijken naar jouw europees BTW nummer. <br />Heb je geen europees BTW nummer? Dan mag je hier je ondernemingsnummer invullen.</p>"
      },
      {
        "iso": "en",
        "value": "<p>Let's take a look at your European VAT number. <br />Don't have a European VAT number? Then you can enter your company number here.</p>"
      }
    ]
  },
  {
    "key": "ONBAORDING_SUBSCRIBER_COMPANY_INFORMATION_VAT_SUCCEEDED",
    "values": [
      {
        "iso": "nl",
        "value": "BTW nummer {{0}} is herkent in het europese VIES systeem."
      },
      {
        "iso": "en",
        "value": "VAT number {{0}} is recognized in the European VIES system."
      }
    ]
  },
  {
    "key": "ONBAORDING_SUBSCRIBER_COMPANY_INFORMATION_VAT_FAILED",
    "values": [
      {
        "iso": "nl",
        "value": "BTW nummer {{0}} is niet herkent in het europese VIES systeem. Controlleer jouw BTW nummer op typfouten. Ben je niet BTW plichtig, is je onderneming nog niet of net opgestart? Dan kan het zijn dat VIES jouw gegevens (nog) niet verwerkt."
      },
      {
        "iso": "en",
        "value": "VAT number {{0}} is not recognized in the European VIES system. Check your VAT number for typos. Are you not subject to VAT, is your company not yet or just starting up? Then it is possible that VIES does not (yet) process your data."
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_COMPANY_INFORMATION_LOGO",
    "values": [
      {
        "iso": "nl",
        "value": "<p>Het logo van een bedrijf is het meest herkenbare element. Upload jouw logo zodat het gekoppeld kan worden aan al jouw publieke documenten.</p>"
      },
      {
        "iso": "en",
        "value": "<p>A company's logo is the most recognizable element. Upload your logo so that it can be linked to all your public documents.</p>"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_COMPLETED",
    "values": [
      {
        "iso": "nl",
        "value": "<p>Je account is aangemaakt. Op de login pagina kan je nu inloggen met de account gegevens die je hebt opgegeven.</p>"
      },
      {
        "iso": "en",
        "value": "<p>Your account has been created. On the login page, you can now log in with the account information you provided.</p>"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_SAVING",
    "values": [
      {
        "iso": "nl",
        "value": "<p>De gegevens worden opgeslagen en je account wordt aangemaakt.<br />Even geduld...</p>"
      },
      {
        "iso": "en",
        "value": "<p>The data will be saved and your account will be created.<br />Please be patient.</p>"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_USER_NAME_REQUIRED",
    "values": [
      {
        "iso": "nl",
        "value": "De naam van de gebruiker ontbreekt!"
      },
      {
        "iso": "en",
        "value": "The name of the user is missing!"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_USER_EMAIL_REQUIRED",
    "values": [
      {
        "iso": "nl",
        "value": "Een e-mailadres om in te loggen is verplicht!"
      },
      {
        "iso": "en",
        "value": "An e-mail address to log in is required!"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_USER_PASSWORD_REQUIRED",
    "values": [
      {
        "iso": "nl",
        "value": "Een login wachtwoord is verplicht!"
      },
      {
        "iso": "en",
        "value": "A login password is required!"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_VAT_REQUIRED",
    "values": [
      {
        "iso": "nl",
        "value": "Een BTW nummer is verplicht!"
      },
      {
        "iso": "en",
        "value": "A VAT number is required!"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_COMPANY_NAME_REQUIRED",
    "values": [
      {
        "iso": "nl",
        "value": "Een bedrijfsnaam is verplicht!"
      },
      {
        "iso": "en",
        "value": "A company name is required!"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_COMPANY_ADDRESS_STREET_REQUIRED",
    "values": [
      {
        "iso": "nl",
        "value": "Een straatnaam in het adres is verplicht!"
      },
      {
        "iso": "en",
        "value": "A street name in the address is required!"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_COMPANY_ADDRESS_NUMBER_REQUIRED",
    "values": [
      {
        "iso": "nl",
        "value": "Een huisnummer in het adres is verplicht!"
      },
      {
        "iso": "en",
        "value": "A house number in the address is required!"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_COMPANY_ADDRESS_ZIP_REQUIRED",
    "values": [
      {
        "iso": "nl",
        "value": "Een postcode in het adres is verplicht!"
      },
      {
        "iso": "en",
        "value": "A zip code in the address is required!"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_COMPANY_ADDRESS_CITY_REQUIRED",
    "values": [
      {
        "iso": "nl",
        "value": "Een stad in het adres is verplicht!"
      },
      {
        "iso": "en",
        "value": "A city in the address is required!"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_COMPANY_ADDRESS_COUNTRY_REQUIRED",
    "values": [
      {
        "iso": "nl",
        "value": "Een land in het adres is verplicht!"
      },
      {
        "iso": "en",
        "value": "A country in the address is required!"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_CANT_SAVE_INCOMPLETE",
    "values": [
      {
        "iso": "nl",
        "value": "De onboarding kon niet worden afgerond wegens ontbrekende informatie."
      },
      {
        "iso": "en",
        "value": "The onboarding could not be completed due to missing information."
      }
    ]
  },
  {
    "key": "UI_ONBOARDING_FAILED",
    "values": [
      {
        "iso": "nl",
        "value": "De onboarding is mislukt! Neem contact op met de support."
      },
      {
        "iso": "en",
        "value": "The onboarding failed! Please contact support."
      }
    ]
  },
  {
    "key": "UI_PDF_SHOW_LEGAL_FOOTER",
    "values": [
      {
        "iso": "nl",
        "value": "Footer met legale informatie"
      },
      {
        "iso": "en",
        "value": "Footer with legal information"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_BANK_NAME_REQUIRED",
    "values": [
      {
        "iso": "nl",
        "value": "Een naam voor jouw bank is verplicht!"
      },
      {
        "iso": "en",
        "value": "A name for your bank account is required!"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_BANK_IBAN_REQUIRED",
    "values": [
      {
        "iso": "nl",
        "value": "Een IBAN rekeningnummer voor jouw bank is verplicht!"
      },
      {
        "iso": "en",
        "value": "An IBAN account number for your bank account is required!"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_BANK_BIC_REQUIRED",
    "values": [
      {
        "iso": "nl",
        "value": "Een BIC voor jouw bank is verplicht!"
      },
      {
        "iso": "en",
        "value": "An BIC for your bank account is required!"
      }
    ]
  },
  {
    "key": "ONBOARDING_GO_TO_LOGIN",
    "values": [
      {
        "iso": "nl",
        "value": "Ga naar inlog pagina"
      },
      {
        "iso": "en",
        "value": "Go to login page"
      }
    ]
  },
  {
    "key": "MODULE_NAME_ARTICLE",
    "values": [
      {
        "iso": "nl",
        "value": "Artikelbeheer"
      },
      {
        "iso": "en",
        "value": "Article management"
      }
    ]
  },
  {
    "key": "MODULE_NAME_ACCOUNTANCY",
    "values": [
      {
        "iso": "nl",
        "value": "Boekhouden"
      },
      {
        "iso": "en",
        "value": "Accountancy"
      }
    ]
  },
  {
    "key": "MODULE_NAME_PREPAIDSERVICE",
    "values": [
      {
        "iso": "nl",
        "value": "Vooruitbetaalde diensten"
      },
      {
        "iso": "en",
        "value": "Pre-paid services"
      }
    ]
  },
  {
    "key": "MODULE_NAME_PURCHASEINVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoopfacturen"
      },
      {
        "iso": "en",
        "value": "Purchase invoices"
      }
    ]
  },
  {
    "key": "MODULE_NAME_PURCHASEORDER",
    "values": [
      {
        "iso": "nl",
        "value": "Aankoopbestellingen"
      },
      {
        "iso": "en",
        "value": "Purchase orders"
      }
    ]
  },
  {
    "key": "MODULE_NAME_RELATION",
    "values": [
      {
        "iso": "nl",
        "value": "Relatiebeheer"
      },
      {
        "iso": "en",
        "value": "Relation management"
      }
    ]
  },
  {
    "key": "MODULE_NAME_SALESINVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "Verkoopfacturen"
      },
      {
        "iso": "en",
        "value": "Sales invoices"
      }
    ]
  },
  {
    "key": "MODULE_NAME_SALESINVOICEREMINDERS",
    "values": [
      {
        "iso": "nl",
        "value": "Betaalherinneringen"
      },
      {
        "iso": "en",
        "value": "Payment reminders"
      }
    ]
  },
  {
    "key": "MODULE_NAME_SALESORDER",
    "values": [
      {
        "iso": "nl",
        "value": "Bestelbonnen"
      },
      {
        "iso": "en",
        "value": "Sales orders"
      }
    ]
  },
  {
    "key": "MODULE_NAME_SALESQUOTE",
    "values": [
      {
        "iso": "nl",
        "value": "Offertes"
      },
      {
        "iso": "en",
        "value": "Quotations"
      }
    ]
  },
  {
    "key": "MODULE_NAME_STOCK",
    "values": [
      {
        "iso": "nl",
        "value": "Stock beheer"
      },
      {
        "iso": "en",
        "value": "Stock management"
      }
    ]
  },
  {
    "key": "MODULE_NAME_SUBSCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Abonnementen"
      },
      {
        "iso": "en",
        "value": "Susbcriptions"
      }
    ]
  },
  {
    "key": "MODULE_NAME_WORKPERFORMANCE",
    "values": [
      {
        "iso": "nl",
        "value": "Werkprestaties"
      },
      {
        "iso": "en",
        "value": "Workperformances"
      }
    ]
  },
  {
    "key": "MODULE_BETA_NOTIFICATION",
    "values": [
      {
        "iso": "nl",
        "value": "Deze module is momenteel in beta"
      },
      {
        "iso": "en",
        "value": "This module is currently in beta"
      }
    ]
  },
  {
    "key": "MODULE_ALPHA_NOTIFICATION",
    "values": [
      {
        "iso": "nl",
        "value": "Deze module is momenteel in alpha, gebruik op eigen risico"
      },
      {
        "iso": "en",
        "value": "This module is currently in alpha, use at own risc"
      }
    ]
  },
  {
    "key": "UI_PICK_A_PASSWORD",
    "values": [
      {
        "iso": "nl",
        "value": "Kies een wachtwoord"
      },
      {
        "iso": "en",
        "value": "Pick a password"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_STEP_WELCOME",
    "values": [
      {
        "iso": "nl",
        "value": "Welkom"
      },
      {
        "iso": "en",
        "value": "Welcome"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_STEP_COMPANY",
    "values": [
      {
        "iso": "nl",
        "value": "Bedrijf"
      },
      {
        "iso": "en",
        "value": "Company"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_STEP_COMPANY_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "<h3>Bedrijfsgegevens</h3><p>Vervolledig de gegevens voor het toevoegen van jouw bedrijf en commerciële informatie.</p>"
      },
      {
        "iso": "en",
        "value": "<h3>Company Information</h3><p>Complete the details to add your company and commercial information.</p>"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_STEP_ADDRESS",
    "values": [
      {
        "iso": "nl",
        "value": "Adres"
      },
      {
        "iso": "en",
        "value": "Address"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_STEP_ADDRESS_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "<h3>Adresgegevens</h3><p>Vervolledig of verbeter de adresgegevens van de onderneming.</p>"
      },
      {
        "iso": "en",
        "value": "<h3>Address Information</h3><p>Complete or correct the company's address details.</p>"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_STEP_FINANCIAL",
    "values": [
      {
        "iso": "nl",
        "value": "Financieel"
      },
      {
        "iso": "en",
        "value": "Financial"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_STEP_FINANCIAL_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "<h3>Financiële gegevens</h3><p>Vervolledig de financiële informatie die vermeld wordt op documenten.</p>"
      },
      {
        "iso": "en",
        "value": "<h3>Financial Information</h3><p>Complete the financial details as mentioned in the documents.</p>"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_STEP_ROUNDOFF",
    "values": [
      {
        "iso": "nl",
        "value": "Afronden"
      },
      {
        "iso": "en",
        "value": "Round off"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_STEP_ROUNDOFF_DESCRIPTION_SAVING",
    "values": [
      {
        "iso": "nl",
        "value": "<h3>Account wordt aangemaakt...</h3><p>Het account wordt momenteel opgeslagen met de ingevulde gegevens. Verlaat of ververs deze pagina niet, alle gegevens kunnen achteraf in hun respectievelijke instellingen worden aangepast.</p>"
      },
      {
        "iso": "en",
        "value": "<h3>Account is being created...</h3><p>The account is currently being saved with the provided information. Please do not leave or refresh this page, all data can be adjusted later in their respective settings.</p>"
      }
    ]
  },
  {
    "key": "ONBOARDING_SUBSCRIBER_STEP_ROUNDOFF_DESCRIPTION_COMPLETED",
    "values": [
      {
        "iso": "nl",
        "value": "<h3>Account is aangemaakt</h3><p>Het account is aangemaakt. Je kan nu inloggen met de e-mail en wachtwoord combinatie.</p>"
      },
      {
        "iso": "en",
        "value": "<h3>Account Created</h3><p>The account has been successfully created. You can now log in using the email and password combination.</p>"
      }
    ]
  },
  {
    "key": "UI_CHANGE_LOGO",
    "values": [
      {
        "iso": "nl",
        "value": "Logo aanpassen"
      },
      {
        "iso": "en",
        "value": "Change Logo"
      }
    ]
  },
  {
    "key": "UI_EMAIL_EXPORT_ALLOW_MULTIPLE_DOWNLOADS",
    "values": [
      {
        "iso": "nl",
        "value": "Meerdere keren downloaden toestaan voor 7 dagen"
      },
      {
        "iso": "en",
        "value": "Allow multiple downloads for 7 days"
      }
    ]
  },
  {
    "key": "UI_DELIVERED_DOCUMENT",
    "values": [
      {
        "iso": "nl",
        "value": "Geleverd document"
      },
      {
        "iso": "en",
        "value": "Delivered document"
      }
    ]
  },
  {
    "key": "UI_DELIVERED_DOCUMENTS",
    "values": [
      {
        "iso": "nl",
        "value": "Geleverde documenten"
      },
      {
        "iso": "en",
        "value": "Delivered documents"
      }
    ]
  },
  {
    "key": "UI_SOURCE",
    "values": [
      {
        "iso": "nl",
        "value": "Bron"
      },
      {
        "iso": "en",
        "value": "Source"
      }
    ]
  },
  {
    "key": "UI_OCR_COMPLETED",
    "values": [
      {
        "iso": "nl",
        "value": "Uitgevoerd"
      },
      {
        "iso": "en",
        "value": "Completed"
      }
    ]
  },
  {
    "key": "UI_PARTNER_LICENSE_SET_AS_INVOICED",
    "values": [
      {
        "iso": "nl",
        "value": "Registreren als gefactureerd"
      },
      {
        "iso": "en",
        "value": "Registed as invoiced"
      }
    ]
  },
  {
    "key": "UI_NO_PDF_FILE_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Geen PDF bestand gevonden!"
      },
      {
        "iso": "en",
        "value": "No PDF file found!"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_DELIVERY",
    "values": [
      {
        "iso": "nl",
        "value": "Geleverd document"
      },
      {
        "iso": "en",
        "value": "Delivered document"
      }
    ]
  },
  {
    "key": "UI_AUTO_RENEW_LICENSE",
    "values": [
      {
        "iso": "nl",
        "value": "Licentie automatisch verlengen"
      },
      {
        "iso": "en",
        "value": "Automatic renew license"
      }
    ]
  },
  {
    "key": "UI_AUTO_RENEW_LICENSE_PERIODE",
    "values": [
      {
        "iso": "nl",
        "value": "Verlengingsperiode"
      },
      {
        "iso": "en",
        "value": "Prolongueperiod"
      }
    ]
  },
  {
    "key": "UI_CURRENT_CREDIT",
    "values": [
      {
        "iso": "nl",
        "value": "Huidig krediet"
      },
      {
        "iso": "en",
        "value": "Current credit"
      }
    ]
  },
  {
    "key": "UI_DISABLE_AUTOMATIC_RENEW_LICENSE",
    "values": [
      {
        "iso": "nl",
        "value": "Automatisch verlengen uitschakelen"
      },
      {
        "iso": "en",
        "value": "Disable automatic renewal"
      }
    ]
  },
  {
    "key": "UI_ENABLE_AUTOMATIC_RENEW_LICENSE",
    "values": [
      {
        "iso": "nl",
        "value": "Automatisch verlengen inschakelen"
      },
      {
        "iso": "en",
        "value": "Enable automatic renewal"
      }
    ]
  },
  {
    "key": "UI_FREE_EXTRA_USERS",
    "values": [
      {
        "iso": "nl",
        "value": "Gratis extra gebruikers"
      },
      {
        "iso": "en",
        "value": "Free extra users"
      }
    ]
  },
  {
    "key": "UI_FREE_EXTRA_DOCUMENTS",
    "values": [
      {
        "iso": "nl",
        "value": "Gratis extra documenten"
      },
      {
        "iso": "en",
        "value": "Free extra documents"
      }
    ]
  },
  {
    "key": "UI_OTHER_LICENSES",
    "values": [
      {
        "iso": "nl",
        "value": "Andere licenties"
      },
      {
        "iso": "en",
        "value": "Other licenses"
      }
    ]
  },
  {
    "key": "UI_RENEWAL_PERIOD",
    "values": [
      {
        "iso": "nl",
        "value": "Verlengingsperiode"
      },
      {
        "iso": "en",
        "value": "Renewal period"
      }
    ]
  },
  {
    "key": "UI_ENABLE_AUTOMATIC_RENEW_LICESE_MODAL_BODY",
    "values": [
      {
        "iso": "nl",
        "value": "Wanneer 'Automatisch verlengen' is ingeschakeld, activeert het systeem automatisch een nieuwe licentie op basis van de huidige licentie, zonder tussenkomst van de gebruiker. Dit kan zowel op jaarlijks als maandelijks niveau plaatsvinden en kan te allen tijde worden uitgeschakeld via de 'Mijn licentie' pagina."
      },
      {
        "iso": "en",
        "value": "When 'Automatic Renewal' is enabled, the system automatically activates a new license based on the current license without user intervention. This can be done on both an annual and monthly basis and can be disabled at any time through the 'My License' page."
      }
    ]
  },
  {
    "key": "UI_DISABLE_AUTOMATIC_RENEW_LICESE_MODAL_BODY",
    "values": [
      {
        "iso": "nl",
        "value": "Wanneer 'Automatisch verlengen' is uitgeschakeld, verliest men toegang tot het programma bij het verlopen van de actieve licentie. Herstel van toegang is uitsluitend mogelijk door handmatig een nieuwe licentie aan te schaffen. 'Automatisch verlengen' kan op elk moment weer geactiveerd worden via de 'Mijn licentie'-pagina."
      },
      {
        "iso": "en",
        "value": "When 'Automatic Renewal' is disabled, access to the program is denied upon expiration of the active license. Regaining access is only possible by manually purchasing a new license. 'Automatic Renewal' can be reactivated at any time through the 'My License' page."
      }
    ]
  },
  {
    "key": "UI_LICENSE_EXPIRED_MESSAGE_LICENSE_VIEW",
    "values": [
      {
        "iso": "nl",
        "value": "Er is geen actieve licentie gevonden. Gebruik de onderstaande knop om een licentie te kopen."
      },
      {
        "iso": "en",
        "value": "No active license found. Use the button below to purchase a license."
      }
    ]
  },
  {
    "key": "UI_USED_CREDIT",
    "values": [
      {
        "iso": "nl",
        "value": "Gebruikt krediet"
      },
      {
        "iso": "en",
        "value": "Used credit"
      }
    ]
  },
  {
    "key": "UI_OCTOPUS_VATRATE",
    "values": [
      {
        "iso": "nl",
        "value": "Octopus BTW tarieven"
      },
      {
        "iso": "en",
        "value": "Octopus VAT rates"
      }
    ]
  },
  {
    "key": "UI_OCTOPUS_ACCOUNTNUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "Octopus rekeningnummer"
      },
      {
        "iso": "en",
        "value": "Octopus account number"
      }
    ]
  },
  {
    "key": "UI_SELECT_ALL",
    "values": [
      {
        "iso": "nl",
        "value": "Alles selecten"
      },
      {
        "iso": "en",
        "value": "Select All"
      }
    ]
  },
  {
    "key": "UI_DESELECT_ALL",
    "values": [
      {
        "iso": "nl",
        "value": "Alles deselecteren"
      },
      {
        "iso": "en",
        "value": "Deselect all"
      }
    ]
  },
  {
    "key": "UI_CREATE_NEW_VATRATE",
    "values": [
      {
        "iso": "nl",
        "value": "Nieuw BTW tarief"
      },
      {
        "iso": "en",
        "value": "New VAT rate"
      }
    ]
  },
  {
    "key": "UI_IMPORT_OCTOPUS_VATRATES",
    "values": [
      {
        "iso": "nl",
        "value": "Importeer BTW tarieven van Octopus"
      },
      {
        "iso": "en",
        "value": "Import VAT rates from octopus"
      }
    ]
  },
  {
    "key": "UI_IMPORT_OCTOPUS_ACCOUNTNUMBERS",
    "values": [
      {
        "iso": "nl",
        "value": "Importeer rekeningnummers van Octopus"
      },
      {
        "iso": "en",
        "value": "Import account numbers from octopus"
      }
    ]
  },
  {
    "key": "UI_EXTERNAL_SALES_CODE",
    "values": [
      {
        "iso": "nl",
        "value": "Externe verkoop code"
      },
      {
        "iso": "en",
        "value": "Extern sales code"
      }
    ]
  },
  {
    "key": "UI_EXTERNAL_PURCHASE_CODE",
    "values": [
      {
        "iso": "nl",
        "value": "Externe aankoop code"
      },
      {
        "iso": "en",
        "value": "Externe aankoop code"
      }
    ]
  },
  {
    "key": "UI_OCTOPUS_SALES_CODE",
    "values": [
      {
        "iso": "nl",
        "value": "Code octopus (verkoop)"
      },
      {
        "iso": "en",
        "value": "Octopus code (verkoop)"
      }
    ]
  },
  {
    "key": "UI_OCTOPUS_PURCHASE_CODE",
    "values": [
      {
        "iso": "nl",
        "value": "Code octopus (aankoop)"
      },
      {
        "iso": "en",
        "value": "Octopus code (purchase)"
      }
    ]
  },
  {
    "key": "UI_INTEGRATION_VATRATE_UPDATED_DETAILS",
    "values": [
      {
        "iso": "nl",
        "value": "Het BTW tarief is met success geüpdate naar de nieuwe externe codes."
      },
      {
        "iso": "en",
        "value": "The VAT rate has been successfully updated to the new external codes."
      }
    ]
  },
  {
    "key": "UI_ALL_LICENSES_EXPIRED_DASHBOARD_NOTIFICATION",
    "values": [
      {
        "iso": "nl",
        "value": "Functies uitgeschakeld wegens vervallen licenties!"
      },
      {
        "iso": "en",
        "value": "Functions disabled due to expired licenses!"
      }
    ]
  },
  {
    "key": "UI_MANAGE_LICENSES",
    "values": [
      {
        "iso": "nl",
        "value": "Beheer licenties"
      },
      {
        "iso": "en",
        "value": "Manage licenses"
      }
    ]
  },
  {
    "key": "UI_RAW_DATA",
    "values": [
      {
        "iso": "nl",
        "value": "Onverwerkte informatie"
      },
      {
        "iso": "en",
        "value": "Unprocessed information"
      }
    ]
  },
  {
    "key": "UI_RELATION_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Relatie gevonden"
      },
      {
        "iso": "en",
        "value": "Relation found"
      }
    ]
  },
  {
    "key": "UI_RELATION_NOT_FOUND",
    "values": [
      {
        "iso": "nl",
        "value": "Relatie niet gevonden"
      },
      {
        "iso": "en",
        "value": "Relation not found"
      }
    ]
  },
  {
    "key": "UI_COMBINE_SUBLINES",
    "values": [
      {
        "iso": "nl",
        "value": "Combineer documentlijnen"
      },
      {
        "iso": "en",
        "value": "Combine document lines"
      }
    ]
  },
  {
    "key": "UI_DOCUMENT_LINES",
    "values": [
      {
        "iso": "nl",
        "value": "Documentlijnen"
      },
      {
        "iso": "en",
        "value": "Document lines"
      }
    ]
  },
  {
    "key": "UI_OCR_WARNING_DOCUMENTS_LIFESPAN_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "Opgelet"
      },
      {
        "iso": "en",
        "value": "Attention"
      }
    ]
  },
  {
    "key": "UI_OCR_WARNING_DOCUMENTS_LIFESPAN_BODY",
    "values": [
      {
        "iso": "nl",
        "value": "Documenten worden maximaal één maand bewaard. Link ze tijdig aan het correcte document!"
      },
      {
        "iso": "en",
        "value": "Documents are stored for a maximum of one month. Link them to the correct document promptly!"
      }
    ]
  },
  {
    "key": "UI_UNIT_PRICE",
    "values": [
      {
        "iso": "nl",
        "value": "Eenheidsprijs"
      },
      {
        "iso": "en",
        "value": "Unitprice"
      }
    ]
  },
  {
    "key": "UI_CHOSE_A_RELATION",
    "values": [
      {
        "iso": "nl",
        "value": "Kies een relatie"
      },
      {
        "iso": "en",
        "value": "Chose a relation"
      }
    ]
  },
  {
    "key": "UI_CHOSE_A_VATRATE",
    "values": [
      {
        "iso": "nl",
        "value": "Kies een BTW tarief"
      },
      {
        "iso": "en",
        "value": "Chose a VAT rate"
      }
    ]
  },
  {
    "key": "UI_OCR_CONVERT_TO_PURCHASE_INVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "Naar aankoopfactuur"
      },
      {
        "iso": "en",
        "value": "To purchase invoice"
      }
    ]
  },
  {
    "key": "UI_OCR_CONVERT_TO_PURCHASE_CREDITNOTE",
    "values": [
      {
        "iso": "nl",
        "value": "Naar aankoopcreditnota"
      },
      {
        "iso": "en",
        "value": "To purchase creditnote"
      }
    ]
  },
  {
    "key": "UI_OCR_LOADING_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Bestand wordt geladen..."
      },
      {
        "iso": "en",
        "value": "Loading document..."
      }
    ]
  },
  {
    "key": "UI_OCR_PROCESSING_UBL_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Het systeem is de UBL nog aan het verwerken. Probeer straks opnieuw."
      },
      {
        "iso": "en",
        "value": "The system is still processing the UBL. Please try again later."
      }
    ]
  },
  {
    "key": "UI_OCR_PROCESSING_PDF_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Het systeem is de PDF nog aan het verwerken."
      },
      {
        "iso": "en",
        "value": "The system is still processing the PDF."
      }
    ]
  },
  {
    "key": "UI_OCR_FAILED_UBL_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Het systeem kan de UBL niet verwerken. Probeer opnieuw of laad het bestand handmatig op via PDF."
      },
      {
        "iso": "en",
        "value": "The system cannot process the UBL. Please try again or manually upload the file via PDF."
      }
    ]
  },
  {
    "key": "UI_OCR_FAILED_PDF_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "Het systeem kan de PDF niet verwerken. Gebruik de onderstaande velden om de data handmatig aan te vullen."
      },
      {
        "iso": "en",
        "value": "The system cannot process the PDF. Please use the fields below to manually fill in the data."
      }
    ]
  },
  {
    "key": "UI_WILL_CREATE_MISSING_RELATION_OCR_MESSAGE",
    "values": [
      {
        "iso": "nl",
        "value": "De gebruikte relatie '{{0}}' met BTW nummer '{{1}}' werd niet herkent door het systeem. Deze zal aangemaakt worden als een nieuwe relatie."
      },
      {
        "iso": "en",
        "value": "The used relationship '{{0}}' with VAT number '{{1}}' was not recognized by the system. It will be created as a new relationship."
      }
    ]
  },
  {
    "key": "UI_WILL_CREATE_MISSING_RELATION_OCR_TITLE",
    "values": [
      {
        "iso": "nl",
        "value": "'{{0}}' aanmaken"
      },
      {
        "iso": "en",
        "value": "Create '{{0}}'"
      }
    ]
  },
  {
    "key": "UI_MANAGE_LICENSE",
    "values": [
      {
        "iso": "nl",
        "value": "Licentie beheren"
      },
      {
        "iso": "en",
        "value": "Manage license"
      }
    ]
  },
  {
    "key": "UI_SALES_INVOICE_SUBSCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Abonnement"
      },
      {
        "iso": "en",
        "value": "Subscription"
      }
    ]
  },
  {
    "key": "UI_OCTOPUS_VATRATE_IMPORT_FINISHED",
    "values": [
      {
        "iso": "nl",
        "value": "Importeren van BTW tarieven afgerond."
      },
      {
        "iso": "en",
        "value": "Import of VAT rates finished"
      }
    ]
  },
  {
    "key": "UI_OCTOPUS_ACCOUNTNUMBER_IMPORT_FINISHED",
    "values": [
      {
        "iso": "nl",
        "value": "Importeren van rekeningnummers afgerond."
      },
      {
        "iso": "en",
        "value": "Import of accountnumbers finished"
      }
    ]
  },
  {
    "key": "UI_LOGIN_REGISTER",
    "values": [
      {
        "iso": "nl",
        "value": "Geen account? Registreer gratis."
      },
      {
        "iso": "en",
        "value": "No account? Register for free."
      }
    ]
  },
  {
    "key": "UI_UPDATE_VAT_RATE",
    "values": [
      {
        "iso": "nl",
        "value": "BTW wijzigen"
      },
      {
        "iso": "en",
        "value": "Update VAT"
      }
    ]
  },
  {
    "key": "UI_UPDATE_ACCOUNTNUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "Nummer wijzigen"
      },
      {
        "iso": "en",
        "value": "Update number"
      }
    ]
  },
  {
    "key": "UI_GOTO_INVOICE",
    "values": [
      {
        "iso": "nl",
        "value": "Ga naar factuur"
      },
      {
        "iso": "en",
        "value": "Go to invoice"
      }
    ]
  },
  {
    "key": "UI_GOTO_CREDITNOTE",
    "values": [
      {
        "iso": "nl",
        "value": "Ga naar creditnota"
      },
      {
        "iso": "en",
        "value": "Go to creditnote"
      }
    ]
  },
  {
    "key": "UI_SEARCH_AN_ARTICLE",
    "values": [
      {
        "iso": "nl",
        "value": "Zoek een artikel"
      },
      {
        "iso": "en",
        "value": "Search an article"
      }
    ]
  },
  {
    "key": "UI_PER_UNIT",
    "values": [
      {
        "iso": "nl",
        "value": "Per eenheid"
      },
      {
        "iso": "en",
        "value": "Per unit"
      }
    ]
  },
  {
    "key": "UI_COMPACT_SUBLINE_VIEW",
    "values": [
      {
        "iso": "nl",
        "value": "Compact overzicht"
      },
      {
        "iso": "en",
        "value": "Copmact view"
      }
    ]
  },
  {
    "key": "UI_ADVANCED_SUBLINE_VIEW",
    "values": [
      {
        "iso": "nl",
        "value": "Uitgebreid overzicht"
      },
      {
        "iso": "en",
        "value": "Advanced view"
      }
    ]
  },
  {
    "key": "UI_REPORT_ARTICLE_SALES_PURCHASE_RATIO_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Artikelen aankoop/verkoop ratio"
      },
      {
        "iso": "en",
        "value": "Articles purchase/sales ratio"
      }
    ]
  },
  {
    "key": "UI_REPORT_ARTICLE_SALES_PURCHASE_RATIO_DESCRIPTION",
    "values": [
      {
        "iso": "nl",
        "value": "Krijg een overzicht van alle verkocht en aangekochte artikelen en de ratio tussen verkocht bedrag en aangekocht bedrag."
      },
      {
        "iso": "en",
        "value": "Get an overview of all sold and purchased items and the ratio between the sold amount and the purchased amount"
      }
    ]
  },
  {
    "key": "UI_RATIO",
    "values": [
      {
        "iso": "nl",
        "value": "Ratio"
      },
      {
        "iso": "en",
        "value": "Ratio"
      }
    ]
  },
  {
    "key": "PEPPOL_INBOUNDDUCMENT_RECEIVED_BODY",
    "values": [

      {
        "iso": "nl",
        "value": "We hebben een PEPPOL factuur voor jou ontvangen op het {{0}} platform. Bekijk deze in jouw aangeleverde documenten."
      },
      {
        "iso": "en",
        "value": "We have received a PEPPOL invoice for you on the {{0}} platform. Please view it in your provided documents."
      }
    ]
  },
  {
    "key": "PEPPOL_INBOUNDDUCMENT_RECEIVED_SUBJECT",
    "values": [
      {
        "iso": "nl",
        "value": "Factuur ontvangen via PEPPOL"
      },
      {
        "iso": "en",
        "value": "Invoice received via PEPPOL"
      }
    ]
  },
  {
    "key": "UI_RELATION_NAME",
    "values": [
      {
        "iso": "nl",
        "value": "Relatie"
      },
      {
        "iso": "en",
        "value": "Relation"
      }
    ]
  },
  {
    "key": "UI_RELATION_VATNUMBER",
    "values": [
      {
        "iso": "nl",
        "value": "BTW nummer"
      },
      {
        "iso": "en",
        "value": "Vat number"
      }
    ]
  },
  {
    "key": "COMBINEDARTICLE_SUBLINE_PART_OF",
    "values": [
      {
        "iso": "nl",
        "value": "Onderdeel van gecombineerd artikel '{{0}}'"
      },
      {
        "iso": "en",
        "value": "Part of combined article '{{0}}'"
      }
    ]
  },
  {
    "key": "CASHBOOK_SYSTEM_ADD_CORRECTION_FOR_DELETED_TRANSACTION",
    "values": [
      {
        "iso": "nl",
        "value": "Correctie - Transactie gewist op document."
      },
      {
        "iso": "en",
        "value": "Correction - Transaction deleted on document."
      }
    ]
  },
  {
    "key": "CASHBOOK_SYSTEM_ADD_FOR_BY_TRANSACTION",
    "values": [
      {
        "iso": "nl",
        "value": "Kasboek via transactie op document."
      },
      {
        "iso": "en",
        "value": "Cashbook via transaction on document."
      }
    ]
  },
  {
    "key": "CASHBOOK_SYSTEM_ADD_CORRECTION_FOR_UPDATED_TRANSACTION",
    "values": [
      {
        "iso": "nl",
        "value": "Correctie - Transactie gewijzigd op document."
      },
      {
        "iso": "en",
        "value": "Correction - Transaction changed on document."
      }
    ]
  }
]
